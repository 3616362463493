import getApiRoot from "./getApiRoot";
import request from "utils/request";

export function getNotifications(clinicId = null) {
    return request()
        .query({ clinicId })
        .get(`${getApiRoot()}/v1/clinicdashboard/notifications/`)
        .send();
}

export function getNotificationById(notificationId, clinicId = null) {
    return request()
        .query({ clinicId })
        .get(`${getApiRoot()}/v1/clinicdashboard/notifications/${notificationId}/`)
        .send();
}

export function setNotificationState(id, clinicId, options) {
    return request()
        .query({ clinicId })
        .put(`${getApiRoot()}/v1/clinicdashboard/notifications/${id}/`)
        .send({
            userInitials: null,
            formFieldsJson: null,
            ...options,
        });
}

export function getProviderNotificationById(notificationId) {
    return request()
        .get(`${getApiRoot()}/v1/providerdashboard/notifications/${notificationId}/`)
        .send();
}

export function setProviderNotificationState(id, providerId, options) {
    return request()
        .put(`${getApiRoot()}/v1/providerdashboard/notifications/${id}/`)
        .send({
            formFieldsJson: null,
            userInitials: null,
            ...options
        });
}

export function getAllNotifications() {
    return request()
        .get(`${getApiRoot()}/v1/services/notifications/partnerprograms/`)
        .send();
}

export function createNotification(notification) {
    return request()
        .post(`${getApiRoot()}/v1/services/notifications/partnerprogram/create/`)
        .send(notification);
}

export function editNotification(notification) {
    return request()
        .put(`${getApiRoot()}/v1/services/notifications/partnerprogram/edit/`)
        .send(notification);
}

export function notifyGreenline(notification) {
    return request()
        .post(`${getApiRoot()}/v1/services/notifications/notifygreenline/`)
        .send(notification);
}
