import React, { useState } from "react";
import PropTypes from "prop-types";
import CheckboxInput from "components/common/CheckboxInput";
import NotificationButtons from "components/notifications/elements/NotificationButtons";
import UserInitials from "components/notifications/elements/UserInitials";
import { PENDING, DECLINED, ENROLLED, STATUS_COLOR_CLASS } from "constants/Notifications";

export default function ZoetisOfferForm(props) {
    const [formData, setFormData] = useState({optIn: null});
    const allRequired = formData.optIn !== null && formData.initials;
    const isEnrolled = props.enrollmentState === ENROLLED;
    const isDeclined = props.enrollmentState === DECLINED;
    const isPending = props.enrollmentState === PENDING;
    const enrolledOrDeclined = isEnrolled || isDeclined;

    const handleFormChange = ({name, value}) => {
        if ((name === 'optInYes' || name === 'optInNo') && !value) {
            setFormData({...formData, optIn: null});
        } else if (name === 'optInYes') {
            setFormData({...formData, optIn: true});
        } else if (name === 'optInNo') {
            setFormData({...formData, optIn: false});
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleDecline = () => {
        props.onDecline();
    };

    const handleDismiss = () => {
        props.onDismiss(formData.initials, formData);
    }

    const renderInitials = () => {
        if (isPending && props.notificationDetails.requiresInitials) {
            return (
                <div className="flex margin-top-md">
                    <div className="flex-1">
                        <UserInitials
                            onChange={handleFormChange}
                            value={formData.initials}
                        />
                    </div>
                    <div className="flex-1" />
                </div>
            );
        }
    }

    const renderButtons = () => {
        if (isPending) {
            return (
                <>
                    <hr className="margin-top-sm" />
                    <div className="margin-top-md">
                        <NotificationButtons
                            notificationDetails={props.notificationDetails}
                            onDecline={handleDecline}
                            onDismiss={handleDismiss}
                            enrollDisabled={!allRequired}
                            isPreview={props.isPreview}
                        />
                    </div>
                </>
            );
        }
    }

    const getStatusColor = (status) => {
        return STATUS_COLOR_CLASS[status] || "";
    }

    return (
        <div>
            {props.notificationDetails.showCurrentStatus && (
                <div className={`${getStatusColor(props.enrollmentState)} margin-bottom-md text-center text-lg`}>- {props.notificationDetails.currentState} -</div>
            )}
            <div dangerouslySetInnerHTML={{ __html: props.notificationDetails.longDescription }} />
            <>
                <div className='margin-top-md'>
                    <div className='flex-centered'>
                        <CheckboxInput
                            name='optInYes'
                            checked={formData.optIn || (enrolledOrDeclined && props.notificationDetails.formFieldsJson?.optIn)}
                            onChange={handleFormChange}
                            required
                            disabled={enrolledOrDeclined}
                            label='Yes'
                        />
                    </div>
                    <div className='flex-centered'>
                        <CheckboxInput
                            name='optInNo'
                            checked={formData.optIn === false || (enrolledOrDeclined && !props.notificationDetails.formFieldsJson?.optIn)}
                            onChange={handleFormChange}
                            required
                            disabled={enrolledOrDeclined}
                            label='No'
                        />
                    </div>
                </div>
                <div className="margin-top-md">
                    <p className="text-center">
                        Once you have selected the above and would like to participate in these Zoetis offers, please select the <strong>“Live chat”</strong> button to be connected to the Greenline Support Team for code set up. 
                        If you have any questions or need assistance, please contact Greenline Support at <a href="tel:18666089038">+1(866)608-9038</a>, or by email at <a href="mailto:support@greenlinepet.com" rel="noopener noreferrer" target="_blank">support@greenlinepet.com</a>.
                    </p>
                </div>
                {renderInitials()}
                {renderButtons()}
            </>
        </div>
    );
}

ZoetisOfferForm.propTypes = {
    notificationDetails: PropTypes.object,
    enrollmentState: PropTypes.string,
    onEnroll: PropTypes.func.isRequired,
    onDecline: PropTypes.func.isRequired,
    onDismiss: PropTypes.func,
    isPreview: PropTypes.bool
};
