import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./NotificationTile.scss";
import {DISMISSED, MESSAGE, OFFER, SUCCESS, WARN} from "constants/Notifications";
import Button from "components/common/Button";
import GreenlineLogo from "components/common/branding/greenline-logo-circle.png";

const ICON_NAMES = {
    [SUCCESS]: "far fa-check-circle",
    [WARN]: "far fa-exclamation-triangle",
    [OFFER]: "far fa-check-circle",
}

const BUTTON_STYLES = {
    [SUCCESS]: "success",
    [WARN]: "danger",
    [OFFER]: "success",
    [MESSAGE]: "dark",
    [DISMISSED]: "gray",
}

export default function NotificationTile(props) {

    const buttonStyle = props.dismissed ? BUTTON_STYLES[DISMISSED] : BUTTON_STYLES[props.type];

    return (
        <div
            className={classnames(styles.root, styles[props.type], {
                [styles.dismissed]: props.dismissed,
            })}
            onClick={() => props.onButtonClick(props.id)}
        >

            <div className={styles.icon}>
                {props.type === MESSAGE ?
                    <img src={GreenlineLogo} alt="Greenline Logo" />
                :
                    <i className={ICON_NAMES[props.type]} />
                }
            </div>
            <div className={styles.content}>
                <h4>{props.label}</h4>
                <p>{props.description}</p>
            </div>
            <div className={styles.button}>
                <Button
                    small
                    type={buttonStyle}
                    reverseHover={props.dismissed}
                >
                    {props.buttonLabel}
                </Button>
            </div>
        </div>
    );
}

NotificationTile.propTypes = {
    type: PropTypes.string,
    label: PropTypes.string,
    buttonLabel: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string,
    onButtonClick: PropTypes.func,
    dismissed: PropTypes.bool
};
