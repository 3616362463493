import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import * as ClinicActions from "actions/ClinicActions";
import AccessDenied from "components/common/AccessDenied";
import ClinicSettingsForm from "components/clinic/widgets/ClinicSettingsForm";
import Modal from "components/common/Modal";
import * as ClinicData from "utils/ClinicData";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

export function ClinicSettingsButton(props) {
    const [showEditModal, setShowEditModal] = useState(false);
    const hasClinic = !!props.clinicInfo;
    const handleOpenClinicSettings = () => {
        if (hasClinic) {
            setShowEditModal(true);
        }
    };

    const handleSubmit = (data) => {
        if(props.canEditClinicInfo) {
            props.updateClinicSettings(data);
        }
        setShowEditModal(false);
    };

    let button;
    if (props.collapsed) {
        button = (
            <i
                data-testid="settings_icon"
                className="fas fa-cogs"
                onClick={handleOpenClinicSettings}
            />
        );
    } else {
        button = (
            <button
                data-testid="settings_button"
                className={classnames("btn btn-link btn-sm btn-color-gray", props.className)}
                onClick={handleOpenClinicSettings}
            >
                Settings
            </button>
        );
    }

    return (
        <>
            {button}
            <Modal
                data-testid="clinic_settings_modal"
                show={showEditModal && hasClinic}
                onClose={() => setShowEditModal(false)}
                mediumSmall
            >
                <h2 className="text-center">Edit Clinic</h2>
                {!props.canViewClinicInfo ? (
                    <AccessDenied/>
                ) : (
                    <ClinicSettingsForm
                        onSubmit={handleSubmit}
                        onCancel={() => setShowEditModal(false)}
                        clinicId={props.clinicId}
                    />
                )}
            </Modal>
        </>
    )
}

ClinicSettingsButton.propTypes = {
    clinicInfo: PropTypes.object,
    updateClinicSettings: PropTypes.func,
    className: PropTypes.string,
    collapsed: PropTypes.bool,
    clinicId: PropTypes.number,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canViewClinicInfo = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_INFO, userProfile);
        const canEditClinicInfo = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_INFO, userProfile);
        return {
            canViewClinicInfo,
            canEditClinicInfo,
            clinicInfo: ClinicData.getClinicInfo(state, ownProps.clinicId),
        }
    },
    (dispatch) => ({
        updateClinicSettings: (clinicSettings) => dispatch(ClinicActions.updateClinicSettings(clinicSettings)),
    })
)(ClinicSettingsButton);
