import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import styles from "./GL_PetPlanTracking.scss";
import * as GreenlineWellnessActions from "actions/GreenlineWellnessActions";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import PlanStatusFilterDropdown from "components/wellness/widgets/PlanStatusFilterDropdown";
import SortableDataTable from "components/common/SortableDataTable";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import Tooltip from "components/common/Tooltip";
import GL_PetPlanEditFormContainer from "components/greenlineWellness/widgets/GL_PetPlanTracking/GL_PetPlanEditFormContainer";
import PcpPetPlanEditFormContainer from "components/wellness/widgets/PcpPetPlanEditFormContainer";
import NewPlanEnrollmentBtn from 'components/greenlineWellness/widgets/WellnessEnrollmentModal/NewPlanEnrollmentBtn';
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as PlanStatusIds from "constants/PlanStatusIds";
import { GREENLINE_WELLNESS, PREMIER_PET_CARE_PLAN } from "constants/ProviderIds";
import * as UserPermissions from "constants/UserPermissions";
import UpdatePaymentForm from "../PlanTrackingModal/UpdatePaymentForm";
import logger from "utils/logger";
import WellnessProvidersFilterDropdown from "components/wellness/widgets/WellnessProvidersFilterDropdown";
import * as ClinicData from "utils/ClinicData";
import * as ProviderId from "constants/ProviderIds";

function GL_PetPlanTracking(props)  {
    const [statusFilter, setStatusFilter] = useState(null);
    const [providerFilter, setProviderFilter] = useState(null);
    const [dataList, setDataList] = useState({});
    const [selectedPetPlanToEdit, setSelectedPetPlanToEdit] = useState(null)
    const [updatePaymentMethod, setUpdatePaymentMethod] = useState(null)

    useEffect(() => {
        if (props.clinicHasGreenlineWellness) {
          setProviderFilter(ProviderId.GREENLINE_WELLNESS);
        } else {
          setProviderFilter(null);
        }
      }, [props.clinicHasGreenlineWellness]);

    const handleEdit = (e, row) => {
        e.stopPropagation();
        e.preventDefault();
        if (props.canEditClinicWellnessPetPlans) {
            setSelectedPetPlanToEdit(row);
        }
    }

    const handleUpdatePayment = (row) => {
        if (props.canEditClinicWellnessPetPlans) {
            setUpdatePaymentMethod(row);
        }
    }

    const COLUMNS = [
        {
            name: 'Client',
            selector: 'name',
            key: "name",
            sortable: true,
            searchable: true,
            format: row => <div>{row.name} <span>({row.petOwnerId})</span></div>,
        }, {
            name: 'Patient Name',
            selector: 'patient',
            key: "patient",
            sortable: true,
            searchable: true,
            format: row => <div>{row.patient} <span>({row.petDisplayId})</span></div>,
        }, {
            name: 'Plan',
            selector: 'planName',
            key: "planName",
            sortable: true,
        }, {
            name: 'Plan Start Date',
            selector: 'planStartDate',
            key: "planStartDate",
            sortable: true,
            format: row => <div>{moment(row.planStartDate).format("MM/DD/YYYY")}</div>,
        }, {
            name: 'Plan Duration',
            selector: 'planDuration',
            key: "planDuration",
            sortable: true,
            format: row => <div>{moment(row.planStartDate).to(row.planEndDate, true)}</div>,
        }, {
            name: 'Last Payment',
            selector: 'lastPayment',
            key: "lastPayment",
            sortable: true,
            sortValue: row => row.lastPayment ? moment(row.lastPayment) : null,
            format: row => (
                <div className="no-wrap">
                    {moment(row.lastPayment).format('MM/DD/YYYY')} {(row.paymentStatusCode === "Payment Failed" || row.paymentStatusCode === "FailedPayment") && (
                        <i className="fa text-danger fa-exclamation-triangle margin-left-sm"/>
                    )}
                </div>
            ),
        }, {
            name: 'Plan Status',
            selector: 'statusName',
            key: "statusName",
            sortable: true,
            format: row => {
                if (row.ppgIsCancelled) {
                    return "Cancelled";
                } else if (!row.statusName || row.statusName === "Unknown") {
                    return(
                        <Tooltip position="bottom" tip="Patient could not be matched">
                            <div>
                                {row.statusName || "Unknown"}
                            </div>
                        </Tooltip>
                    )
                } else if (row.statusName === 'FailedPayment') {
                    return(
                        <div>{'Failed Payment'}</div>
                    )
                } else {
                    return(
                        <div>{row.statusName}</div>
                    )
                }
            }
        }, {
            name: '',
            selector: 'edit',
            key: "edit",
            format: row => (row.isEditable && props.canEditClinicWellnessPetPlans) ? (
                <Button
                    small
                    iconOnly
                    disabled={!props.canEditClinicWellnessPetPlans}
                    onClick={e => handleEdit(e, row)}
                >
                    <i className="fa fa-pencil text-sm"/>
                </Button>
            ) : "",
        },
    ];

    const loadPetTracking = (clinicId, status=null, providerId=null) => {
        if(props.canViewClinicWellnessPetPlans) {
            props.getWellnessPlans(clinicId, status, providerId);
        }
    }

    useEffect(() => {
        loadPetTracking(props.clinicId, statusFilter, providerFilter);
    }, [props.clinicId, statusFilter, providerFilter]);

    logger.log(props.searchResults, "SEARCH");
    logger.log(props.plans, "PLANS");

    useEffect(() => {
        if(props.searchResults) {
            setDataList(props.searchResults);

        } else if(props.plans) {
            setDataList(props.plans);
        }
    }, [props.plans, props.searchResults]);

    const selectWellnessPlan = (planData) => {
        if (props.canEditClinicWellnessPetPlans && (planData.paymentStatusCode === "Payment Failed" || planData.paymentStatusCode === "FailedPayment")) {
            handleUpdatePayment(planData);
            props.selectWellnessPlan(planData.id);
        } else {
            props.selectWellnessPlan(planData.id);
        }
    }
    const handleStatusFilterChanged = ({value}) => {
        setStatusFilter(value)
    }
    const handleProviderFilterChanged = ({ value }) => {
        setProviderFilter(value)
    }

    const handleClosePetPlanEdit = () => {
        setSelectedPetPlanToEdit(null);
    }

    const getConditionalRowProps = (row) => {
        if (row.status === 2) {
            return {
                style: {
                    backgroundColor: 'rgba(201, 0, 0, 0.15)',
                    color: 'rgb(201, 0, 0)',
                    borderColor: 'rgb(201, 0, 0)',
                }
            };
        } else if (row.status === 0) {
            return {
                style: {
                    color: '#A9A9A9',
                }
            };
        } else {
            return {};
        }
    };

    const getEditForm = () => {
        if (!props.canEditClinicWellnessPetPlans) {
            return (
                <AccessDenied/>
            )
        } else if(selectedPetPlanToEdit?.wellnessProviderId === GREENLINE_WELLNESS) {
            return (
                <GL_PetPlanEditFormContainer
                    clinicId={props.clinicId}
                    plan={selectedPetPlanToEdit}
                    onClose={handleClosePetPlanEdit}
                    canViewClinicWellnessPetPlans={props.canViewClinicWellnessPetPlans}
                    canEditClinicWellnessPetPlans={props.canEditClinicWellnessPetPlans}
                />
            );
        } else if ( selectedPetPlanToEdit?.wellnessProviderId === PREMIER_PET_CARE_PLAN) {
            return (
                <PcpPetPlanEditFormContainer
                    clinicId={props.clinicId}
                    plan={selectedPetPlanToEdit}
                    onClose={handleClosePetPlanEdit}
                />
            );
        } else return null;
    };

    return (
        <div className={styles.root}>
            <div className={styles.mainSection}>
                <div className={styles.section}>
                    <div className={styles.sectionHeader}>
                        <h2>Pet Plan Tracking</h2>
                        <div className={styles.headerWrapper} >
                            <WellnessProvidersFilterDropdown
                                clinicHasGreenlineWellness={props.clinicHasGreenlineWellness}
                                name="providerId"
                                placeholder="All Providers"
                                value={providerFilter}
                                onChange={handleProviderFilterChanged}
                                small
                                wide
                            />
                            <PlanStatusFilterDropdown
                                name="statusFilter"
                                value={statusFilter}
                                options={PlanStatusIds.createPlanOptionsObject([PlanStatusIds.ACTIVE, PlanStatusIds.CANCELLED])}
                                onChange={handleStatusFilterChanged}
                            />
                            {props.canViewClinicWellnessPlans && props.canGLWellness && (<NewPlanEnrollmentBtn clinicId={props.clinicId} />)}
                        </div>
                    </div>
                    {!props.plans ? (
                        <SpinnerTakeover show/>
                    ) : !Object.keys(props.plans).length ? (
                        <div className={styles.noWellnessVisits}>
                            <span>No Pet Plans to Show Yet!</span>
                        </div>
                    ) : !Object.keys(dataList).length ? (
                        <div className={styles.noWellnessVisits}>
                            <span>No Pet Plans to show! Try Changing your Filters.</span>
                        </div>
                    ) : (
                        <SortableDataTable
                            columns={COLUMNS}
                            rawData={dataList}
                            onClick={selectWellnessPlan}
                            conditionalRowProps={getConditionalRowProps}
                            resultsPerPage={15}
                            search={props.search}
                            allowSearch
                            hideSearchBar
                        />
                    )}
                </div>
            </div>
            <Modal
                show={!!selectedPetPlanToEdit}
                modalTitle="Edit Pet Plan"
                small
                onClose={handleClosePetPlanEdit}
            >
                {getEditForm()}
            </Modal>
            <Modal
                show={!!updatePaymentMethod}
                onClose={() => setUpdatePaymentMethod(false)}
                modalTitle="Update Payment"
                small
                style={{ zIndex: "1002" }}
            >
                {!!updatePaymentMethod ? (
                    <UpdatePaymentForm
                        clinicId={props.clinicId}
                        selectedPlan={updatePaymentMethod}
                        onAfterSave={() => setUpdatePaymentMethod(false)}
                    />
                ) : null }
            </Modal>
        </div>
    );
}

GL_PetPlanTracking.defaultProps = {
    search: "",
    clinicId: PropTypes.number.isRequired,
    clinicHasGreenlineWellness: false,
};

GL_PetPlanTracking.propTypes = {
    search: PropTypes.string,
    clinicId: PropTypes.number.isRequired,
    canGLWellness: PropTypes.bool,
    clinicHasGreenlineWellness: PropTypes.bool,
};

export default connect(
    (state, props) => {
        const userProfile = state.user.userProfile;
        const canViewClinicWellnessPetPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PET_PLANS, userProfile);
        const canEditClinicWellnessPetPlans = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_WELLNESS_PET_PLANS, userProfile);
        const canViewClinicWellnessPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);
        return {
            plans: state.wellness.plans,
            searchResults: state.wellness.searchResults,
            selectedId: state.wellness.selectedId,
            search: state.entities.genericSearch,
            clinicHasGreenlineWellness: ClinicData.clinicHasGreenlineWellness(state, props.clinicId),
            // Permissions
            canViewClinicWellnessPetPlans,
            canEditClinicWellnessPetPlans,
            canViewClinicWellnessPlans,
        }
    },
    (dispatch) => ({
        getWellnessPlans: (clinicId, status, providerId) => dispatch(GreenlineWellnessActions.getWellnessPlans(clinicId, status, providerId)),
        selectWellnessPlan: (petId) => dispatch(GreenlineWellnessActions.selectWellnessPlan(petId)),
        updatePetPlan: (clinicId, petId, patientPlanEnrollmentId, planPatientGreenlineId) => dispatch(GreenlineWellnessActions.updatePetPlan(clinicId, petId, patientPlanEnrollmentId, planPatientGreenlineId)),
    }),
)(GL_PetPlanTracking);
