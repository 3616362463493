// Statuses sent to the API
export const CANCEL = "Cancel"; // DO NOT CHANGE: Spelling from database / API response
export const DECLINE = "Decline";
export const ENROLL = "Enroll";

// Statuses received from the API
export const CANCELLED = "Canceled";
export const DECLINED = "Declined";
export const ELIGIBLE = "Eligible";
export const ENROLLED = "Enrolled";
export const INELIGIBLE = "Ineligible";


export const SIGNATURE_PROGRAM_STATUSES = [DECLINED, ENROLLED, CANCELLED];
