// Application State
export const REQUEST_ERROR = "REQUEST_ERROR";
export const APP_ERROR_CLEARED = "APP_ERROR_CLEARED";
// export const ROUTE_CHANGED = "ROUTE_CHANGED";
export const APP_WORKING_START = "APP_WORKING_START";
export const APP_WORKING_END = "APP_WORKING_END";
// export const SET_QUERY_PARAMS = "SET_QUERY_PARAMS";

//Search
export const SET_FORCE_SEARCH = "SET_FORCE_SEARCH";
export const CLEAR_ALL_SEARCHES = "CLEAR_ALL_SEARCHES";

// User
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const CURRENT_USER_UNKNOWN = "CURRENT_USER_UNKNOWN";
export const CURRENT_USER_LOADED = "CURRENT_USER_LOADED";
export const CURRENT_USER_NODE_ID = "CURRENT_USER_NODE_ID";
export const CURRENT_USER_UPDATING = "CURRENT_USER_UPDATING";
export const CURRENT_USER_UPDATED = "CURRENT_USER_UPDATED";
export const LOG_OUT_USER = "LOG_OUT_USER";
export const USER_RESET_REQUESTED = "USER_RESET_REQUESTED";
export const USER_RESET_COMPLETE = "USER_RESET_COMPLETE";
export const USER_VERIFY_COMPLETE = "USER_VERIFY_COMPLETE";
export const USER_NODE_LOADED = "USER_NODE_LOADED";
export const PASSWORD_RESET_CLICKED = "PASSWORD_RESET_CLICKED";

// Providers
export const PROVIDER_LIST_LOADED = "PROVIDER_LIST_LOADED";
export const PROVIDER_LOADED = "PROVIDER_LOADED";
export const PROVIDER_UPDATED = "PROVIDER_UPDATED";
export const PROVIDER_GAMES_LOADING = "PROVIDER_GAMES_LOADING";
export const PROVIDER_GAMES_LOADED = "PROVIDER_GAMES_LOADED";
export const PROVIDER_CLINICS_PROGRAM_ENROLLMENT_LOADED = "PROVIDER_CLINICS_PROGRAM_ENROLLMENT_LOADED";
export const PROVIDER_CLINICS_PROGRAM_ENROLLMENT_LOADING = "PROVIDER_CLINICS_PROGRAM_ENROLLMENT_LOADING";
export const PROVIDER_GAME_TEMPLATES_LOADING = "PROVIDER_GAME_TEMPLATES_LOADING";
export const PROVIDER_GAME_TEMPLATES_LOADED = "PROVIDER_GAME_TEMPLATES_LOADED";
export const PROVIDER_PREVIOUS_GAMES_LOADING = "PROVIDER_PREVIOUS_GAMES_LOADING";
export const PROVIDER_PREVIOUS_GAMES_LOADED = "PROVIDER_PREVIOUS_GAMES_LOADED";
export const PROVIDER_BI_GAMES_CLINICS_LOADING = "PROVIDER_BI_GAMES_CLINICS_LOADING";
export const PROVIDER_BI_GAMES_CLINICS_LOADED = "PROVIDER_BI_GAMES_CLINICS_LOADED";
export const PROVIDER_CLINIC_ALIAS_UPDATED = "PROVIDER_CLINIC_ALIAS_UPDATED";
export const PROVIDER_CLINIC_STATS_LOADING = "PROVIDER_CLINIC_STATS_LOADING";
export const PROVIDER_CLINIC_STATS_LOADED = "PROVIDER_CLINIC_STATS_LOADED";
export const PROVIDER_PRODUCT_BRANDS_LOADING = "PROVIDER_PRODUCT_BRANDS_LOADING";
export const PROVIDER_PRODUCT_BRANDS_LOADED = "PROVIDER_PRODUCT_BRANDS_LOADED";
export const PROVIDER_CLEAR_ALL_GAMES = "PROVIDER_CLEAR_ALL_GAMES";

//snapshot
export const SNAPSHOT_LOADING = "SNAPSHOT_LOADING";
export const SNAPSHOT_LOADED = "SNAPSHOT_LOADED";
export const TWO_SNAPSHOTS_LOADING = "TWO_SNAPSHOTS_LOADING";
export const TWO_SNAPSHOTS_LOADED = "TWO_SNAPSHOTS_LOADED";
export const TWO_SNAPSHOTS_LOAD_ERROR = "TWO_SNAPSHOTS_LOAD_ERROR";

//PROVIDER PLANS
export const PROVIDER_PLANS_LOADED = "PROVIDER_PLANS_LOADED";
export const PROVIDER_PLAN_UPDATED = "PROVIDER_PLAN_UPDATED";

// Role Management
export const ROLES_LOADING = "ROLES_LOADING";
export const ROLES_LOADED= "ROLES_LOADED";
export const ROLE_CREATED= "ROLE_CREATED";

// User Management
export const USER_CREATED = "USER_CREATED";
export const USERS_SHOW_CREATE_FORM = "USERS_SHOW_CREATE_FORM";
export const USERS_HIDE_CREATE_FORM = "USERS_HIDE_CREATE_FORM";
export const ADMIN_CREATED = "ADMIN_CREATED";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const USERS_LOADED = "USERS_LOADED";
export const USER_UPDATING = "USER_UPDATING";
export const USER_UPDATED = "USER_UPDATED";
export const USER_LOCATION_CHANGED = "USER_LOCATION_CHANGED";
export const USER_ROLES_LOADED = "USER_ROLES_LOADED";
export const IMPERSONATE_USER = "IMPERSONATE_USER";
export const REVERT_TO_SELF = "REVERT_TO_SELF";

// Recently Impersonated User Management
export const RECENTLY_IMPERSONATED_USERS_LOADED = "RECENTLY_IMPERSONATED_USERS_LOADED";
export const RECENTLY_IMPERSONATED_USERS_UPDATED = "RECENTLY_IMPERSONATED_USERS_UPDATED";
export const RECENTLY_IMPERSONATED_USER_DISMISSED = "RECENTLY_IMPERSONATED_USER_DISMISSED";


// Voucher Code
export const VOUCHER_CODE_VERIFIED = "VOUCHER_CODE_VERIFIED";
export const VOUCHER_CODE_SUBMITTED = "VOUCHER_CODE_SUBMITTED";
export const VOUCHER_CODE_REJECTED = "VOUCHER_CODE_REJECTED";
export const VOUCHER_CODE_CLEARED = "VOUCHER_CODE_CLEARED";
export const PROGRAM_ADDED_TO_CLINIC = "PROGRAM_ADDED_TO_CLINIC";

// Keywords
export const KEYWORDS_LOADED = "KEYWORDS_LOADED";

// Coupons
export const COUPONS_LOADING = "COUPONS_LOADING";
export const COUPONS_LOADED = "COUPONS_LOADED";
export const UNPROCESSED_COUPONS_LOADED = "UNPROCESSED_COUPONS_LOADED";
export const COUPONS_PROCESSED = "COUPONS_PROCESSED";
export const COUPONS_DECLINED = "COUPONS_DECLINED";
export const COUPON_AUDIT_LOADED = "COUPON_AUDIT_LOADED";
export const COUPON_CHANGE_HISTORY_LOADING = "COUPON_CHANGE_HISTORY_LOADING";
export const COUPON_CHANGE_HISTORY_LOADED = "COUPON_CHANGE_HISTORY_LOADED";
export const STRAY_COUPONS_CLEARED = "STRAY_COUPONS_CLEARED";
export const STRAY_COUPONS_LOADED = "STRAY_COUPONS_LOADED";
export const STRAY_COUPON_STATUSES_LOADED = "STRAY_COUPON_STATUSES_LOADED";
export const STRAY_COUPONS_RESOLVED = "STRAY_COUPONS_RESOLVED";
export const STRAY_COUPONS_BATCH_RESOLVED = "STRAY_COUPONS_BATCH_RESOLVED";
export const STRAY_COUPONS_MARK_HIDDEN = "STRAY_COUPONS_MARK_HIDDEN";
export const STRAY_COUPONS_BULK_MARK_HIDDEN = "STRAY_COUPONS_BULK_MARK_HIDDEN"
export const STRAY_COUPONS_MARK_UNHIDDEN = "STRAY_COUPONS_MARK_UNHIDDEN"
export const STRAY_COUPON_MARK_PEND = "STRAY_COUPON_MARK_PENDED"
export const INELIGIBLE_MARKED_ELIGIBLE = "INELIGIBLE_MARKED_ELIGIBLE";

// Coupon Offer History
export const COUPON_OFFER_HISTORY_LOADED = "COUPON_OFFER_HISTORY_LOADED";

// Offers
export const OFFER_OPT_IN = "OFFER_OPT_IN";
export const OFFER_OPT_OUT = "OFFER_OPT_OUT";
export const OFFER_SETTINGS_SAVED = "OFFERS_SETTINGS_SAVED";

export const OFFERS_LOADED = "OFFERS_LOADED";
export const CLEAR_OFFERS = "CLEAR_OFFERS";
export const COUPON_SEARCH_FILTERED = "COUPON_SEARCH_FILTERED";
export const COUPON_SEARCH_CLEARED = "COUPON_SEARCH_CLEARED";
export const UNPROCESSED_COUPON_DETAILS_LOADED = "UNPROCESSED_COUPON_DETAILS_LOADED";
export const COUPON_DETAILS_LOADED = "COUPON_DETAILS_LOADED";
export const COUPON_UPDATED = "COUPON_UPDATED";
export const UNPROCESSED_COUPON_UPDATED = "UNPROCESSED_COUPON_UPDATED";
export const COUPON_DOWNLOADED = "COUPON_DOWNLOADED";
export const PROGRAMS_LOADED = "PROGRAMS_LOADED";
export const PROGRAM_WALK_THROUGH_LOADED = "PROGRAM_WALK_THROUGH_LOADED";
export const USER_SEARCH_FILTERED = "USER_SEARCH_FILTERED";
export const USER_SEARCH_CLEARED = "USER_SEARCH_CLEARED";

// Coupons for reprocessing
export const REPROCESSING_JOBS_LOADED = "REPROCESSING_JOBS_LOADED";
export const REPROCESSING_JOBS_SEARCH_FILTERED = "REPROCESSING_JOBS_SEARCH_FILTERED";
export const REPROCESSING_JOBS_SEARCH_CLEARED = "REPROCESSING_JOBS_SEARCH_CLEARED";
export const COUPON_PRE_AUDIT_LOADED = "COUPON_PRE_AUDIT_LOADED";
export const COUPON_PRE_AUDIT_APPROVED = "COUPON_PRE_AUDIT_APPROVED";
export const COUPON_PRE_AUDIT_DECLINED = "COUPON_PRE_AUDIT_DECLINED";

// Clinics
export const CLINIC_CREATED = "CLINIC_CREATED";
export const CLINIC_UPDATED = "CLINIC_UPDATED";
export const CLINIC_DETAILS_LOADED = "CLINIC_DETAILS_LOADED";
export const START_ADMIN_CLINIC_DETAILS_LOAD = "START_ADMIN_CLINIC_DETAILS_LOAD";
export const PROVIDER_CLINIC_MAP_DETAILS_LOADED = "PROVIDER_CLINIC_MAP_DETAILS_LOADED";
export const ADMIN_CLINIC_MAP_DETAILS_LOADED = "ADMIN_CLINIC_MAP_DETAILS_LOADED";
export const CLINIC_DETAILS_UPDATED = "CLINIC_DETAILS_UPDATED";
export const CLINIC_DELETED = "CLINIC_DELETED";
export const ALL_CLINICS_LOADED = "ALL_CLINICS_LOADED";
export const CLINICS_LOADED = "CLINICS_LOADED";
export const CLINICS_LOADING = "CLINICS_LOADING";
export const CLINICS_TABLE_IDS_SET = "CLINICS_TABLE_IDS_SET";
export const CLINICS_TABLE_IDS_CLEARED = "CLINICS_TABLE_IDS_CLEARED";
export const CLINIC_DASHBOARD_STATS_LOADING = "CLINIC_DASHBOARD_LOADING";
export const CLINIC_DASHBOARD_STATS_LOADED = "CLINIC_DASHBOARD_STATS_LOADED";
export const CLINIC_DASHBOARD_FULL_LOADED = "CLINIC_DASHBOARD_FULL_LOADED";
export const CLINIC_DASHBOARD_TILES_LOADED = "CLINIC_DASHBOARD_TILES_LOADED";
export const CLINIC_PROGRAMS_DASHBOARD_LOADED = "CLINIC_PROGRAMS_DASHBOARD_LOADED";
export const CLINIC_WELLNESS_DASHBOARD_TILES_LOADED = "CLINIC_WELLNESS_DASHBOARD_TILES_LOADED";
export const CLINIC_DASHBOARD_TILE_ADDED = "CLINIC_DASHBOARD_TILE_ADDED";
export const CLINIC_DASHBOARD_TILE_REMOVED = "CLINIC_DASHBOARD_TILE_REMOVED";
export const CLINIC_DASHBOARD_TILE_DATA = "CLINIC_DASHBOARD_TILE_DATA";
export const CLINIC_REPS_LOADED = "CLINIC_REPS_LOADED";
export const CLINIC_HOSPITALS_LOADING = "CLINIC_HOSPITALS_LOADING";
export const CLINIC_HOSPITALS_LOADED = "CLINIC_HOSPITALS_LOADED";
export const CLINIC_HOSPITAL_ASSIGNED = "CLINIC_HOSPITAL_ASSIGNED";
export const CLINIC_PROVIDER_LOCATIONS_LOADED = "CLINIC_PROVIDER_LOCATIONS_LOADED";
export const CLINIC_PROVIDER_LOCATION_UPDATED = "CLINIC_PROVIDER_LOCATION_UPDATED";
export const CLINIC_DVMS_LOADING = "CLINIC_DVMS_LOADING";
export const CLINIC_DVMS_LOADED = "CLINIC_DVMS_LOADED";
export const CLINIC_GO_GREEN_REQUIREMENTS_LOADED = "CLINIC_GO_GREEN_REQUIREMENTS_LOADED";
export const CLINIC_GO_GREEN_UPDATED = "CLINIC_GO_GREEN_UPDATED";
export const CLINIC_PRICE_COMPARISONS_LOADED = "CLINIC_PRICE_COMPARISONS_LOADED";
export const CLINIC_SUPPORT_FILES_LOADED = "CLINIC_SUPPORT_FILES_LOADED";
export const ACCOUNT_HOLDS_LOADED = "ACCOUNT_HOLDS_LOADED";
export const ACCOUNT_HOLD_CREATED = "ACCOUNT_HOLD_CREATED";
export const ACCOUNT_HOLD_RELEASED = "ACCOUNT_HOLD_RELEASED";
export const INSTALL_CODE_GENERATED = "INSTALL_CODE_GENERATED";
export const CLINIC_VETCHECK_STATUS_LOADED = "CLINIC_VETCHECK_STATUS_LOADED";
export const CLINIC_ADMIN_PASSWORD_RESET = "CLINIC_ADMIN_PASSWORD_RESET";

export const CLINIC_SEARCH_RESULTS_LOADED = "CLINIC_SEARCH_RESULTS_LOADED";
export const CLINIC_SEARCH_CLEARED = "CLINIC_SEARCH_CLEARED";

export const BRAND_FAVORITES_LOADED = "BRAND_FAVORITES_LOADED";
export const BRAND_FAVORITE_ADDED = "BRAND_FAVORITE_ADDED";
export const BRAND_FAVORITE_REMOVED = "BRAND_FAVORITE_REMOVED";

//Clinic Redemption Summary
export const CLINIC_REDEMPTION_SUMMARY_LOADED = "CLINIC_REDEMPTION_SUMMARY_LOADED";

// Custom Clinic View
export const CLINIC_VIEWS_LOADED = "CLINIC_VIEWS_LOADED";
export const CLINIC_VIEW_CREATED = "CLINIC_VIEW_CREATED";
export const CLINIC_VIEW_UPDATED = "CLINIC_VIEW_UPDATED";
export const CLINIC_VIEW_DELETED = "CLINIC_VIEW_DELETED";

// Recently Viewed Clinic Management
export const RECENTLY_VIEWED_CLINICS_LOADED = "RECENTLY_VIEWED_CLINICS_LOADED";
export const RECENTLY_VIEWED_CLINICS_UPDATED = "RECENTLY_VIEWED_CLINICS_UPDATED";
export const RECENTLY_VIEWED_CLINIC_DISMISSED = "RECENTLY_VIEWED_CLINIC_DISMISSED";


// Clinic Users
export const CLINIC_USERS_LOADED = "CLINIC_USERS_LOADED";
export const CLINIC_USER_UPDATED = "CLINIC_USER_UPDATED";
export const CLINIC_USER_CREATED = "CLINIC_USER_CREATED";


// Wellness
export const WELLNESS_PLANS_LOADED = "WELLNESS_PLANS_LOADED";
export const WELLNESS_SEARCH_LOADING = "WELLNESS_SEARCH_LOADING";
export const WELLNESS_SEARCH_RESULTS_LOADED = "WELLNESS_SEARCH_RESULTS_LOADED";
export const WELLNESS_SEARCH_CLEARED = "WELLNESS_SEARCH_CLEARED";
export const WELLNESS_VISITS_LOADING = "WELLNESS_VISITS_LOADING";
export const WELLNESS_VISITS_LOADED = "WELLNESS_VISITS_LOADED";
export const WELLNESS_VISIT_UPDATING = "WELLNESS_VISIT_UPDATING";
export const WELLNESS_VISIT_UPDATED = "WELLNESS_VISIT_UPDATED";
export const WELLNESS_VISIT_VOIDED = "WELLNESS_VISIT_VOIDED";
export const WELLNESS_AUDIT_LOADED = "WELLNESS_AUDIT_LOADED";
export const WELLNESS_PLAN_DETAILS_LOADED = "WELLNESS_PLAN_DETAILS_LOADED";
export const WELLNESS_PLAN_SELECTED = "WELLNESS_PLAN_SELECTED";
export const WELLNESS_CLEAR_SELECTED_PLAN = "WELLNESS_CLEAR_SELECTED_PLAN";
export const WELLNESS_PREMIER_PET_PLAN_UPDATED = "WELLNESS_PREMIER_PET_PLAN_UPDATED";
export const WELLNESS_HISTORY_SEARCH_FILTERED = "WELLNESS_HISTORY_SEARCH_FILTERED";
export const WELLNESS_HISTORY_SEARCH_CLEARED = "WELLNESS_HISTORY_SEARCH_CLEARED";
export const WELLNESS_HISTORY_DOWNLOADED = "WELLNESS_HISTORY_DOWNLOADED";
export const WELLNESS_DVM_COMMISSIONS_LOADED = "WELLNESS_DVM_COMMISSIONS_LOADED";
export const WELLNESS_DVM_COMMISSION_HISTORY_LOADED = "WELLNESS_DVM_COMMISSION_HISTORY_LOADED";
export const WELLNESS_DVM_COMMISSION_HISTORIES_UPDATED = "WELLNESS_DVM_COMMISSION_HISTORIES_UPDATED";
export const WELLNESS_DVM_COMMISSION_UPDATED = "WELLNESS_DVM_COMMISSION_UPDATED";
export const WELLNESS_DVM_COMMISSION_ADDED = "WELLNESS_DVM_COMMISSION_ADDED";
export const WELLNESS_DVM_COMMISSION_DELETED = "WELLNESS_DVM_COMMISSION_DELETED";
export const CLINIC_WELLNESS_PLANS_LOADED = "CLINIC_WELLNESS_PLANS_LOADED";
export const CLINIC_WELLNESS_PLANS_LOADING = "CLINIC_WELLNESS_PLANS_LOADING";
export const CLINIC_WELLNESS_PLAN_HOUSE_ACCOUNT_UPDATED = "CLINIC_WELLNESS_PLAN_HOUSE_ACCOUNT_UPDATED";
export const CLINIC_WELLNESS_PLAN_INSTANCES_LOADED = "CLINIC_WELLNESS_PLAN_INSTANCES_LOADED";
export const CLINIC_WELLNESS_PLAN_INSTANCES_LOADING = "CLINIC_WELLNESS_PLAN_INSTANCES_LOADING";
export const CLINIC_WELLNESS_PLAN_INSTANCE_ADDED = "CLINIC_WELLNESS_PLAN_INSTANCE_ADDED";


// GREENLINE Wellness
export const GLN_WELLNESS_PLANS_LOADED = "GLN_WELLNESS_PLANS_LOADED";
export const GLN_WELLNESS_SEARCH_LOADING = "GLN_WELLNESS_SEARCH_LOADING";
export const GLN_WELLNESS_SEARCH_RESULTS_LOADED = "GLN_WELLNESS_SEARCH_RESULTS_LOADED";
export const GLN_WELLNESS_SEARCH_CLEARED = "GLN_WELLNESS_SEARCH_CLEARED";
export const GLN_WELLNESS_VISITS_LOADING = "GLN_WELLNESS_VISITS_LOADING";
export const GLN_WELLNESS_VISITS_LOADED = "GLN_WELLNESS_VISITS_LOADED";
export const GLN_WELLNESS_VISIT_UPDATING = "GLN_WELLNESS_VISIT_UPDATING";
export const GLN_WELLNESS_VISIT_UPDATED = "GLN_WELLNESS_VISIT_UPDATED";
export const GLN_WELLNESS_VISIT_VOIDED = "GLN_WELLNESS_VISIT_VOIDED";
export const GLN_WELLNESS_AUDIT_LOADED = "GLN_WELLNESS_AUDIT_LOADED";
export const GLN_WELLNESS_PLAN_DETAILS_LOADED = "GLN_WELLNESS_PLAN_DETAILS_LOADED";
export const GLN_WELLNESS_PLAN_SELECTED = "GLN_WELLNESS_PLAN_SELECTED";
export const GLN_WELLNESS_CLEAR_SELECTED_PLAN = "GLN_WELLNESS_CLEAR_SELECTED_PLAN";
export const GLN_WELLNESS_PLAN_UPDATED = "GLN_WELLNESS_PREMIER_PET_PLAN_UPDATED";
export const GLN_WELLNESS_HISTORY_SEARCH_FILTERED = "GLN_WELLNESS_HISTORY_SEARCH_FILTERED";
export const GLN_WELLNESS_HISTORY_SEARCH_CLEARED = "GLN_WELLNESS_HISTORY_SEARCH_CLEARED";
export const GLN_WELLNESS_HISTORY_DOWNLOADED = "GLN_WELLNESS_HISTORY_DOWNLOADED";
export const GLN_WELLNESS_DVM_COMMISSIONS_LOADED = "GLN_WELLNESS_DVM_COMMISSIONS_LOADED";
export const GLN_WELLNESS_DVM_COMMISSION_HISTORY_LOADED = "GLN_WELLNESS_DVM_COMMISSION_HISTORY_LOADED";
export const GLN_WELLNESS_DVM_COMMISSION_HISTORIES_UPDATED = "GLN_WELLNESS_DVM_COMMISSION_HISTORIES_UPDATED";
export const GLN_WELLNESS_DVM_COMMISSION_UPDATED = "GLN_WELLNESS_DVM_COMMISSION_UPDATED";
export const GLN_WELLNESS_DVM_COMMISSION_ADDED = "GLN_WELLNESS_DVM_COMMISSION_ADDED";
export const GLN_WELLNESS_DVM_COMMISSION_DELETED = "GLN_WELLNESS_DVM_COMMISSION_DELETED";
export const GLN_CLINIC_WELLNESS_PLANS_LOADED = "GLN_CLINIC_WELLNESS_PLANS_LOADED";
export const GLN_CLINIC_WELLNESS_PLANS_LOADING = "GLN_CLINIC_WELLNESS_PLANS_LOADING";



// Notifications
export const NOTIFICATIONS_LOADED = "NOTIFICATIONS_LOADED";
export const NOTIFICATION_DISMISSED = "NOTIFICATION_DISMISSED";
export const NOTIFICATION_STATE_WAS_SET = "NOTIFICATION_STATE_WAS_SET";

export const ALL_NOTIFICATIONS_LOADED = "ALL_NOTIFICATIONS_LOADED";
export const NOTIFICATION_CREATED = "NOTIFICATION_CREATED";
export const NOTIFICATION_UPDATED = "NOTIFICATION_UPDATED";

// Games
export const CLINIC_GAMES_LOADED = "CLINIC_GAMES_LOADED";
export const LOADING_CLINIC_GAMES = "LOADING_CLINIC_GAMES";

//Game Invites
export const GAME_INVITES_LOADED = "GAME_INVITES_LOADED";
export const GAME_INVITE_UPDATED = "GAME_INVITE_UPDATED";

// Tags
export const TAGS_LOADED = "TAGS_LOADED";
export const TAG_CREATED = "TAG_CREATED";
export const CLINIC_TAG_ADDED = "CLINIC_TAG_ADDED"; // TODO: this may not ever be called
export const CLINIC_TAGS_UPDATED = "CLINIC_TAGS_UPDATED";

// Pharmacy Mappings
export const PRODUCTS_LOADED = "PRODUCTS_LOADED";

export const FOOD_PRODUCTS_LOADED = "FOOD_PRODUCTS_LOADED";
export const FOOD_MAPPINGS_LOADED = "FOOD_MAPPINGS_LOADED";
export const FOOD_MAPPING_CREATED = "FOOD_MAPPING_CREATED";
export const FOOD_MAPPING_UPDATED = "FOOD_MAPPING_UPDATED";
export const FOOD_MAPPING_DELETED = "FOOD_MAPPING_DELETED";
export const FOOD_MAPPING_CREATED_AND_DELETED = "FOOD_MAPPING_CREATED_AND_DELETED";

export const PHARMACY_PRODUCTS_LOADED = "PHARMACY_PRODUCTS_LOADED";
export const PHARMACY_MAPPINGS_LOADED = "PHARMACY_MAPPINGS_LOADED";
export const PHARMACY_MAPPING_CREATED = "PHARMACY_MAPPING_CREATED";
export const PHARMACY_MAPPING_UPDATED = "PHARMACY_MAPPING_UPDATED";
export const PHARMACY_MAPPING_DELETED = "PHARMACY_MAPPING_DELETED";
export const PHARMACY_MAPPING_CREATED_AND_DELETED = "PHARMACY_MAPPING_CREATED_AND_DELETED";

export const VACCINES_LOADED = "VACCINES_LOADED";
export const VACCINE_MAPPINGS_LOADED = "VACCINE_MAPPINGS_LOADED";
export const VACCINE_MAPPING_CREATED = "VACCINE_MAPPING_CREATED";
export const VACCINE_MAPPING_UPDATED = "VACCINE_MAPPING_UPDATED";
export const VACCINE_MAPPING_DELETED = "VACCINE_MAPPING_DELETED";

export const SERVICES_LOADED = "SERVICES_LOADED";
export const SERVICE_MAPPINGS_LOADED = "SERVICE_MAPPINGS_LOADED";
export const SERVICE_MAPPING_CREATED = "SERVICE_MAPPING_CREATED";
export const SERVICE_MAPPING_UPDATED = "SERVICE_MAPPING_UPDATED";
export const SERVICE_MAPPING_DELETED = "SERVICE_MAPPING_DELETED";

//For CLINIC VetCheck pages
export const VETCHECK_CONTENT_LOADED = "VETCHECK_CONTENT_LOADED";
export const VETCHECK_SINGLE_CONTENT_LOADED = "VETCHECK_SINGLE_CONTENT_LOADED";

// For ADMIN VetCheck mapping page
export const CLINIC_VETCHECK_CONTENT_LOADED = "CLINIC_VETCHECK_CONTENT_LOADED";
export const CLINIC_VETCHECK_SINGLE_CONTENT_LOADED = "CLINIC_VETCHECK_SINGLE_CONTENT_LOADED";
export const CLINIC_VETCHECK_MAPPINGS_LOADED = "CLINIC_VETCHECK_MAPPINGS_LOADED";
export const CLINIC_VETCHECK_MAPPING_CREATED = "CLINIC_VETCHECK_MAPPING_CREATED";
export const CLINIC_VETCHECK_MAPPING_UPDATED = "CLINIC_VETCHECK_MAPPING_UPDATED";
export const CLINIC_VETCHECK_MAPPING_DELETED = "CLINIC_VETCHECK_MAPPING_DELETED";

export const VETCHECK_AUTOMATIONS_LOADED = "VETCHECK_AUTOMATIONS_LOADED";
export const VETCHECK_AUTOMATION_CREATED = "VETCHECK_AUTOMATION_CREATED";
export const VETCHECK_AUTOMATION_DELETED = "VETCHECK_AUTOMATION_DELETED";

export const SHOW_CHANGE_VETCHECK_PASSWORD = "SHOW_CHANGE_VETCHECK_PASSWORD";

// NEW VETCHECK ACTION TYPES
export const CLINIC_REGISTERED_FOR_VETCHECK = "CLINIC_REGISTERED_FOR_VETCHECK";

export const WELLNESS_LOADED = "WELLNESS_LOADED";
export const WELLNESS_MAPPINGS_LOADING = "WELLNESS_MAPPINGS_LOADING";
export const WELLNESS_MAPPINGS_LOADED = "WELLNESS_MAPPINGS_LOADED";
export const WELLNESS_MAPPING_CREATED = "WELLNESS_MAPPING_CREATED";
export const WELLNESS_MAPPING_UPDATED = "WELLNESS_MAPPING_UPDATED";
export const WELLNESS_MAPPING_DELETED = "WELLNESS_MAPPING_DELETED";

export const BRANDS_LOADED = "BRANDS_LOADED";
export const MAPPING_ADDED = "MAPPING_ADDED";
export const MAPPING_DELETED = "MAPPING_DELETED";

// PIMS
export const PIMS_LOADING = "PIMS_LOADING";
export const PIMS_LOADED = "PIMS_LOADED";
export const PIMS_CONFIGS_LOADED = "PIMS_CONFIG_LOADED";
export const PIMS_USERS_LOADED = "PIMS_USERS_LOADED";
export const PIMS_USERS_UPDATED = "PIMS_USERS_UPDATED";
export const PIMS_USERS_UPDATING = "PIMS_USERS_UPDATING";

export const SOFTWARE_VENDORS_LOADING = "SOFTWARE_VENDORS_LOADING";
export const SOFTWARE_VENDORS_LOADED = "SOFTWARE_VENDORS_LOADED";

export const CLINIC_PIMS_CONFIG_LOADED = "CLINIC_PIMS_CONFIG_LOADED";
export const CLINIC_PIMS_CONFIG_UPDATED = "CLINIC_PIMS_CONFIG_UPDATED";

// TOKEN
export const TOKEN_DETAILS_LOADED = "TOKEN_DETAILS_LOADED";
export const TOKEN_REDEMPTION_UPDATED = "TOKEN_REDEMPTION_UPDATED";
export const REPROCESSING_JOB_CREATED = "REPROCESSING_JOB_CREATED";

//INVOICES
export const INVOICES_SEARCHED = "INVOICES_SEARCHED";
export const CLEAR_INVOICES = "CLEAR_INVOICES";
export const INVOICE_LINE_ITEMS_LOADING = "INVOICE_LINE_ITEMS_LOADING";
export const INVOICE_LINE_ITEMS_LOADED = "INVOICE_LINE_ITEMS_LOADED";

// Product Overrides
export const ALL_CLINIC_PRODUCT_OVERRIDES_LOADED = "ALL_CLINIC_PRODUCT_OVERRIDES_LOADED";
export const PRODUCT_OVERRIDE_LOADED = "PRODUCT_OVERRIDE_LOADED";
export const PRODUCT_OVERRIDE_UPDATED = "PRODUCT_OVERRIDE_UPDATED";
export const PRODUCT_OVERRIDE_UPDATE_REQUESTED = "PRODUCT_OVERRIDE_UPDATE_REQUESTED";

// Generic Admin Tools
export const GENERIC_HIDE_ADD_FORM = "GENERIC_HIDE_ADD_FORM";
export const GENERIC_SHOW_ADD_FORM = "GENERIC_SHOW_ADD_FORM";

// Generic Search Store
export const GENERIC_SEARCHED = "GENERIC_SEARCHED";
export const GENERIC_SEARCH_CLEARED = "GENERIC_SEARCH_CLEARED";
export const CLINIC_MANAGEMENT_SEARCH = "CLINIC_MANAGEMENT_SEARCH";
export const USER_MANAGEMENT_SEARCH = "USER_MANAGEMENT_SEARCH";

//ProductTags
export const CLINIC_PRODUCT_TAGS_LOADING = "CLINIC_PRODUCT_TAGS_LOADING";
export const CLINIC_PRODUCT_TAGS_LOADED = "CLINIC_PRODUCT_TAGS_LOADED";
export const CLINIC_PRODUCT_TAGS_UPDATED = "CLINIC_PRODUCT_TAGS_UPDATED";
export const CLINIC_PRODUCT_TAGS_SEARCHED = "CLINIC_PRODUCT_TAGS_SEARCHED";
export const CLINIC_PRODUCT_TAGS_SEARCH_CLEARED = "CLINIC_PRODUCT_TAGS_SEARCH_CLEARED";
export const CLINIC_PRODUCT_ANALYSIS_LOADED = "CLINIC_PRODUCT_TAGS_UPDATED";

//PLANS
export const PLANS_LOADED = "PLANS_LOADED";

//VOUCHERS
export const VOUCHER_CODES_LOADED = "VOUCHER_CODES_LOADED";
export const VOUCHER_CODE_UPDATED = "VOUCHER_CODE_UPDATED";
export const VOUCHERS_GENERATED = "VOUCHERS_GENERATED";
export const VOUCHER_PLAN_SELECTED = "VOUCHER_PLAN_SELECTED";

//CLINIC TAX RATES
export const CLINIC_TAX_RATES_LOADED = "CLINIC_TAX_RATES_LOADED";
export const CLINIC_TAX_RATES_UPDATED = "CLINIC_TAX_RATES_UPDATED";

//CLINIC PRICING SCHEDULE
export const CLINIC_PRICING_SCHEDULE_LOADED = "CLINIC_PRICING_SCHEDULE_LOADED";
export const CLINIC_PRICING_SCHEDULE_DELETED = "CLINIC_PRICING_SCHEDULE_DELETED";

//PROVIDER REPORTS
export const PROVIDER_REPORTS_LOADED = "PROVIDER_REPORTS_LOADED";

// PROVIDER REDEMPTIONS
export const REDEMPTIONS_LOADED = "REDEMPTIONS_LOADED";

//OFFERS
export const ALL_OFFERS_LOADED = "ALL_OFFERS_LOADED";
export const CUMULATIVE_OFFER_TRACKING_OPTIONS_LOADING = "CUMULATIVE_OFFER_TRACKING_OPTIONS_LOADING"; // For the offer select dropdown
export const CUMULATIVE_OFFER_TRACKING_OPTIONS_LOADED = "CUMULATIVE_OFFER_TRACKING_OPTIONS_LOADED";
export const CUMULATIVE_OFFER_TRACKING_LOADING = "CUMULATIVE_OFFER_TRACKING_LOADING"; // Data for selected offer(s)
export const CUMULATIVE_OFFER_TRACKING_LOADED = "CUMULATIVE_OFFER_TRACKING_LOADED";

//VetCheck Handouts
export const VETCHECK_HANDOUT_SEARCH_FILTERED = "VETCHECK_HANDOUT_SEARCH_FILTERED";
export const VETCHECK_HANDOUT_SEARCH_CLEARED = "VETCHECK_HANDOUT_SEARCH_CLEARED";

//VetCheck Pet Owners
export const VETCHECK_PET_OWNERS_LOADING = "VETCHECK_PET_OWNERS_LOADING";
export const VETCHECK_PET_OWNERS_LOADED = "VETCHECK_PET_OWNERS_LOADED";
export const VETCHECK_PET_OWNERS_LOADED_SEARCH_FILTERED = "VETCHECK_PET_OWNERS_LOADED_SEARCH_FILTERED";
export const VETCHECK_PET_OWNERS_LOADED_SEARCH_CLEARED = "VETCHECK_PET_OWNERS_LOADED_SEARCH_CLEARED";

//Pet Marketing Info
export const PET_MARKETING_INFO_LOADING = "PET_MARKETING_INFO_LOADING";
export const PET_MARKETING_INFO_LOADED = "PET_MARKETING_INFO_LOADED";
export const PET_MARKETING_INFO_UPDATED = "PET_MARKETING_INFO_UPDATED";
export const PET_MARKETING_INFO_FILTER_OUT = "PET_MARKETING_INFO_FILTER_OUT";

// Clinic Programs
export const CLINIC_PROGRAMS_LOADED = "CLINIC_PROGRAMS_LOADED";
export const CLINIC_PROGRAM_DETAILS_LOADED = "CLINIC_PROGRAM_DETAILS_LOADED";
export const UPDATE_CLINIC_PROGRAM = "UPDATE_CLINIC_PROGRAM";
export const CLINIC_PROGRAM_SERVICES_LOADED = "CLINIC_PROGRAM_SERVICES_LOADED";
export const CLINIC_PROGRAM_SERVICE_DETAILS_LOADED = "CLINIC_PROGRAM_SERVICE_DETAILS_LOADED";
export const UPDATE_CLINIC_PROGRAM_SERVICE = "UPDATE_CLINIC_PROGRAM_SERVICE";

//Admin - Price History
export const PRICE_HISTORY_LOADED = "PRICE_HISTORY_LOADED";

//Clinic Report Activation Status
export const CLINIC_REPORTS_LOADING = "CLINIC_REPORTS_LOADING";
export const CLINIC_REPORTS_LOADED = "CLINIC_REPORTS_LOADED";
export const CLINIC_REPORTS_UPDATED = "CLINIC_REPORTS_UPDATED";

// Provider Dashboard
export const PROVIDER_DASHBOARD_LOADING = "PROVIDER_DASHBOARD_LOADING";
export const PROVIDER_DASHBOARD_LOADED = "PROVIDER_DASHBOARD_LOADED";

//Corporate Group Clinic Aggregates
export const PROVIDER_CLINIC_AGGREGATES_LOADED = "PROVIDER_CLINIC_AGGREGATES_LOADED";

// Window in Focus
export const WINDOW_FOCUS_EVENT = "WINDOW_FOCUS_EVENT";
export const WINDOW_BLUR_EVENT = "WINDOW_BLUR_EVENT";

// Health Check
export const HEALTH_CHECK_SUCCESSFUL = "HEALTH_CHECK_SUCCESSFUL";
export const HEALTH_CHECK_UNSUCCESSFUL = "HEALTH_CHECK_UNSUCCESSFUL";

// Provider Hierarchy
export const PROVIDER_HIERARCHY_LOADING = "PROVIDER_HIERARCHY_LOADING";
export const PROVIDER_HIERARCHY_LOADED = "PROVIDER_HIERARCHY_LOADED";
export const PROVIDER_NODE_CLINICS_LOADED = "PROVIDER_NODE_CLINICS_LOADED";

// Node
export const NODE_LOADING = "NODE_LOADING";
export const NODE_LOADED = "NODE_LOADED";
export const NODE_CHILDREN_LOADING = "NODE_CHILDREN_LOADING";
export const NODE_CHILDREN_LOADED = "NODE_CHILDREN_LOADED";

// Stripe
export const CLINIC_SUBSCRIPTIONS_LOADED = "CLINIC_SUBSCRIPTIONS_LOADED";
export const CLINIC_SUBSCRIPTION_CREATED = "CLINIC_SUBSCRIPTION_CREATED";
export const GREENLINE_PRODUCTS_LOADED = "GREENLINE_PRODUCTS_LOADED";
export const PRICE_OPTIONS_LOADED = "PRICE_OPTIONS_LOADED";

// Reassign Game
export const REASSIGNING_GAME = "REASSIGNING_GAME";
export const GAME_REASSIGNED = "GAME_REASSIGNED";

// Came From User Management
export const CAME_FROM_USER_MANAGEMENT = "CAME_FROM_USER_MANAGEMENT";

// Change the Location ID for a Corporate Group Clinic
export const CORPORATE_GROUP_LOCATION_ID_UPDATE = "CORPORATE_GROUP_LOCATION_ID_UPDATE";

export const STORE_DISMISSED_ALERTS = "STORE_DISMISSED_ALERTS";

export const LOOKER_REPORTS_LOADED = "LOOKER_REPORTS_LOADED";
