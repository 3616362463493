import { atomFamily, selectorFamily, useRecoilState } from "recoil";
import produce from "immer";
import { adminUpdateClinicSettings, getClinicById, generateInstallCode, updateClinicTags } from "api/ClinicApi";
import { mapSingleClinicFromAppToServer, mapSingleClinicFromServerToApp } from "data/serverMapping";

const loadClinicDetails = selectorFamily({
    key: "LoadClinicDetails",
    get: (clinicId) => async () => {
        const response = await getClinicById(clinicId);
        return mapSingleClinicFromServerToApp(response.body);
    },
});

export const clinicDetailsState = atomFamily({
    key: "ClinicDetailsState",
    default: loadClinicDetails,
});

export const getClinicTags = selectorFamily({
    key: "GetClinicTags",
    get: clinicId => ({ get }) => {
        const clinicDetails = get(clinicDetailsState(clinicId));
        return clinicDetails.tags;
    },
});

export function useClinicDetailsMutations(clinicId) {
    const [clinicDetails, setClinicDetails] = useRecoilState(clinicDetailsState(clinicId));

    const generateInstallCodeForClinic = async () => {
        const response = await generateInstallCode(clinicId);
        setClinicDetails(produce(clinicDetails, draft => {
            draft.installationAccess = response.body;
        }));
    };

    const updateClinicDetails = async (data) => {
        await adminUpdateClinicSettings(mapSingleClinicFromAppToServer(data));
        const response = await getClinicById(clinicId);
        setClinicDetails(mapSingleClinicFromServerToApp(response.body));
    };

    const updateClinicDetailsTags = async (data) => {
        const response = await updateClinicTags(clinicId, data);
        setClinicDetails(produce(clinicDetails, draft => {
            draft.tags = JSON.parse(response.body);
        }));
    };

    return {
        generateInstallCodeForClinic,
        updateClinicDetails,
        updateClinicDetailsTags,
    };
}