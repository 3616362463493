import React from "react";
import PropTypes from "prop-types";
import "yup-phone-lite";
import moment from "moment";
import LabelValueRow from "components/common/LabelValueRow";
import InstallationCode from "components/admin/elements/InstallationCode";

export default function AgentPanel(props) {
    return (
		<div className="card">
			<h4>Agent</h4>
			<LabelValueRow
				label="Version"
				value={props.clinic.agentVersion || "Not yet installed"}
			/>
			<InstallationCode
                clinicId={props.clinicId}
                value={props.clinic.installationAccess?.accessCode || ""}
                expirationDate={props.clinic.installationAccess?.expirationDate || ""}
            />
			{(props.clinic.agentVersion && props.clinic.agentInstallationDate) && (
				<>
					<LabelValueRow
						label="Installation Date"
						value={props.clinic.agentInstallationDate && moment(props.clinic.agentInstallationDate).format('MM/DD/YYYY, LTS')}
					/>
					<LabelValueRow
						label="Last Product Pull Date"
						value={props.clinic.lastProductPullDate && moment(props.clinic.lastProductPullDate).format('MM/DD/YYYY, LTS')}
					/>
					<LabelValueRow
						label="Sync Time"
						value={props.clinic.approximateCloudSyncTime || "N/A"}
					/>
					<LabelValueRow
						label="Last Ping Date"
						value={props.clinic.lastPingDate && moment(props.clinic.lastPingDate).format('MM/DD/YYYY, LTS')}
					/>
				</>
			)}
		</div>
    );
}

AgentPanel.defaultProps = {
    clinic: {},
};

AgentPanel.propTypes = {
    clinic: PropTypes.object,
	clinicId: PropTypes.number.isRequired,
};
