import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import * as UserActions from "actions/UserActions";
import BuildVersion from "components/layout/elements/BuildVersion";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import WellnessCheck from "components/layout/WellnessCheck";
import { canUserAccessPage } from "utils/permissions/rolesPermissions";
import { parseQueryString, setQueryStringParams } from "utils/urls";
/*
This should be the root container under the <Provider/> level for all
apps. It should handle any init() functions or other startup triggers
so that they can be managed in one place.
 */

function RootContainer(props) {
    const {
        checkForToken,
        children,
        isLoggedIn = false,
        loadCurrentUser,
        userProfile,
    } = props;
    const [selectedPR, setSelectedPR] = useState(null);
    const [versionHash, setVersionHash] = useState("");
    const location = useLocation();
    const parsedQueryString = parseQueryString(location.search);

    useEffect(() => {
        const prNumber = localStorage.getItem("prNumber");
        const versionHash = localStorage.getItem("versionHash");
        if (prNumber) {
            if (!parsedQueryString.pr) {
                const newQs = setQueryStringParams(location.search, {
                    ...parsedQueryString,
                    pr: prNumber,
                });
                window.location.href = `${location.pathname}?${newQs}`;
            }
            setSelectedPR(prNumber || null);
            setVersionHash(versionHash || "");
        }

        if (!isLoggedIn) {
            checkForToken();
            return;
        }

        if (!userProfile) {
            loadCurrentUser();
        }
    }, []);

    if (!isLoggedIn) {
        return <SpinnerTakeover show/>;
    }

    if (!userProfile) {
        return <SpinnerTakeover show/>;
    }

    if (!canUserAccessPage(userProfile, location.pathname)) {
        window.location.href = `${document.location.origin}/no-access`;
        return null;
    }

    return (
        <WellnessCheck>
            {children}
            <BuildVersion prNumber={selectedPR} versionHash={versionHash} />
        </WellnessCheck>
    );
}

RootContainer.propTypes = {
    location: PropTypes.object,
};

export default connect(
    (state) => ({
        userProfile: state.user.userProfile,
        isLoggedIn: state.user.isLoggedIn,
        hasError: state.applicationState.hasError,
        errorMessage: state.applicationState.errorMessage,
    }),
    (dispatch) => ({
        checkForToken: () => dispatch(UserActions.checkForToken()),
        loadCurrentUser: () => dispatch(UserActions.loadCurrentUser()),
    }),
)(RootContainer);
