import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
// import "react-quill/dist/quill.snow.css";
// import ReactQuill from "react-quill";
import styles from "./RichText.scss";
import CKEditorComponent from "./CKEditorComponent";

export default function RichText({
    dataTestId,
    disabled,
    label,
    name,
    onChange,
    required,
    value,
}) {

    const handleOnChange = (html) => {
        // handled in case we need to swap out editors and only use HTML
        onChange({ name, value: html });
    }



    return (
        <div
            data-testid={dataTestId}
            className={classnames(styles.root, {
                [styles.disabled]: disabled,
            })}
        >
            {label &&
                <div
                    data-testid="rich_text_label"
                    className="label"
                >
                    {label}{required && "*"}
                </div>
            }

            <CKEditorComponent
                onChange={handleOnChange}
                initialHtml={value}
            />
        </div>
    );
}

RichText.propTypes = {
    dataTestId: PropTypes.string,
    disabled: PropTypes.bool,
    focusEnd: PropTypes.bool,
    grayBorder: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    noPadding: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.string.isRequired,
};
