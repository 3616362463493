import env from "utils/environment";

export default function getApiRoot() {
    return env.getValue("CORE_API_HOST");
}

export function getIsDemoApi(isDemo) {
    if (isDemo) {
        return "/demo";
    }
    return "";
}
