import React, { useEffect, useState} from "react";
import {connect} from "react-redux";
import styles from "./WellnessLanding.scss";

import ActivityGrid from "components/dashboard/ActivityGrid";
import ClinicBase from "components/layout/ClinicBase";
import NewPlanEnrollmentBtn from 'components/greenlineWellness/widgets/WellnessEnrollmentModal/NewPlanEnrollmentBtn';

import * as GreenlineWellnessActions from "actions/GreenlineWellnessActions";

import {CLINIC_TAGS} from "constants/AdminControls";
import * as SearchTypes from "constants/SearchTypes";
import * as UserPermissions from "constants/UserPermissions";

import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import {clinicHasWellness, getClinicInfo} from "utils/ClinicData";

function WellnessLanding(props)  {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.clinicId) {
            setLoading(true);
            props.getWellnessDashboardTiles(props.clinicId);
        }
    }, [props.clinicId]);

    useEffect(() => {
        if (props.wellnessTiles && loading) {
            setLoading(false);
        }
    }, [props.wellnessTiles]);

    return (
        <ClinicBase
            searchConfig={SearchTypes.WELLNESS_PLANS_PAGE}
            clinicId={props.clinicId}
            adminControls={CLINIC_TAGS}
            docTitle="Wellness Plans"
        >
            <div className={styles.root}>
                {props.canViewClinicWellnessPlans && (
                    <div className={styles.wellnessButtons}>
                        <NewPlanEnrollmentBtn clinicId={props.clinicId} />
                    </div>
                )}
                <div className={styles.wellnessTiles}>
                    <ActivityGrid
                        tiles={props.wellnessTiles}
                    />
                </div>
            </div>
        </ClinicBase>
    );
}

WellnessLanding.propTypes = {};

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;
        const userProfile = state.user.userProfile;
        const canViewWellnessPetPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PET_PLANS, userProfile);
        const canViewClinicWellnessPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);

        return {
            clinicId,
            clinicHasWellness: clinicHasWellness(state, clinicId),
            //FIXME: wellnessTiles is also being stored in state.wellness so it should probably be removed from state.entities
            wellnessTiles: state.entities.wellnessDashboardTiles[clinicId],
            clinicWellnessPlans: state.wellness.clinicWellnessPlans[clinicId],
            // Permissions
            canViewWellnessPetPlans,
            canViewClinicWellnessPlans,
        }
    },
    (dispatch) => ({
        getWellnessDashboardTiles: (clinicId) => dispatch(GreenlineWellnessActions.getWellnessDashboardTiles(clinicId,)),
    })
)(WellnessLanding);
