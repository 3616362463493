

// Clinic Links



// Provider Links
export const PROVIDER_LINKS = {
    DASHBOARD:"dashboard",
    MAP: "map",
    CLINICS: "clinics_overview",
    PROGRAMS: "clinic_programs_overview",
    GAMES: "games",
    DEMO_GAMES: "demo_games",
    TERRITORY_GAMES: "territory_games",
    WELLNESS_PLAN_SETUP: "wellness_plan_setup",
    REPORTS: "reports",
    RESOURCES: "resources",
    PRODUCT_TAGGING: "product_tagging",
};
