import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./OfferGroupAccordion.scss";
import filter from "lodash/filter";
import forEach from "lodash/forEach";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import reject from "lodash/reject";
import * as CouponActions from "actions/CouponActions";
import Accordion from "components/common/Accordion";
import AccordionSection from "components/common/AccordionSection";
import ProgramOfferGroup from "components/coupons/widgets/ProgramOfferGroup";
import logger from "utils/logger";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function OfferGroupAccordion(props) {

    if(!props.offerGroups) {
        return null;
    }

    const handleOptInChanged = (offer, optIn) => {
        logger.log(offer, "OFFER");
        if (props.canOptInCouponLibrary && !props.isReadOnlyView) {
            if (optIn) {
                props.optInForOffer(offer.offerId, props.programId);
            } else {
                props.optOutForOffer(offer.offerId, props.programId);
            }
        }
    }

    const renderOfferSectionHeader = (offerGroup) => {
        const offers = filter(offerGroup?.offers, {isVisibleToClinic: true});
        const claimed = reject(offers, {activationDate: null}).length;
        const total = offers?.length;

        return (
            <div className={styles.headerContent}>
                <div className={styles.headerLeft} dangerouslySetInnerHTML={{ __html: offerGroup.description }} />
                <div className={styles.headerRight}>
                    <span className={styles.optInCount}>{claimed}/{total} opted-in &nbsp;</span>
                </div>
            </div>
        );
    }

    const filterOfferGroups = () => {
        // No need to run the loop if there's no query.
        if (!props.searchQuery) {
            return map(props.offerGroups, offerGroup => offerGroup);
        }

        // Find the offers
        const offerGroups = [];
        forEach(props.offerGroups, offerGroup => {
            // Search filter
            const offers = filter(offerGroup.offers, offer => (offer.isVisibleToClinic && offer.name.toLowerCase().includes(props.searchQuery.toLowerCase())));

            if (offers.length > 0) {
                offerGroups.push({
                    ...offerGroup,
                    offers, // We only want the offers that match the search query
                });
            }
        });

        return offerGroups;
    }

    const offerGroups = filterOfferGroups();

    return(
        <div className={styles.offers}>
            <Accordion
                theme={{
                    root: styles.root,
                    header: styles.header,
                    collapse: styles.collapse,
                    content: styles.collapseContent,
                }}
                multiOpen={true}
                onChange={props.headerClicked}
            >
                {map(orderBy(offerGroups, "sortOrder"), (offerGroup) => (
                    <AccordionSection
                        key={`group_${offerGroup.groupId}`}
                        id={offerGroup.groupId}
                        header={renderOfferSectionHeader(offerGroup)}
                    >
                        <ProgramOfferGroup
                            offers={filter(offerGroup.offers, {isVisibleToClinic: true})}
                            groupId={offerGroup.groupId}
                            selectedId={props.offerId}
                            onClick={props.offerChanged}
                            optInChange={props.canOptInCouponLibrary ? handleOptInChanged : null}
                        />
                    </AccordionSection>
                ))}
            </Accordion>
        </div>
    );
}

OfferGroupAccordion.propTypes = {
    programId: PropTypes.number.isRequired,
    headerClicked: PropTypes.func.isRequired,
    offerChanged: PropTypes.func.isRequired,
    offerId: PropTypes.number,
    searchQuery: PropTypes.string,
    isReadOnlyView: PropTypes.bool
}

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const offerGroups = state.entities.programOffers[ownProps.programId]?.offers || null;
        //Permissions
        const canOptInCouponLibrary = userHasPermission(PermissionTypes.OPT_IN, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);

        return {
            program: state.entities.programs[ownProps.programId],
            offerGroups:  !!offerGroups ? filter(offerGroups, offerGroup => !!filter(offerGroup.offers, {isVisibleToClinic: true})?.length) : null, //Filter the offerGroups that don't have any "isVisibleToClinic" offers
            // Permissions
            canOptInCouponLibrary,
        }
    },
    (dispatch) => ({
        optInForOffer: (offerId, programId) => dispatch(CouponActions.optInForOffer(offerId, programId)),
        optOutForOffer: (offerId, programId) => dispatch(CouponActions.optOutForOffer(offerId, programId))
    }),
)(OfferGroupAccordion);
