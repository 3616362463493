import React, { useState } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import map from "lodash/map";
import set from "lodash/set";
import Button from "components/common/Button";
import EditCorporateId from "components/admin/elements/EditCorporateId";
import LabelValueRow from "components/common/LabelValueRow";
import Modal from "components/common/Modal";
import CorporateGroupDropdown from "components/registration/elements/CorporateGroupDropdown";
import produce from "immer";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import { useRecoilValue } from "recoil";
import { clinicDetailsState, useClinicDetailsMutations } from "state/clinicDetailsState";
import { handleErrorResponse } from "utils/request";


export default function CorporateGroupsPanel({
    clinicId,
    corporateGroups = [],
    providers,
}) {
    const clinic = useRecoilValue(clinicDetailsState(clinicId));
    const { updateClinicDetails } = useClinicDetailsMutations(clinicId);
    const [showEditForm, setShowEditForm] = useState(null);
    const [isBusy, setIsBusy] = useState(false);

    const InfoRow = ({ label, corporateGroup, provider }) => {
        return (
            <div className="flex spaced-content align-center">
                <div className="flex-1">
                    <LabelValueRow
                        label={label}
                        value={corporateGroup?.locationId || (<span className="text-warn">Not Set</span>)}
                    />
                </div>
                <div className="flex-none">
                    <div className="flex-none">
                        <Button
                            iconOnly
                            onClick={() => handleEdit(corporateGroup, provider)}
                            type="primary"
                            text
                        >
                            <i className="fa fa-pencil" />
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

	const handleEdit = (corporateGroup, provider) => {
		setShowEditForm({corporateGroup, provider});
	};

	const handleCloseEditForm = () => {
		setShowEditForm(null);
    };

    const handleCorporateGroupChange = async formData => {
        const update = produce(clinic, draft => {
            set(draft, "corporateMembershipId", formData.value);
        });

        try {
            setIsBusy(true);
            await updateClinicDetails(update);
        } catch (error) {
            console.error(error.message);
            handleErrorResponse("update corporate group settings", error);
        } finally {
            setIsBusy(false);
        }
    };

    return (
        <div className="card">
            <div className="flex spaced-content">
                <h4 className="flex-1">Corporate Groups</h4>
            </div>
            <div className="spaced-content-vertical">
                {map(corporateGroups, corporateGroup => {
                    const provider = providers[corporateGroup.providerId];
                    return provider ? (
                        <InfoRow
                            label={provider.name}
                            corporateGroup={corporateGroup}
                            provider={provider}
                        />
                    ) : (
                        <div>Loading...</div>
                    )
                })}
                <CorporateGroupDropdown
                    label="Reported Clinic Group"
                    name="corporateMembershipId"
                    value={get(clinic, "corporateMembershipId")}
                    onChange={handleCorporateGroupChange}
                />
            </div>
            <Modal
                show={!!showEditForm}
                modalTitle={`Edit ${showEditForm?.provider ? showEditForm.provider.name : ""} Corporate Group ID`}
                onClose={handleCloseEditForm}
                small
            >
                {!!showEditForm && (
                    <EditCorporateId
                        initialData={showEditForm.corporateGroup}
                        clinicId={clinicId}
                        providerId={showEditForm.provider.id}
                        onClose={handleCloseEditForm}
                    />
                )}
            </Modal>
            <SpinnerTakeover show={isBusy} />
        </div>
    );
}

CorporateGroupsPanel.propTypes = {
	clinicId: PropTypes.number.isRequired,
	corporateGroups: PropTypes.arrayOf(PropTypes.shape({})),
	providers: PropTypes.object.isRequired,
};
