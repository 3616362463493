
import React from "react";
import ReactDOM from "react-dom";
import styles from "./Modal.scss";
import classnames from "classnames";
import PropTypes from "prop-types";
import * as ModalTypes from "constants/ModalTypes";
const VETCHECK_LOGO = require("components/common/branding/vetcheck/vetcheck_logo.png");
import Image from "components/common/Image";


class Modal extends React.Component {

    static defaultProps = {
        clickOutsideToClose: true,
        modalType: ModalTypes.OVERLAY,
        small: false,
        hideCloseButton: false,
    };

    constructor(props) {
        super(props);
        this.el = document.createElement("div");
    }

    componentDidMount() {
        document.getElementById("modal-root")?.appendChild(this.el);
    }

    componentWillUnmount() {
        if (document.getElementById("modal-root")?.contains(this.el)) {
            document.getElementById("modal-root")?.removeChild(this.el);
        }
    }

    render() {
        let component = null;

        if (this.props.show) {
            component = (
                <div
                    data-testid={this.props["data-testid"]}
                    style={this.props.style}
                    className={classnames(styles.root, {
                        [styles.small]: this.props.small,
                        [styles.medSmall]: this.props.mediumSmall,
                        [styles.medium]: this.props.medium,
                        [styles.large]: this.props.large,
                        [styles.notificationModal]: this.props.modalType === ModalTypes.NOTIFICATION,
                        [styles.confirmModal]: this.props.modalType === ModalTypes.CONFIRM,
                        [styles.fullscreenModal]: this.props.modalType === ModalTypes.FULLSCREEN,
                        [styles.overlayModal]: this.props.modalType === ModalTypes.OVERLAY,
                        [styles.fixedModal]: this.props.modalType === ModalTypes.FIXED,
                        [styles.warningModal]: this.props.modalStyle === ModalTypes.WARNING,
                        [styles.successModal]: (this.props.modalStyle === ModalTypes.SUCCESS || this.props.modalStyle === ModalTypes.TOTAL_SUCCESS),
                        [styles.greenHeader]: this.props.modalStyle === ModalTypes.GREEN_HEADER,
                        [styles.toolModal]: this.props.modalStyle === ModalTypes.TOOL,
                        [styles.relative]: this.props.relative,
                        [styles.tallModal]: this.props.tall,
                        [styles.noSelect]: !this.props.allowSelect,
                        [styles.flexBody]: this.props.flexBody,
                    })}
                >
                    <div
                        data-testid="modal-backdrop"
                        className={classnames(styles.modalBackdrop, {
                            [styles.modalErrorBackdrop]: this.props.modalStyle === ModalTypes.WARNING,
                            [styles.modalSuccessBackdrop]: this.props.modalStyle === ModalTypes.TOTAL_SUCCESS,
                            [styles.noBackdrop]: this.props.hideBackdrop,
                            [styles.relativeBackdrop]: this.props.relative,
                        })}
                        onClick={this.props.clickOutsideToClose ? this.props.onClose : () => {}}
                    />
                    <div
                        data-testid="modal-body"
                        className={styles.modalBody}
                        style={this.props.style}
                    >
                        {(!!this.props.modalTitle || (this.props.modalStyle === ModalTypes.VET_CHECK)) && (
                            <div className={classnames(styles.modalHeader, {
                                [styles.vetCheckHeader]: this.props.modalStyle === ModalTypes.VET_CHECK
                            })}>
                                {(this.props.modalStyle === ModalTypes.VET_CHECK) && (
                                    <div className="flex flex-centered full-width">
                                        <Image
                                            src={VETCHECK_LOGO}
                                            alt="VetCheck Logo"
                                            style={{maxWidth: "100%", width: "170px"}}
                                        />
                                    </div>
                                )}
                                {!!this.props.icon && (
                                    <div className={styles.icon}>
                                        <i
                                            data-testid="modal-icon"
                                            className={classnames("fa", this.props.icon, {
                                                [`fa-${this.props.iconFontSize}x`]: this.props.iconFontSize,
                                            })}
                                        />
                                    </div>
                                )}
                                {!!this.props.modalTitle && (
                                    <div className={styles.modalTitle}>
                                        <h3 data-testid="modal-title">{this.props.modalTitle}</h3>
                                        {this.props.modalSubTitle && (
                                            <h5 data-testid="modal-subtitle">{this.props.modalSubTitle}</h5>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}

                        <div className={classnames(styles.modalBodyContainer, {
                            [styles.flexRow]: this.props.flexRow,
                        })}>
                            {this.props.children}
                        </div>
                        {!this.props.hideCloseButton &&
                            <span
                                className={styles.closeBtn}
                                onClick={this.props.onClose}
                            >
                                <i
                                    data-testid="modal-close-button"
                                    className="far fa-times-circle"
                                />
                            </span>
                        }
                    </div>
                </div>
            );
        }
        if (!this.props.relative) {
            return ReactDOM.createPortal(
                component,
                this.el,
            );
        } else {
            return component;
        }
    }
}

Modal.propTypes = {
    ["data-testid"]: PropTypes.string,
    clickOutsideToClose: PropTypes.bool,
    modalType: PropTypes.string,
    onClose: PropTypes.func,
    show: PropTypes.bool,
    hideCloseButton: PropTypes.bool,
    small: PropTypes.bool,
    mediumSmall: PropTypes.bool,
    medium: PropTypes.bool,
    large: PropTypes.bool,
    modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    modalSubTitle: PropTypes.string,
    modalStyle: PropTypes.string,
    tall: PropTypes.bool,
    hideBackdrop: PropTypes.bool,
    relative: PropTypes.bool,
    icon: PropTypes.string,
    iconFontSize: PropTypes.number,
    allowSelect: PropTypes.bool,
    flexBody: PropTypes.bool,
    style: PropTypes.any,
    flexRow: PropTypes.bool,
};

export default Modal;
