import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./EnrollmentSignaturesModal.scss";
import ProgramOptInDetailsModal from "components/clinic/elements/ProgramOptInDetailsModal";
import Button from "components/common/Button";
import logger from "utils/logger";

function EnrollmentSignaturesModal({
    clinicId,
    onClose,
    programs,
}) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const lastSignature = useMemo(() => {
        return programs?.length - 1;
    }, [programs]);
    const currentSignature = useMemo(() => {
        if (programs?.length) {
            const currentService = programs[currentIndex];
            return (
                <ProgramOptInDetailsModal
                    key={`Program_service_${clinicId}_${currentService.programId}_${currentService.programServiceID}`}
                    clinicId={clinicId}
                    onClose={onClose}
                    program={currentService}
                    programId={currentService.programServiceID}
                    parentProgram={currentService.programId}
                    readOnly
                    show
                />
            );
        }
    }, [programs, currentIndex]);

    const useControls = useMemo(() => {
        return programs?.length > 1;
    }, [programs]);

    const prevDisabled = useMemo(() => {
        return currentIndex === 0;
    }, [currentIndex]);

    const nextDisabled = useMemo(() => {
        return currentIndex === lastSignature;
    }, [currentIndex, programs, lastSignature]);



    logger.info("EnrollmentSignaturesModal :: EnrollmentSignaturesModal :: programs = ", programs);

    return (
        <div className={styles.root}>
            {useControls && (
                <div className={classnames(styles.prevButton, styles.controlButton)}>
                    <Button
                        icon
                        iconOnly
                        large
                        disabled={prevDisabled}
                        onClick={() => setCurrentIndex(currentIndex-1)}
                    >
                        <i className={"fa fa-arrow-left"} />
                    </Button>
                </div>
            )}
            {currentSignature}
            {useControls && (
                <div className={classnames(styles.nextButton, styles.controlButton)}>
                    <Button
                        icon
                        iconOnly
                        large
                        disabled={nextDisabled}
                        onClick={() => setCurrentIndex(currentIndex+1)}
                    >
                        <i className={"fa fa-arrow-right"} />
                    </Button>
                </div>
            )}
        </div>
  );
}


EnrollmentSignaturesModal.propTypes = {
    clinicId: PropTypes.number,
    onClose: PropTypes.func,
    programs: PropTypes.array,
}

export default EnrollmentSignaturesModal;
