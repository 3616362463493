import React, { useMemo, useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import find from "lodash/find";
import filter from "lodash/filter";
import styles from "./ProviderClinicsProgramEnrollmentPage.scss";
import * as ProviderApi from "api/ProviderApi";
import * as ProviderActions from "actions/ProviderActions";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import ProviderBase from "components/layout/ProviderBase";
import ProviderClinicsProgramEnrollmentTable from "components/provider/widgets/ProviderClinicsProgramEnrollmentTable";
import ResultsPerPageDropdown from "components/coupons/widgets/ResultsPerPageDropdown";
import {getNodeId} from "utils/AdminData";
import * as ClinicAdminLinks from "utils/ClinicAdminLinks";
import * as ClinicProviderLinks from "utils/ClinicProviderLinks";
import getProviderId from "utils/getProviderId";
import {downloadFile} from "utils/request";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import toast from "utils/toast";
import * as UserPermissions from "constants/UserPermissions";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import EnrollmentSignaturesModal from "../../widgets/EnrollmentSignaturesModal";
import { SIGNATURE_PROGRAM_STATUSES } from "constants/ProgramStatuses";

// Provider clinic table and Coupon Redemptions Summary modal
function ProviderClinicsProgramEnrollmentPage({
    canViewCorporateGroupClinic,
    canViewCorpGroupProgramEnrollment,
    canViewProviderInfo,
    clinics,
    getProviderClinicsProgramEnrollment,
    history,
    isAdmin,
    location,
    node,
    nodeId,
    nodeOverview,
    provider,
    providerId,
    userProfile,
}) {
    const [selectedClinicId, setSelectedClinicId] = useState(null);
    const [resultsPerPage, setResultsPerPage] = useState(25);

    useEffect(() => {
        if (userProfile && canViewCorpGroupProgramEnrollment && nodeId) {
            getProviderClinicsProgramEnrollment({ nodeId });
        }
    }, [nodeId]);

    const pageTitle = useMemo(() => {
        if (provider?.name) {
            return `${provider.name} - Clinic Programs Enrollment`;
        }
        return "Clinic Programs Enrollment";
    }, [provider]);

    const clinicEnrolledPrograms = useMemo(() => {
        if (!selectedClinicId) {
            return null;
        }
        const selectedClinic = find(clinics, ["clinicId", selectedClinicId]);
        return filter((selectedClinic?.programs||[]), program => SIGNATURE_PROGRAM_STATUSES.includes(program.enrollmentStatus));

    }, [selectedClinicId]);

    const handleSignatureIconClicked = (row) => {
        setSelectedClinicId(row.clinicId);
    }

    const handleRowClicked = (row) => {
        if (canViewCorporateGroupClinic) {
            if(isAdmin) {
                history.push(ClinicAdminLinks.getLinkLocation("dashboard", row.clinicId));
            } else {
                history.push(ClinicProviderLinks.getLinkLocation("dashboard", nodeId, row.clinicId));
            }
        } else {
            handleSignatureIconClicked(row);
        }
    }
    const handleClose = () => {
        setSelectedClinicId(null);
    }

    const handleDownload = async () => {
        toast.success("Generating Export...");
        const res = await ProviderApi.exportProviderClinicsProgramEnrollment(nodeId, "xlsx");
        downloadFile(res, "xlsx");
    }

    return (
        <ProviderBase
            providerId={providerId}
            location={location}
            pageTitle={pageTitle}
            collapsible
            nodeId={nodeId}
        >
            {!canViewProviderInfo ? (
                <AccessDenied/>
            ) : (
                <>
                    {!clinics && <SpinnerTakeover show/>}
                    <div className={styles.root}>
                        <div className={styles.mainSection}>

                            <div className="flex flex-align-center spaced-content margin-bottom-sm">
                                <div className={"flex-1"}>
                                    <Button
                                        iconOnly
                                        onClick={handleDownload}
                                    >
                                        <i className="fa fa-download"/>
                                    </Button>
                                </div>
                                <div>
                                    <ResultsPerPageDropdown
                                        onChange={({value}) => setResultsPerPage(value)}
                                        value={resultsPerPage}
                                    />
                                </div>

                            </div>
                            <div className="full-width flex">
                                {(clinics) && (
                                    <ProviderClinicsProgramEnrollmentTable
                                        rawData={clinics}
                                        // onRowClick={handleRowClicked}
                                        onSignatureIconClicked={handleSignatureIconClicked}
                                        resultsPerPage={resultsPerPage}
                                        providerId={providerId}
                                        nodeId={nodeId}
                                        isAdmin={isAdmin}
                                    />
                                )}
                            </div>
                        </div>
                        {clinicEnrolledPrograms && (
                            <EnrollmentSignaturesModal
                                clinicId={selectedClinicId}
                                onClose={handleClose}
                                programs={clinicEnrolledPrograms}
                            />
                        )}
                    </div>
                </>
            )}
        </ProviderBase>
    );
}

const connector = connect(
    (state, ownProps) => {
        const params = ownProps?.match?.params || {};
        const userProfile = state.user.userProfile;
        const canViewCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        const canViewProviderInfo = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_INFO, userProfile);
        const providerId = getProviderId(params, userProfile);
        const nodeId = getNodeId(state, ownProps);
        const nodeOverview = state.entities.providerHierarchies?.[providerId]?.[nodeId];

        const canViewCorporateGroupClinic =  userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);
        const canViewCorpGroupProgramEnrollment = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_PROGRAM_ENROLLMENT, userProfile);

        // If User has one of the Wellness Permissions then give them the settings permission
        return {
            nodeId,
            userProfile,
            canViewCouponLibrary,
            canViewProviderInfo,
            providerId,
            nodeOverview,
            provider: state.entities.providers[providerId],
            node: state.entities.nodes[nodeId] || null,
            clinics: state.entities.nodeClinicsProgramEnrollment[nodeId] || null,
            isAdmin: !!(ownProps?.location?.pathname?.includes("/admin") && providerId && nodeId),

            // Permissions
            canViewCorporateGroupClinic,
            canViewCorpGroupProgramEnrollment,
        };
    },
    (dispatch) => ({
        getProviderClinicsProgramEnrollment: (data) => dispatch(ProviderActions.getProviderClinicsProgramEnrollment(data)),
    }),
);


ProviderClinicsProgramEnrollmentPage.propTypes = {}

export default compose(
    withRouter,
    connector
)(ProviderClinicsProgramEnrollmentPage);
