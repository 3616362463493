import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "yup-phone-lite";
import * as Yup from "yup";
import filter from "lodash/filter";
import includes from "lodash/includes";
import map from "lodash/map";
import * as ClinicActions from "actions/ClinicActions";
import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import TextBox from "components/common/TextBox";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import useFormHandlers from "utils/useFormHandlers";
import * as UserPermissions from "constants/UserPermissions";
import * as UserRoles from "constants/UserRoles";

const schema = Yup.object().shape({
    clinicAdminUserId: Yup.number().nullable(true),
    contactEmail: Yup.string().email().required(),
    contactPhone: Yup.string().phone(["US", "CA"]).required(),
});

function ClinicAdminForm(props) {
	const { formData, isValid, handleUpdate, invalidFields } = useFormHandlers(
        props.initialData,
        schema,
    );

	useEffect(() => {
        if (props.canViewUserManagement) {
            props.getClinicUsers(props.clinicId);
        }
    }, [props.canViewUserManagement, props.clinicId]);

	const CLINIC_ADMIN_OPTIONS = map(props.clinicUsers, u => {return {name: `${u.firstName} ${u.lastName} (${u.userName})`, value: u.userId}});

	const handleSubmit = (e) => {
		e.stopPropagation();
		e.preventDefault();
		if (isValid) {
			props.updateClinic(formData);
			props.onClose();
        }
	}

    return (
		<form onSubmit={handleSubmit}>
            {props.canViewUserManagement && (
                <Dropdown
                    label="(Designated) Clinic Admin"
                    placeholder="- Unassigned - "
                    options={CLINIC_ADMIN_OPTIONS}
                    name="clinicAdminUserId"
                    onChange={handleUpdate}
                    value={formData.clinicAdminUserId}
                    hasError={includes(invalidFields, "clinicAdminUserId")}
                />
            )}
            <TextBox
                hasError={includes(invalidFields, "contactEmail")}
                label="Contact Email"
                name="contactEmail"
                onChange={handleUpdate}
                required
                value={formData.contactEmail}
            />
            <TextBox
                hasError={includes(invalidFields, "contactPhone")}
                label="Contact Phone"
                name="contactPhone"
                onChange={handleUpdate}
                required
                value={formData.contactPhone}
            />
			<hr />
			<div className="flex justify-flex-end spaced-content">
				<div>
					<Button
						onClick={props.onClose}
						type="gray"
					>
						Cancel
					</Button>
				</div>
				<div>
					<Button
						disabled={!isValid}
						buttonType="submit"
					>
						Save
					</Button>
				</div>
			</div>
		</form>
    );
}

ClinicAdminForm.defaultProps = {
    initialData: {},
};

ClinicAdminForm.propTypes = {
	clinicId: PropTypes.number.isRequired,
    initialData: PropTypes.object,
	onClose: PropTypes.func.isRequired,
};

export default connect(
    (state) => {
		const userProfile = state.user.userProfile;
		const filteredClinicUsers = filter(state.entities.clinicUsers, user => {
            return (user.role === UserRoles.CLINIC_ADMIN || user.role === UserRoles.CLINIC_OWNER)
        });
        const canViewUserManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.USER_MANAGEMENT, userProfile);

        return {
            canViewUserManagement,
	        clinicUsers: filteredClinicUsers,
        }
    },
    (dispatch) => ({
        getClinicUsers: (clinicId) => dispatch(ClinicActions.getClinicUsers(clinicId)),
	    updateClinic: (clinicUpdate) => dispatch(ClinicActions.adminUpdateClinicSettings(clinicUpdate)),
    })
) (ClinicAdminForm);
