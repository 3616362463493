import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import LabelValueRow from "components/common/LabelValueRow";
import Button from "components/common/Button";
import Tooltip from "components/common/Tooltip";
import Modal from "components/common/Modal";
import PIMSFormContainer from "components/admin/forms/PIMSFormContainer";

export default function PIMSPanel(props) {
	const [showConfigurePIMS, setShowConfigurePIMS] = useState(false);

	if (!props.clinic && props.clinicId) {
		return null;
	}

	const handleConfigure = () => {
        setShowConfigurePIMS(true);
    }

    return (
		<div className="card">
			<div className="flex spaced-content align-center">
				<h4 className="flex-1">PIMS</h4>
				<Tooltip
					position="left"
					tip="Configure PIMS"
				>
					<div>
						<Button
							onClick={handleConfigure}
							iconOnly
							type="primary"
						>
							<i className="fa fa-cogs"/>

						</Button>
					</div>
				</Tooltip>
			</div>
			<LabelValueRow
				label="Software"
				value={props.clinic.pimsSoftware}
			/>
			<LabelValueRow
				label="Server Name"
				value={props.clinic.lastSyncServerName}
			/>
			<LabelValueRow
				label="Last Sync Date"
				value={props.clinic.lastSyncDate && moment(props.clinic.lastSyncDate).format('MM/DD/YYYY, LTS')}
			/>
			<LabelValueRow
				label="Last Product Sync Date"
				value={props.clinic.lastProductPullDate && moment(props.clinic.lastProductPullDate).format('MM/DD/YYYY, LTS')}
			/>
			<LabelValueRow
				label="Last Query Date"
				value={props.clinic.lastQueryDate && moment(props.clinic.lastQueryDate).format('MM/DD/YYYY, LTS')}
			/>
			<Modal
                show={showConfigurePIMS}
                onClose={() => setShowConfigurePIMS(false)}
                mediumSmall
                modalTitle="PIMS Configuration"
            >
                {showConfigurePIMS && (
                    <PIMSFormContainer
                        clinicId={props.clinicId}
                        onClose={() => setShowConfigurePIMS(false)}
                    />
                )}
            </Modal>
		</div>
    );
}

PIMSPanel.defaultProps = {
    clinic: {},
};

PIMSPanel.propTypes = {
    clinic: PropTypes.object,
	clinicId: PropTypes.number.isRequired,
};
