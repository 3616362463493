import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import * as styles from "./ClinicWellnessPlans.scss";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import find from "lodash/find";
import flatMap from "lodash/flatMap";
import forEach from "lodash/forEach";
import keyBy from "lodash/keyBy";
import last from "lodash/last";
import map from "lodash/map";
import merge from "lodash/merge";
import orderBy from "lodash/orderBy";
import reject from "lodash/reject";
import * as ClinicActions from "actions/ClinicActions";
import * as MappingActions from "actions/MappingActions";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import DateInput from "components/common/DateInput";
import Dropdown from "components/common/Dropdown";
import ManageClinicPriceSchedule from "components/admin/forms/ManageClinicPriceSchedule";
import Modal from "components/common/Modal";
import PlanSetupForm from "components/admin/forms/PlanSetupForm";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {getClinicInfo} from "utils/ClinicData";
import getLocalTimezone from "utils/getLocalTimezone";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import { PREMIER_PET_CARE_PLAN} from "constants/ProviderIds";
import * as UserPermissions from "constants/UserPermissions";

function ClinicWellnessPlans(props) {
    const [formData, setFormData] = useState({});
    const [groupName, setGroupName] = useState("Dog");
    const [showManagePriceSchedule, setShowManagePriceSchedule] = useState(false);
    const [selectedClinicPriceScheduleId, setSelectedClinicPriceScheduleId] = useState(null);
    const readOnly = !props.canEditClinicWellnessPlans;
    const TODAY = new moment();

    const defaultPricingSchedule = props.pricingSchedules ? (filter(props.pricingSchedules, schedule => {
        const startDate = moment(schedule.startDate);
        const endDate = moment(schedule.endDate);

        if (TODAY.isBetween(startDate, endDate)) {
            return schedule;
        }
    })[0] || {}) : {};

    const combinedData = cloneDeep(props.clinicWellnessPlans)

    merge(combinedData, formData);

    useEffect(() => {
        if (props.canViewClinicWellnessPlans) {
            props.loadClinicWellnessPlans(props.clinicId, PREMIER_PET_CARE_PLAN);
            props.getPIMs(props.clinicId);

            if (props.canViewClinicWellnessPriceSchedules && !props.pricingSchedules) {
                props.loadPricingSchedule(props.clinicId);
            }
        }
    }, [props.clinicId]);

    const handleChangeGroup = ({value}) => {
        setGroupName(value);
    }

    const handleSave = () => {
        if(props.canEditClinicWellnessPlans) {
            const updatePlans = filter(combinedData.plans, "hasBeenUpdated");
            const lastPlanId = !!updatePlans.length ? (last(updatePlans)?.clinicPlanInstanceId) : 0;
            forEach(updatePlans, plan => {
                const isLast = plan.clinicPlanInstanceId === lastPlanId;
                const newData = {
                    clinicIdentifier: props.clinicId,
                    wellnessStartDate: combinedData.wellnessStartDate,
                    houseAccountClinicDvmId: combinedData.houseAccountClinicDvmId,
                    wellnessClinicPlanInstance: {
                        ...plan,
                        enrollmentMappings: map(reject(plan.enrollmentMappings, "isDeleted"), em => {
                            if (em.isNew) {
                                return {
                                    id: null,
                                    clinicProductId: em.clinicProductId
                                }
                            } else {
                                return {
                                    id: em.id,
                                    clinicProductId: em.clinicProductId
                                }
                            }
                        }),
                        services: map(plan.services, service => {
                            return {
                                ...service,
                                enrollmentMappings: map(reject(service.enrollmentMappings, "isDeleted"), em => {
                                    if (em.isNew) {
                                        return {
                                            id: null,
                                            clinicProductId: em.clinicProductId
                                        }
                                    } else {
                                        return {
                                            id: em.id,
                                            clinicProductId: em.clinicProductId
                                        }
                                    }
                                }),
                            }
                        })
                    }
                }
                props.updateClinicWellnessPlans(props.clinicId, newData, isLast);
            });
        }
        setFormData({
            ...formData,
            plans: {}
        });
    }

    const handleChange = ({name, value, plan=null}) => {
        let newData;
        if (!!plan) {
            newData = {
                ...formData,
                plans: {
                    ...formData.plans,
                    [plan.clinicPlanInstanceId]: {
                        ...plan,
                        hasBeenUpdated: true
                    },
                }
            }
        } else {
            newData = {
                ...formData,
                [name]: value,
            };
        }
        setFormData(newData);
    }

    const handleChangeHouseAccount = ({ name, value, plan=null }) => {
        handleChange({name, value, plan});
        if(props.canEditClinicWellnessPlans) {
            props.updateHouseAccount(props.clinicId, value, PREMIER_PET_CARE_PLAN);
        }
    }

    const handleCopy = (clinicPlanInstanceId, clinicPriceScheduleId) => {
        const planToCopy = combinedData.plans[clinicPlanInstanceId];
        if (!!planToCopy) {
            //Get the plans that need to be updated
            const plansToUpdate = filter(combinedData.plans, p => {
                return (
                    p.clinicPriceScheduleId === clinicPriceScheduleId &&
                    p.groupName === planToCopy.groupName &&
                    !(p.clinicPlanInstanceId === clinicPlanInstanceId)
                );
            });

            const newData = {
                ...formData,
                plans: {
                    ...formData.plans,
                    ...keyBy(map(plansToUpdate, plan => {
                        return {
                            ...plan,
                            hasBeenUpdated: true,
                            services: {
                                ...plan.services,
                                ...keyBy(map(plan.services, service => {
                                    const serviceCopy = find(planToCopy.services, {serviceTypeId: service.serviceTypeId});
                                    if(serviceCopy) {
                                        return {
                                            ...service,
                                            isUnlimited: serviceCopy.isUnlimited,
                                            monthlyAddOnCostToClient: serviceCopy.monthlyAddOnCostToClient,
                                            maxQuantityAllowedInTerm: serviceCopy.maxQuantityAllowedInTerm,
                                        }
                                    } else {
                                        return service;
                                    }
                                }), "clinicPlanServiceTypeId"),
                            }
                        }
                    }), "clinicPlanInstanceId")
                }
            }
            setFormData(newData);
        }
    }

    const GROUP_OPTIONS = combinedData.planGroups ? map(combinedData.planGroups, (group) => {
        return {name: group.name, value: group.name};
    }) : [];
    
    const PRICE_SCHEDULE_OPTIONS = props.pricingSchedules ? map( props.pricingSchedules, (price) => {
        return {name: `${price.name} (${getLocalTimezone(price.startDate)} - ${getLocalTimezone(price.endDate)}) ${defaultPricingSchedule.clinicPriceScheduleId === price.clinicPriceScheduleId ? "[CURRENT]" : ""}`, value: price.clinicPriceScheduleId};
    }) : [];

    const HOUSE_ACCOUNT_OPTIONS = combinedData.houseAccounts ? orderBy(map(combinedData.houseAccounts, account => {
        return {
            name: `${account.firstName} ${account.lastName} [${account.displayId}] ${!account.isActive ? "(INACTIVE)" : ""}`,
            value: account.id,
            isActive: account.isActive,
            firstName: account.firstName
        };
    }), ["isActive", "firstName"], ["desc", "asc"]) : [];

    const renderPlans = () => {
        if (groupName && (selectedClinicPriceScheduleId || defaultPricingSchedule.clinicPriceScheduleId)) {
            const filteredPlans = orderBy(filter(combinedData.plans, plan => {
                return (
                    (plan.groupName === groupName) &&
                    (selectedClinicPriceScheduleId ? (
                        plan.clinicPriceScheduleId === selectedClinicPriceScheduleId
                    ) : (
                        plan.clinicPriceScheduleId === defaultPricingSchedule.clinicPriceScheduleId
                    ))
                )}), ["displayOrder"]);
            const usedPims = flatMap(filteredPlans, f => {
                //Service Enrollment Mappings
                const enrollmentMappings = map(f.services, s => s.enrollmentMappings);
                //Plan Enrollment Mappings
                enrollmentMappings.push(f.enrollmentMappings);
                //Flatten the List of Mappings
                return flatMap(flatMap(enrollmentMappings, e => filter(e, m => m.id)), "clinicProductId");
            });

            return map(filteredPlans, plan => (
                <PlanSetupForm
                    handleChange={handleChange}
                    key={plan.clinicPlanInstanceId}
                    clinicId={props.clinicId}
                    plan={plan}
                    readOnly={readOnly}
                    copy={handleCopy}
                    usedPims={usedPims}
                />
            ));
        } else {
            return (
                <div className="flex flex-centered">
                    {!groupName ? "(Select Plan Group)" : "(Select Pricing Schedule)"}
                </div>
            );
        }
    }

    if(!props.canViewClinicWellnessPlans) {
        return <AccessDenied/>
    }

    return (
        <div className={styles.root}>
            <DateInput
                name="wellnessStartDate"
                value={combinedData.wellnessStartDate ? moment(combinedData.wellnessStartDate) : null}
                label="Wellness Start Date"
                dateFormat={"MM/DD/YYYY"}
                information="This is the date the clinic started processing wellness. If not set, this will get set to the Go Green date."
                onChange={handleChange}
                disabled={readOnly}
            />
            <Dropdown
                options={HOUSE_ACCOUNT_OPTIONS}
                name="houseAccountClinicDvmId"
                value={combinedData.houseAccountClinicDvmId}
                label="House Account"
                placeholder="--Select House Account--"
                onChange={handleChangeHouseAccount}
                disabled={readOnly}
            />
            <Dropdown
                options={GROUP_OPTIONS}
                name="groupName"
                value={groupName}
                label="Plan Group"
                placeholder="--Select Plan Group--"
                onChange={handleChangeGroup}
            />
            {props.canViewClinicWellnessPriceSchedules && (
                <div className="flex spaced-content align-bottom">
                    <div className="flex-1">
                        <Dropdown
                            options={PRICE_SCHEDULE_OPTIONS}
                            name="clinicPriceScheduleId"
                            value={selectedClinicPriceScheduleId || defaultPricingSchedule.clinicPriceScheduleId}
                            label="Pricing Schedule"
                            onChange={({value}) => setSelectedClinicPriceScheduleId(value)}
                            placeholder="--Select Pricing Schedule--"
                        />
                    </div>
                    {props.canEditClinicWellnessPriceSchedules && (
                        <div>
                            <Button
                                type="primary"
                                onClick={() => setShowManagePriceSchedule(true)}
                            >
                                Manage
                            </Button>
                        </div>
                    )}
                </div>
            )}
            <hr className="margin-top-md margin-bottom-md"/>
            <h1 className="text-center margin-bottom-sm">Available Plans in Plan Group</h1>
            {renderPlans()}
            {!readOnly && (
                <div className={styles.save}>
                    <Button
                        onClick={handleSave}
                        disabled={!(formData && props.canEditClinicWellnessPlans)}
                        iconOnly
                        large
                    >
                        <i className="far fa-save"/>
                    </Button>
                </div>
            )}
            <Modal
                show={showManagePriceSchedule}
                onClose={() => setShowManagePriceSchedule(false)}
                mediumSmall
                modalTitle={`Edit ${!!(props.clinic && props.clinic.name) ? props.clinic.name : ""} Pricing Schedules`}
            >
                {!props.canEditClinicWellnessPriceSchedules ? (
                    <AccessDenied/>
                ) : (
                    <ManageClinicPriceSchedule
                        clinicId={props.clinicId}
                        onCancel={() => setShowManagePriceSchedule(false)}
                    />
                )}
            </Modal>
            <SpinnerTakeover show={props.loading}/>
        </div>
    );
}

ClinicWellnessPlans.propTypes = {
    clinicId: PropTypes.number,
}

export default connect(
    (state, ownProps) => {
        const clinicInfo = getClinicInfo(state, ownProps.clinicId);
        const userProfile = state.user.userProfile;
        const canViewClinicWellnessPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);
        const canEditClinicWellnessPlans = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);
        const canViewProductMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.PRODUCT_MAPPING, userProfile);
        const canViewClinicWellnessPriceSchedules = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_PRICE_SCHEDULES, userProfile);
        const canEditClinicWellnessPriceSchedules = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_PRICE_SCHEDULES, userProfile);

        return {
            canViewClinicWellnessPlans,
            canEditClinicWellnessPlans,
            canViewProductMapping,
            canViewClinicWellnessPriceSchedules,
            canEditClinicWellnessPriceSchedules,
            clinic: clinicInfo,
            pricingSchedules: state.entities.pricingSchedules[ownProps.clinicId],
            clinicWellnessPlans: state.entities.clinicWellnessPlans[ownProps.clinicId] || {},
            loading: state.entities.clinicWellnessPlans.loading || false
        };
    },
    (dispatch) => ({
        getPIMs: (clinicId) => dispatch(MappingActions.getPIMs(clinicId)),
        loadPricingSchedule: (clinicId) => dispatch(ClinicActions.loadPricingSchedule(clinicId)),
        loadClinicWellnessPlans: (clinicId, providerId) => dispatch(ClinicActions.loadClinicWellnessPlans(clinicId, providerId)),
        updateClinicWellnessPlans: (clinicId, clinicPlanInstanceId, data) => dispatch(ClinicActions.updateClinicWellnessPlans(clinicId, clinicPlanInstanceId, data)),
        updateHouseAccount: (clinicId, houseAccountClinicDvmId, providerId) => dispatch(ClinicActions.updateHouseAccount(clinicId, houseAccountClinicDvmId, providerId)),
    })
)(ClinicWellnessPlans);
