import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ProgramStatusIcon.scss";
import { CANCELLED, DECLINED, ELIGIBLE, ENROLLED, INELIGIBLE } from "constants/ProgramStatuses";
import Tooltip from "components/common/Tooltip";

function ProgramStatusIcon({
    fullExplanation,
    status,
    statusDate,
    showToolTip,
}) {
    const STATUS_EXPLANATIONS = {
        [CANCELLED]: <div>Cancelled status indicates that the clinic<br />has cancelled their participation in this<br />specific program.</div>,
        [DECLINED]: <div>Declined indicates that the clinic<br />declined to participate in this specific<br />program.</div>,
        [ELIGIBLE]: <div>Eligible status indicates that the clinic is<br />eligible to participate in this program per<br />the manufacturer.</div>,
        [ENROLLED]: <div>Enrolled indicates that the clinic has<br />fully executed the necessary agreement<br />and is participating in this program.</div>,
        [INELIGIBLE]: <div>Ineligible status indicates that the clinic<br />is not eligible to participate per the<br />manufacturer's requirements for this<br />specific program.</div>
    };


    const tipPosition = fullExplanation ? "top" : "left";
    const iconClasses = useMemo(() => {
        switch (status) {
            case CANCELLED:
                return "fa-solid fa-circle-x text-light-gray";
            case DECLINED:
                return "fa-solid fa-circle-x text-danger";
            case ELIGIBLE:
                return "fa-regular fa-circle-check text-success";
            case ENROLLED:
                return "fa-solid fa-circle-check text-success";
            case INELIGIBLE:
            default:
                return "fa-solid fa-circle-minus text-light-gray";
        }
    },[status]);

    const iconLabel = useMemo(() => {
        switch (status) {
            case CANCELLED:
                return "Cancelled";
            case DECLINED:
                return "Declined";
            case ELIGIBLE:
                return "Eligible";
            case ENROLLED:
                return "Enrolled";
            case INELIGIBLE:
            default:
                return "Ineligible";
        }
    },[status]);

    const tipMessage = useMemo(() => {
        if (fullExplanation) {
            return STATUS_EXPLANATIONS[status];
        }

        return (
            <span><strong>{iconLabel}{statusDate ? ": " : ""}</strong>{statusDate}</span>
        )
    }, [statusDate, fullExplanation, iconLabel]);


    return (
        <div className={styles.root}>
            <Tooltip position={tipPosition} tip={tipMessage} showing={showToolTip} manuallyShow={showToolTip} >
                <i className={classnames(iconClasses, {
                    [styles.smaller]: fullExplanation,
                })} />
            </Tooltip>
            {fullExplanation  && <div className={styles.label}>{iconLabel}</div>}
        </div>
    );
}


ProgramStatusIcon.propTypes = {
    status: PropTypes.string,
    statusDate: PropTypes.string,
    showToolTip: PropTypes.bool,
}

export default connect(
    (state, ownProps) => ({
        // property: state.property,
    }),
    (dispatch) => ({
        // function: () => dispatch(ACTION.function()),
    })
)(ProgramStatusIcon);
