import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as ClinicActions from "actions/ClinicActions";
import * as ClinicApi from "api/ClinicApi";
import CreateTagForm from "./CreateTagForm";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import { handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function CreateTagFormContainer(props) {
    const [loading, setLoading] = useState(false);

    if (!props.canViewClinicManagement) {
        return null;
    }

    const handleSubmit = (newTag) => {
        setLoading(true);
        ClinicApi.createClinicTag(newTag)
            .then((res) => {
                props.tagCreated(res.body);
                if (props.clinicId) {
                    const newTags = [...props.clinicTags];
                    // console.log(res.body);
                    newTags.push(res.body.id);
                    props.updateClinicTags(props.clinicId, newTags);
                }
            })
            .catch((error) => {
                handleErrorResponse("creating clinic tag", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <>
            <CreateTagForm
                canViewClinicManagement={props.canViewClinicManagement}
                onSubmit={handleSubmit}
            />
            <SpinnerTakeover show={loading || props.working} />
        </>
    )
}


CreateTagFormContainer.propTypes = {
    clinicId: PropTypes.number,
    clinicTags: PropTypes.arrayOf(PropTypes.string),
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canEditClinicManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            canViewClinicManagement,
            canEditClinicManagement,
            working: state.applicationState.working > 0,
        }
    },
    (dispatch) => ({
        tagCreated: (tag) => dispatch(ClinicActions.tagCreated(tag)),
        updateClinicTags: (clinicId, tags) => dispatch(ClinicActions.updateClinicTags(clinicId, tags))
    })
)(CreateTagFormContainer);

