import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as Yup from "yup";
import styles from "./UserCreateForm.scss";
import debounce from "lodash/debounce";
import filter from "lodash/filter";
import includes from "lodash/includes";
import lowerCase from "lodash/lowerCase";
import map from "lodash/map";
import reject from "lodash/reject";
import upperCase from "lodash/upperCase";
import some from "lodash/some";
import * as ClinicApi from "api/ClinicApi";
import * as CouponActions from "actions/CouponActions";
import * as ProviderActions from "actions/ProviderActions";
import Button from "components/common/Button";
import CheckboxInput from "components/common/CheckboxInput";
import Dropdown from "components/common/Dropdown";
import DropdownSearch from "components/common/DropdownSearch";
import TextBox from "components/common/TextBox";
import { ROLES } from "constants/RoleTypes";
import * as UserPermissions from "constants/UserPermissions";
import { CLINIC_GUEST } from "constants/UserRoles";
import { CLINIC, CORPORATE_GROUP, GREENLINE_STAFF, MFR, WELLNESS, USER_TYPE_NAMES } from "constants/UserTypes";
import { handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import useFormHandlers from "utils/useFormHandlers";

const schema = Yup.object().shape({
    userId: Yup.number().nullable(true).default(null),
    userType: Yup.string().when("userId", {
        is: (userId) => !!userId,
        then: () => Yup.string().nullable(true).default(null),
        otherwise: () => Yup.string().required(),
    }),
    clinicId: Yup.number().nullable(true).default(null),
    email: Yup.string().min(8).email('Must be a valid email').required(),
    emailConfirmed: Yup.bool().default(true),
    firstName: Yup.string().min(2).required(),
    nodeId: Yup.number().nullable(true).default(null),
    isActive: Yup.bool().default(true),
    lastName: Yup.string().min(2).required(),
    userName: Yup.string().min(6).matches(/^[A-Za-z][A-Za-z0-9-_.@]+$/).required(),
    password: Yup.string()
        .default("")
        .min(10, "Password cannot have less than 10 characters")
        .max(50, "Password cannot have more than 50 characters")
        .required(),
    passwordConfirm: Yup.string()
        .default("")
        .min(10, "Password cannot have less than 10 characters")
        .max(50, "Password cannot have more than 50 characters")
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required(),
    providerId: Yup.number().when("userType", {
        is: (userType) => includes([CORPORATE_GROUP, MFR, WELLNESS], userType),
        then: () => Yup.number().required(),
        otherwise: () => Yup.number().nullable(true).default(null),
    }),
    role: Yup.string().required(),
    sendWelcomeMessage: Yup.bool().default(true),
});

function UserCreateForm(props) {
    const { formData, handleUpdate, setFormData, invalidFields, isValid } = useFormHandlers({ sendWelcomeMessage: true }, schema);
    const [clinicLoading, setClinicsLoading] = useState(null);
    const [clinics, setClinics] = useState(null);
    const [search, setSearch] = useState(null);

    const hasDigit = some(formData.password, (char) => /\d/.test(char));
    const hasLowercase = some(formData.password, char => char >= 'a' && char <= 'z');
    const hasSpaces = includes(formData.password, " ");
    const hasSpecialCharacter = some(formData.password, char => /[^a-zA-Z0-9]/.test(char));
    const hasUppercase = some(formData.password, char => char === upperCase(char) && char !== lowerCase(char));
    const includesUserName = formData.userName && includes(lowerCase(formData.password), lowerCase(formData.userName));
    const isPassphrase = formData.password?.length >= 20;
    const tooLong = formData.password?.length > 50;
    const tooShort = formData.password?.length ? formData.password.length < 10 : true;
    const hasAtLeast3Of4Options = hasDigit && hasLowercase && hasUppercase || hasDigit && hasLowercase && hasSpecialCharacter || hasDigit && hasUppercase && hasSpecialCharacter || hasLowercase && hasUppercase && hasSpecialCharacter;
    const passwordCorrect = !includes(invalidFields, "password") && (isPassphrase || hasAtLeast3Of4Options) && !tooShort && !tooLong && !hasSpaces && !includesUserName

    useEffect(() => {
        props.getProviderList();
    }, []);

    useEffect(() => {
        if (!!formData.providerId && !props.hierarchies[formData.providerId]) {
            props.getProviderHierarchies(formData.providerId);
        }
    }, [formData.providerId]);

    const loadClinics = (search) => {
        if(props.canViewClinicManagement) {
            const searchValues = {
                search: search,
                offset: 0,
                limit: 9999,
                orderBy: "clinicName",
                orderDir: "desc",
                filters: [{
                    id: "Active",
                    equality: "==",
                    level1Values: [{
                        value: "Active"
                    }]
                }]

            };

            setClinicsLoading(true);
            ClinicApi.searchClinics(searchValues)
                .then((res) => {
                    setClinics(res.body.data);
                    setClinicsLoading(false);
                })
                .catch((error) => {
                    handleErrorResponse("loading clinics", error);
                    setClinicsLoading(false);
                });
        }
    }

    const searchClinics = useCallback(debounce((search) => {
        loadClinics(search);
    }, 500), []);

     useEffect(() => {
         if (!!(search && search.length)) {
             searchClinics(search);
         }
    }, [search]);

    const handleChangeUserType = ({name, value}) => {
        setFormData({
            ...formData,
            [name]: value,
            providerId: null,
            role: null,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        props.onSubmit(formData);
    };

    const USER_TYPE_OPTIONS = map(USER_TYPE_NAMES, (role, index) => {
        return {name: role, value: index};
    });

    const ROLE_OPTIONS = filter(ROLES, role => {
        if (role.userType === formData.userType) {
            if(role.userType === CLINIC && !formData.clinicId) {
                if (role.value === CLINIC_GUEST) {
                    return {name: role.shortName, value: role.value};
                }
            } else {
                return {name: role.shortName, value: role.value};
            }
        }
    });

    const CLINIC_OPTIONS = map(clinics, (clinic) => {
        return { name: `${clinic.clinicName} - ${clinic.city}, ${clinic.state} (${clinic.clinicId})`, value: clinic.clinicId };
    });

    const PROVIDER_OPTIONS = map(props.providers, (provider) => {
        return {name: provider.name, value: provider.id};
    });

    const NODE_OPTIONS = useMemo(() => {
        return map(reject(props.hierarchies?.[formData.providerId], {nodeType: "Clinic"}), (node) => {
            return {name: `(${node.nodeId}) ${node.displayName} (${node.sourceRefCode}) - ${node.nodeType}`, value: node.nodeId}
        });
    }, [formData.providerId, props.hierarchies]);

    const getCompleteIcon = (isComplete, required) => {
		if (isComplete) {
			return <i className="fa fa-check text-success" />
		} else {
			if (required) {
				return <i className="fa fa-times text-danger"/>
			} else return <span style={{ paddingLeft: "1.2em" }} />;
		}
	};

    const lineThrough = (text, lineThrough) => {
        if (lineThrough) {
            return <span className={styles.lineThrough}>{text}</span>
        } else return text;
    };

    return (
        <form
            className={styles.root}
            onSubmit={handleSubmit}
        >
            <Dropdown
                label="User Type"
                name="userType"
                value={formData.userType}
                onChange={handleChangeUserType}
                options={USER_TYPE_OPTIONS}
                placeholder="--Select User Type--"
                hidePlaceholder
            />
            {CLINIC === formData.userType && (
                <DropdownSearch
                    options={CLINIC_OPTIONS}
                    name="clinicId"
                    value={formData.clinicId}
                    label="Clinic"
                    placeholder="--Search Clinic--"
                    onChange={handleUpdate}
                    onSearchChange={(value) => setSearch(value)}
                    loading={clinicLoading}
                />
            )}
            {(CORPORATE_GROUP === formData.userType || WELLNESS === formData.userType || MFR === formData.userType ) && (
                <Dropdown
                    options={PROVIDER_OPTIONS}
                    label={MFR === formData.userType ? "Manufacturer" : "Provider"}
                    name="providerId"
                    value={formData.providerId}
                    placeholder={MFR === formData.userType ? "--Select Manufacturer--" : "--Select Provider--"}
                    onChange={handleUpdate}
                />
            )}
            {(!!NODE_OPTIONS?.length && (CORPORATE_GROUP === formData.userType || WELLNESS === formData.userType || MFR === formData.userType)) && (
                <DropdownSearch
                    options={NODE_OPTIONS}
                    label="Node"
                    name="hierarchyNodeId"
                    value={formData.hierarchyNodeId}
                    placeholder="--Select Node--"
                    onChange={handleUpdate}
                    disabled={!formData.providerId || !NODE_OPTIONS?.length}
                />
            )}
            {GREENLINE_STAFF === formData.userType && (
                <TextBox
                    name="location"
                    value="Greenline Support"
                    label="Location"
                    onChange={() => {}}
                    disabled
                />
            )}
            <hr className="margin-top-md margin-bottom-sm"/>
            <TextBox
                name="userName"
                value={formData.userName}
                label="Username"
                onChange={handleUpdate}
                required
                minLength={6}
                pattern="[A-Za-z][A-Za-z0-9-_.@]+"
                title="Minimum 6 character length. Must start with a letter and can contain dashes (-), underscores (_), periods (.), letters (A-Z), or digits (0-9)"
                information="Will be used to Login - Minimum 6 character length. Must start with a letter and can contain dashes (-), underscores (_), periods (.), letters (A-Z), or digits (0-9)"
            />
            <TextBox
                onChange={handleUpdate}
                name="firstName"
                value={formData.firstName}
                label="First Name"
                required
            />
            <TextBox
                onChange={handleUpdate}
                name="lastName"
                value={formData.lastName}
                label="Last Name"
                required
            />
            <TextBox
                onChange={handleUpdate}
                name="email"
                inputType="email"
                value={formData.email}
                label="Email"
                required
            />
            <TextBox
                hasError={!passwordCorrect}
                inputType="password"
                label="Password"
                maxLength={50}
                minLength={10}
                name="password"
                onChange={handleUpdate}
                required
                value={formData.password}
            />
            <ul className={styles.passwordRequirements}>
                <li>Must not be any of your last 10 passwords</li>
                <li>{getCompleteIcon(!includesUserName, true)} Must not include your username</li>
                <li>{getCompleteIcon(!hasSpaces, true)} Must not contain spaces</li>
                <li>{getCompleteIcon(!tooLong && isPassphrase, isPassphrase)} {lineThrough("Must be a passphrase of 20 to 50 characters", !isPassphrase && !tooShort)}</li>
                <li style={{ paddingLeft: "1.2em" }}>- OR - a password that:</li>
                <li>{getCompleteIcon(!(tooShort || tooLong || isPassphrase), (tooShort && !isPassphrase))} {lineThrough("Must be at least 10 Characters (up to 19)", isPassphrase)}</li>
                <li>
                    {getCompleteIcon((hasAtLeast3Of4Options && !isPassphrase), !isPassphrase)} {lineThrough("Must contain at least 3 of the following:", isPassphrase)}
                    <ul>
                        <li>{getCompleteIcon((hasLowercase && !isPassphrase), !(hasAtLeast3Of4Options || isPassphrase))} {lineThrough("at least 1 lowercase letter", (isPassphrase || (hasAtLeast3Of4Options && !hasLowercase)))}</li>
                        <li>{getCompleteIcon((hasUppercase && !isPassphrase), !(hasAtLeast3Of4Options || isPassphrase))} {lineThrough("at least 1 uppercase letter", (isPassphrase || (hasAtLeast3Of4Options && !hasUppercase)))}</li>
                        <li>{getCompleteIcon((hasDigit && !isPassphrase), !(hasAtLeast3Of4Options || isPassphrase))} {lineThrough("at least 1 digit", (isPassphrase || (hasAtLeast3Of4Options && !hasDigit)))}</li>
                        <li>{getCompleteIcon((hasSpecialCharacter && !isPassphrase), !(hasAtLeast3Of4Options || isPassphrase))} {lineThrough("at least 1 special character (#, $, %, etc.)", (isPassphrase || (hasAtLeast3Of4Options && !hasSpecialCharacter)))}</li>
                    </ul>
                </li>
            </ul>
            <TextBox
                disabled={!formData.password}
                hasError={includes(invalidFields, "passwordConfirm")}
                inputType="password"
                label="Confirm Password"
                maxLength={50}
                minLength={10}
                name="passwordConfirm"
                onChange={handleUpdate}
                required
                value={formData.passwordConfirm}
            />
            {(formData.password && formData.passwordConfirm && formData.password !== formData.passwordConfirm) && (
                <div className="text-danger">Passwords do not match.</div>
            )}
            <Dropdown
                onChange={handleUpdate}
                name="role"
                value={formData.role}
                label="Role"
                placeholder="--Select Role--"
                information={!!(formData.userType === CLINIC && !formData.clinicId) && "Select a Clinic to see all Role options"}
                options={ROLE_OPTIONS}
                disabled={!formData.userType}
                dropdownDirection="up"
            />
            {!(CLINIC === formData.userType || GREENLINE_STAFF === formData.userType) && (
                <div className="margin-top-sm">
                    <CheckboxInput
                        checked={formData.sendWelcomeMessage}
                        name="sendWelcomeMessage"
                        onChange={handleUpdate}
                        label="Send Welcome Message to Email Provided?"
                        leftLabel
                    />
                </div>
            )}
            <div className="flex justify-flex-end spaced-content margin-top-sm">
                <Button onClick={props.onCancel} type="gray" buttonType="button">
                    Cancel
                </Button>
                <Button
                    buttonType="submit"
                    disabled={!isValid || !passwordCorrect}
                >
                    Send Invitation
                </Button>
            </div>
        </form>
    )
}

UserCreateForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            canViewClinicManagement,
            providers: state.entities.providers,
            hierarchies: state.entities?.providerHierarchies
        };
    },
    (dispatch) => ({
        getProviderList: () => dispatch(CouponActions.getProviderList()),
        getProviderHierarchies: (providerId) => dispatch(ProviderActions.getProviderHierarchies(providerId)),
    })
)(UserCreateForm);
