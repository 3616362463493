import React, { useState, useEffect } from 'react';
import styles from './PaymentsHeader.scss';
import PropTypes from "prop-types";
import Button from 'components/common/Button';
import CardModal from './CardModal';
import { createPaymentOption, updateDefaultPaymentOption } from "api/StripeApi";
import toast from "utils/toast";
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Dropdown from 'components/common/Dropdown';
import {handleErrorResponse} from "utils/request";

export default function PaymentsHeader(props) {
    const [error, setError] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [btnDisabled, disableBtn] = useState(false);
    const [name, setName] = useState('');
    const [options, setOptions] = useState([]);
    const [defaultCard, setDefaultCard] = useState('');
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        setOptions(props.cards.map((card) => {
            if (card.id !== defaultCard && card.isDefault) {
                setDefaultCard(card.id);
            }

            return {
                name: `${card.brand.toUpperCase()} ${card.last4}`,
                value: card.id
            }
        }));
    }, [props.cards]);

    const closeModal = () => {
        setIsOpen(false);
        setName('');
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        disableBtn(true);
        props.setIsLoading(true);

        const card = elements.getElement(CardElement);
        const stripeModel = { name: name }
        const result = await stripe.createToken(card, stripeModel);

        if (result.error) {
            setError(result.error.message);
        } else {
            setError(null);
            closeModal();
            try {
                await createPaymentOption({clinicId: props.clinicId, tokenId: result.token.id});
                props.getCards();
                toast.success("Card has been added.");
            } catch (error) {
                handleErrorResponse("adding payment method", error);
            }
        }

        props.setIsLoading(false);
        disableBtn(false);
    }

    const handleError = (event) => {
        if (event.error) {
            setError(event.error.message);
        } else {
            setError(null);
        }
    }

    const handleOnChange = async (e) => {
        props.setIsLoading(true);

        try {
            await updateDefaultPaymentOption(props.clinicId, e.value);
            setDefaultCard(e.value);
            props.onDefaultCardUpdated(e.value);
            toast.success("Default card updated.");
        } catch(error) {
            handleErrorResponse("setting default card", error);
        }

        props.setIsLoading(false);
    }

    return (
        <>
            <div className={styles.rootContainer}>
                {/*<h2>Payment Options</h2>*/}
                <div className={styles.optionsContainer}>

                    <div className={styles.defaultCard}>
                        <span>Default Card:</span>
                        <div className={styles.defaultSelect}>
                            <Dropdown
                                fullWidth
                                options={options}
                                name="defaultPayment"
                                value={defaultCard}
                                onChange={handleOnChange}
                                disabled={!props.canEditPaymentSettings}
                            />
                        </div>
                    </div>
                    <div className={styles.addMore}>
                        <Button
                            icon
                            onClick={() => setIsOpen(true)}
                            disabled={!props.canEditPaymentSettings}
                        >
                            <i className="fas fa-plus" /> Add a Payment Option
                        </Button>
                    </div>
                </div>
            </div>
            <CardModal
                isOpen={isOpen}
                closeModal={closeModal}
                name={name}
                handleFormChange={({name, value}) => {setName(value)}}
                handleSubmit={handleSubmit}
                stripeError={error}
                setStripeError={handleError}
                btnDisabled={btnDisabled || !props.canEditPaymentSettings}
            />
        </>
    )
}

PaymentsHeader.propTypes = {
    clinicId: PropTypes.number?.isRequired,
    cards: PropTypes.array.isRequired,
    getCards: PropTypes.func,
    setIsLoading: PropTypes.func,
    onDefaultCardUpdated: PropTypes.func,
    canEditPaymentSettings:PropTypes.bool,
};
