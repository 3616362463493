import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import get from "lodash/get";
import includes from "lodash/includes";
import map from "lodash/map";
import remove from "lodash/remove";
import CheckboxInput from "components/common/CheckboxInput";
import NotificationButtons from "components/notifications/elements/NotificationButtons";
import UserInitials from "components/notifications/elements/UserInitials";
import SignatureBox from "components/common/SignatureBox";
import useFormHandlers from "utils/useFormHandlers";
import { PENDING, ORAVET_2022, STATUS_COLOR_CLASS, SYNC_TRIGGER_CLOO } from "constants/Notifications";

export default function BasicNotification(props) {
	const {
		acceptedOffers = [],
		canSelectOffers = false,
		enrollmentState,
		initialData = {},
		isPreview = false,
		notificationDetails,
		notificationType,
		offers,
		onCancel,
		onDecline,
		onDismiss,
		onEnroll,
	} = props;

	const schema = useMemo(() => {
		return Yup.object().shape({
			acceptTermsAndConditions: Yup.bool().when([], {
		        is: () => notificationType === SYNC_TRIGGER_CLOO,
		        then: () => Yup.bool().oneOf([true], 'Field must be checked').default(false).required(),
		        otherwise: () => Yup.bool(),
		    }),
			checked: Yup.array().of(Yup.number()),
			includeLTOCampaign: Yup.bool().when([], {
		        is: () => notificationType === SYNC_TRIGGER_CLOO,
		        then: () => Yup.bool().oneOf([true], 'Field must be checked').default(false).required(),
		        otherwise: () => Yup.bool(),
		    }),
			initials: Yup.string().when([], {
				is: () => !notificationDetails.requiresInitials,
				then: () => Yup.string().nullable(true),
				otherwise: () => Yup.string().min(2),
			}),
			signature: Yup.string().when([], {
		        is: () => notificationType === SYNC_TRIGGER_CLOO,
		        then: () => Yup.string().min(2).required(),
		        otherwise: () => Yup.string().nullable(true),
		    }),
		});
	}, [notificationType, notificationDetails]);

	const { formData, isValid, handleUpdate, invalidFields } = useFormHandlers(
        initialData,
		schema,
		[],
		{},
		false,
	);

	const selectedOffers = canSelectOffers ? acceptedOffers : map(offers, "ccloId");
	const statusColor = useMemo(() => {
		return STATUS_COLOR_CLASS[enrollmentState] || "";
	}, [enrollmentState]);
	const isPending = useMemo(() => {
		return enrollmentState === PENDING
	}, [enrollmentState]);

    useEffect(() => {
        if (get(notificationDetails, "requiresInitials") && formData.initials === undefined) {
            // For notifications with initials, help Yup validate so the accept button is disabled at start.
            handleUpdate({
                name: "initials",
                value: "",
            });
        }
    }, [notificationDetails]);

	const handleCheckedChange = ( clooId, value ) => {
		if (isPending) {
			let newChecked = [...formData.checked];
			if (!!value) {
				newChecked.push(clooId);
			} else {
				remove(newChecked, c => c === clooId);
			}

			handleUpdate({
				name: "checked",
				value: newChecked
			});
		}
	};

	return (
		<div>
			{notificationDetails.showCurrentStatus && (
				<div className={`${statusColor} margin-bottom-md text-center text-lg`}>- {notificationDetails.currentState} -</div>
			)}
			<div className="quillText" dangerouslySetInnerHTML={{ __html: notificationDetails.longDescription }} />
			{notificationType === SYNC_TRIGGER_CLOO && (
				<>
					<CheckboxInput
						name="includeLTOCampaign"
						label="Include Merck Spring 2024 Limited Time Campaign"
						checked={formData.includeLTOCampaign}
						onChange={handleUpdate}
						large
					/>
					<CheckboxInput
						checked={formData.acceptTermsAndConditions}
						label="I accept the terms and conditions"
						large
						name="acceptTermsAndConditions"
						onChange={handleUpdate}
						value="checkboxTermsConditions"
					/>
					<SignatureBox
						autoFocus
						hasError={includes(invalidFields, "signature")}
						label="*Please Sign Here"
						name="signature"
						onChange={handleUpdate}
						required
						value={formData.signature}
					/>
				</>
			)}
			<>
				{notificationDetails.requiresInitials && (
					<div className="flex margin-top-md">
						<div className="flex-1">
							<UserInitials
								onChange={handleUpdate}
								value={formData.initials}
							/>
						</div>
						<div className="flex-1" />
					</div>
				)}
				{(canSelectOffers) && (
					<>
						<div>
							<div className="text-lg text-center">Available Programs</div>
							{isPending ? (
								<div className="text-center">Please select the products you would like to participate with Below</div>
							) : (
								<div className="text-center">Selected product participation</div>
							)}
						</div>
						<div className="flex flex-centered">
							<div className="margin-top-sm">
								{map(offers, o => (
									<CheckboxInput
										key={o.ccloId}
										name="bsc"
										label={o.libraryTitle}
										checked={!!includes(selectedOffers, o.ccloId)}
										onChange={({value}) => handleCheckedChange(o.ccloId, value)}
										large
										disabled={!isPending}
									/>
								))}
							</div>
						</div>
					</>
				)}
				<hr className="margin-top-sm"/>
				<div className="margin-top-md">
					<NotificationButtons
						notificationDetails={notificationDetails}
						onEnroll={onEnroll}
						onDecline={onDecline}
						buttonText={notificationType === SYNC_TRIGGER_CLOO ? "Codes Created, Opt-In" : undefined}
						onCancel={onCancel}
						onDismiss={onDismiss}
						enrollDisabled={!isValid}
						declineDisabled={notificationType === SYNC_TRIGGER_CLOO && !formData.signature}
						initials={formData.initials}
						formFieldsJson={formData}
						selectedOffers={selectedOffers}
						cancelledEnrolls={(notificationDetails?.notificationType === ORAVET_2022)}
						isPreview={isPreview}
					/>
				</div>
			</>
		</div>
	);
}

BasicNotification.propTypes = {
	acceptedOffers: PropTypes.array,
	canSelectOffers: PropTypes.bool,
	clinicId: PropTypes.number,
	enrollmentState: PropTypes.string,
	initialData: PropTypes.object,
	isPreview: PropTypes.bool,
	notificationDetails: PropTypes.object.isRequired,
	notificationType: PropTypes.string,
	offers: PropTypes.array,
	onCancel: PropTypes.func,
	onDecline: PropTypes.func,
	onDismiss: PropTypes.func,
	onEnroll: PropTypes.func.isRequired,
};
