import React, { useState, useEffect } from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./EditCorporateId.scss";
import Button from "components/common/Button";
import Image from "components/common/Image";
import TextBox from "components/common/TextBox";
import * as ProviderActions from "actions/ProviderActions";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function EditCorporateId(props) {
    const [locationId, setLocationId] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLocationId(props.corporateGroup.locationId);
        setLoading(false);
    }, [props.corporateGroup]);

    if(!props.canViewClinicManagement) {
        return null;
    }

    const handleSaveCorporateId = () => {
        if(locationId.length) {
            setLoading(true);
            props.updateCorporateGroupLocationId({
                clinicId: props.clinicId,
                providerId: props.providerId,
                corporateLocationIdentifier: locationId,
            });
        }
    }

    const handleCancel = () => {
        setLocationId(props.corporateGroup.locationId);
        props.onClose();
    }

    return (
        <div className={styles.corporateGroupInfo}>
            <div className={styles.corporateGroupImage}>
                <Image src={props.provider?.mediumImageUri}/>
            </div>
            <div className={classnames(styles.corporateId, {
                [styles.noId]: !locationId?.length
            })}>
                <TextBox
                    label="Corporate Id"
                    onChange={({value}) => setLocationId(value)}
                    name="locationId"
                    value={locationId}
                    placeholder="No Corporate Id"
                    large
                />
            </div>
            <div className={classnames(styles.buttonWrap, "spaced-content")}>
                <Button
                    onClick={handleSaveCorporateId}
                    disabled={loading || !locationId?.length || (locationId === props.corporateGroup.locationId)}
                    icon={loading}
                >
                    {loading && (<i className="fa fa-spinner-third fa-spin"/>)} Save
                </Button>
                <Button
                    onClick={handleCancel}
                    type="gray"
                >
                    Cancel
                </Button>
            </div>
        </div>
    )
}


EditCorporateId.propTypes = {
    clinicId: PropTypes.number.isRequired,
    providerId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canEditClinicManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            canViewClinicManagement,
            canEditClinicManagement,
            provider: state.entities.providers[ownProps.providerId],
            corporateGroup: state.entities.clinicProviderLocations[ownProps.clinicId]?.[ownProps.providerId] || {},
        }
    },
    (dispatch) => ({
        updateCorporateGroupLocationId: (locationInfo) => dispatch(ProviderActions.updateCorporateGroupLocationId(locationInfo)),
    })
)(EditCorporateId);

