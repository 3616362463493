import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ClinicTags.scss";
import map from "lodash/map";
import ClinicTagsFormContainer from "./ClinicTagsFormContainer";
import CreateTagFormContainer from "./CreateTagFormContainer";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function ClinicTags(props) {
    const [showAllTags, setShowAllTags] = useState(!props.clinicTags?.length);

    if (!props.canViewClinicManagement) {
        return null;
    }

    return (
        <div className={classnames(styles.root, "card", {
            [styles.open]: showAllTags,
        })}>
            <div className="flex spaced-content flex-wrap">
                <h3 className="flex-none">Tags</h3>
                <div className="flex-1 flex flex-wrap spaced-content-sm spaced-content-vertical-sm">
                    {map(props.clinicTags, tag => (
                        <div key={tag}>
                            <div className={styles.tag}>{tag}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.collapsible}>
                {props.canEditClinicManagement && (
                    <ClinicTagsFormContainer
                        clinicId={props.clinicId}
                        initialData={props.clinicTags}
                    />
                )}
                <CreateTagFormContainer
                    clinicId={props.clinicId}
                    clinicTags={props.clinicTags}
                />
            </div>
            <div
                className="flex flex-centered padding-top-sm border-top text-dark-gray pointer"
                onClick={() => setShowAllTags(!showAllTags)}
            >
                {!showAllTags ? (
                    <span><i className="fa fa-chevron-down" /> Show All</span>
                ) : (
                    <span><i className="fa fa-chevron-up" /> Hide</span>
                )}
            </div>
        </div>
    )
}


ClinicTags.propTypes = {
    clinicId: PropTypes.number,
    clinicTags: PropTypes.any,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canEditClinicManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            canViewClinicManagement,
            canEditClinicManagement,
        }
    }
)(ClinicTags);

