import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";
import toast from "utils/toast";
import styles from "./StrayCouponsTable.scss";
import filter from "lodash/filter";
import flatMap from "lodash/flatMap";
import head from "lodash/head";
import includes from "lodash/includes";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import times from "lodash/times";
import uniq from "lodash/uniq";
import { batchUpdateStrayCoupon } from "api/CouponsApi";
import { getDefaultErrorHandler } from "actions/base";
import * as CouponActions from "actions/CouponActions";
import AccordionSection from "components/common/AccordionSection";
import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import OpenInvoicePDFLinkNewTab from "components/common/OpenInvoicePDFLinkNewTab";
import Modal from "components/common/Modal";
import SortableDataTable from "components/common/SortableDataTable";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import Tooltip from "components/common/Tooltip";
import { formatUSD } from "utils/numeric";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as StrayCouponsBatchTypes from "constants/StrayCouponsBatchTypes";
import * as StrayCouponTableTypes from "constants/StrayCouponTableTypes";
import { Ineligible } from "constants/StrayCouponsBatchTypes";
import * as UserPermissions from "constants/UserPermissions";
import StrayCouponStatusDropdown from "../StrayCouponStatusDropdown";
import { RIGHT } from "constants/TooltipPositionIds";
import ShowHideButton from "./ShowHideButton";
import { useLocation } from "react-router-dom";

const toolTips = {
    [StrayCouponTableTypes.RESOLVED]: (
        <div>
            <div>These coupons have been resolved with reason listed below:</div>
            <div className="padding-left-sm">
                <div>Sent to Manufacturer - Greenline has escalated this invoice to Manufacturer for reimbursement.</div>
                <div>Resolved by Greenline - Resolved per the request of the clinic with no reimbursement, clinic has fixed the rebate on their own or Greenline fixed the rebate for approval.</div>
                <div>Fixer Invoice - Greenline has assisted clinic to create an invoice to generate reimbursement.</div>
                <div>Return - Clinic returned rebate to fix previous transaction.</div>
            </div>
        </div>
    ),
    [StrayCouponTableTypes.INELIGIBLE]: "These rebates were applied to invoices where the client was not eligible to receive the offer.",
    [StrayCouponTableTypes.PENDING_RESOLUTION]: "These rebates are awaiting resolution - please reach out to Greenline for further assistance.",
    [StrayCouponTableTypes.EXPIRED]: "These invoices are from the previous year and are expired.",
}

function StrayCouponsTable(props) {
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [selectedAction, setSelectedAction] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [ineligibleToReprocess, setIneligibleToReprocess] = useState(null);
    const [ineligibleMfrIdToReprocess, setIneligibleMfrIdToReprocess] = useState(null);
    const [showSpinner, setShowSpinner] = useState(false);
    const [sectionOpen, setSectionOpen] = useState(props.sectionOpenByDefault);
    const { tableType } = props;
    const { pathname } = useLocation();
    const isAdmin = !!includes(pathname, "/admin");

    useEffect(() => {
        if (tableType === StrayCouponTableTypes.INELIGIBLE && props.canReprocessStrayCoupons && props.canViewReprocessStrayCoupons) {
            props.getPrograms(props.clinicId);
        }
    }, [props.clinicId, props.tableType, props.canReprocessStrayCoupons, props.canReprocessStrayCoupons]);

    useEffect(() => {
        if (props.selectedRows !== selectedRowIds) {
            setSelectedRowIds(props.selectedRows);
        }
    }, [props.selectedRows]);

    const sectionTitleText = useMemo(() => {
        if (tableType === StrayCouponTableTypes.RESOLVED) {
            return "Resolved";
        } else if (tableType === StrayCouponTableTypes.INELIGIBLE) {
            return "Ineligible";
        } else if (tableType === StrayCouponTableTypes.PENDING_RESOLUTION) {
            return "Pending Resolution";
        } else if (tableType === StrayCouponTableTypes.EXPIRED) {
            return "Expired";
        }
    }, [tableType]);

    const noResultsText = useMemo(() => {
        let statusWord = "";

        if (tableType === StrayCouponTableTypes.RESOLVED) {
            statusWord = "Resolved ";
        } else if (tableType === StrayCouponTableTypes.INELIGIBLE) {
            statusWord = "Ineligible ";
        } else if (tableType === StrayCouponTableTypes.EXPIRED) {
            statusWord = "Expired ";
        } else if (tableType === StrayCouponTableTypes.PENDING_RESOLUTION) {
            statusWord = "Pending Resolution ";
        }

        return `No ${statusWord}Stray Coupons to Review`;

    }, [tableType]);

    const HIDDEN_COLUMN = [{
        name: "",
        selector: "invoiceDate",
        key: "invoiceDate_hidden",
        format: (row) => (
            <ShowHideButton strayCoupon={row} />
        ),
    }];

    const INELIGIBLE_COLUMN = [{
        name: "",
        selector: "invoiceDate",
        key: "reprocessIneligible",
        format: (row) => (
            <Button
                onClick={() => setIneligibleToReprocess(row)}
                disabled={!props.canReprocessStrayCoupons}
            >
                Reprocess
            </Button>
        ),
    }];

    const STRAY_STATUS_COLUMN = [{
        name: "Stray Status",
        selector: "status",
        key: "status",
        sortable: true,
    }];

    const RESOLVED_COLUMN = [{
        name: "",
        selector: "resolvedDate",
        key: "resolvedDate",
        format: (row) => row.resolvedDate ? (
                <div className="flex spaced-content">
                    <div className="flex-centered flex-column">
                        {props.canViewReprocessStrayCoupons ? (
                            <div className="no-wrap flex-1">Resolved by {row.resolvedUserInitials ? row.resolvedUserInitials : "Unknown"} on {moment(row.resolvedDate).format("MM/DD/YYYY")}</div>
                            ) : (
                            <div className="no-wrap flex-1">Resolved on {moment(row.resolvedDate).format("MM/DD/YYYY")}</div>
                        )}
                    </div>
                </div>
        ) : (
            <div className="flex spaced-content">
                {!props.canResolveInstantRebateInvoiceFailures && (
                    <Tooltip position="left" tip="Please reach out to Greenline support to resolve Stray Coupons">
                        <div className="flex flex-centered">
                            <i className="far fa-info-circle"/>
                        </div>
                    </Tooltip>
                )}
            </div>
        ),
    }];

    const REBATE_COLUMN = [{
        name: "Rebates",
        selector: "lineItemsDto",
        key: "lineItemsDto_rebates",
        sortable: true,
        sortValue: row => {
            const firstValue = head(orderBy(filter(row.lineItemsDto, "isTrigger"), "lineItemName"));
            return firstValue?.lineItemName || "";
        },
        format: (row) => map(filter(row.lineItemsDto, "isTrigger"), (lineItem, x) => {
            return times(lineItem.excessQuantity, index => (
                <div key={`rebate_name_${x}_${index}`}>
                    <div className="no-wrap text-sm">
                        {lineItem.lineItemName}
                    </div>
                    <div className="no-wrap text-success">
                        Rebate - {formatUSD((lineItem.price * -1) / lineItem.quantity)}
                    </div>
                </div>
            ));
        }),
    }];

    const COLUMNS = [{
        name: "Inv #",
        selector: "extendedInvoiceNumber",
        key: "extendedInvoiceNumber",
        sortable: true,
        sortValue: row => row.extendedInvoiceNumber,
        format: (row) => (
            <OpenInvoicePDFLinkNewTab
                className="text-primary"
                invoiceId={row.invoiceId}
                extendedInvoiceNumber={row.extendedInvoiceNumber}
            />
        ),
    }, {
        name: "Date",
        selector: "invoiceDate",
        key: "invoiceDate",
        sortable: true,
        format: (row) => row.invoiceDate ? moment(row.invoiceDate).format("MM/DD/YYYY") : "Unknown",
    }, {
        name: "Customer",
        selector: "firstName",
        key: "firstName",
        sortable: true,
        sortValue: row => `${row.firstName} ${row.lastName}`,
        format: (row) => (
            <div>{row.firstName} {row.lastName}</div>
        ),
    // }, {
    //     name: "Offer",
    //     selector: "offerName",
    //     key: "offerName",
    //     // sortable: true,
    //     format: (row) => (
    //         <div className={styles.details}>
    //             <div className={styles.descriptor}>
    //                 <span className={styles.label}>{row.offerName}</span>
    //             </div>
    //         </div>
    //     ),
    },
    ...(isAdmin ? REBATE_COLUMN : []),
    {
        name: "Items",
        selector: "lineItemsDto",
        key: "lineItemsDto_items",
        format: (row) => map(row.lineItemsDto, (lineItem, x) => (
            <div
                key={`item_name_${x}`}
                className="no-wrap"
            >
                {lineItem.lineItemName}
            </div>
        )),
    }, {
        name: (<div className="text-center">Qty</div>),
        selector: "lineItemsDto",
        key: "lineItemsDto_qty",
        format: (row) => map(row.lineItemsDto, (lineItem, x) => (
            <div
                key={`item_qty_${x}`}
                className="no-wrap text-center"
            >
                {lineItem.quantity}
            </div>
        )),
    }, {
        name: "Price",
        selector: "lineItemsDto",
        key: "lineItemsDto_price",
        // sortable: true,
        format: (row) => map(row.lineItemsDto, (lineItem, x) => (
            <div key={`item_price_${x}`} className="no-wrap">{formatUSD(lineItem.price)}</div>
        )),
    },
        ...(tableType === StrayCouponTableTypes.RESOLVED ? STRAY_STATUS_COLUMN : []),
        ...(tableType === StrayCouponTableTypes.RESOLVED ? RESOLVED_COLUMN : []),
        ...(props.canHideInstantRebateInvoiceFailures ? HIDDEN_COLUMN : []),
        ...(tableType === StrayCouponTableTypes.INELIGIBLE && props.canViewReprocessStrayCoupons ? INELIGIBLE_COLUMN : []),
    ];

    const  handleRowSelectChanged = (rows) => {
        const tableTypeSources = {
            [StrayCouponTableTypes.INELIGIBLE]: "ineligible",
            [StrayCouponTableTypes.RESOLVED]: "resolved",
            [StrayCouponTableTypes.PENDING_RESOLUTION]: "pending",
            [StrayCouponTableTypes.EXPIRED]: "expired",
        };

        if (props.onRowsSelected) {
            props.onRowsSelected(rows, tableTypeSources[tableType]);
        } else {
            setSelectedRowIds(rows);
        }
    }

    //Simple Search
    const searchFields = ["invoiceNumber"];
    const redemptionSearchFields = ["redemptionId", "petName", "offerDescriptionShort", "lineItemDescription"];
    const searchTokens = props.search?.toLowerCase().split(" ");

    const checkFieldAllMatch = (fieldValue) => {
        if(fieldValue) {
            let allMatch = true;
            for (let j = 0; j < searchTokens.length; j++) {
                const token = searchTokens[j];
                if (!fieldValue.toString().toLowerCase().includes(token)) {
                    allMatch = false;
                }
            }
            return allMatch;
        }
        return false
    }

    const tableData = useMemo(() => {
        if(!!props.search) {
            return getFilteredCoupons();
        }
        return props.filteredCoupons;
    }, [props.search, props.filteredCoupons]);

    useEffect(() => {
        if (!selectedAction) {
            setSelectedAction(props.availableActions[0].value);
        }
    }, [props.availableActions])

    const getFilteredCoupons = () => {
        return filter(props.filteredCoupons, invoice => {
            for (let i = 0; i < searchFields.length; i++) {
                const fieldValue = invoice[searchFields[i]];
                if (!!checkFieldAllMatch(fieldValue)){
                    return true;
                }

                //Filter visible invoices based on the redemptionSearchFields
                const filteredRedemptions =  filter(invoice.redemptions, redemption => {
                    for (let i = 0; i < redemptionSearchFields.length; i++) {
                        const redemptionFieldValue = redemption[redemptionSearchFields[i]];
                        if (!!checkFieldAllMatch(redemptionFieldValue)){
                            return true;
                        }
                    }
                })
                if (!!filteredRedemptions?.length){
                    return true;
                }
            }
            return false;
        })
    };

    const handleReprocessCoupons = (e) => {
        e.stopPropagation();
        e.preventDefault();
        startReprocessCoupons(selectedRowIds, () => {
            setSelectedRowIds([]);
        })
    }

    const startReprocessCoupons = (invoiceIds, callback = () => {}) => {
        if (props.canReprocessCoupons && invoiceIds.length) {
            // Collect the manufacturer IDs
            let selectedManufacturers = [];
            invoiceIds.forEach(invoiceId => {
                const coupon = props.strayCoupons.unresolved?.[invoiceId] || props.strayCoupons.ineligible?.[invoiceId];
                selectedManufacturers = [
                    ...selectedManufacturers,
                    ...coupon.lineItemsDto.map(dto => dto.mappedToManufacturerId)
                ]
            });

            const body = {
                "clinicId": props.clinicId,
                "invoices": invoiceIds,
                "manufacturers": [...new Set(selectedManufacturers)]
            };

            props.createReprocessingJob(body, (res) => {
                toast.success(`Successfully reprocessed coupons`);
                callback();
            })
        }
    }


    const handleBulkAction = (e) => {
        e.stopPropagation();
        e.preventDefault();

        props.batchUpdateStrayCoupon({
            invoiceIds: selectedRowIds,
            couponAction: selectedAction,
            status: selectedStatus
        });
        setSelectedRowIds([]);
    }

    const manufacturesFromIneligible = useMemo(() => {
        if (!ineligibleToReprocess) {
            return [];
        }
        const selectedManufacturers = uniq(flatMap(ineligibleToReprocess.lineItemsDto, "mappedToManufacturerId"));
        return map(selectedManufacturers, mfrId => ({ name: (props.providers?.[mfrId]?.name || `UNKNOWN - ID: ${mfrId}`), value: mfrId }));
    }, [ineligibleToReprocess]);

    const handleReprocessStrayCoupon = (e) => {
        e.stopPropagation();
        e.preventDefault();

        setShowSpinner(true);

        batchUpdateStrayCoupon({
            invoiceIds: [ineligibleToReprocess.invoiceId],
            couponAction: StrayCouponsBatchTypes.Eligible,
            manufacturerId: ineligibleMfrIdToReprocess.value,
        }).then(res => {
            startReprocessCoupons([ineligibleToReprocess.invoiceId], () => {
                props.markIsEligible(ineligibleToReprocess.invoiceId);
                setIneligibleToReprocess(null);
                setIneligibleMfrIdToReprocess(null);
                setShowSpinner(false);
            });
        }).catch((error) => {
            getDefaultErrorHandler(() => {}, "resolving stray coupons")(error);
        });
    }

    const handleChangeAction = ({ value }) => {
        setSelectedAction(value);
        setSelectedStatus(null);
    };

    const sectionHeader = (
        <Tooltip
            position={RIGHT}
            tip={toolTips[tableType]}
        >
            <h3>{sectionTitleText} <i className="far fa-info-circle" /></h3>
        </Tooltip>
    );
    const canPerformAction = !!selectedRowIds.length;
    const canReprocess = props.canReprocessCoupons && canPerformAction;
    const canSubmit = props.canResolveInstantRebateInvoiceFailures && canPerformAction && selectedAction && (selectedAction !== StrayCouponsBatchTypes.Resolved || selectedStatus);

    return (
        <div className={styles.root}>
            <AccordionSection
                header={sectionHeader}
                id={sectionTitleText}
                onClick={() => setSectionOpen(!sectionOpen)}
                isOpen={sectionOpen}
            >
                {(tableType === StrayCouponTableTypes.PENDING_RESOLUTION && !tableData?.length) ? (
                    <h3 className="text-center padding-bottom-lg text-gray">Congratulations! You don't have any stray coupons at the moment.</h3>
                ) : (
                    <>
                        <div className={styles.actionButtons}>
                            {(tableType === StrayCouponTableTypes.PENDING_RESOLUTION && (props.canReprocessCoupons || props.canViewReprocessCoupons)) && (
                                <div className={classnames({
                                    [styles.addDivider]: props.canResolveInstantRebateInvoiceFailures
                                })}>
                                    <Button
                                        onClick={handleReprocessCoupons}
                                        disabled={!canReprocess}
                                    >
                                        Reprocess Selected
                                    </Button>
                                </div>
                            )}
                            {props.canResolveInstantRebateInvoiceFailures && (
                                <div className="flex spaced-content align-center">
                                    <div>Mark Selected As</div>
                                    <Dropdown
                                        value={selectedAction}
                                        name="markSelectedDropdown"
                                        onChange={handleChangeAction}
                                        options={props.availableActions}
                                    />
                                    {((tableType === StrayCouponTableTypes.PENDING_RESOLUTION || tableType === StrayCouponTableTypes.EXPIRED) && selectedAction === StrayCouponsBatchTypes.Resolved) && (
                                        <StrayCouponStatusDropdown
                                            onChange={data => setSelectedStatus(data.value)}
                                            name="status"
                                            value={selectedStatus}
                                            label=""
                                            hidePlaceholder
                                            required={selectedAction === StrayCouponsBatchTypes.Resolved}
                                        />
                                    )}
                                    <Button
                                        onClick={handleBulkAction}
                                        disabled={!canSubmit}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            )}
                        </div>
                        <SortableDataTable
                            columns={COLUMNS}
                            rawData={tableData}
                            striped
                            selectable={tableType !== StrayCouponTableTypes.RESOLVED || props.canResolveInstantRebateInvoiceFailures || props.canReprocessCoupons}
                            selectedRowIds={selectedRowIds}
                            onRowSelectChange={handleRowSelectChanged}
                            noPagination
                            green
                            noResultsText={noResultsText}
                        />
                    </>
                )}
            </AccordionSection>
            {!!ineligibleToReprocess && (
                <Modal
                    show
                    onClose={() => {
                        setIneligibleToReprocess(null);
                        setIneligibleMfrIdToReprocess(null);
                    }}
                    small
                    modalTitle={"Reprocess Ineligible Coupon"}
                >
                    <div className={"margin-bottom-sm"}>
                        Select Manufacturer:
                        <Dropdown
                            name={"manufacturer"}
                            onChange={setIneligibleMfrIdToReprocess}
                            options={manufacturesFromIneligible}
                            value={ineligibleMfrIdToReprocess?.value}
                            placeholder={"Select a Manufacturer to Reprocess"}
                        />
                    </div>
                    <div className={"text-right"}>
                        <Button
                            onClick={handleReprocessStrayCoupon}
                            disabled={!ineligibleMfrIdToReprocess}
                        >
                            Submit for Reprocess
                        </Button>
                    </div>
                </Modal>
            )}
            <SpinnerTakeover show={showSpinner} onTop />
        </div>
    );
}

StrayCouponsTable.defaultProps = {
    isIneligible: false,
    isResolved: false,
    onRowsSelected: undefined,
    sectionOpenByDefault: false,
    selectedRows: [],
};

StrayCouponsTable.propTypes = {
    tableType: PropTypes.oneOf(StrayCouponTableTypes.ALL_TYPES).isRequired,
    clinicId: PropTypes.number.isRequired,
    onRowsSelected: PropTypes.func,
    sectionOpenByDefault: PropTypes.bool,
    selectedRows: PropTypes.array,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        //Permissions
        const canViewInstantRebateInvoiceFailures = userHasPermission(PermissionTypes.VIEW, UserPermissions.INSTANT_REBATE_INVOICE_FAILURES, userProfile);
        const canHideInstantRebateInvoiceFailures = userHasPermission(PermissionTypes.HIDE, UserPermissions.INSTANT_REBATE_INVOICE_FAILURES, userProfile);
        const canResolveInstantRebateInvoiceFailures = userHasPermission(PermissionTypes.RESOLVE, UserPermissions.INSTANT_REBATE_INVOICE_FAILURES, userProfile);
        const canReprocessCoupons = userHasPermission(PermissionTypes.EDIT, UserPermissions.COUPON_REPROCESSING, userProfile);
        const canViewReprocessCoupons = userHasPermission(PermissionTypes.VIEW, UserPermissions.COUPON_REPROCESSING, userProfile);


        const canReprocessStrayCoupons = userHasPermission(PermissionTypes.EDIT, UserPermissions.STRAY_COUPON_REPROCESSING, userProfile);
        const canViewReprocessStrayCoupons = userHasPermission(PermissionTypes.VIEW, UserPermissions.STRAY_COUPON_REPROCESSING, userProfile);


        const ResetToPendingText = `Pending${ownProps.tableType === StrayCouponTableTypes.INELIGIBLE ? " (Ineligible)": ""}`;
        const primaryAction = ownProps.tableType === StrayCouponTableTypes.RESOLVED ? (
            { name:ResetToPendingText, value: StrayCouponsBatchTypes.Pending }
        ) : (
            { name:"Resolved", value: StrayCouponsBatchTypes.Resolved }
        );
        const availableActions = [
            primaryAction,
            { name:"Hidden", value: StrayCouponsBatchTypes.Hidden },
            { name:"Not Hidden", value: StrayCouponsBatchTypes.Unhidden }
        ];

        let filteredCoupons;
        if (ownProps.tableType === StrayCouponTableTypes.RESOLVED) {
            filteredCoupons = (canHideInstantRebateInvoiceFailures ? state.entities.strayCoupons?.resolved : filter(state.entities.strayCoupons?.resolved, {isHiddenFromClinic: false}) || []);
        } else if (ownProps.tableType === StrayCouponTableTypes.INELIGIBLE) {
            filteredCoupons = (canHideInstantRebateInvoiceFailures ? state.entities.strayCoupons?.ineligible : filter(state.entities.strayCoupons?.ineligible, {isHiddenFromClinic: false}) || []);
        } else if (ownProps.tableType === StrayCouponTableTypes.PENDING_RESOLUTION) {
            filteredCoupons = (canHideInstantRebateInvoiceFailures ? state.entities.strayCoupons?.unresolved : filter(state.entities.strayCoupons?.unresolved, {isHiddenFromClinic: false}) || []);
        } else if (ownProps.tableType === StrayCouponTableTypes.EXPIRED) {
            filteredCoupons = (canHideInstantRebateInvoiceFailures ? state.entities.strayCoupons?.expired : filter(state.entities.strayCoupons?.expired, {isHiddenFromClinic: false}) || []);
        }

        // Order the coupons by invoiceDate (defaultOrder)
        filteredCoupons = orderBy(filteredCoupons, "defaultOrder", "asc");

        return {
            canViewInstantRebateInvoiceFailures,
            canHideInstantRebateInvoiceFailures,
            canResolveInstantRebateInvoiceFailures,
            canReprocessCoupons,
            canViewReprocessCoupons,
            canReprocessStrayCoupons,
            canViewReprocessStrayCoupons,
            strayCoupons: state.entities.strayCoupons,
            filteredCoupons,
            search: state.entities.genericSearch || "",
            availableActions,
            providers: keyBy(flatMap(state.entities.programs, "provider"), "id"),
        }
    },
    (dispatch) => ({
        batchUpdateStrayCoupon: (data) => dispatch(CouponActions.batchUpdateStrayCoupon(data)),
        createReprocessingJob: (data, successCallback) => dispatch(CouponActions.createReprocessingJob(data, successCallback)),
        markIsEligible: (invoiceId) => dispatch(CouponActions.markIsEligible(invoiceId)),
        getPrograms: (clinicId) => dispatch(CouponActions.getPrograms(clinicId)),
    })
)(StrayCouponsTable);
