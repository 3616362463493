import React, { useContext, useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import moment from "moment";
import ceil from "lodash/ceil";
import get from "lodash/get";
import * as AdminApi from "api/AdminApi";
import {windowSizeContext} from "AppRoot";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import DateInput from "components/common/DateInput";
import OpenInvoicePDFLinkNewTab from "components/common/OpenInvoicePDFLinkNewTab";
import ResultsPerPageDropdown from "components/coupons/widgets/ResultsPerPageDropdown";
import SortableDataTable from "components/common/SortableDataTable";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import getLocalTimezone from "utils/getLocalTimezone";
import { addCommasToNumber, formatUSD, hasValue } from "utils/numeric";
import { downloadFile, handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import toast from "utils/toast";
import * as UserPermissions from "constants/UserPermissions";

const COLUMNS = [{
    name: "Invoice Date",
    selector: "invoiceDate",
    key: "invoiceDate",
    sortable: true,
    format: (row) => row.invoiceDate ? getLocalTimezone(row.invoiceDate, "MM/DD/YYYY") : "",
}, {
    name: "Invoice #",
    selector: "extendedInvoiceNumber",
    key: "extendedInvoiceNumber",
    sortable: true,
    format: row => (
        <OpenInvoicePDFLinkNewTab
            className="text-primary"
            invoiceId={row.invoiceId}
            extendedInvoiceNumber={row.extendedInvoiceNumber}
        />
    )
}, {
    name: "Patient",
    selector: "petName",
    key: "petName",
    sortable: true,
    format: row => <div className="no-wrap">{row.petName}</div>
}, {
    name: "Client Last Name",
    selector: "petOwnerLastName",
    key: "petOwnerLastName",
    sortable: true,
    format: row => <div className="no-wrap">{row.petOwnerLastName}</div>

}, {
    name: "Species",
    selector: "species",
    key: "species",
    sortable: true,
}, {
    name: "DOB",
    selector: "dob",
    key: "dob",
    sortable: true,
    format: (row) => row.dob ? getLocalTimezone(row.dob, "MM/DD/YYYY") : "",
}, {
    name: "Product Code",
    selector: "clinicProductCode",
    key: "clinicProductCode",
    sortable: true,
}, {
    name: "Product",
    selector: "clinicProductName",
    key: "clinicProductName",
    sortable: true,
    format: (row) => <div className="no-wrap">{row.clinicProductName}</div>
}, {
    name: "Qty",
    selector: "quantity",
    key: "quantity",
    sortable: true,
}, {
    name: "Price",
    selector: "price",
    key: "price",
    sortable: true,
    format: (row) => hasValue(row.price) ? formatUSD(row.price) : "",
}, {
    name: "MFR",
    selector: "providerName",
    key: "providerName",
    sortable: true,
    format: (row) => <div className="no-wrap">{row.providerName}</div>
}];

const areDatesValidForExport = (startDate, endDate) => {
    // API fails if dates are more than 6 months apart
    // Return true if dates are less than 6 months apart
    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);
    const halfYearDays = 183; // Roughly 6 months (365 days / 2)
    return endDateMoment.diff(startDateMoment, "days") <= halfYearDays;
};

function InvoiceLineItems(props) {
    if (!props.canViewClinicManagement) {
        return (<AccessDenied/>);
    }

    const TODAY = new moment().format('MM/DD/YYYY');
    const LAST_MONTH = new moment().subtract(1, 'months').format('MM/DD/YYYY');
    const [startDate, setStartDate] = useState(LAST_MONTH);
    const [endDate, setEndDate] = useState(TODAY);
    const [loading, setLoading] = useState(false);
    const [invoiceLineItems, setInvoiceLineItems] = useState([]);
    const [totalLineItems, setTotalLineItems] = useState(0);
    const [orderValue, setOrderValue] = useState("invoiceDate");
    const [orderDir, setOrderDir] = useState("desc");
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(25);
    const context = useContext(windowSizeContext) || {};

    const searchData = {
        clinicId: props.clinicId,
        invoiceStartDate: startDate,
        invoiceEndDate: endDate,
        clinicProductId: null,
        search: props.search,
        orderBy: orderValue,
        orderDir: orderDir,
        limit,
        offset
    };

    const fetchData = () => {
        if (props.canViewClinicManagement) {
            setLoading(true);
            AdminApi.getInvoiceLineItems(searchData)
                .then((res) => {
                    setInvoiceLineItems(res.body.data);
                    setTotalLineItems(res.body.totalRecordCount);
                    setLoading(false);
                })
                .catch((error) => {
                    handleErrorResponse("loading invoice line items", error);
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        if(startDate && endDate) {
            fetchData();
        }
    }, [offset]);

    useEffect(() => {
        if(startDate && endDate) {
            //If a search option is changed then either set the offset to 0 (which will run the search)
            // or run the search
            if(offset !== 0) {
                setOffset(0);
            } else {
                fetchData();
            }
        }
    }, [props.search, startDate, endDate, limit, orderValue, orderDir]);

    const handleDownloadInvoiceLineItems = async () => {
        if (props.canViewClinicManagement) {
            if (areDatesValidForExport(startDate, endDate)) {
                toast.success("Generating Export...");
                const newData = {
                    clinicId: searchData.clinicId,
                    startDate: searchData.invoiceStartDate,
                    endDate: searchData.invoiceEndDate
                }

                try {
                    const res = await AdminApi.downloadInvoiceLineItems(newData);
                    downloadFile(res, "xlsx");
                } catch (e) {
                    if (e.response) {
                        const bodyString = await e.response.body.text();
                        const bodyJson = JSON.parse(bodyString);
                        const failMessage = get(bodyJson, "Detail");
                        if (!!failMessage) {
                            toast.error(`Could Not Generate Export: ${failMessage}`);
                        } else {
                            toast.error("Error Generating Export");
                        }
                    }
                    console.error(e);
                }
            } else {
                toast.warn("Dates Must Be Within 6 Months of Each Other to Generate Export");
            }
        }
    };

    const hasDates = (startDate && endDate);
    const canShowTable = (hasDates && invoiceLineItems?.length);

    const handleSort = (value, direction) => {
        setOrderValue(value);
        setOrderDir(direction);
    }
    const totalPages = ceil(totalLineItems / limit);
    const currentPage = ceil(offset / limit) + 1;

    const getTable = () => {
        if(canShowTable) {
            return (
                <>
                    <div className="full-width flex spaced-content">
                        <div className="flex-1">
                            <Button
                                onClick={handleDownloadInvoiceLineItems}
                                iconOnly
                            >
                                <i className="fa fa-download"/>
                            </Button>
                        </div>
                        <div className="flex-none flex align-center margin-bottom-sm">
                            <label className="margin-right-sm mobile-hide">Viewing</label>
                            <ResultsPerPageDropdown
                                value={limit}
                                onChange={({value}) => setLimit(value)}
                            />
                            <div className="margin-left-sm">of {addCommasToNumber(totalLineItems)} <span className="mobile-hide">line items</span></div>
                        </div>
                    </div>
                    <div className="flex justify-center ">
                        <SortableDataTable
                            resultsPerPage={limit}
                            columns={COLUMNS}
                            rawData={invoiceLineItems}
                            onSort={handleSort}
                            striped
                            green
                        />
                    </div>

                    {totalPages > 1 && (
                        <div className="flex full-width flex-centered  margin-top-sm">
                            <div className="flex-none">
                                <Button
                                    disabled={!(currentPage > 1)}
                                    onClick={() => setOffset(offset - limit)}
                                >
                                    <i className="fa fa-chevron-left margin-right-sm" /> Previous
                                </Button>
                            </div>

                            <div className="flex-1 text-center">
                                Page {currentPage} of {totalPages}
                            </div>
                            <div className="flex-none">
                                <Button
                                    disabled={(currentPage >= totalPages)}
                                    onClick={() => setOffset(offset + limit)}
                                >
                                    Next <i className="fa fa-chevron-right margin-left-sm" />
                                </Button>
                            </div>
                        </div>
                    )}
                </>
            )
        } else {
            if(hasDates) {
                return(
                    <div className="flex justify-center margin-top-md text-center text-lg text-gray">
                        <span>There are no results for the specified date range.</span>
                    </div>
                )
            } else {
                return (
                    <div className="flex justify-center margin-top-md text-lg text-gray">
                        <span>Select a date range to see the report.</span>
                    </div>
                )
            }
        }
    }

    return (
        <div className="margin-bottom-lg">
            <div className="flex flex-column">
                <div className="flex spaced-content justify-center flex-wrap">
                    <div className="flex-none">
                        <DateInput
                            disabled={!props.canViewClinicManagement}
                            dateFormat={"MM/DD/YYYY"}
                            value={startDate}
                            onChange={({value}) => setStartDate(value)}
                            maxDate={endDate || TODAY}
                            label="Start Date"
                            aligned={!context.isPhone}
                        />
                    </div>
                    <div className="flex-none">
                        <DateInput
                            disabled={!props.canViewClinicManagement}
                            dateFormat={"MM/DD/YYYY"}
                            value={endDate}
                            onChange={({value}) => setEndDate(value)}
                            maxDate={TODAY}
                            minDate={startDate}
                            label="End Date"
                            aligned={!context.isPhone}
                        />
                    </div>
                </div>
                {getTable()}
            </div>
            <SpinnerTakeover show={loading} />
        </div>
    );
}

InvoiceLineItems.propTypes = {
    clinicId: PropTypes.number.isRequired,
    isAdmin: PropTypes.bool,
}

const connector = connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            canViewClinicManagement,
            search: state.entities.genericSearch,
        }
    }
);

export default compose(
    withRouter,
    connector
)(InvoiceLineItems);
