import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import keys from "lodash/keys";
import map from "lodash/map";
import styles from "./CouponLibrary.scss";
import ProgramTile from "components/coupons/widgets/ProgramTile";
import * as CouponActions from "actions/CouponActions";
import { getNodeId } from "utils/AdminData";
import { getClinicInfo } from "utils/ClinicData";
import AccessDenied from "components/common/AccessDenied";
import ClinicBase from "components/layout/ClinicBase";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import * as SearchTypes from "constants/SearchTypes";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import { CLINIC_TAGS } from "constants/AdminControls";
import * as UserPermissions from "constants/UserPermissions";

function CouponLibrary(props) {
    const location = useLocation();

    useEffect(() => {
        if (props.canViewCouponLibrary) {
            props.getPrograms(props.clinicId);
        }
    }, [props.clinicId]);

    const isAdminView = () => {
        return props.clinicId && location.pathname.indexOf("/admin/") > -1;
    };

    const isProviderView = () => {
        return props.clinicId && location.pathname.indexOf("/provider/") > -1;
    };

    const renderProgramTiles = () => {
        if (keys(props.programs).length) {
            return map(props.programs, couponProgram => {
                if (couponProgram.isLibraryDisabled) {
                    return null;
                }

                const data = {
                    ...couponProgram,
                    imageUrl: couponProgram.provider.mediumImageUri,
                    providerName: couponProgram.provider.name,
                };

                return (
                    <ProgramTile
                        canViewClinicEnrollment={props.canViewClinicEnrollment}
                        canEditClinicEnrollment={props.canEditClinicEnrollment}
                        programInfo={data}
                        key={`programId_${couponProgram.id}`}
                        adminView={isAdminView()}
                        providerView={isProviderView()}
                        clinicId={props.clinicId}
                        nodeId={props.nodeId}
                    />
                )
            });
        }
        return (
            <div className={styles.noPrograms}>
                <span>No Programs to show!</span>
            </div>
        );
    }

    return (
        <ClinicBase
            searchConfig={SearchTypes.VOUCHER_CODE}
            clinicId={props.clinicId}
            adminControls={CLINIC_TAGS}
            docTitle="Coupon Library"
            pageTitle="Coupon Library"
        >
            {!props.clinicInfo ? (
                <SpinnerTakeover show />
            ) : (
                <div className={styles.root}>
                    <div className={styles.gridContainer}>
                        <div className="flex-none flex margin-bottom-sm">
                            <h2 className="flex-1">Programs</h2>
                        </div>
                        {!props.canViewCouponLibrary ? (
                            <AccessDenied/>
                        ) : (
                            <div className={styles.grid}>
                                {renderProgramTiles()}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </ClinicBase>
    );
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;
        const nodeId = getNodeId(state, ownProps);
        const userProfile = state.user.userProfile;
        const canViewCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        const canViewClinicEnrollment = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_ENROLLMENT, userProfile);
        const canEditClinicEnrollment = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_ENROLLMENT, userProfile);
        return {
            clinicId,
            nodeId,
            clinicInfo,
            canViewCouponLibrary,
            canViewClinicEnrollment,
            canEditClinicEnrollment,
            userProfile: state.user.userProfile,
            providers: state.entities.providers,
            programs: state.entities.programs,
        }
    },
    (dispatch) => ({
        getPrograms: (clinicId) => dispatch(CouponActions.getPrograms(clinicId)),
    }),
)(CouponLibrary);
