import React, { useContext, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {NavLink, useLocation, useParams} from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ProviderSidebar.scss";
import includes from "lodash/includes";
import map from "lodash/map";
import Button from "components/common/Button";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import flatMap from "lodash/flatMap";
import filter from "lodash/filter";
import { getLinkIsVisible } from "utils/permissions/sidebarVisibilityRules";
import { windowSizeContext } from "AppRoot";
import Image from "components/common/Image";
import getProviderId from "utils/getProviderId";

const watermark = require("components/common/branding/watermark_background.jpg");
const reportsIcon = require("components/common/branding/reports-icon.png");

const LINK_ID_DASHBOARD = "dashboard";
const LINK_ID_MAP = "map";
const LINK_ID_CLINICS = "clinics_overview";
const LINK_ID_CLINIC_PROGRAMS = "clinic_programs_overview";
const LINK_ID_GAMES = "games";
const LINK_ID_MANAGE_INVITES = "manage_invites";
const LINK_ID_DEMO_GAMES = 'demo_games';
const LINK_ID_DEMO_MANAGE_INVITES = 'demo_manage_invites';
const LINK_ID_DEMO_CLINIC_GAMES = 'demo_clinic_games';
const LINK_ID_REPORTS = "reports";
const LINK_ID_RESOURCES = "resources";
const LINK_ID_PRODUCT_TAGGING = "product_tagging";


function ProviderSidebar(props) {
    const LINKS = [{
        icon: "far fa-tachometer-fast",
        id: LINK_ID_DASHBOARD,
        location: "/",
        text: "Dashboard",
    }, {
        icon: "far fa-building",
        id: LINK_ID_CLINICS,
        location: "/clinics",
        text: "Clinics Overview",
    }, {
        icon: "far fa-book",
        id: LINK_ID_CLINIC_PROGRAMS,
        location: "/clinic-program-enrollment",
        text: "Programs",
    }, {
        icon: "far fa-map",
        id: LINK_ID_MAP,
        location: "/map",
        text: "Map",
    }, {
        icon: "far fa-trophy-alt",
        id: LINK_ID_GAMES,
        location: "/games",
        text: "Greenline Games™",
        subItems: [{
            id: LINK_ID_GAMES,
            location: "/games",
            text: "Manage Games",
            visible: true,
        }, {
            id: LINK_ID_MANAGE_INVITES,
            location: "/games/invites",
            text: "Manage Invites",
            visible: true,
        }],
    }, {
        icon: "far fa-trophy-alt",
        id: LINK_ID_DEMO_GAMES,
        location: "/demo/games",
        text: "DEMO - Greenline Games™",
        subItems: [{
            id: LINK_ID_DEMO_GAMES,
            location: "/demo/games",
            text: "Demo Manage Games",
            visible: true,
        }, {
            id: LINK_ID_DEMO_MANAGE_INVITES,
            location: "/demo/games/invites",
            text: "Demo Manage Invites",
            visible: true,
        }, {
            id: LINK_ID_DEMO_CLINIC_GAMES,
            location: "/demo/games/clinic-games",
            text: "Demo Clinic Games",
            visible: true,
        }],
    }, {
        icon: "far fa-books",
        id: LINK_ID_RESOURCES,
        location: "/resources/",
        text: "Resources",
    }, {
        icon: "far fa-tag",
        id: LINK_ID_PRODUCT_TAGGING,
        location: "/product-tagging",
        alternate: "products/tags",
        text: "Product Tagging",
    }, {
        iconImage: reportsIcon,
        id: LINK_ID_REPORTS,
        location: "/reports",
        text: "Reports",
    }];

    const location = useLocation();
    const params = useParams();
    const nodeId = Number(params?.nodeId || props.nodeId);
    const providerId = getProviderId(params, props.userProfile);
    const [collapsed, setCollapsed] = useState(props.collapsible);
    const context = useContext(windowSizeContext) || {};
    const collapsible = props.collapsible || context.isPhone;

    const isAdmin = useMemo(() => providerId && nodeId && location.pathname.indexOf("/admin/") > -1,
        [providerId, nodeId, location]
    );
    const basePath = useMemo(() =>
        isAdmin ? `/admin/provider/${providerId}/location/${nodeId}` : `/provider/location/${nodeId}`
    , [isAdmin, nodeId, providerId]);

    const collapseBtnIcons = useMemo(() =>
        collapsed ? "fas fa-chevron-circle-left" : "fas fa-chevron-circle-right",
    [collapsed]);

    useEffect(() => {
        if (collapsible) {
            setCollapsed(true);
        }
    }, [collapsible]);

    const getDefaultLocation = (link) => {
        const visibleSubLinks = filter(link?.subItems, { visible: true });
        if (visibleSubLinks?.length) {
            return visibleSubLinks[0].location;
        } else {
            return link.location;
        }
    }

    const subLinks = ( link ) => {
        return map(filter(link.subItems, { visible: true }), (item, index) => {
            let {exact: subExact} = getExactMatch(item.location);
            return (
                <NavLink
                    data-testid={`sidebar_links_${item.id}`}
                    key={`navItem_${link.id}_sub_${index}`}
                    className={classnames(styles.navItem, {
                        [styles.selected]: subExact,
                        "test-selected": subExact,
                    })}
                    to={`${basePath}${item.location}`}
                >
                    <span className={styles.icon}><i className="fas fa-circle"/></span>
                    <span className={styles.text}>{item.text}</span>
                </NavLink>
            );
        });
    }

    const getExactMatch = (linkLocation) => {
        const { pathname } = location;
        let relativePath = pathname;
        if (props.clinicId) {
            if ((pathname.indexOf("/admin/") > -1) || (pathname.indexOf("/provider/") > -1)) {
                relativePath = pathname.split(props.clinicId)[1];
            }
        } else if (props.nodeId) {
            if ((pathname.indexOf("/admin/") > -1) || (pathname.indexOf("/provider/") > -1)) {
                relativePath = pathname.split(props.nodeId)[1];
            }
        } else if (pathname.indexOf("/admin/") > -1) {
            relativePath = pathname.split("admin")[1]
        }
        const pathParts = relativePath.substr(1).split("/");
        const exact = relativePath === linkLocation;
        let partial = pathParts[0].indexOf(linkLocation.substr(1)) > -1;
        if (!partial) {
            partial = `${pathParts[0]}/${pathParts[1]}`.indexOf(linkLocation.substr(1)) > -1;
        }

        return {
            partial,
            exact
        }
    };

    const navLink = (link) => {
        const defaultLocation = getDefaultLocation(link);
        const { partial, exact} = getExactMatch(link.location);
        let selected = exact;
        let showSubs = false;

        if (link.subItems?.length) {
            showSubs = partial || exact;
            selected = partial || exact;
        }

        return (
            <div
                key={`nav_item_${link.id}`}
                className={styles.subNavList}
            >
                <NavLink
                    data-testid={`sidebar_links_${link.id}`}
                    to={`${basePath}${defaultLocation}`}
                    className={classnames(styles.navItem, {
                        [styles.selected]: selected,
                        "test-selected": selected,
                    })}
                >
                    {link.iconImage ? (
                        <span className={classnames(styles.icon, {
                            [styles.vetCheckIcon]: (link.id === "vetcheck")
                        })}>
                            <Image
                                className={styles.iconImage}
                                src={link.iconImage}
                                title={link.text}
                                alt={link.text}
                            />
                        </span>
                    ) : (
                        <span className={styles.icon}><i className={link.icon}/></span>
                    )}
                    {link.textImage ? (
                        <span className={styles.text}>
                            <Image style={{maxHeight: "30px", maxWidth: "100%"}} src={link.textImage} title={link.text}/>
                        </span>
                    ) : (
                        <span className={styles.text}>{link.text}</span>
                    )}
                </NavLink>
                {showSubs && (
                    <div className={styles.subLinks}>
                        {subLinks(link)}
                    </div>
                )}
            </div>
        );
    }

    const renderNavItems = () => {
        return map(LINKS, link => {
            if (!getLinkIsVisible(link, props, "provider")) {
                // skip this one...
                return;
            }

            return navLink(link);
        });
    }

    return (
        <nav className={classnames(styles.root, {
            [styles.collapsible] : collapsible,
        })}>
            <div className={classnames(styles.menuBar, {
                [styles.collapsed]: collapsed && collapsible
            })}>
                {collapsible && (
                    <Button
                        text
                        className={styles.collapseButton}
                        onClick={() => setCollapsed(!collapsed)}
                    >
                        <i className={collapseBtnIcons} />
                    </Button>
                )}
                <div className={styles.navItems}>
                    {renderNavItems()}
                </div>
                <div className={styles.clinicInfo} />
                <div className={styles.bgLogo}>
                    <img src={watermark} alt="Greenline Watermark" />
                </div>
            </div>
        </nav>
    );
}


ProviderSidebar.propTypes = {
    collapsible: PropTypes.bool,
    nodeId: PropTypes.number,
    providerId: PropTypes.number,
}

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const node = state.entities.providerHierarchies?.[ownProps.providerId]?.[ownProps.nodeId] || {};
        const corporateGroupProviderIds = flatMap(filter(state.entities.providers, {isCorporateGroup: true}), "id");
        const isCorporateGroup = !!includes(corporateGroupProviderIds, ownProps?.providerId);

        //Permissions
        const canViewProviderBiGames = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canViewProviderBiGamesDemo = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES_DEMO, userProfile);
        const canAdminProviderBiGames = userHasPermission(PermissionTypes.ADMIN, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canViewProductTagging = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_PRODUCT_TAGGING, userProfile);

        // Check user and node have permission to view games tab
        const canViewGames = ((canAdminProviderBiGames || canViewProviderBiGames) && node?.hasGames);
        const canViewGamesDemo = canViewProviderBiGamesDemo && node && !node.hasGames;
        const canViewProviderBiGamesDrillDown = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES_DRILLDOWN, userProfile);
        const canViewClinics = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canViewProviderWellnessPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_WELLNESS_PLANS, userProfile);
        const canViewCorporateGroupClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);
        const canViewCorpGroupProgramEnrollment = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_PROGRAM_ENROLLMENT, userProfile);
        const canViewProviderReports = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_REPORTS, userProfile);
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);

        return {
            userProfile,
            node,
            isCorporateGroup,
            onClinicLevel: state.entities.onClinicLevel,
            hasGames: node.hasGames,

            // Permissions
            canViewGames,
            canViewGamesDemo,
            canViewProviderBiGamesDrillDown,
            canViewProviderWellnessPlans,
            canViewClinics,
            canViewCorporateGroupClinic,
            canViewProductTagging,
            canViewProviderReports,
            canViewClinicBIGamesDashboard,
            canViewCorpGroupProgramEnrollment,
        };
    }
)(ProviderSidebar);
