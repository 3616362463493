import React from "react";
import PropTypes from "prop-types";
import * as ClinicApi from "api/ClinicApi";
import Button from "components/common/Button";
import Tooltip from "components/common/Tooltip";
import { PRIMARY } from "constants/ButtonTypes";
import { LEFT } from "constants/TooltipPositionIds";
import { daysFromNow } from "utils/DateTransformations";
import { handleErrorResponse, handleSuccessResponse } from "utils/request";

export default function ExtendPasswordExpiration({
	canResetPasswords = false,
    clinicAdminUserId,
    clinicId,
}) {

	const extendPasswordExpiration = async() => {
        const newExpirationDate = daysFromNow(7)

        try {
            await ClinicApi.extedAdminPasswordExpiration(clinicId, clinicAdminUserId, newExpirationDate);
            handleSuccessResponse("Password Updated");
        }
        catch (e) {
            handleErrorResponse("Extend Password Validity Failed", e);
        }
    }

	if (canResetPasswords && !!clinicAdminUserId) {
		return (
			<Tooltip
				position={LEFT}
				tip="Extend Password Expiration"
			>
				<div className="flex-none">
					<Button
						className="margin-bottom-x-sm"
						disabled={!canResetPasswords}
						iconOnly
						onClick={extendPasswordExpiration}
						type={PRIMARY}
					>
						<i className="fa fa-key"/>
					</Button>
				</div>
			</Tooltip>
		);
	} else return null;
}

ExtendPasswordExpiration.defaultProps = {
	canResetPasswords: false,
	clinicAdminUserId: null,
};

ExtendPasswordExpiration.propTypes = {
	canResetPasswords: PropTypes.bool,
	clinicAdminUserId: PropTypes.number,
	clinicId: PropTypes.number.isRequired,
};
