import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clone from "lodash/clone";
import map from "lodash/map";
import AddClientFields from "./AddClientFields";
import Button from "components/common/Button";
import ClientAddressFields from "./ClientAddressFields";
import ClientContactFields from "./ClientContactFields";
import PatientsList from "./PatientsList";
import SearchPetOwners from "components/greenlineWellness/elements/SearchPetOwners";
import * as ButtonTypes from "constants/ButtonTypes";

export default function ClientPatientForm(props) {
    const [showEditSelectedOwner, setShowEditSelectedOwner] = useState(false);
    const [invalidFields, setInvalidFields] = useState([]);
    const [patientsInvalidFields, setPatientsInvalidFields] = useState([]);
    const [isValid, setIsValid] = useState(false);

    const checkIsValid = async (owner, patients) => {
        let ownerValid = true;
        let patientsValid = true;
        if (props.schema) {
            // Check Owner data is valid
            await props.schema.validate(owner, {abortEarly: false})
                .then((value) => {
                    setInvalidFields([]);
                })
                .catch((err) => {
                    const invFields = map(err.inner, (inner) => inner.path);
                    setInvalidFields(map(invFields, f => f));
                    ownerValid = false;
                    // Show the edit fields by default if form has errors
                    setShowEditSelectedOwner(true);
                });
        }

        if (!!props.patientsSchema) {
            // Check Patient data is valid
            await props.patientsSchema.validate((patients || []), {abortEarly: false})
                .then((value) => {
                    setPatientsInvalidFields([]);
                })
                .catch((err) => {
                    const invFields = map(err.inner, (inner) => inner.path);
                    setPatientsInvalidFields(map(invFields, f => f));
                    patientsValid = false;
                });
        }
        setIsValid(ownerValid && patientsValid);
    }

    useEffect(() => {
        checkIsValid(props.owner, props.patients);
    }, [props.owner, props.patients]);

    const handleGoNext = (e) => {
        e.stopPropagation();
        e.preventDefault();
        props.onNext();
    }

    const handleChange = ({ name, value }) => {
        props.onChange({
            name: `${props.namePrefix}${name}`,
            value
        });
    }
    const clearForms = () => {
        props.onChange([{
            name: "owner",
            value: {},
        }, {
            name: "patients",
            value: [],
        }]);
    };

    const handleShowNewClientForm = () => {
        props.onSetShowAddClientForm(true);
        props.onChange([{
            name: "owner",
            value: {
                patients: [{}]
            },
        }, {
            name: "patients",
            value: [],
        }]);
    }

    const handleCloseNewClientForm = () => {
        props.onSetShowAddClientForm(false);
        clearForms();
    }

    const handleSelectOwner = (owner) => {
        setShowEditSelectedOwner(false);
        const newData = map(owner, (o, i) => {
            return {
                name: `${props.namePrefix}${i}`,
                value: o,
            }
        });
        newData.push({ name: "patients", value: [] });
        props.onChange(newData);
    };

    const handleAddPatient = () => {
        // Update the list of available patients
        let newPatients;
        if (!props.owner?.patients) {
            newPatients = [{
                patientName: null
            }];
        } else {
            newPatients = clone(props.owner.patients);
            newPatients.push({ patientName: null });
        }

        // Update the list of selected patients
        let newSelectedPatients;
        if (!props.patients) {
            newSelectedPatients = [{}];
        } else {
            newSelectedPatients = clone(props.patients);
            newSelectedPatients.push({ patientName: null });
        }
        props.onChange([{
            name: `${props.namePrefix}patients`,
            value: newPatients,
        }, {
            name: "patients",
            value: newSelectedPatients,
        }]);
    };

    return (
        <form onSubmit={handleGoNext}>
            <div className="flex margin-bottom-x-sm">
                <h2 className="flex-1">Client Information</h2>
                {props.showAddClientForm ? (
                    <Button
                        onClick={handleCloseNewClientForm}
                        type="gray"
                        disabled={props.readOnly}
                    >
                        Cancel/Select Existing Client
                    </Button>
                ) : (
                    <Button
                        onClick={handleShowNewClientForm}
                        action
                        title="Add New Client"
                        icon
                        disabled={props.readOnly}
                    >
                        <i className="icon far fa-plus" /> Add New Client
                    </Button>
                )}
            </div>
            {props.showAddClientForm ? (
                <AddClientFields
                    onSetSelectedPatients={props.onSetSelectedPatients}
                    namePrefix={props.namePrefix}
                    onChange={props.onChange}
                    owner={props.owner}
                    patients={props.patients}
                    readOnly={props.readOnly}
                    errors={props.invalidFields}
                />
            ) : (
                <>
                    <div className="flex spaced-content">
                        <div className="flex-1">
                            <SearchPetOwners
                                clinicId={props.clinicId}
                                onChange={(data, value) => handleSelectOwner(value)}
                                name={`${props.namePrefix}owner`}
                                value={props.owner?.petOwnerId}
                                disabled={props.readOnly}
                                autofocus
                                defaultSearch={`${props.owner?.petOwnerFirstName} ${props.owner?.petOwnerLastName}`}
                            />
                        </div>
                        {props.owner?.petOwnerId && (
                            <div className="flex-none">
                                {showEditSelectedOwner ? (
                                    <Button
                                        onClick={() => setShowEditSelectedOwner(false)}
                                        type={ButtonTypes.GRAY}
                                        disabled={!showEditSelectedOwner}
                                    >
                                        Close
                                    </Button>
                                ) : (
                                    <Button
                                        icon
                                        onClick={() => setShowEditSelectedOwner(true)}
                                        type="primary"
                                        disabled={showEditSelectedOwner}
                                    >
                                        <i className="far fa-edit" /> Edit
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                    {props.owner?.petOwnerId && (
                        showEditSelectedOwner ? (
                            <div>
                                <ClientContactFields
                                    errors={invalidFields}
                                    initialData={props.owner}
                                    namePrefix={props.namePrefix}
                                    onChange={handleChange}
                                />
                                <ClientAddressFields
                                    errors={invalidFields}
                                    initialData={props.owner}
                                    onChange={handleChange}
                                />
                            </div>
                        ) : (
                            <div>
                                <div className="flex spaced-content align-center margin-top-x-sm">
                                    <h3>Email:</h3>
                                    <div>
                                        <a href={`mailto:${props.owner.petOwnerEmail}`}>{props.owner.petOwnerEmail}</a>
                                    </div>
                                </div>
                                <div className="flex spaced-content align-center">
                                    <h3>Phone Number:</h3>
                                    <div>{props.owner.phone}</div>
                                </div>
                                <div>
                                    <h3>Address:</h3>
                                    <div className="margin-left-x-sm">
                                        <div>{props.owner.street1}</div>
                                        {props.owner.street2 &&
                                            <div>{props.owner.street2}</div>
                                        }
                                        <div>{props.owner.city}, {props.owner.stateProvince} {props.owner.postalCode}</div>
                                    </div>
                                </div>
                            </div>
                    ))}


                    <div className="flex spaced-content margin-top-md margin-bottom-x-sm">
                        <h2 className="flex-1">Patient Information</h2>
                        {props.owner?.petOwnerId && (
                            <Button icon onClick={handleAddPatient}>
                                <i className="fa fa-plus" /> Add Patient
                            </Button>
                        )}
                    </div>
                    <div className={"margin-bottom-md"}>
                        {!props.owner?.patients?.length ? (
                            <div className="flex flex-centered padding-top-md">
                                <span>Select a Pet Owner to view patients</span>
                            </div>
                        ) : (
                            <PatientsList
                                errors={patientsInvalidFields}
                                patients={props.patients}
                                onChange={props.onChange}
                                initialData={props.owner.patients}
                                readOnly={props.readOnly}
                                invalidFields={invalidFields}
                            />
                        )}
                    </div>
                </>
            )}
            <div className="flex justify-flex-end">
                <Button
                    disabled={!isValid}
                    buttonType="submit"
                >
                    Next
                </Button>
            </div>
        </form>
    );
}

ClientPatientForm.defaultProps = {
    invalidFields: [],
    namePrefix: "",
    owner: undefined,
    patients: [],
    patientsSchema: undefined,
    schema: undefined,
};

ClientPatientForm.propTypes = {
    clinicId: PropTypes.number.isRequired,
    invalidFields: PropTypes.arrayOf(PropTypes.string),
    namePrefix: PropTypes.string,
    onNext: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onSetShowAddClientForm: PropTypes.func.isRequired,
    owner: PropTypes.object,
    patients: PropTypes.arrayOf(PropTypes.object),
    patientsSchema: PropTypes.object,
    schema: PropTypes.object,
};
