import React, { useRef } from "react";
import { connect } from "react-redux";
import styles from "./InstallationCode.scss";
import PropTypes from "prop-types";
import moment from "moment";
import classnames from "classnames";
import * as ClinicActions from "actions/ClinicActions";
import Button from "components/common/Button";
import Tooltip from "components/common/Tooltip";
import { PRIMARY } from "constants/ButtonTypes";
import * as UserPermissions from "constants/UserPermissions";
import getLocalTimezone from "utils/getLocalTimezone";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import toast from "utils/toast";

function InstallationCode(props) {
    const installationCodeRef = useRef(null);
    const currentTime = new moment();
    const expiration = new moment(getLocalTimezone(props.expirationDate, "MM/DD/YYYY LTS"));
    const accessCodeExpiration = moment(currentTime).to(expiration);
    const expired = ((currentTime > expiration) || !props.expirationDate);

    const handleCopyClicked = () => {
        const element = installationCodeRef.current;
        if (element) {
            element.focus();
            element.select();
            element.setSelectionRange(0, 99999); // For mobile devices (says w3schools)
            if (document.execCommand("copy")) {
                toast.success("Installation Code Copied to Clipboard");
            }
        }
    }

    const handleGenerateCode = () => {
        if (props.canEditClinicManagement) {
            props.generateInstallCode(props.clinicId);
        }
    }

    return (
        <div className={classnames(styles.root, "flex flex-wrap spaced-content full-width")}>
            <div
                className="flex-1 form-group"
                onClick={handleCopyClicked}
                style={{minWidth: "200px"}}
            >
                <label>Installation Code <span
                    className={classnames({
                        "text-dark-green": !expired,
                        "text-danger": expired
                    })}
                >
                    {!expired ? `(Expires ${accessCodeExpiration})` : "(Expired)"}
                </span></label>
                <input
                    className={styles.code}
                    ref={installationCodeRef}
                    type="text"
                    value={props.value}
                    readOnly
                />
                <div className="status-icon has-label pointer">
                    <i
                        className="far fa-copy text-primary pointer"
                        title="Copy to clipboard"
                    />
                </div>
            </div>
            <div className="flex-none flex flex-end margin-bottom-xx-sm">
                <Tooltip
                    position="left"
                    tip="Generate New Code"
                >
                    <div>
                        <Button
                            disabled={!props.canEditClinicManagement}
                            iconOnly
                            onClick={handleGenerateCode}
                            type={PRIMARY}
                        >
                            <i className="fa fa-arrows-rotate" />
                        </Button>
                    </div>
                </Tooltip>
            </div>
        </div>
    );
}

InstallationCode.defaultProps = {
    value: "",
};

InstallationCode.propTypes = {
    clinicId: PropTypes.number.isRequired,
    expirationDate: PropTypes.string,
    value: PropTypes.string,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canEditClinicManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            canEditClinicManagement,
        }
    },
    (dispatch) => ({
        generateInstallCode: (clinicId) => dispatch(ClinicActions.generateInstallCode(clinicId)),
    })
) (InstallationCode);
