import React from "react";
import {connect} from "react-redux";
import ClinicBase from "components/layout/ClinicBase";
import * as SearchTypes from "constants/SearchTypes";
import AccessDenied from "components/common/AccessDenied";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import {clinicHasWellness, getClinicInfo} from "utils/ClinicData";
import PetPlanTracking from "components/greenlineWellness/widgets/GL_PetPlanTracking";
import {CLINIC_TAGS} from "constants/AdminControls";
import PlanTrackingModal from "components/greenlineWellness/widgets/PlanTrackingModal";
import * as GreenlineWellnessActions from "actions/GreenlineWellnessActions";
import {WELLENSS_PET_PLAN_TRACKING} from "constants/SearchTypes";

function PetPlanTrackingPage(props)  {
    // GREENLINE WELLNESS PET PLAN TRACKING

    const clearSelectedPlan = () => {
        props.clearSelectedWellnessPlan();
    };
    
    return (
        <ClinicBase
            searchConfig={SearchTypes.WELLENSS_PET_PLAN_TRACKING}
            clinicId={props.clinicId}
            adminControls={CLINIC_TAGS}
            docTitle={"Greenline: Pet Plan Tracking"}
        >
            {!props.canViewWellnessPetPlans ? (
                <div className="full-width full-height">
                    <h2>Pet Plan Tracking</h2>
                    <AccessDenied/>
                </div>
            ) : !props.clinicHasWellness ? (
                <div className="full-width full-height">
                    <h2>Pet Plan Tracking</h2>
                    <AccessDenied customMessage="This clinic does not have access to Pet Plan Tracking"/>
                </div>
            ) : (
                <PetPlanTracking
                    clinicId={props.clinicId}
                    canGLWellness={props.canGLWellness}
                />
            )}
            {props.selectedId &&
                <PlanTrackingModal
                    selectedId={props.selectedId}
                    onClose={clearSelectedPlan}
                    clinicId={props.clinicId || props.clinicInfo?.clinicId }
                    canGLWellness={props.canGLWellness}
                />
            }
        </ClinicBase>
    );
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;
        const userProfile = state.user.userProfile;
        const canViewWellnessPetPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PET_PLANS, userProfile);
        const canGLWellness = clinicInfo?.programs?.Wellness?.providers.includes(69);
        return {
            clinicId,
            clinicInfo,
            canViewWellnessPetPlans,
            clinicHasWellness: clinicHasWellness(state, clinicId),
            selectedId: state.wellness.selectedId,
            canGLWellness,
        }
    },
    (dispatch) => ({
        clearSelectedWellnessPlan: () => dispatch(GreenlineWellnessActions.clearSelectedWellnessPlan()),
    })
)(PetPlanTrackingPage);
