exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ProviderClinicsProgramEnrollmentTable__root{width:100%}.ProviderClinicsProgramEnrollmentTable__root .columnGroupings .TextBox__root{height:50px}.ProviderClinicsProgramEnrollmentTable__root .columnGroupings .TextBox__root input{border-radius:25px}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/provider/widgets/ProviderClinicsProgramEnrollmentTable/ProviderClinicsProgramEnrollmentTable.scss"],"names":[],"mappings":"AAAA,6CAAM,UAAU,CAAC,6EAAwD,WAAW,CAAC,mFAA8D,kBAAkB,CAAC","file":"ProviderClinicsProgramEnrollmentTable.scss","sourcesContent":[".root{width:100%}.root :global(.columnGroupings) :global(.TextBox__root){height:50px}.root :global(.columnGroupings) :global(.TextBox__root) input{border-radius:25px}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "ProviderClinicsProgramEnrollmentTable__root"
};