import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { useLocation } from "react-router";
import classnames from "classnames";
import styles from "./ClinicBase.scss";
import * as ClinicActions from "actions/ClinicActions";
import * as StripeActions from "actions/StripeActions";
import * as UserActions from "actions/UserActions";
import ToolBar from "components/common/Toolbar/Toolbar";
import Button from "components/common/Button";
import Footer from "components/layout/Footer";
import Header from "components/layout/Header";
import Sidebar from "components/layout/Sidebar";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {getNodeId} from "utils/AdminData";
import {getClinicInfo} from "utils/ClinicData";
import LocalData from "utils/LocalData";
import logger from "utils/logger";
import {redirectToLogout} from "utils/login";
import {PermissionTypes, userHasPermission, userHasRole} from "utils/permissions/rolesPermissions";
import { setPageTitle } from "utils/windowUtils";
import * as UserPermissions from "constants/UserPermissions";
import * as UserRoles from "constants/UserRoles";

/*
This should be the root container for all Clinic Pages. It should handle any init() functions or other startup triggers
so that they can be managed in one place.
 */

function ClinicBase(props) {
    const location = useLocation();
    const isAdminView = !!props.clinicId && location.pathname.includes("/admin");
    const isProviderView = !!props.clinicId && location.pathname.includes("/provider");
    
    const fetchData = () => {
        if (!props.userProfile) {
            props.loadCurrentUser();
        }

        if (props.userProfile && props.userProfile.canClinic) {
            if(isAdminView && props.canViewClinicManagement) {
                logger.log("Loading clinic");
                props.loadClinicById(props.clinicId);
            } else if(isProviderView) {
                props.getClinicInfo(props.clinicId);
            }
            if(props.clinicId && props.canViewPaymentSettings) {
                props.getClinicSubscriptions(props.clinicId);
            }
        }
    }
    
    useEffect(() => {
        if (props.pageTitle || props.docTitle) {
            const title = props.docTitle || props.pageTitle;
            setPageTitle(title);
        }
    }, []);
    
    useEffect(() => {
        fetchData();
    }, [props.userProfile, props.clinicId]);
    
    if (!props.clinicInfo && !props.userProfile) {
        return(
            <SpinnerTakeover show/>
        );
    }

    const handleLogout = () => {
        LocalData.clearToken();
        redirectToLogout();
    }
    
    const handleClick = () => {
        window.history.back();
    };

    if (!(props.clinicInfo || props.canViewCorporateGroupClinic) && props.userProfile?.canClinic) {
        return (
            <div className={classnames(styles.root, {
                [styles.adminView]: isAdminView,
            })}>
                <Header
                    pageTitle="Access Denied"
                    logoutOnly
                />
                <main className={styles.main}>
                    <div className={styles.content}>
                        <div className="full-width margin-md">
                            <Button onClick={handleClick} icon>
                                <i className="fas fa-reply" /> Go Back
                            </Button>
                        </div>
                        <div className={styles.accessError}>
                            <h1>Permissions Error</h1>
                            <h3>You do not have the required permissions to view this page.</h3>
                            <p>Contact Greenline Pet Support for assistance with your account.</p>
                            <div>
                                <Button onClick={handleLogout}>Log Out</Button>
                            </div>
                        </div>
                        <Footer clinicHasPrioritySupport={props.clinicHasPrioritySupport}/>
                    </div>
                </main>
            </div>
        )
    }

    return (
        <div
            className={classnames(styles.root, {
                [styles.adminView]: isAdminView,
            })}
        >
            <Header
                searchConfig={props.searchConfig}
                pageTitle={props.pageTitle}
                clinicId={props.clinicId}
            />
            <main className={styles.main}>
                <div className="flex flex-1">
                    <Sidebar
                        collapsible={!!props.collapsible}
                        clinicId={props.clinicId}
                    />
                    <div className={classnames(styles.content, {
                        [styles.overflowHidden]: props.overflowHidden
                    })}>
                        <div
                            style={props.style}
                            className={styles.children}
                        >
                            {props.children}
                        </div>
                        {/* This empty div pushes the footer down if the children don't take up the whole page*/}

                        {!!userHasRole(props.userProfile, [UserRoles.CLINIC_ADMIN, UserRoles.CLINIC_STAFF, UserRoles.CLINIC_OWNER]) && (
                            <div className="flex-none">
                                <Footer
                                    clinic={props.clinicInfo}
                                    clinicHasPrioritySupport={props.clinicHasPrioritySupport}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex-none">
                    {/*{(isAdminView || isProviderView) && (*/}
                    {(isAdminView) && (
                        <ToolBar
                            clinicId={props.clinicId}
                            nodeId={props.nodeId}
                            controls={props.adminControls}
                            searchConfig={props.searchConfig}
                            isClinic
                        />
                    )}
                </div>
            </main>
        </div>
    );
}

ClinicBase.propTypes = {
    clinicId: PropTypes.number.isRequired,
    collapsible: PropTypes.bool,
    searchConfig: PropTypes.object,
    pageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    adminControls: PropTypes.string,
    providerControls: PropTypes.string,
    docTitle: PropTypes.string,
    overflowHidden:PropTypes.bool,
    style: PropTypes.object,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const clinicInfo = getClinicInfo(state, ownProps.clinicId);
        const nodeId = getNodeId(state, ownProps);
        //Permissions
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canViewClinicVetCheck = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_VETCHECK, userProfile);
        const canViewPaymentSettings = userHasPermission(PermissionTypes.VIEW, UserPermissions.PAYMENT_SETTINGS, userProfile);
        const canViewVetCheckMappings = userHasPermission(PermissionTypes.VIEW, UserPermissions.VETCHECK_MAPPING, userProfile);
        const clinicHasPrioritySupport = !!clinicInfo?.prioritySupport?.length;
        return {
            userProfile,
            clinicInfo,
            nodeId,
        //    Permissions
            canViewClinicManagement,
            canViewClinicVetCheck,
            canViewPaymentSettings,
            canViewVetCheckMappings,
            clinicHasPrioritySupport
        }
    },
    (dispatch) => ({
        loadCurrentUser: () => dispatch(UserActions.loadCurrentUser()),
        getClinicInfo: (clinicId) => dispatch(ClinicActions.getClinicInfo(clinicId)),
        loadClinicById: (clinicId) => dispatch(ClinicActions.getClinicById(clinicId)),
        getClinicSubscriptions: (clinicId) => dispatch(StripeActions.getClinicSubscriptions(clinicId))
    }),
)(ClinicBase);
