import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import filter from "lodash/filter";
import find from "lodash/find";
import map from "lodash/map";
import * as CouponsApi from "api/CouponsApi";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import Dropdown, { MultiSelectDropdown } from "components/common/Dropdown";
import ProviderBase from "components/layout/ProviderBase";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import Tooltip from "components/common/Tooltip";
import { BOTTOM } from "constants/TooltipPositionIds";
import * as UserPermissions from "constants/UserPermissions";
import { getNodeId } from "utils/AdminData";
import getProviderId from "utils/getProviderId";
import { downloadFile, handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import toast from "utils/toast";
import useFormHandlers from "utils/useFormHandlers";

const timeFrameOptions = Object.freeze([
    { name: "30 Days", value: 30 },
    { name: "60 Days", value: 60 },
    { name: "90 Days", value: 90 },
    { name: "120 Days", value: 120 },
]);

const schema = Yup.object().shape({
	days: Yup.number().required(),
	providerIds: Yup.array().of(Yup.number()).required(),
});

const CorporateGroupAuditHistoryExportPage = (props) => {
	const { formData, handleUpdate, isValid } = useFormHandlers(
		props.initialData,
		schema,
	);
	const [providerOptions, setProviderOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [downloading, setDownloading] = useState(false);

	const loadData = async () => {
		setLoading(true);
		try {
			const res = await CouponsApi.getProviderList();
			const currentProvider = find(res.body, { id: props.providerId });
			const visibleProviders = filter(res.body, o => {
				return find(currentProvider.providerVisibility, { providerId: o.id })?.isVisible;
			})
			const options = map(visibleProviders, opt => ({
				name: opt.name,
				value: opt.id,
			}));
			setProviderOptions(options);
		} catch (error) {
			handleErrorResponse("loading providers", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		loadData();
	}, []);

	const handleDownload = async () => {
		setDownloading(true);
		toast.success("Generating export...");
		try {
			const res = await CouponsApi.corporateGroupAuditHistoryExport(
				props.providerId,
				formData.providerIds.toString(),
				formData.days,
			);
			downloadFile(res, "xlsx");
		} catch (error) {
			handleErrorResponse("downloading export", error);
		} finally {
			setDownloading(false);
		}
	};

	return (
		<ProviderBase
			match={props.match}
			location={props.location}
			pageTitle="Corporate Audit History"
			providerId={props.providerId}
			nodeId={props.nodeId}
		>
			{props.canViewCouponAudit ? (
				<div className="flex flex-column full-width full-height flex-centered">
					<div className="flex-none flex justify-flex-start spaced-content padding-top-md full-width">
						<div className="flex-none">
							<i className="fa fa-info-circle text-success" />
						</div>
						<div className="flex-1">
							Use this report to view the detailed Greenline clinic coupon audit history for the manufacturer(s) and time frame(s) selected.
							For questions regarding data outside of the time frame options shown, please contact your Greenline corporate account manager directly.
						</div>
					</div>
					<div className="flex-1 flex flex-column flex-centered full-width">
						<div
							className="flex spaced-content full-width"
							style={{ maxWidth: "500px" }}
						>
							<div className="flex-1">
								<MultiSelectDropdown
									fullWidth
									label="Manufacturer(s)"
									name="providerIds"
									onChange={handleUpdate}
									options={providerOptions}
									placeholder="Select manufacturer(s)"
									value={formData.providerIds}
								/>
							</div>
							<div className="flex-1">
								<Dropdown
									fullWidth
									label="Time Frame"
									name="days"
									onChange={handleUpdate}
									options={timeFrameOptions}
									placeholder="Select time frame"
									value={formData.days}
								/>
							</div>
						</div>

						<div className="padding-top-md">
							<Button
								disabled={!(isValid && formData.providerIds?.length) || downloading}
								icon
								onClick={handleDownload}
							>
								{downloading && <i className="fa fa-loader fa-spin"/>} Download
							</Button>
						</div>
						<SpinnerTakeover show={loading}/>
					</div>
				</div>
			) : (
				<div className="full-width full-height">
					<AccessDenied/>
				</div>
			)}
</ProviderBase>
)
	;
};

export default connect(
	(state, ownProps) => {
		const params = ownProps?.match?.params || {};
		const userProfile = state.user.userProfile;
		const providerId = getProviderId(params, userProfile);
		const nodeId = getNodeId(state, ownProps);
		const canViewCouponAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_AUDIT, userProfile);

		return {
			canViewCouponAudit,
			nodeId,
			providerId,
			userProfile,
		};
	},
)(CorporateGroupAuditHistoryExportPage);
