import React from "react";
import PropTypes from "prop-types";
import styles from "./AdminClinicInfo.scss";
import AgentPanel from "./AgentPanel";
import ClinicAdminPanel from "./ClinicAdminPanel";
import ClinicDetailsPanel from "./ClinicDetailsPanel";
import ClinicRepsTable from "components/admin/widgets/ClinicRepsTable";
import ClinicTags from "components/admin/widgets/ClinicTags/ClinicTags";
import CorporateGroupsPanel from "./CorporateGroupsPanel";
import ExcludedClientsPanel from "./ExcludedClientsPanel";
import HospitalsTable from "./HospitalsTable";
import PIMSPanel from "./PIMSPanel";

export default function AdminClinicInfo(props) {
	if (!props.clinic?.clinicId && props.clinicId) {
		return null;
	}

    return (
		<div className={styles.root}>
			<ClinicDetailsPanel
				clinicId={props.clinicId}
				clinic={props.clinic}
			/>
			<PIMSPanel
				clinic={props.clinic}
				clinicId={props.clinicId}
			/>
			<AgentPanel
				clinicId={props.clinicId}
				clinic={props.clinic}
			/>
			<ClinicAdminPanel
				clinic={props.clinic}
				clinicId={props.clinicId}
				canResetPasswords={props.canResetPasswords}
			/>
			<ExcludedClientsPanel
				clinic={props.clinic}
				clinicId={props.clinicId}
			/>
            <CorporateGroupsPanel
                clinic={props.clinic}
                clinicId={props.clinicId}
                corporateGroups={props.greenCorporateGroups}
                providers={props.providers}
            />
			<ClinicTags
                clinicId={props.clinicId}
                clinicTags={props.clinic?.tags}
            />
			<ClinicRepsTable clinicId={props.clinicId} />
			<HospitalsTable clinicId={props.clinicId} />
		</div>
    );
}

AdminClinicInfo.defaultProps = {
	canResetPasswords: false,
    clinic: {},
	greenCorporateGroups: [],
};

AdminClinicInfo.propTypes = {
	canResetPasswords: PropTypes.bool,
    clinic: PropTypes.object,
	clinicId: PropTypes.number.isRequired,
	greenCorporateGroups: PropTypes.arrayOf(PropTypes.shape({

	})),
	providers: PropTypes.object,
};
