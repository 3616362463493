import React, {useMemo} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import styles from "./NotificationModal.scss";
import * as NotificationActions from "actions/NotificationsActions";
import Image from "components/common/Image";
import Modal from "components/common/Modal";
import Notification from "components/notifications/widgets/Notification";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as ModalTypes from "constants/ModalTypes";
import {
    ADOBE_ONLY,
    BI_ET_NON_CC_OPTIN,
    BI_GAMES,
    GAMES_SURVEY,
    GREENLINE_GIVING,
    LAPSED_USER_OPT_IN,
    MERCK_ADOBE_ONLY,
    PUPPY_GRADUATION_OPT_IN
} from "constants/Notifications";
import * as UserPermissions from "constants/UserPermissions";
import {SUCCESS} from "constants/ModalTypes";

const MEDIUM_SIZED = [
    ADOBE_ONLY,
    BI_ET_NON_CC_OPTIN,
    GAMES_SURVEY,
    LAPSED_USER_OPT_IN,
    MERCK_ADOBE_ONLY,
    PUPPY_GRADUATION_OPT_IN,
];

function NotificationModal(props) {
    const notificationId = props.isPreview ? props.notification?.partnerProgramIdentifier : props.notification?.notificationId;

    if(!notificationId) {
        return null;
    }

    const getModalTitle = () => {
        if(props.notification?.notificationType) {
            if(props.notification.notificationType === BI_GAMES) {
                return "Enroll in Greenline Games for Boehringer Ingelheim";
            } else if(props.notification?.notificationType === GREENLINE_GIVING) {
                return(
                    <div className="flex spaced-content">
                        <div className="flex-none" style={{maxWidth: "200px", maxHeight: "50px", overflow: "hidden"}}>
                            <Image
                                style={{width: "100%", marginTop: "-25px"}}
                                src={`https://glcdn.azureedge.net/images/partnerprograms/GreenlineGiving/VHF_Logo_Horizontal_Logo.jpg`}
                            />
                        </div>
                        <div className="flex-1 flex align-center">{props.notification.title}</div>
                    </div>
                );
            }
            else {
                return props.notification.title;
            }
        } else {
            return "";
        }
    }
    let modalStyleType = ModalTypes.DEFAULT;
    if (props.notification.notificationType === GAMES_SURVEY) {
        modalStyleType = ModalTypes.GREEN_HEADER;
    }

    const modalTitle = getModalTitle();
    // Show the corrct modal size for each notification
    const useMediumModal = useMemo(() => (
        MEDIUM_SIZED.includes(props.notification?.notificationType)
    ), [props.notification]);

    return (
        <Modal
            style={{ maxHeight: "90%", overflowY: "auto" }}
            show={props.show}
            clickOutsideToClose
            modalType={!!props.notification?.isAlert ? ModalTypes.WARNING : ModalTypes.NOTIFICATION}
            modalStyle={modalStyleType}
            mediumSmall={!useMediumModal}
            medium={useMediumModal}
            modalTitle={modalTitle}
            onClose={props.onClose}
        >
            <div className={styles.root}>
                <Notification
                    actionPerformed={props.actionPerformed}
                    clinicId={props.clinicId}
                    isPreview={props.isPreview}
                    notification={props.notification}
                    performAction={props.performAction}
                    providerId={props.providerId}
                    onClose={props.onClose}
                />
            </div>
        </Modal>
    );
}

NotificationModal.propTypes = {
    actionPerformed: PropTypes.bool,
    clinicId: PropTypes.number,
    isPreview: PropTypes.bool,
    notification: PropTypes.object,
    onClose: PropTypes.func,
    performAction: PropTypes.func,
    providerId: PropTypes.number,
}

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        //Permissions
        const canViewClinicCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        return {
            canViewClinicCouponLibrary,
        }
    },
    (dispatch) => ({
        dismissNotification: (id) => dispatch(NotificationActions.dismissNotification(id)),
        setNotificationState: (notificationId, clinicId, newState, userInitials, formFieldsJson) => dispatch(NotificationActions.setNotificationState(notificationId, clinicId, newState, userInitials, formFieldsJson)),
        setProviderNotificationState: (notificationId, providerId, newState, userInitials, formFieldsJson) => dispatch(NotificationActions.setProviderNotificationState(notificationId, providerId, newState, userInitials, formFieldsJson)),
    }),
)(NotificationModal);
