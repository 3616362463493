import React  from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import classnames from "classnames";
import styles from "./WellnessVisitTable.scss";
import map from "lodash/map";
import * as WellnessApi from "api/WellnessApi";
import * as WellnessActions from "actions/WellnessActions";
import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import Image from "components/common/Image";
import OpenInvoicePDFLinkNewTab from "components/common/OpenInvoicePDFLinkNewTab";
import SortableDataTable from "components/common/SortableDataTable";
import Tooltip from "components/common/Tooltip";
import { formatUSD } from "utils/numeric";
import { downloadFile } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import toast from "utils/toast";
import * as UserPermissions from "constants/UserPermissions";

function WellnessVisitTable(props) {
    const visits = map(props.visits, v => {
        const newVisit = {...v}
        newVisit.requiresValidation = (!newVisit.isPlanLocked && !newVisit.dvmId) || !newVisit.isVisitCurrent;
        return(newVisit)
    })

    const handleVoidVisit = (row) => {
        if(props.canVoidClinicWellnessAudit) {
            if (confirm(`Are you sure you want to VOID out this visit (${row.visitId})`)) {
                props.voidWellnessVisit(row.visitId);
            }
        }
    }

    let COLUMNS = [
        {
            name: "Inv. #",
            selector: "extendedInvoiceNumber",
            key: "extendedInvoiceNumber",
            sortable: true,
            format: row => {
                return (
                    <div className="flex align-center">
                        {row?.isPlanLocked && (
                            <Tooltip
                                position="right"
                                tip="Plan Locked"
                                title="Plan Locked"
                            >
                                <i className="fa fa-lock margin-right-x-sm"/>
                            </Tooltip>
                        )}
                        <OpenInvoicePDFLinkNewTab
                            className={styles.invoice}
                            invoiceId={row.invoiceId}
                            extendedInvoiceNumber={row.extendedInvoiceNumber}
                        />
                    </div>
                );
            },
        }, {
            name: "Inv. Date",
            selector: "invoiceDate",
            key: "invoiceDate",
            sortable: true,
            format: row => <div className="nowrap">{moment(row.invoiceDate).format('MM/DD/YYYY')}</div>
        }, {
            name: "Pet",
            selector: "pet",
            key: "pet",
            sortable: true,
        }, {
            name: "Client",
            selector: "client",
            key: "client",
            sortable: true,
        }, {
            name: "Type",
            selector: "serviceType",
            key: "serviceType",
            sortable: true,
        }, {
            name: "Product",
            selector: "productName",
            key: "productName",
            sortable: true,
        }, {
            name: "Qty",
            selector: "quantity",
            key: "quantity",
            sortable: true,
        }, {
            name: "Commission",
            selector: "commissionAmount",
            key: "commissionAmount",
            format: row => formatUSD(row.commissionAmount),
        }, {
            name: "",
            selector: "canVoid",
            key: "canVoid",
            format: row => (
                <div className="flex-centered">
                    {(props.canVoidClinicWellnessAudit && row.canVoid) && (
                        <Button
                            small
                            onClick={() => handleVoidVisit(row)}
                        >
                            Void
                        </Button>
                    )}
                </div>
            )
        }

    ];
    if(!!props.historical) {
        COLUMNS = [
            {
                name: "D.V.M.",
                selector: "dvmFirstName",
                key: "dvmFirstName",
                sortable: true,
                format: row => (
                    <div>{row.dvmFirstName} {row.dvmLastName}</div>
                )
            },
            ...COLUMNS,
            {
                //TODO: There is currently no way to tell if it was approved or declined, only that it was processed
                name: "Approved",
                selector: "processed",
                key: "processed",
                sortable: true,
                format: row => {

                    return (
                        <div className={styles.approvedBy}>
                            <span>{moment(row.processed).format("MM/DD/YYYY")}</span><br/>
                            {row.initials && <span>By: {row.initials}</span>}
                        </div>
                    )
                }
            },
        ];
    } else {
        COLUMNS = [
            ...COLUMNS,
            {
                name: "",
                selector: "dvmId",
                key: "dvmId",
                sortable: false,
                format: row => (
                    (!(props.canViewWellnessDvms && props.canChangeDvmClinicWellnessAudit) || !!row.dvmId) ? (
                        ""
                    ) : row.isPlanLocked ? (
                        <div className="text-center" title="Pet Plan Instance is Locked">
                            <i className="fa fa-lock"/> Plan Locked
                        </div>
                    ) : !row.isVisitCurrent ? (
                        <div className="text-center">Not Current</div>
                    ) : (
                        <div>
                            <Dropdown
                                name="dvmId"
                                value={row.dvmId}
                                options={props.vets}
                                onChange={(selection) => props.onDVMChanged({selection, row})}
                                placeholder="Select D.V.M."
                                disabled={row.isPlanLocked || !row.isVisitCurrent}
                                insideScrollingTable
                            />
                        </div>
                    )
                ),
            },
        ];
    }
    if (props.isWellnessHistoryPage) {
        COLUMNS = [
            {
                name: "Provider",
                selector: "providerId",
                key: "providerId",
                sortable: true,
                sortValue: row => props.providers?.[row.providerId]?.providerName || "Unknown",
                format: row => {
                    const provider = props.providers?.[row.providerId];
                    if (provider) {
                        return (
                            <div className={styles.providerLogo}>
                                <Image
                                    src={provider.mediumImageURI}
                                    alt={provider.providerName}
                                />
                            </div>
                        )
                    } else return "Unknown";
                }
            },
            ...COLUMNS,
        ]
    }

    const handleExcelDownload = async () => {
        if(props.canViewClinicWellnessAudit && !props.downloadDisabled) {
            toast.success("Generating Export...");

            const res = await WellnessApi.downloadWellnessVisitsExcel(props.downloadOptions);
            downloadFile(res, "xlsx");
        }
    };

    return (
        <div className={classnames(styles.root, {
            [styles.noDVM]: props.noDVM,
            [styles.history]: props.historical
        })}>
            <SortableDataTable
                columns={COLUMNS}
                rawData={visits}
                selectedRowIds={props.selectedRowIds}
                selectable={props.canApproveWellnessAudit && !props.historical}
                onRowSelectChange={props.onRowSelectChange}
                downloadExcel={(!props.downloadDisabled && props.historical && props.canViewClinicWellnessAudit) ? handleExcelDownload : null}
                header={props.header}
                resultsPerPage={50}
            />
        </div>
    );
}

WellnessVisitTable.propTypes = {
    clinicId: PropTypes.number.isRequired,
    visits: PropTypes.arrayOf(PropTypes.object).isRequired,
    vets: PropTypes.array,
    historical: PropTypes.bool,
    header: PropTypes.any,

    onInvoiceClick: PropTypes.func,
    onRowSelectChange: PropTypes.func,
    selectedRowIds: PropTypes.array,
    noDVM: PropTypes.bool,
    onDVMChanged: PropTypes.func,
    downloadDisabled: PropTypes.bool,
    downloadOptions: PropTypes.shape({
        period: PropTypes.string.isRequired,
        clinicId: PropTypes.number.isRequired,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
    }),
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canApproveWellnessAudit = userHasPermission(PermissionTypes.APPROVE, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);
        const canVoidClinicWellnessAudit = userHasPermission(PermissionTypes.VOID, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);
        const canChangeDvmClinicWellnessAudit = userHasPermission(PermissionTypes.CHANGE_DVM, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);
        const canViewWellnessDvms = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        const canViewClinicWellnessAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);

        return {
            canApproveWellnessAudit,
            canVoidClinicWellnessAudit,
            canChangeDvmClinicWellnessAudit,
            canViewWellnessDvms,
            canViewClinicWellnessAudit,
            providers: state.entities.clinicProviderLocations?.[ownProps.clinicId],
        }
    },
    (dispatch) => ({
        voidWellnessVisit: (visitId) => dispatch(WellnessActions.voidWellnessVisit(visitId)),
    })
)(WellnessVisitTable);
