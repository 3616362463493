import React, { useState } from "react";
import PropTypes from "prop-types";
import filter from "lodash/filter";
import ClinicDetailsForm from "./ClinicDetailsForm";
import Button from "components/common/Button";
import LabelValueRow from "components/common/LabelValueRow";
import Modal from "components/common/Modal";
import Tooltip from "components/common/Tooltip";
import { LEFT } from "constants/TooltipPositionIds";

export default function ClinicDetailsPanel(props) {
	const [showEditClinic, setShowEditClinic] = useState(false);

	if (!props.clinic && props.clinicId) {
		return null;
	}

	const handleEditClinic = () => {
		setShowEditClinic(true);
	};

	const handleCloseEditForm = () => {
		setShowEditClinic(false);
	};

    const billingAddressElements = filter([props.clinic.billingAddress.city, props.clinic.billingAddress.state, props.clinic.billingAddress.zipCode, props.clinic.billingAddress.countryCode], e => !!e);
    const billingAddress = billingAddressElements.join(", ");

    return (
		<div className="card">
			<div className="flex spaced-content">
				<h4 className="flex-1">{props.clinic.name} ({props.clinic.id})</h4>
				<Tooltip
					position={LEFT}
					tip="Edit Clinic"
				>
					<div className="flex-none">
						<Button
							iconOnly
							onClick={handleEditClinic}
							type="primary"
						>
							<i className="fa fa-pencil"/>
						</Button>
					</div>
				</Tooltip>
			</div>
			<LabelValueRow
				label="Address"
                value={(
	                <div>
		                <div>{props.clinic.street1}</div>
		                <div>{props.clinic.street2}</div>
		                <div>{props.clinic.city}, {props.clinic.state}, {props.clinic.zipCode}, {props.clinic.countryCode}</div>
					</div>
                )}
			/>
            <LabelValueRow
                label="Billing Name"
                value={(<span style={{ overflowWrap: "break-word" }}>{props.clinic.clinicBillingName}</span>)}
            />
            <LabelValueRow
                label="Billing Address"
                value={(
                    <div>
                        <div>{props.clinic.billingAddress.street1}</div>
                        <div>{props.clinic.billingAddress.street2}</div>
                        <div>{billingAddress}</div>
                    </div>
                )}
            />
			<table className="table">
				<thead>
					<tr>
						<th className="text-center">Active</th>
						<th className="text-center">Setup Complete</th>
						<th className="text-center">
							<Tooltip
								position="right"
								tip="When set, this clinic is allowed to sync. And if hospitals are available, this flag cannot be modified and hospitals can be assigned to other clinics. If this clinic is enrolled into wellness, Gateway flag cannot be changed"
							>
								<div>Gateway Clinic</div>
							</Tooltip>
						</th>
						<th className="text-center">Test Clinic</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className="text-center">{props.clinic.isActive ? <i className="fa fa-check-circle text-success"/> : <i className="fa fa-times-circle text-danger"/>}</td>
						<td className="text-center">{props.clinic.isClinicSetupComplete ? <i className="fa fa-check-circle text-success"/> : <i className="fa fa-times-circle text-danger"/>}</td>
						<td className="text-center">{props.clinic.isGatewayClinic ? <i className="fa fa-check-circle text-success"/> : <i className="fa fa-times-circle text-danger"/>}</td>
						<td className="text-center">{props.clinic.isTestClinic ? <i className="fa fa-check-circle text-success"/> : <i className="fa fa-times-circle text-danger"/>}</td>
					</tr>
				</tbody>
			</table>
			<LabelValueRow
				label="Salesforce ID"
				value={(
					<a
	                    title="Link to Salesforce"
	                    href={`https://na159.lightning.force.com/lightning/r/Account/${props.clinic.salesforceAccountId}/view`}
	                    target="_blank"
	                    className="pointer text-primary"
					>
						{props.clinic.salesforceAccountId}
					</a>
				)}
			/>
			{props.clinic?.hasTokenCallback !== null && (
				<LabelValueRow
					label="Received Token"
	                value={props.clinic.hasTokenCallback ? (
						<i className="fa fa-check-circle text-success" />
	                ) : (
						<i className="fa fa-times-circle text-danger" />
	                )}
				/>
			)}
			<LabelValueRow
				label="Clinic GUID"
				value={props.clinic.clinicGuid}
			/>
			<Modal
				modalTitle="Edit Clinic Details"
				show={showEditClinic}
				onClose={handleCloseEditForm}
				small
			>
				<ClinicDetailsForm
					clinicId={props.clinicId}
					initialData={props.clinic}
					onClose={handleCloseEditForm}
				/>
			</Modal>
		</div>
    );
}

ClinicDetailsPanel.defaultProps = {
    clinic: {},
};

ClinicDetailsPanel.propTypes = {
    clinic: PropTypes.object,
	clinicId: PropTypes.number.isRequired,
};
