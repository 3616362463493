import React from "react";
import find from "lodash/find";
import filter from "lodash/filter";
import forEach from "lodash/forEach";
import includes from "lodash/includes";
import keyBy from "lodash/keyBy";
import keys from "lodash/keys";
import sortBy from "lodash/sortBy";
import * as ProviderIds from "constants/ProviderIds";
import { PermissionTypes, canPermission } from "utils/permissions/rolesPermissions";
import { GREENLINE_WELLNESS, PREMIER_PET_CARE_PLAN } from "constants/ProviderIds";

export function getClinicInfo(state, clinicId) {
    const { user, entities: { clinicDetails } } = state;

    if (clinicId) {
        return clinicDetails[clinicId] ? clinicDetails[clinicId] : {clinicId, clinicName: ""};
    }

    if (!user.userProfile || !user.userProfile.primaryClinic) {
        return null
    }

    const selectedClinic = user.currentClinicId || user.userProfile.primaryClinic.id;
    return clinicDetails[selectedClinic];
}

export function getClinicEnrolledInProgram(clinicDetails, programId) {
    let hasProgram = false;
    forEach(clinicDetails?.programs, (program, index) => {
        if (includes(program.providers, programId)) {
            hasProgram = true;
            return false;
        }
    })
    return hasProgram;
}

export function getClinicDetails(clinicDetails, user, clinicId) {
    if (clinicId) {
        return clinicDetails[clinicId] ? clinicDetails[clinicId] : { clinicId, clinicName: "" };
    }

    if (!user.userProfile || !user.userProfile.primaryClinic) {
        return null
    }

    const selectedClinic = user.currentClinicId || user.userProfile.primaryClinic.id;
    return clinicDetails[selectedClinic];
}

export function getPendingCouponCount(state) {
    const { entities: { unprocessedCoupons } } = state;
    if (!unprocessedCoupons) {
        return 0;
    }

    return keys(getUnprocessedCoupons(state)).length || 0;
}

export function getPendingVisitCount(state) {
    const { entities: { wellness } } = state;
    return wellness.visits ? keys(filter(wellness.visits, {processed: null})).length : 0;
}

export function getClinicWellnessStatus(state, clinicId) {
    const { user } = state;
    if (!user.userProfile || !user.userProfile.permissions ) {
        return false
    }

    const { permissions } = user.userProfile;

    // Does Clinic have Wellness?
    if (clinicHasWellness(state, clinicId)) {
        // Does the User Have Wellness?
        return permissions.ClinicWellnessAudit && canPermission(PermissionTypes.VIEW, permissions.ClinicWellnessAudit.access);
    }
}

export function getUnreadNotificationCount(state) {
    return state.entities.stats?.UnreadNotifications?.value || 0;
}

export function getDashboardTiles(state) {
    if (!state.entities.dashboard.items) {
        return null;
    }
    return state.entities.dashboard;
}

export function getDashboardTileData(state, typeId) {
    const { entities: { dashboard } } = state;
    return dashboard[typeId] && dashboard[typeId].data ? dashboard[typeId].data : null;
}

export function getUnprocessedCoupons(state) {
    const { entities: { unprocessedCoupons } } = state;
    if (!unprocessedCoupons) {
        return null;
    }

    const filtered = filter(unprocessedCoupons, coupon => !coupon.processedDate);
    return keyBy(filtered, "id");
}

export function getCurrentGames(state, limit=null) {
    const { entities: { games } } = state;

    if (!games) {
        return null;
    }

    // const currentGames = games; // TESTING!
    const currentGames = sortBy(filter(games, game => game.isCurrent), "createDate");

    if (currentGames) {
       return currentGames;
    }

    return null;
}

export function clinicHasGames(state, clinicId) {
    const { entities } = state;
    return entities.clinicDetails[clinicId]?.programs?.BIGames?.enabled || false;
}

export function clinicHasWellness(state, clinicId) {
    const { entities } = state;
    return entities.clinicDetails[clinicId]?.programs?.Wellness?.enabled || false;
}

export function clinicHasPremierWellness(state, clinicId) {
    const { entities } = state;
    return includes(entities.clinicDetails[clinicId]?.programs?.Wellness?.providers, PREMIER_PET_CARE_PLAN);
}

export function clinicHasGreenlineWellness(state, clinicId) {
    const { entities } = state;
    return includes(entities.clinicDetails[clinicId]?.programs?.Wellness?.providers, GREENLINE_WELLNESS);
}

export function clinicHasVetCheck(state, clinicId) {
    const { entities } = state;
    return entities.clinicDetails[clinicId]?.programs?.VetCheck?.enabled || false;
}

export function clinicHasPriceWatch(state, clinicId) {
    const { entities } = state;
    return entities.clinicDetails[clinicId]?.programs?.PriceWatch?.enabled || false;
}

export function clinicHasCorporateGroups(state, clinicId) {
    const { entities } = state;
    return entities.clinicDetails[clinicId]?.programs?.CorporateGroups?.enabled || false;
}

export function clinicHasReporting(state, clinicId) {
    const { entities } = state;
    return entities.clinicDetails[clinicId]?.programs?.Reporting?.enabled || false;
}

export function clinicHasCoupons(state, clinicId) {
    const { entities } = state;
    return entities.clinicDetails[clinicId]?.programs?.Coupons?.enabled || false;
}

export function clinicHasPrograms(state, clinicId) {
    const { entities } = state;
    return entities.clinicDetails[clinicId]?.programs?.Coupons?.enabled || false;
    // TODO: make this work correctly
    // return entities.clinicDetails[clinicId]?.programs?.Programs?.enabled || false;
}

export function getClinicGames(state) {
    const { entities: { games } } = state;
    if (!games) {
        return null;
    }
    return games;
}

export function getClinicGameById(state, gameId) {
    const { entities: { games } } = state;
    if (!games) {
        return null;
    }
    return games[gameId];
}

export function slugifyClinicName(clinicData) {
    // Example: PVCC+East+Orlando+Animal+Hospital+(7777)
    const clinicName = clinicData.name || clinicData.clinicName;

    if (!clinicName) {
        return "";
    }

    const slug = clinicName.replace(/\s/g, '+')
    return `${slug}+(${clinicData.clinicId})`;
}

export function premierSlugifyClinicName(clinicData) {
    // Example: PVCC+East+Orlando+Animal+Hospital+(7777-Premier)
    const clinicName = clinicData.name || clinicData.clinicName;

    if (!clinicName) {
        return "";
    }

    const slug = clinicName.replace(/\s/g, '+')
    return `${slug}+(${clinicData.clinicId}-Premier)`;
}

export function getProviderImage(provider) {
    if (!!provider) {
        return provider.id === ProviderIds.BOEHRINGER_INGELHEIM ? provider.mediumImageUri : provider.smallImageUri;
    } else return null;

}

export function clinicHasPrioritySupport(providerId, clinicInfo) {
    if (!!clinicInfo?.prioritySupport) {
        const providerPrioritySupport = find(clinicInfo?.prioritySupport, {providerId: providerId});
        return !!providerPrioritySupport?.isPrioritySupport;
    } else return false;
}
