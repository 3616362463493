import * as ActionTypes from "constants/ActionTypes";
import * as GreenlineWellnessApi from "api/GreenlineWellnessApi";
import * as ClinicApi from "api/ClinicApi";
import { handleErrorResponse } from "utils/request";
import toast from "utils/toast";
import {
    mapWellnessPlansFromServerToApp,
    mapWellnessDashboardTilesFromServerToApp,
} from "data/serverMapping";

export function getWellnessPlans(clinicId, status=null, providerId=null) {
    return (dispatch) => {
        GreenlineWellnessApi.getWellnessPlans(clinicId, status, providerId)
            .then((res) => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.GLN_WELLNESS_PLANS_LOADED,
                        plans: mapWellnessPlansFromServerToApp(res.body.data),
                    });
                }
            })
            .catch((error) => {
                handleErrorResponse("loading wellness plans", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function selectWellnessPlan(planId) {
    return (dispatch) => (
        dispatch({
            type: ActionTypes.GLN_WELLNESS_PLAN_SELECTED,
            planId
        })
    )
}

export function clearSelectedWellnessPlan() {
    return (dispatch) => (
        dispatch({
            type: ActionTypes.GLN_WELLNESS_CLEAR_SELECTED_PLAN
        })
    )
}

export function downloadPDF() {
    return (dispatch) => {
        toast.success("Downloading Wellness History PDF");
        dispatch({
            type: ActionTypes.GLN_WELLNESS_HISTORY_DOWNLOADED,
        })
    }
}

export function updatePetPlan(clinicId, petId, patientPlanEnrollmentId, ppgPatientPlanEnrollmentId) {
    return (dispatch) => {
        GreenlineWellnessApi.updatePetPlan(clinicId, petId, patientPlanEnrollmentId, ppgPatientPlanEnrollmentId)
            .then(res => {
                toast.success("Greenline wellness plan updated");
                dispatch({
                    type: ActionTypes.GLN_WELLNESS_PLAN_UPDATED,
                    petId,
                    ppgPatientPlanEnrollmentId,
                });
            })
            .catch((error) => {
                handleErrorResponse("updating Greenline wellness plan", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}


export function getWellnessDashboardTiles(clinicId) {
    return dispatch => {
        GreenlineWellnessApi.getWellnessDashboardTiles(clinicId)
            .then(res => {
                dispatch({
                    type: ActionTypes.CLINIC_WELLNESS_DASHBOARD_TILES_LOADED,
                    data: mapWellnessDashboardTilesFromServerToApp(res.body),
                    clinicId,
                });
            })
            .catch(error => {
                handleErrorResponse("loading clinic wellness dashboard tiles", error);
            });
    }
}

export function loadClinicWellnessPlans(clinicId, includeInactive) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.GLN_CLINIC_WELLNESS_PLANS_LOADING });

        ClinicApi.loadClinicWellnessPlans(clinicId, includeInactive)
            .then((res) => {
                dispatch({
                    type: ActionTypes.GLN_CLINIC_WELLNESS_PLANS_LOADED,
                    wellnessPlans: res.body,
                    clinicId
                });
            })
            .catch((error) => {
                handleErrorResponse("loading clinic wellness plans", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function updateClinicWellnessPlans(clinicId, data, isLast=false) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.GLN_CLINIC_WELLNESS_PLANS_LOADING });

        ClinicApi.updateClinicWellnessPlans(clinicId, data)
            .then((res) => {
                if (isLast) {
                    ClinicApi.loadClinicWellnessPlans(clinicId)
                        .then((res) => {
                            dispatch({
                                type: ActionTypes.GLN_CLINIC_WELLNESS_PLANS_LOADED,
                                wellnessPlans: res.body,
                                clinicId
                            });
                        })
                        .catch((error) => {
                            handleErrorResponse("loading clinic wellness plan", error);
                            dispatch({
                                type: ActionTypes.REQUEST_ERROR,
                                error,
                            });
                        });
                }
            })
            .catch((error) => {
                handleErrorResponse("updating clinic wellness plans", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function batchUpdateClinicWellnessPlans(clinicId, data) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.GLN_CLINIC_WELLNESS_PLANS_LOADING });

        ClinicApi.batchUpdateClinicWellnessPlans(clinicId, data)
            .then((res) => {
                dispatch({
                    type: ActionTypes.GLN_CLINIC_WELLNESS_PLANS_LOADED,
                    wellnessPlans: res.body,
                    clinicId
                });
            })
            .catch((error) => {
                handleErrorResponse("updating clinic wellness plans", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}
