export const getLinkLocation = (link, clinicId, otherInfo=null) => {
    const adminClinicRoot = `/admin/clinic/${clinicId}`;
    const withLocation = otherInfo ? otherInfo : '';
    switch (link) {
        case "dashboard":
            return `${adminClinicRoot}`;
        case "settings":
            return `${adminClinicRoot}/settings`;
        case "games":
            return `${adminClinicRoot}/games`;
        case "historic-games":
            return `${adminClinicRoot}/games/history`;
        case "couponLibrary":
            return `${adminClinicRoot}/coupons/library`;
        case "couponHistory":
            return `${adminClinicRoot}/coupons/history`;
        case "libraryProgram":
            return `${adminClinicRoot}/coupons/library/${withLocation}`;
        case "strayCoupons":
            return `${adminClinicRoot}/coupons/stray`;
        case "wellnessAudit":
            return `${adminClinicRoot}/wellness/audit`;
        case "wellnessHistory":
            return `${adminClinicRoot}/wellness/history`;
        case "petPlanTracking":
            return `${adminClinicRoot}/wellness/patients`;
        case "wellnessPlanSetup":
            return `${adminClinicRoot}/wellness/plans`;
        case "wellnessDVM":
            // return `${adminClinicRoot}/wellness/commission/dvm`;
            return `${adminClinicRoot}/setup/wellness/commission/dvm`;
        case "pharmacyMapping":
            return `${adminClinicRoot}/mapping/pharmacy`;
        case "foodMapping":
            return `${adminClinicRoot}/mapping/food`;
        case "vaccineMapping":
            return `${adminClinicRoot}/mapping/vaccines`;
        case "serviceMapping":
            return `${adminClinicRoot}/mapping/services`;
        case "vetCheckMapping":
            return `${adminClinicRoot}/mapping/vetcheck`;
        case "wellnessMapping":
            return `${adminClinicRoot}/mapping/wellness`;
        case "par":
            return `${adminClinicRoot}/par`;
        case "pimsUsers":
            return `${adminClinicRoot}/pimsusers`;
        case "programs":
            return `${adminClinicRoot}/programs`;
        case "programsDashboard":
            return `${adminClinicRoot}/programs-dashboard`;
        case "providerSelection":
            return `${adminClinicRoot}/provider/setup`;
        case "providerSetup":
            return `${adminClinicRoot}/provider/setup/${withLocation}`;
        case "productTags":
            return `${adminClinicRoot}/products/tags`;
        case "productOverride":
            return `${adminClinicRoot}/product/overrides`;
        case "invoiceLineItems":
            return `${adminClinicRoot}/reports/invoice-line-items`;
        case "cumulativeOfferTracking":
            return `${adminClinicRoot}/reports/cumulative-offer-tracking`;
        case "premier":
            return `${adminClinicRoot}/premier/${otherInfo}`;
        case "vetcheck":
            return `${adminClinicRoot}/vetcheck`;
        case "vetcheck_handouts_sent":
            return `${adminClinicRoot}/vetcheck/handouts-sent`;
        case "vetcheck_forms_sent":
            return `${adminClinicRoot}/vetcheck/forms-sent`;
        case "vetcheck_charts_sent":
            return `${adminClinicRoot}/vetcheck/charts-sent`;
        case "vetcheck_opt_out":
            return `${adminClinicRoot}/vetcheck/opt-out`;
        case "vetcheck_manage_automations":
            return `${adminClinicRoot}/vetcheck/manage-automations`;
        case "vetcheck_dashboard":
            return `${adminClinicRoot}/vetcheck/dashboard`;
        default:
            return "";
    }
}

export const getClinicAdminLinkLocation = (link) => {
    switch (link) {
        case "wellnessPlanSetup":
            return "/setup/wellness/plans";
        case "wellnessDVM":
            return "/setup/wellness/commission/dvm";
        case "mapping":
        case "wellnessMapping":
            return "/setup/mapping";
        default:
            return "";
    }
}

export const getLocationLinkMatch = (link, clinic, path) => {
    return getLinkLocation(link, clinic) === path;
}


