import React from "react";
import PropTypes from "prop-types";
import styles from "./InformationTile.scss";
import classnames from "classnames";
import * as StyleId from "constants/CssStyleIds";
import InformationBadge from "components/common/InformationBadge";

export default function InformationTile(props) {
    let rootClass = styles.root;

    switch (props.style) {
        case StyleId.WARN:
            rootClass = styles.rootWarn;
            break;
        case StyleId.DANGER:
            rootClass = styles.rootDanger;
            break;
        case StyleId.SUCCESS:
            rootClass = styles.rootSuccess;
            break;
    }

    let headerClass = styles.header;
    let footerClass = styles.footer;
    let overlayClass = styles.overlayMessage;

    switch (props.style) {
        case StyleId.WARN:
            headerClass = styles.headerWarn;
            break;
        case StyleId.DANGER:
            headerClass = styles.headerDanger;
            break;
        case StyleId.SUCCESS:
            headerClass = styles.headerSuccess;
            break;
        case StyleId.INACTIVE:
            footerClass = classnames(styles.footer, styles.inactive);
            break;
    }

    switch (props.style) {
        case StyleId.ACTIVATION_REQUIRED:
            overlayClass = classnames(styles.overlayMessage, styles.activationRequired);
            break;
    }

    return (
        <div className={rootClass}>
            {(props.title || props.iconButton) &&
                <header className={headerClass}>
                    <div className={styles.headerInner}>
                        <div data-testid="tile-title">{props.title}</div>
                        {props.badge && (
                            <InformationBadge
                                data-testid="tile-info-badge"
                                message={props.badge}
                            />
                        )}
                        {props.iconButton}
                    </div>
                </header>
            }
            <div className={styles.content}>
                {props.children}
            </div>
            {props.footer &&
                <div
                    data-testid="tile-footer"
                    className={footerClass}
                >
                    {props.footer}
                </div>
            }
            {props.overlay &&
                <div
                    data-testid="tile-overlay"
                    className={classnames(styles.overlayMessage, overlayClass)}
                >
                    <div>
                        <span>{props.overlay}</span>
                    </div>
                </div>
            }
        </div>
    );
}

InformationTile.propTypes = {
    badge: PropTypes.string,
    style: PropTypes.oneOf([StyleId.WARN, StyleId.DANGER, StyleId.SUCCESS, StyleId.INACTIVE, StyleId.ACTIVATION_REQUIRED, StyleId.GRAY]),
    title: PropTypes.string,
    iconButton: PropTypes.node,
    footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    overlay: PropTypes.string,
};
