import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./ClinicReports.scss";
import filter from "lodash/filter";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import sortBy from "lodash/sortBy";
import * as ClinicActions from "actions/ClinicActions";
import ClinicBase from "components/layout/ClinicBase";
import InformationTile from "components/common/InformationTile";
import Modal from "components/common/Modal";
import { getNodeId } from "utils/AdminData";
import * as ClinicData from "utils/ClinicData";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import { getClinicBasePath } from "utils/routingUtils";
import { clinicSubscriptions, SubscriptionStatuses } from "utils/SubscriptionStatus";
import { CLINIC_TAGS } from "constants/AdminControls";
import * as StyleId from "constants/CssStyleIds";
import { MERCK, PRICE_WATCH } from "constants/ProviderIds";
import * as UserPermissions from "constants/UserPermissions";

function ClinicReports(props) {
    const [openModal, setOpenModal] = useState(null);
    const basePath = getClinicBasePath(props.location.pathname, props.clinicId, props.nodeId);

    useEffect(() => {
        props.loadLookerReports(props.clinicId);
    }, [props.clinicId]);

    const TILES = [
        ...map(props.lookerReports, report => {
            return {
                ...report,
                overlayMessage: report.hasAccess ? "" : "Contact Greenline",
                visible: true,
                openInNewTab: report.dashboardId !== 5,
                openInModal: report.dashboardId === 5,
                onClick: () => props.loadLookerReports(props.clinicId),
            };
        }),
        {
            dashboardId: "cumulativeOffers",
            description: "View summary of parasiticide sales in comparison to previous year",
            hasAccess: true,
            iconUrl: "fal fa-chart-bar",
            dashboardUrl: `${basePath}/reports/cumulative-offer-tracking`,
            openInModal: false,
            openInNewTab: false,
            overlayMessage: "",
            reportName: "Cumulative Offer Tracking",
            visible: props.canViewCouponAudit,
        }, {
            dashboardId: "clientCampaignTracking",
            description: "View mailing lists to manually opt-out specific clients from Merck Email Campaigns.",
            hasAccess: true,
            iconUrl: "fal fa-mail-bulk",
            dashboardUrl: `${basePath}/reports/client-campaign-tracking`,
            openInModal: false,
            openInNewTab: false,
            overlayMessage: "",
            reportName: "Pet Owner List",
            visible: props.clinicHsMerck && props.canOptInCouponLibrary,
        }, {
            dashboardId: "priceWatch",
            description: "",
            hasAccess: false,
            iconUrl: "fal fa-scanner-gun",
            dashboardUrl: `${basePath}/reports/price-watch`,
            openInModal: false,
            openInNewTab: false,
            overlayMessage: "Coming Soon",
            reportName: "Price Watch",
            visible: true,
        }, {
            dashboardId: "wellnessPayoutReport",
            description: "",
            hasAccess: true,
            iconUrl: "fal fa-file-chart-column",
            dashboardUrl: `${basePath}/reports/wellness-payout-report`,
            openInModal: false,
            openInNewTab: false,
            overlayMessage: "",
            reportName: "Wellness Payout Report",
            visible: props.canViewClinicWellnessPayoutReport,
        }
    ];

    const renderTileContent = (tile) => {
        return (
            <InformationTile
                title={tile.reportName}
                style={tile.hasAccess ? "" : StyleId.INACTIVE}
                overlay={tile.overlayMessage}
            >
                <div className={styles.tileInfo}>
                    <div className={styles.tileIcon}>
                        <i className={tile.iconUrl}/>
                    </div>
                    <div className={styles.description}>
                        {tile.description}
                    </div>
                </div>
            </InformationTile>
        );
    }

    const handleTileClicked = (tile) => {
        if (!!tile.onClick) {
            tile.onClick();
        }
    };

    const handleOpenModal = (tile) => {
        setOpenModal(tile);
        if (!!tile.onClick) {
            tile.onClick();
        }
    };

    const renderReportTiles = () => {
        return map(orderBy(filter(TILES, "visible"), ["hasAccess", "reportName"], ["desc", "asc"]), tile => {
            if (tile.hasAccess) {
                if (tile.openInNewTab) {
                    // Open tile content in a new tab
                    return (
                        <div
                            key={tile.dashboardId}
                            onClick={() => handleTileClicked(tile)}
                        >
                            <a
                                href={tile.dashboardUrl}
                                target="_blank"
                            >
                                {renderTileContent(tile)}
                            </a>
                        </div>
                    );
                } else if (tile.openInModal) {
                    // Open tile content in a modal
                    return (
                        <div
                            key={tile.dashboardId}
                            onClick={() => handleOpenModal(tile)}
                        >
                            {renderTileContent(tile)}
                        </div>
                    );
                } else {
                    // Go to page associated with tile
                    return (
                        <div
                            key={tile.dashboardId}
                            onClick={() => handleTileClicked(tile)}
                        >

                            <Link to={tile.dashboardUrl}>
                                {renderTileContent(tile)}
                            </Link>
                        </div>
                    );
                }
            } else {
                // Disabled Tile
                return (
                    <div key={tile.dashboardId}>
                        {renderTileContent(tile)}
                    </div>
                );
            }
        });
    }

    return (
        <ClinicBase
            pageTitle="Reports"
            clinicId={props.clinicId}
            adminControls={CLINIC_TAGS}
        >
            <div className={styles.root}>
                <div className={styles.gridContainer}>
                    <div className={styles.grid}>
                        {renderReportTiles()}
                    </div>
                </div>
                <Modal
                    show={!!openModal}
                    onClose={() => setOpenModal(null)}
                    modalTitle={openModal?.reportName || ""}
                >
                    {openModal && (
                        <iframe
                            id={openModal.dashboardId}
                            style={{
                                width: "100%",
                                minHeight: "80vh",
                                flex: "none",
                                border: "none",
                            }}
                            src={openModal?.dashboardUrl}
                        />
                    )}
                </Modal>
            </div>
        </ClinicBase>
    );
}
export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = ClinicData.getClinicInfo(state, adminClinicId);
        const clinicHsMerck = ClinicData.getClinicEnrolledInProgram(clinicInfo, MERCK);
        const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;
        const nodeId = getNodeId(state, ownProps);
        //Permissions
        const canOptInCouponLibrary = userHasPermission(PermissionTypes.OPT_IN, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        // Only show the clientCampaignTracking to Merck (8 === Merck)
        const canViewCouponAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_AUDIT, userProfile);
        // TODO: Delete once the `hasPriceWatch` permission is ready
        const hasActiveSubscription = !!clinicSubscriptions(state, clinicId, PRICE_WATCH, SubscriptionStatuses.ACTIVE)?.length;
        const hasPaidSubscription = !!clinicSubscriptions(state, clinicId, PRICE_WATCH, SubscriptionStatuses.PAID)?.length;

        // GO-162 Hotfix
        const enrolledInGLWellness = clinicInfo.programs.Wellness?.enabled && clinicInfo.programs.Wellness?.providers.includes(69);
        const canViewClinicWellnessPayoutReport = enrolledInGLWellness && userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PAYOUT_REPORT, userProfile);

        return {
            clinicId,
            nodeId,
            canOptInCouponLibrary,
            canViewCouponAudit,
            clinicHsMerck,
            // TODO: Uncomment once the clinic permission is ready
            // hasPriceWatch: ClinicData.clinicHasPriceWatch(state, clinicId),
            hasPriceWatch: (hasActiveSubscription || hasPaidSubscription),
            canViewClinicWellnessPayoutReport,
            lookerReports: sortBy(state.entities.lookerReports?.[clinicId], "sortOrder"),
        }
    },
    (dispatch) => ({
		loadLookerReports: (clinicId) => dispatch(ClinicActions.loadLookerReports(clinicId)),
	})
)(ClinicReports);
