import request from "utils/request";
import getApiRoot from "./getApiRoot";

export function loadUserById(userId) {
    return request()
        .get(`${getApiRoot()}/v1/accounts/users/${userId}/`)
        .send();
}

export function getRoles() {
    return request()
        .get(`${getApiRoot()}/v1/security/roleadmin/roles/`)
        .send();
}

export function createRole(data) {
    return request()
        .post(`${getApiRoot()}/v1/security/roleadmin/roles/`)
        .send(data);
}

export function getRolePermissions(roleId) {
    return request()
        .get(`${getApiRoot()}/v1/security/roleadmin/roles/${roleId}/`)
        .send();
}

export function updateRolePermissions(data) {
    return request()
        .put(`${getApiRoot()}/v1/security/roleadmin/roles/${data.id}/`)
        .send(data);
}

export function getPlans() {
    return request()
        .get(`${getApiRoot()}/v1/admin/plans/`)
        .send();
}

export function searchVouchers(searchParams) {
    return request()
        .post(`${getApiRoot()}/v1/admin/plans/vouchercodes/search/`)
        .send(searchParams);
}

export function updateVoucher(voucher) {
    return request()
        .put(`${getApiRoot()}/v1/admin/plans/vouchercodes/${voucher.voucherCode}/`)
        .send(voucher);
}

export function generateVouchers(data) {
    return request()
        .post(`${getApiRoot()}/v1/admin/plans/vouchercodes/`)
        .send({
            ...data
        });
}

export function getInvoiceLineItems(data) {
    return request()
        .post(`${getApiRoot()}/v1/pimsdata/invoicelineitems/search/`)
        .send(data);
}

export function downloadInvoiceLineItems(data) {
    return request()
        .post(`${getApiRoot()}/v1/pimsdata/invoicelineitems/export/`)
        .responseType("blob")
        .send({
            exportType: "xlsx",
            ...data
        });
}

export function getProviderPlans(providerId) {
    return request()
        .get(`${getApiRoot()}/v1/admin/wellness/provider/${providerId}/plans/`)
        .send();
}

export function updateProviderPlan(providerId, data) {
    return request()
        .put(`${getApiRoot()}/v1/admin/wellness/provider/${providerId}/plans/${data.planId}/`)
        .send(data);
}

export function getProductTags() {
    return request()
        .get(`${getApiRoot()}/v1/admin/producttags/`)
        .send();
}

// E.g., https://gl-webapi-dev.azurewebsites.net/v1/clinics/7777/providers/1/gogreenrequirements
export function getClinicGoGreenRequirements(clinicId, providerId) {
    return request()
        .get(`${getApiRoot()}/v1/clinics/${clinicId}/providers/${providerId}/gogreenrequirements/`)
        .send();
}

export function goGreen(clinicId, providerId, goGreenStartDate, clooEnrollmentTag=null) {
    const newData = {
        goGreenStartDate: goGreenStartDate ? goGreenStartDate : new Date(),
        suppressEmail: false,
        clooEnrollmentTag
    }
    return request()
        .post(`${getApiRoot()}/v2/clinics/${clinicId}/providers/${providerId}/gogreen/`)
        .send(newData);
}

export function goBrown(clinicId, providerId, goBrownDate) {
    const data = {
        goBrownDate: goBrownDate ? goBrownDate : new Date(),
    }
    return request()
        .post(`${getApiRoot()}/v1/clinics/${clinicId}/providers/${providerId}/gobrown/`)
        .send(data);
}

export function impersonateUser(username) {
    return request()
        .query({
            username
        })
        .get(`${getApiRoot()}/v1/accounts/identity/impersonate/`)
        .send();
}

export function revertToSelf() {
    return request()
        .get(`${getApiRoot()}/v1/accounts/identity/reverttoself/`)
        .send();
}

export function getPriceHistory(clinicId, clinicProductId) {
    return request()
        .post(`${getApiRoot()}/v1/admin/clinics/${clinicId}/clinicproduct/${clinicProductId}/pricehistory/`)
        .send();
}

export function getProviderReports() {
    return request()
        .get(`${getApiRoot()}/v1/services/reporting/providerreports/`)
        .send();
}

export function getProviderAccountHolds(clinicId) {
    return request()
        .get(`${getApiRoot()}/v1/admin/accountholds/clinics/${clinicId}/`)
        .send();
}

export function createAccountHold(holdInfo) {
    return request()
        .post(`${getApiRoot()}/v1/admin/accountholds/clinics/accounthold/`)
        .send(holdInfo);
}

export function loadAccountHoldHistory(clinicId) {
    return request()
        .get(`${getApiRoot()}/v1/admin/accountholds/clinics/${clinicId}/history/`)
        .send();
}

export function releaseAccountHold(clinicId, manufacturerId, form) {
    return request()
        .put(`${getApiRoot()}/v1/admin/accountholds/clinics/${clinicId}/${manufacturerId}/accounthold/release/`)
        .send(form);
}

