import request from "utils/request";
import getApiRoot from "api/getApiRoot";

export function searchContentSent(options, clinicId) {
    return request()
        .post(`${getApiRoot()}/v2/vetcheck/clinics/${clinicId}/petownernotifications/`)
        .send(options);
}

export function getPetOwners(clinicId, options={}) {
    const allOptions = {
        search: "",
        offset: 0,
        limit: 10,
        orderBy: "petOwnerFirstName",
        orderDir: "asc",
        ...options
    }

    return request()
        .post(`${getApiRoot()}/v1/vetcheck/clinics/${clinicId}/petowners/`)
        .send(allOptions);
}

export function vetCheckOptOut(clinicId, petOwnerId) {
    return request()
        .put(`${getApiRoot()}/v1/vetcheck/clinics/${clinicId}/petowneroptouts/${petOwnerId}/`)
        .send();
}

export function vetCheckOptIn(clinicId, petOwnerId) {
    return request()
        .delete(`${getApiRoot()}/v1/vetcheck/clinics/${clinicId}/petowneroptouts/${petOwnerId}/`)
        .send();
}

export function getClinicVetCheckStatus(clinicId, loginCallbackUri, registerCallbackUri) {
    return request()
        .query({
            loginCallbackUri,
            registerCallbackUri
        })
        .get(`${getApiRoot()}/v1/vetcheck/clinics/${clinicId}/`)
        .send();
}

export function getVetCheckStatus(clinicId) {
    return request()
        .query({ clinicId })
        .get(`${getApiRoot()}/v1/vetcheckhandouts/mappingsclinicregistration/`)
        .send();
}

// NEW VET_CHECK ENDPOINTS
export function vetCheckRegister(clinicId, clinicInfo) {
    return request()
        .post(`${getApiRoot()}/v2/vetcheck/clinics/${clinicId}/register/`)
        .send(clinicInfo);
}

export function getVetCheckContent(clinicId) {
    return request()
        .get(`${getApiRoot()}/v2/vetcheckcontent/${clinicId}/`)
        .send();
}

export function getVetCheckContentById(clinicId, vetCheckContentId, contentType) {
    return request()
        .query({
            type: contentType
        })
        .get(`${getApiRoot()}/v2/vetcheckcontent/${clinicId}/customcontent/${vetCheckContentId}/`)
        .send();
}

export function sendVetCheckContent(info) {
    return request()
        .query({
            clinicId: info.clinicId
        })
        .post(`${getApiRoot()}/v2/vetcheckcontent/notify/`)
        .send(info);
}

export function getVetCheckMappings(clinicId, type=null) {
    return request()
        .query({
            clinicId,
            type
        })
        .get(`${getApiRoot()}/v2/vetcheckcontent/mappings/`)
        .send();
}

export function createVetCheckMapping(data) {
    return request()
        .post(`${getApiRoot()}/v2/vetcheckcontent/mappings/`)
        .send(data);
}

export function updateVetCheckMapping(mapping) {
    return request()
        .put(`${getApiRoot()}/v2/vetcheckhandouts/mappings/${mapping.id}/`)
        .send(mapping);
}

export function deleteVetCheckMapping(mappingId, contentType) {
    return request()
        .query({
            type: contentType
        })
        .delete(`${getApiRoot()}/v2/vetcheckhandouts/mappings/${mappingId}/`)
        .send();
}

export function getKeywords(clinicId) {
    return request()
        .get(`${getApiRoot()}/v2/vetcheck/clinics/${clinicId}/keywords/`)
        .send();
}
