import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as ClinicActions from "actions/ClinicActions";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import ChooseHospital from "components/admin/elements/ChooseHospital";
import DataTable from "components/common/datatable/DataTable";
import Modal from "components/common/Modal";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import Tooltip from "components/common/Tooltip";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function HospitalsTable(props) {
	const [selectedClinicForHospitalAssignment, setSelectedClinicForHospitalAssignment] = useState(false);

    useEffect(() => {
        if(props.canViewClinicManagement) {
            props.getClinicHospitals(props.clinicId);
        }
    }, [props.canViewClinicManagement, props.clinicId]);

	if (!props.canViewClinicManagement) {
		return null;
	}

    const assignHospital = (updatedHospital) => {
        if (props.canAssignHospital) {
            props.assignHospital(updatedHospital);
        }
    }

    const handleAssignHospital = ({id, name}) => {
        const newClinicForHospitalAssignment = {
            ...selectedClinicForHospitalAssignment,
            targetClinicId: id,
            targetClinicName: name,
            canAssign: false,
            canUnassign: true
        };
        assignHospital(newClinicForHospitalAssignment);
        setSelectedClinicForHospitalAssignment(null);
    }

    const handleUnassignHospital = (hospital) => {
        const updatedHospital = {
            ...hospital,
            targetClinicId: hospital.gatewayClinicId,
            targetClinicName: hospital.gatewayClinicName,
            canAssign: true,
            canUnassign: false
        };
        assignHospital(updatedHospital);
    }

	const ASSIGN_COLUMNS = props.canAssignHospital ? [{
        name: "",
        selector: "assign",
        key: "assign",
        format: row => (
            <div className="text-center">
                {row.canAssign && (
                    <Button
                        onClick={() => setSelectedClinicForHospitalAssignment(row)}
                        small
                    >
                        Assign
                    </Button>
                )}
                {row.canUnassign && (
                    <Button
                        onClick={() => handleUnassignHospital(row)}
                        small
                    >
                        Unassign
                    </Button>
                )}
                {!(row.canAssign || row.canUnassign) && "Data Cannot be Moved"}
            </div>
        ),
    }] : [];

    const HOSPITAL_COLUMNS = [{
        name: (
            <div className="text-center">
                <div>Hospital ID</div>
                <div>(Remote)</div>
            </div>
        ),
        selector: "remoteClinicHospitalID",
        key: "remoteClinicHospitalID",
        format: row => (
            <div className="text-center">{row.remoteClinicHospitalID}</div>
        ),
    }, {
        name: (
            <div className="text-center">
                <div>Hospital ID</div>
                <div>(Greenline)</div>
            </div>
        ),
        selector: "hospitalId",
        key: "hospitalId",
        format: row => (
            <div className="text-center">{row.hospitalId}</div>
        ),
    }, {
        name: "Hospital Name",
        selector: "hospitalName",
        key: "hospitalName",
        format: row => (
            <div className="nowrap">{row.hospitalName}</div>
        ),
    }, {
        name: "Identifier",
        selector: "identifier",
        key: "identifier",
        format: row => (
            <div className="nowrap">{row.identifier}</div>
        ),
    }, {
        name: (<div className="text-center">Invoices</div>),
        selector: "invoiceCount",
        key: "invoiceCount",
        format: row => (
            <div className="text-center">{row.invoiceCount}</div>
        ),
    }, {
        name: (
            <div className="text-center">
                <div>Clinic ID</div>
                <div>(Assigned To)</div>
            </div>
        ),
        selector: "gatewayClinicId",
        key: "gatewayClinicId",
        format: row => (
            <div className="text-center">
                {row.gatewayClinicId === row.targetClinicId ? row.gatewayClinicId : row.targetClinicId}
            </div>
        )
    }, {
        name: "Hospital ID (Assigned To)",
        selector: "gatewayClinicName",
        key: "gatewayClinicName",
        format: row => (
            (row.gatewayClinicId === row.targetClinicId) ? (
                <Tooltip position="left" tip="Gateway Clinic">
                    <div className="no-wrap">
                        <i className="fas fa-hospitals"/> {row.targetClinicName}
                    </div>
                </Tooltip>
            ) : (
                <div className="no-wrap">{row.targetClinicName}</div>
            )
        ),
    },
    ...ASSIGN_COLUMNS];

    const hasHospitals = !!props.hospitals && Object.keys(props.hospitals).length;

    return (
	    <div
            className="card"
            style={{ gridColumn: "1 / -1" }}
        >
	        {hasHospitals ? (
	            <DataTable
                    green
	                title="Clinics"
	                columns={HOSPITAL_COLUMNS}
	                data={Object.values(props.hospitals)}
	                highlightOnHover
                    striped
	            />
	        ) : (
	            <div>
	                <h3>Clinics</h3>
	                <div>Nothing to show</div>
	            </div>
	        )}
	        <Modal
	            show={!!selectedClinicForHospitalAssignment}
	            onClose={() => setSelectedClinicForHospitalAssignment(null)}
	            mediumSmall
	            modalTitle="Choose Clinic"
	            modalSubTitle="Please choose a Non-Gateway Clinic from the list below"
	        >
	            {props.canAssignHospital ? (
	                <ChooseHospital
	                    onSelect={handleAssignHospital}
	                />
	            ) : (
	                <AccessDenied/>
	            )}
	        </Modal>
	        <SpinnerTakeover show={props.loadingHospitals}/>
	    </div>
    );
}

HospitalsTable.defaultProps = {
	canAssignHospital: false,
	canViewClinicManagement: false,
    hospitals: {},
};

HospitalsTable.propTypes = {
	canAssignHospital: PropTypes.bool,
	canViewClinicManagement: PropTypes.bool,
    clinicId: PropTypes.number.isRequired,
    hospitals: PropTypes.object,
};

export default connect(
	(state, ownProps) => {
        const userProfile = state.user.userProfile;
        const hospitals = state.entities.hospitals.data || {};

        //Permissions
        const canAssignHospital = userHasPermission(PermissionTypes.ASSIGN_HOSPITAL, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);

        return {
            canAssignHospital,
            canViewClinicManagement,
            hospitals: hospitals[ownProps.clinicId] || {},
            loadingHospitals: state.entities.hospitals.loading || false,
        }
    },
    (dispatch) => ({
        assignHospital: (data) => dispatch(ClinicActions.assignHospital(data)),
        getClinicHospitals: (clinicId) => dispatch(ClinicActions.getClinicHospitals(clinicId)),
    })
)(HospitalsTable);
