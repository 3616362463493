import React from "react";
import {connect} from "react-redux";
import styles from "./WellnessAudit.scss";
import AccessDenied from "components/common/AccessDenied";
import ClinicBase from "components/layout/ClinicBase";
import UnprocessedWellnessVisits from "components/wellness/widgets/UnprocessedWellnessVisits";
import {getClinicInfo, clinicHasWellness} from "utils/ClinicData";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import {CLINIC_TAGS} from "constants/AdminControls";
import * as UserPermissions from "constants/UserPermissions";
import * as SearchTypes from "constants/SearchTypes";
import NewPlanEnrollmentBtn from 'components/greenlineWellness/widgets/WellnessEnrollmentModal/NewPlanEnrollmentBtn';

class WellnessAudit extends React.Component {
    render() {
        return (
            <ClinicBase
                searchConfig={SearchTypes.WELLNESS_AUDIT}
                clinicId={this.props.clinicId}
                adminControls={CLINIC_TAGS}
                docTitle="Wellness Audit"
            >
                <div className={styles.root}>
                    <div className={styles.topLine}>
                        <h2>Wellness Audit</h2>
                        {(this.props.canEnrollNew) && <NewPlanEnrollmentBtn clinicId={this.props.clinicId} />}
                    </div>
                    <div className="flex-1">
                        {!(this.props.canViewWellnessAudit && this.props.hasWellness) ? (
                            <AccessDenied/>
                        ) : (
                            <UnprocessedWellnessVisits
                                clinicId={this.props.clinicId}
                                isAdminView={!!this.props.isAdminView}
                                canGLWellness={this.props.canGLWellness}
                                wellnessProviders={this.props.wellnessProviders}
                            />
                        )}
                    </div>
                </div>
            </ClinicBase>
        )
    }
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;
        const userProfile = state.user.userProfile;
        const canViewWellnessAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);
        const canViewClinicWellnessPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);
        const canGLWellness = clinicInfo?.programs?.Wellness?.providers.includes(69);
        const wellnessProviders = clinicInfo?.programs?.Wellness?.providers || [];
        const hasWellness = clinicHasWellness(state, clinicId);
        const canEnrollNew = canGLWellness && canViewWellnessAudit && canViewClinicWellnessPlans && hasWellness;

        return {
            clinicId,
            isAdminView: !!adminClinicId,
            hasWellness,
            wellnessProviders,
            // Permissions
            canViewClinicWellnessPlans,
            canViewWellnessAudit,
            canGLWellness,
            canEnrollNew,
        }
    }
)(WellnessAudit);
