/* USAGE
*
*   Dropdown component options (array of name:value pairs)
*       usStatesDropdownList = 50 States + DC
*       usStatesAndTerritoriesDropdownList = 50 states + DC + Territories
*
* *   Elsewhere:
*       usStates : Object of US States names keyed by Postal Abbreviation
*       usStatesByName : Object of US States postal abbreviations keyed by state name
*       usStatesPostal : Array of US States postal abbreviations
*       usStatesNames : Array of US State names
*
*       usTerritories : Object of US Territory names keyed by Postal Abbreviation
*       usTerritoriesByName : Object of US Territory postal abbreviations keyed by territory name
*
*       usStatesAndTerritories : Object of US States and Territory names keyed by Postal Abbreviation
*       usStatesAndTerritoriesByName : Object of US State and Territory postal abbreviations keyed by state name
*       usStatesAndTerritoriesPostal : Array of US State and Territory postal abbreviations
*       usStatesAndTerritoriesNames :  Array of US State and Territory names
*
* */
import { invert, keys, map, values } from "lodash";

export const USStates = {
    "AL": "Alabama",
    "AK": "Alaska",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "District Of Columbia",
    "FL": "Florida",
    "GA": "Georgia",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PA": "Pennsylvania",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
}

export const usTerritories = {
    "AS": "American Samoa",
    "FM": "Federated States Of Micronesia",
    "GU": "Guam",
    "MH": "Marshall Islands",
    "MP": "Northern Mariana Islands",
    "PW": "Palau",
    "PR": "Puerto Rico",
    "VI": "Virgin Islands"
}

export const usStatesByName = invert(USStates);
export const usStatesPostal = keys(USStates);
export const usStatesNames = values(USStates);
export const usTerritoriesByName = invert(usTerritories);
export const usStatesAndTerritories = { ...USStates, ...usTerritories }
export const usStatesAndTerritoriesByName = invert(usStatesAndTerritories);
export const usStatesAndTerritoriesPostal = keys(usStatesAndTerritories);
export const usStatesAndTerritoriesNames = values(usStatesAndTerritories);



export const usStatesList = [
    {
        "name": "Alabama",
        "abbr": "AL"
    },
    {
        "name": "Alaska",
        "abbr": "AK"
    },
    {
        "name": "Arizona",
        "abbr": "AZ"
    },
    {
        "name": "Arkansas",
        "abbr": "AR"
    },
    {
        "name": "California",
        "abbr": "CA"
    },
    {
        "name": "Colorado",
        "abbr": "CO"
    },
    {
        "name": "Connecticut",
        "abbr": "CT"
    },
    {
        "name": "Delaware",
        "abbr": "DE"
    },
    {
        "name": "District Of Columbia",
        "abbr": "DC"
    },
    {
        "name": "Florida",
        "abbr": "FL"
    },
    {
        "name": "Georgia",
        "abbr": "GA"
    },
    {
        "name": "Hawaii",
        "abbr": "HI"
    },
    {
        "name": "Idaho",
        "abbr": "ID"
    },
    {
        "name": "Illinois",
        "abbr": "IL"
    },
    {
        "name": "Indiana",
        "abbr": "IN"
    },
    {
        "name": "Iowa",
        "abbr": "IA"
    },
    {
        "name": "Kansas",
        "abbr": "KS"
    },
    {
        "name": "Kentucky",
        "abbr": "KY"
    },
    {
        "name": "Louisiana",
        "abbr": "LA"
    },
    {
        "name": "Maine",
        "abbr": "ME"
    },
    {
        "name": "Maryland",
        "abbr": "MD"
    },
    {
        "name": "Massachusetts",
        "abbr": "MA"
    },
    {
        "name": "Michigan",
        "abbr": "MI"
    },
    {
        "name": "Minnesota",
        "abbr": "MN"
    },
    {
        "name": "Mississippi",
        "abbr": "MS"
    },
    {
        "name": "Missouri",
        "abbr": "MO"
    },
    {
        "name": "Montana",
        "abbr": "MT"
    },
    {
        "name": "Nebraska",
        "abbr": "NE"
    },
    {
        "name": "Nevada",
        "abbr": "NV"
    },
    {
        "name": "New Hampshire",
        "abbr": "NH"
    },
    {
        "name": "New Jersey",
        "abbr": "NJ"
    },
    {
        "name": "New Mexico",
        "abbr": "NM"
    },
    {
        "name": "New York",
        "abbr": "NY"
    },
    {
        "name": "North Carolina",
        "abbr": "NC"
    },
    {
        "name": "North Dakota",
        "abbr": "ND"
    },
    {
        "name": "Ohio",
        "abbr": "OH"
    },
    {
        "name": "Oklahoma",
        "abbr": "OK"
    },
    {
        "name": "Oregon",
        "abbr": "OR"
    },
    {
        "name": "Pennsylvania",
        "abbr": "PA"
    },
    {
        "name": "Rhode Island",
        "abbr": "RI"
    },
    {
        "name": "South Carolina",
        "abbr": "SC"
    },
    {
        "name": "South Dakota",
        "abbr": "SD"
    },
    {
        "name": "Tennessee",
        "abbr": "TN"
    },
    {
        "name": "Texas",
        "abbr": "TX"
    },
    {
        "name": "Utah",
        "abbr": "UT"
    },
    {
        "name": "Vermont",
        "abbr": "VT"
    },
    {
        "name": "Virginia",
        "abbr": "VA"
    },
    {
        "name": "Washington",
        "abbr": "WA"
    },
    {
        "name": "West Virginia",
        "abbr": "WV"
    },
    {
        "name": "Wisconsin",
        "abbr": "WI"
    },
    {
        "name": "Wyoming",
        "abbr": "WY"
    }
];

export const usTerritoriesList = [
    {
        "name": "American Samoa",
        "abbr": "AS"
    },
    {
        "name": "Federated States Of Micronesia",
        "abbr": "FM"
    },
    {
        "name": "Guam",
        "abbr": "GU"
    },
    {
        "name": "Marshall Islands",
        "abbr": "MH"
    },
    {
        "name": "Northern Mariana Islands",
        "abbr": "MP"
    },
    {
        "name": "Palau",
        "abbr": "PW"
    },
    {
        "name": "Puerto Rico",
        "abbr": "PR"
    },
    {
        "name": "Virgin Islands",
        "abbr": "VI"
    }
];

export const usStatesDropdownList = map(usStatesList, state => (
    { name: state.name, value: state.abbr }
));

export const usStateValueOptions = map(usStatesList, "abbr");


export const usStatesAndTerritoriesDropdownList = map([...usStatesList, ...usTerritoriesList], state => (
    { name: state.name, value: state.abbr }
));
