import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import classnames from "classnames";
import styles from "./ClinicGames.scss";
import filter from "lodash/filter";
import groupBy from "lodash/groupBy";
import keys from "lodash/keys";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import reverse from "lodash/reverse";
import BasicGridTiles from "components/common/BasicGridTiles";
import BiProductDisclosures from "components/common/BiProductDisclosures";
import Button from "components/common/Button";
import GreenlineGameCard from "components/games/elements/GreenlineGameCard";
import { getClinicInfo } from "utils/ClinicData";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function HistoricGamesList(props) {
    const {
        clinicId,
        games,
        nodeId,
        onClickCurrent,
    } = props;
    const [gamesList, setGamesList] = useState({});

    useEffect(() => {
        const historicGames = filter(games, game => game.isCurrent === false);
        const groupedByYear = groupBy(historicGames, game => {
            return moment(game.endDate).format("YYYY");
        })
        setGamesList(groupedByYear);
    }, [games]);

    const getAllCards = (yearGames) => {
        return map(orderBy(yearGames, ["startDate"], ["desc"]), game => (
            <GreenlineGameCard
                game={game}
                key={`game_${game.greenlineGameId}`}
                clinicId={clinicId}
                nodeId={nodeId}
            />
        ));
    }

    return (
        <div className={classnames(styles.root, styles.historic)}>
            <section className="flex-1 overflow-y-scroll">
                <div className={"flex border-bottom"}>
                    <Button onClick={onClickCurrent}>
                        Current Games
                    </Button>
                    <Button
                        onClick={() => {}}
                        disabled
                    >
                        Historic Games
                    </Button>
                </div>
                <h3>Historic Games</h3>
                {!!keys(gamesList)?.length ? (
                    <div>
                        {map(reverse(keys(gamesList)), (year, index) => (
                            <div
                                key={year}
                                className={classnames({
                                    "margin-top-lg": index !== 0,
                                })}
                            >
                                <h2 className="border-bottom">{year}</h2>
                                <BasicGridTiles tiles={getAllCards(gamesList[year])} large />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div>No Historic Games Available</div>
                )}
            </section>
            <div className="text-x-sm border-top margin-bottom-sm">
                <BiProductDisclosures/>
            </div>
        </div>
    );
}

HistoricGamesList.propTypes = {
    games: PropTypes.object,
    onClickCurrent: PropTypes.func.isRequired,
};

const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);

        return {
            clinicId,
            canViewClinicBIGamesDashboard,
        }
    }
);

export default compose(
    withRouter,
    connector
)(HistoricGamesList);