import React, {useState} from "react";
import PropTypes from "prop-types";
import Button from "components/common/Button";
import ClinicAdminForm from "./ClinicAdminForm";
import ExtendPasswordExpiration from "components/admin/elements/ExtendPasswordExpiration";
import LabelValueRow from "components/common/LabelValueRow";
import Modal from "components/common/Modal";
import PasswordResetForm from "components/admin/forms/AdminClinicSettingsFormContainer/PasswordResetForm";
import Tooltip from "components/common/Tooltip";
import toast from "utils/toast";
import { PRIMARY } from "constants/ButtonTypes";
import { LEFT } from "constants/TooltipPositionIds";

export default function ClinicAdminPanel(props) {
	const [showEditClinicAdmin, setShowEditClinicAdmin] = useState(false);
	const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);

	if (!props.clinic && props.clinicId) {
		return null;
	}

	const handleEditClinicAdmin = () => {
		setShowEditClinicAdmin(true);
	};

	const handleCloseEditClinicAdmin = () => {
		setShowEditClinicAdmin(false);
	};

	const handleCloseResetForm = () => {
		setShowResetPasswordForm(false);
	};

    return (
        <div className="card">
            <div className="flex spaced-content">
                <h4 className="flex-1">Clinic Admin</h4>
                <Tooltip
                    position={LEFT}
                    tip="Edit Clinic Admin"
                >
                    <div className="flex-none">
                        <Button
                            iconOnly
                            onClick={handleEditClinicAdmin}
                            type="primary"
                        >
                            <i className="fa fa-pencil" />
                        </Button>
                    </div>
                </Tooltip>
            </div>
            <LabelValueRow
                label="Name"
                value={`${props.clinic.clinicAdminFirstName} ${props.clinic.clinicAdminLastName}`}
            />
            <LabelValueRow
                label="Username"
                value={props.clinic.clinicAdminUserName}
                contentAfter={(
                    <Tooltip
                        position="left"
                        tip="Copy to Clipboard"
                    >
                        <div className="flex-none">
                            <Button
                                iconOnly
                                type="primary"
                                disabled={!props.clinic.clinicAdminUserName}
                                onClick={() => {
                                    navigator.clipboard.writeText(props.clinic.clinicAdminUserName);
                                    toast.success("Username Copied to Clipboard");
                                }}
                            >
                                <i className="far fa-copy" />
                            </Button>
                        </div>
                    </Tooltip>
                )}
            />
            <LabelValueRow
                label="Email"
                value={(<span style={{ overflowWrap: "break-word" }}>{props.clinic.contactEmail}</span>)}
            />
            <LabelValueRow
                label="Phone"
                value={props.clinic.contactPhone}
            />
            <div className="flex spaced-content align-center">
                <div className="flex-1">
                    <LabelValueRow
                        label="Due for Password Reset"
                        value={props.clinic.needsPasswordChange ? <span className="text-danger">Yes</span> : <span className="text-success">No</span>}
                        contentAfter={(
							<div className="flex flex-centered spaced-content-sm">
		                        <ExtendPasswordExpiration
	                                canResetPasswords={props.canResetPasswords}
	                                clinicAdminUserId={props.clinic?.clinicAdminUserId}
	                                clinicId={props.clinicId}
	                            />
								<Tooltip
									position={LEFT}
									tip="Reset Password"
								>
									<div>
				                        <Button
					                        className="margin-bottom-x-sm"
				                            iconOnly
				                            onClick={() => setShowResetPasswordForm(true)}
					                        type={PRIMARY}
				                        >
					                        <i className="fa fa-pencil" />
				                        </Button>
									</div>
								</Tooltip>
	                        </div>
                        )}
                    />
                </div>
            </div>
            <Modal
                show={showEditClinicAdmin}
                modalTitle="Edit Clinic Admin"
                onClose={handleCloseEditClinicAdmin}
                small
            >
                {showEditClinicAdmin && (
                    <ClinicAdminForm
                        clinicId={props.clinicId}
                        initialData={props.clinic}
                        onClose={handleCloseEditClinicAdmin}
                    />
                )}
            </Modal>
	        <Modal
		        modalTitle="Reset the Clinic Admin's Password"
		        onClose={handleCloseResetForm}
		        show={showResetPasswordForm}
		        small
	        >
		        <PasswordResetForm
	                initialData={props.clinic}
	                canResetPasswords={props.canResetPasswords}
	                onClose={handleCloseResetForm}
	            />
	        </Modal>
        </div>
    );
}

ClinicAdminPanel.defaultProps = {
	canResetPasswords: false,
    clinic: {},
};

ClinicAdminPanel.propTypes = {
	canResetPasswords: PropTypes.bool,
    clinic: PropTypes.object,
	clinicId: PropTypes.number.isRequired,
};
