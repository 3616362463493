import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import * as MarketingApi from "api/MarketingApi";
import * as MarketingActions from "actions/MarketingActions";
import Dropdown from "components/common/Dropdown";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import SortableDataTable from "components/common/SortableDataTable";
import Button from "components/common/Button";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import ResultsPerPageDropdown from "components/coupons/widgets/ResultsPerPageDropdown";
import toast from "utils/toast";
import { downloadFile, handleErrorResponse } from "utils/request";

function CampaignTrackingTable(props) {
    const [enrollmentStates, setEnrollmentStates] = useState(null);
    const [offset, setOffset] = useState(0);
    const [sortBy, setSortBy] = useState("lastName");
    const [sortDirection, setSortDirection] = useState("desc");
    const [currentFilter, setCurrentFilter] = useState({status: null})
    const [limit, setLimit] = useState(10);


    const tableData = useMemo(() => (
        orderBy(props.petMarketingInfo?.data||{}, [sortBy], [sortDirection])
    ), [props.petMarketingInfo]);

    const totalPages = useMemo(() => (
        props.petMarketingInfo?.totalRecordCount ? Math.ceil(props.petMarketingInfo.totalRecordCount / limit): 0
    ), [props.petMarketingInfo]);

    const page = useMemo(() => ((offset / limit) + 1), [offset, limit]);
    const canGoNext = useMemo(() => (page < totalPages), [page, totalPages]);
    const canGoPrevious = page > 1;


    const ENROLLMENT_STATE_OPTIONS = !!enrollmentStates ? map(enrollmentStates, s => {
        return {name: s.text, value: s.value}
    }) : [];

    const getEnrollmentStates = () => {
        MarketingApi.getEnrollmentStates()
            .then((res) => {
                setEnrollmentStates(res.body);
            })
            .catch((error) => {
                handleErrorResponse("loading enrollment stats", error);
            });
    };

    const getPetMarketingInfo = (searchData) => {
        const defaultSearchData = {
            clinicId: props.clinicId,
            search: props.searchTerms,
            orderBy: sortBy,
            orderDir: sortDirection,
            offset,
            limit,
            ...searchData
        }
        props.getPetMarketingInfo(defaultSearchData);
        setCurrentFilter(searchData?.filters);
    };

    useEffect(() => {
        if(!enrollmentStates) {
            getEnrollmentStates();
        }
        if(props.clinicId) {
            const searchData = currentFilter?.status ? { filters: currentFilter } : null;
            getPetMarketingInfo(searchData);
        }
    }, [props.clinicId, sortBy, sortDirection, offset]);

    useEffect(() => {
        if(props.clinicId && !offset) {
            getPetMarketingInfo();
        } else {
            setOffset(0);
        }
    }, [props.searchTerms, limit]);

    const handleChange = ({name, value}, row) => {
        const updateData = {
            [name]: value
        }
        props.updatePetMarketingInfo(props.clinicId, row.clientId, updateData, currentFilter);
    }

    const COLUMNS = [
        {
            name: "Pet Owner",
            selector: "lastName",
            key: "lastName",
            sortable: true,
            searchable: true,
            format: row => (
                <div>{row.firstName} {row.lastName}</div>
            )
        },
        // {
        //     name: "Pet Name",
        //     selector: "patientName",
        //     key: "patientName",
        //     sortable: true,
        //     searchable: true,
        // },
        {
            name: "Email",
            selector: "email",
            key: "email",
            sortable: true,
            searchable: true,
        }, {
            name: "Status",
            selector: "status",
            key: "status",
            filter: {
                enabled: true,
                title: '--Select Status--',
                values: ENROLLMENT_STATE_OPTIONS,
                onChange: (newFilterValue) => {
                    getPetMarketingInfo({
                        filters: {
                            status: newFilterValue
                        },
                        offset: 0,
                    })
                }
            },
            format: row => (
                <Dropdown
                    options={ENROLLMENT_STATE_OPTIONS}
                    name="status"
                    onChange={(data) => handleChange(data, row)}
                    value={row.status}
                    insideScrollingTable
                    edgeOfConatiner
                />
            )
        }
    ];

    const handleSort = (value) => {
        if (value === sortBy) {
            if (sortDirection === "asc") {
                setSortDirection("desc");
            } else {
                setSortDirection("asc")
            }
        } else {
            setSortBy(value);
            setSortDirection("asc");
        }
    }

    const handlePrev = () => {
        const newOffset = (offset - limit);
        setOffset(newOffset);
    }

    const handleNext = () => {
        const newOffset = (offset + limit);
        setOffset(newOffset);
    }

    const handleDownloadExcel = async () => {
        toast.success("Generating Export...");
        const res = await MarketingApi.exportPetMarketingInfo({ clinicId: props.clinicId, exportType: "xlsx" });
        downloadFile(res, "xlsx");
    };

    return (
        <div className="height-100-perc flex flex-column full-width">
            <div className="flex justify-flex-end margin-bottom-sm">
                <div className="flex-1">
                    <h2 className="flex-none mobile-hide">Pet Owner List</h2>
                    <div className="flex-none">
                        Please use this list to manually opt-out pet owners from Merck Email Campaigns.
                        Pet owners who are opted out by the clinic will not receive future communications.
                    </div>
                </div>
            </div>
            <div className="flex justify-flex-end margin-bottom-x-sm">
                <ResultsPerPageDropdown
                    onChange={({value}) => {setLimit(value)}}
                    value={limit}
                    max={props.petMarketingInfo?.totalRecordCount || 0}
                />
            </div>
            <SortableDataTable
                onSort={handleSort}
                columns={COLUMNS}
                rawData={tableData}
                striped
                green
                resultsPerPage={limit}
                hideSearchBar
                noPagination
                downloadExcel={handleDownloadExcel}
            />
            {(totalPages > 1) && (
                <div className="flex full-width flex-centered margin-top-x-sm">
                    <div className="flex-none">
                        <Button
                            disabled={!canGoPrevious}
                            onClick={handlePrev}
                        >
                            <i className="fa fa-chevron-left margin-right-sm" /> Previous
                        </Button>
                    </div>

                    <div className="flex-1 text-center">
                        Page {page} of {totalPages}
                    </div>
                    <div className="flex-none">
                        <Button
                            disabled={!canGoNext}
                            onClick={handleNext}
                        >
                            Next <i className="fa fa-chevron-right margin-left-sm" />
                        </Button>
                    </div>
                </div>
            )}
            <SpinnerTakeover show={props.loading}/>
        </div>
    );
}

CampaignTrackingTable.defaultProps = {
    clinicId: PropTypes.number,
}


export default connect(
    (state, ownProps) => ({
        petMarketingInfo: state.entities.petMarketingInfo[ownProps.clinicId],
        loading: !!state.entities.petMarketingInfo?.loading,
        searchTerms: state.entities.genericSearch,
    }),
    (dispatch) => ({
        getPetMarketingInfo: (data) => dispatch(MarketingActions.getPetMarketingInfo(data)),
        updatePetMarketingInfo: (clinicId, clientId, data, currentFilter) => dispatch(MarketingActions.updatePetMarketingInfo(clinicId, clientId, data, currentFilter)),
    })
)(CampaignTrackingTable);
