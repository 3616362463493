import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

export default function InputFocusListener({
    onFocus = () => undefined,
    onBlur = () => undefined,
    children,
}) {
    const focusRef = useRef();

    useEffect(() => {
        listen();

        return () => {
            unlisten();
        };
    }, []);

    function listen() {
        window.addEventListener("mousedown", handleMouseDown);
    }

    function unlisten() {
        window.removeEventListener("mousedown", handleMouseDown);
    }

    function handleMouseDown(e) {
        const selfEl = focusRef.current;
        const targetEl = e.target;
        const isSelf = targetEl === selfEl;
        const all = Array.from(selfEl.getElementsByTagName("*"));
        const isChild = !!(all.includes(targetEl));

        if (isSelf || isChild) {
            onFocus(e);
        } else {
            onBlur(e);
        }
    }

    return (
        <div ref={focusRef}>
            {children}
        </div>
    );
}

InputFocusListener.propTypes = {
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
};
