import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import includes from "lodash/includes";
import * as ClinicActions from "actions/ClinicActions";
import ClinicBase from "components/layout/ClinicBase";
import HistoricGamesList from "components/games/pages/HistoricGamesList";
import * as ClinicAdminLinks from "utils/ClinicAdminLinks";
import { getClinicGames, getClinicInfo } from "utils/ClinicData";
import * as ClinicLinks from "utils/ClinicLinks";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import { CLINIC_TAGS } from "constants/AdminControls";
import * as UserPermissions from "constants/UserPermissions";

function ClinicHistoricGames(props) {
    const history = useHistory();
    const { pathname } = useLocation();
    const isAdmin = !!includes(pathname, "/admin");

    useEffect(() => {
        if (props.canViewClinicBIGamesDashboard) {
            props.getClinicGreenlineGames(props.clinicId);
        }
    }, [props.clinicId]);

    const goToCurrentGames = () => {
        if (isAdmin) {
            history.push(ClinicAdminLinks.getLinkLocation("games", props.clinicId));
        } else {
            history.push(ClinicLinks.getLinkLocation("games"));
        }
    };

    return (
        <ClinicBase
            pageTitle="Greenline Games™"
            clinicId={props.clinicId}
            adminControls={CLINIC_TAGS}
        >
            <HistoricGamesList
                games={props.games}
                onClickCurrent={goToCurrentGames}
            />
        </ClinicBase>
    );
}

export default connect (
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;
        const userProfile = state.user.userProfile;
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);

        return {
            clinicId,
            canViewClinicBIGamesDashboard,
            games: getClinicGames(state),
        }
    },
    (dispatch) => ({
        getClinicGreenlineGames: (clinicId) => dispatch(ClinicActions.getGreenlineGames(clinicId)),
    }),
)(ClinicHistoricGames);
