import { useRecoilCallback } from "recoil";

const syncer = {};

export default function RecoilSyncer() {
    // syncer.get = useRecoilCallback(({ snapshot }) => (atom) => {
    //     return snapshot.getLoadable(atom).contents;
    // }, []);
    syncer.set = useRecoilCallback(({ set }) => {
        return function (atom, valOrUpdater) {
            set(atom, valOrUpdater);
        };
    }, []);

    return null;
}

export async function setRecoilAtom(atom, valOrUpdater) {
    await syncer.set(atom, valOrUpdater);
}
