import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form } from "informed";
import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import UserInitials from "components/notifications/elements/UserInitials";
import toast from "utils/toast";
import * as WellnessApi from "api/WellnessApi";
import TextBox from "components/common/TextBox";
import {handleErrorResponse} from "utils/request";

export default function CancelPlanForm(props) {
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    const [showOtherBox, setShowOtherBox] = useState(false);

    const handleChange = ({ name, value }) => {
        setFormData({
            ...formData,
            [name]: value
        });

        if(name === 'cancelReason') {
            setShowOtherBox(value === 'other')
        }
    }

    // Cancel the subscription
    const handleSubmit = () => {
        setLoading(true);
        let reason = (formData.cancelReason == 'other' && formData.customReason) || formData.cancelReason

        WellnessApi.cancelWellnessSubscription(props.clinicId, props.subscription.petPlanEnrollmentId, reason, formData.initials)
        .then(() => {
            toast.success("Plan Cancelled Successfully");
            props.onAfterSave();
        })
        .catch((error) => {
            handleErrorResponse("cancelling subscription", error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Dropdown
                label="Reason for Cancel"
                name="cancelReason"
                onChange={handleChange}
                options={props.cancelReasons}
                value={formData.cancelReason}
                placeholder={"Choose a reason for cancelling the plan"}
                alignedLeft
            />
            { showOtherBox ? (
                <TextBox
                    onChange={handleChange}
                    name="customReason"
                    value={formData.customReason}
                    placeholder="Cancel Reason"
                    label="Other"
                    visible={showOtherBox}
                />
            ) : <></>}
            <div className="padding-top-xs">
                <UserInitials
                    value={formData.initials}
                    onChange={handleChange}
                    alignedLeft
                />
            </div>
            <div className="flex spaced-content justify-flex-end">
                <Button
                    onClick={props.onAfterSave}
                    type="gray"
                >
                    Close
                </Button>
                <Button
                    buttonType="submit"
                    disabled={!(formData.initials && formData.cancelReason)}
                >
                    Submit
                </Button>
            </div>
            <SpinnerTakeover show={loading} />
        </Form>
    );
}

// TODO: this might come from the API in the future.
//  Or the values will at least need to be updated.
CancelPlanForm.defaultProps = {
    cancelReasons: [
        {
            name: "Pet Deceased",
            value: "pet_deceased",
        },
        {
            name: "Owner Relocation",
            value: "owner_relocated",
        },
        {
            name: "Pet Rehomed",
            value: "pet_rehomed",
        },
        {
            name: "Other",
            value: "other",
        },
    ]
};

CancelPlanForm.propTypes = {
    subscription: PropTypes.object,
    clinicId: PropTypes.number.isRequired,
    cancelReasons: PropTypes.arrayOf(PropTypes.object),
    onAfterSave: PropTypes.func.isRequired,
};
