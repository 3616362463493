exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".AdminClinicInfo__root{display:-ms-grid;display:grid;-ms-grid-columns:(minmax(430px, 1fr))[auto-fill];grid-template-columns:repeat(auto-fill, minmax(430px, 1fr));grid-gap:1em}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/admin/forms/AdminClinicSettingsFormContainer/AdminClinicInfo.scss"],"names":[],"mappings":"AAAA,uBAAM,iBAAiB,aAAa,iDAAiD,4DAA4D,YAAY,CAAC","file":"AdminClinicInfo.scss","sourcesContent":[".root{display:-ms-grid;display:grid;-ms-grid-columns:(minmax(430px, 1fr))[auto-fill];grid-template-columns:repeat(auto-fill, minmax(430px, 1fr));grid-gap:1em}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "AdminClinicInfo__root"
};