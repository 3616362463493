import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import includes from "lodash/includes";
import * as CouponActions from "actions/CouponActions";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import ClinicBase from "components/layout/ClinicBase";
import CouponOptInOffers from "components/coupons/widgets/CouponOptInOffers";
import { CLINIC_TAGS } from "constants/AdminControls";
import * as SearchTypes from "constants/SearchTypes";
import * as UserPermissions from "constants/UserPermissions";
import * as ClinicAdminLinks from "utils/ClinicAdminLinks";
import { getClinicInfo, getProviderImage } from "utils/ClinicData";
import * as ClinicProviderLinks from "utils/ClinicProviderLinks";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import {useLocation, useParams} from "react-router-dom";

function CouponOptIn(props) {
    const history = useHistory();
    const params = useParams();
    const { pathname } = useLocation();
    const nodeId = Number(params.nodeId) || null;
    const programId = Number(params.programId) || null;
    const isAdmin = props.clinicId && includes(pathname, "/admin");
    useEffect(() => {
        if(props.canViewClinicCouponLibrary) {
            props.getProgramOffers(programId, props.clinicId);
        }
        if (props.canViewClinicCouponLibrary && !(props.program && props.program?.provider)) {
            props.getPrograms(props.clinicId);
        }
    }, []);

    const handleBackToCouponLibrary = () => {
        if (isAdmin) {
            history.push(ClinicAdminLinks.getLinkLocation("couponLibrary", props.clinicId))
        } else {
            history.push(ClinicProviderLinks.getLinkLocation("couponLibrary", nodeId, props.clinicId))
        }
    };

    if (!props.program?.provider) {
        return (
            <ClinicBase
                collapsible={true}
                clinicId={props.clinicId}
                adminControls={CLINIC_TAGS}
                docTitle="Coupon Library"
                pageTitle="Coupon Library"
                overflowHidden
            >
                <div className="flex flex-column full-width">
                    <div className="flex-none">
                        <Button
                            icon
                            onClick={handleBackToCouponLibrary}
                            type="primary"
                        >
                            <i className="fa fa-chevron-left"/> Go to Coupon Library
                        </Button>
                    </div>
                    <div className="flex-1 flex flex-centered">
                        <div className="text-gray text-lg">Coupon library is empty for this manufacturer.</div>
                    </div>
                </div>
            </ClinicBase>
        )
    }

    return (
        <ClinicBase
            collapsible={true}
            searchConfig={{
                ...SearchTypes.LIBRARY_OFFERS,
                preBoxJsx: props.program.provider ? (
                    <img
                        alt={`${props.program.provider.name} logo`}
                        src={getProviderImage(props.program.provider)}
                        style={{ maxHeight: "50px" }}
                    />
                ) : null,
            }}
            clinicId={props.clinicId}
            adminControls={CLINIC_TAGS}
            docTitle={`${props.program.provider.name} Coupon Library`}
            pageTitle={`${props.program.provider.name} Coupon Library`}
            overflowHidden
        >
            {props.canViewClinicCouponLibrary ? (
                props.program ? (
                    <CouponOptInOffers
                        programId={programId}
                        clinicId={props.clinicId}
                    />
                ) : (
                    <div className="full-height">
                        <AccessDenied
                            customMessage="Clinic does not have access to this Coupon Library"
                            customSubMessage="Contact Greenline for more information"
                        />
                    </div>
                )
            ) : (
                <div className="full-height">
                    <AccessDenied customMessage="You do not have permission to view the Clinic Library"/>
                </div>
            )}
        </ClinicBase>
    );
}

export default connect(
    (state, ownProps) => {
        const programId = Number(ownProps.match.params.programId);
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;
        const userProfile = state.user.userProfile;
        const canViewClinicCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        return {
            clinicId,
            canViewClinicCouponLibrary,
            program: state.entities.programs[programId],
        }
    },
    (dispatch) => ({
        getPrograms: (clinicId) => dispatch(CouponActions.getPrograms(clinicId)),
        getProgramOffers: (programId, clinicId) => dispatch(CouponActions.getProgramOffers(programId, clinicId)),
    }),
)(CouponOptIn);
