import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {compose} from "redux";
import {withRouter} from "react-router";
import classnames from "classnames";
import * as styles from "./CorporateGroupDashboard.scss";
import upperFirst from "lodash/upperFirst";
import filter from "lodash/filter";
import find from "lodash/find";
import get from "lodash/get";
import * as ProviderApi from "api/ProviderApi";
import * as ProviderActions from "actions/ProviderActions";
import CorporateGroupClinicTable from "components/provider/widgets/CorporateGroupClinicTable";
import NotificationsDashboardWidget from "components/dashboard/widgets/NotificationsDashboardWidget";
import NotificationModal from "components/notifications/widgets/NotificationModal";
import ProviderBase from "components/layout/ProviderBase";
import ProviderTileGrid from "components/provider/widgets/ProviderTileGrid";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import * as ClinicAdminLinks from "utils/ClinicAdminLinks";
import {getUnreadNotificationCount} from "utils/ClinicData";
import * as ClinicProviderLinks from "utils/ClinicProviderLinks";
import {handleErrorResponse} from "utils/request";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import {PENDING} from "constants/Notifications";
import * as UserRoles from "constants/UserRoles";
import * as OrgNodeTypes from "constants/OrgNodeTypes";
import * as UserPermissions from "constants/UserPermissions";


function CorporateGroupDashboard(props) {
    const [showSpinner, setShowSpinner] = useState(false);
    const [tiles, setTiles] = useState([]);
    const [selectedNotificationId, setSelectedNotificationId] = useState(null);

    const getCorporateGroupSummary = (nodeId) => {
        setShowSpinner(true);
        ProviderApi.getCorporateGroupSummary(nodeId)
            .then((res) => {
                setShowSpinner(false);
                setTiles([
                    {
                        id: "couponsPending",
                        title: res.body?.couponsPendingTitle,
                        value: res.body?.couponsPending,
                        type: "Number"
                    }, {
                        id: "strayCouponsPending",
                        title: res.body?.strayCouponsPendingTitle,
                        value: res.body?.strayCouponsPending,
                        type: "Number"
                    }, {
                        id: "couponsPendingDollars",
                        title: res.body?.couponsPendingDollarsTitle,
                        value: res.body?.couponsPendingDollars,
                        type: "Currency"
                    }, {
                        id: "numberOfClinics",
                        title: res.body?.numberOfClinicsTitle,
                        value: `${res.body?.numberOfActiveClinics}/${res.body?.numberOfClinics}`,
                    }, {
                        id: "couponsApprovedYtd",
                        title: res.body?.couponsApprovedYtdTitle,
                        value: res.body?.couponsApprovedYtd,
                        type: "Number"
                    }, {
                        id: "couponsApprovedDollarsYtdTitle",
                        title: res.body?.couponsApprovedDollarsYtdTitle,
                        value: res.body?.couponsApprovedDollarsYtd,
                        type: "Currency"
                    }
                ])
            })
            .catch((error) => {
                handleErrorResponse("loading corporate group summary", error);
            });
    };

    useEffect(() => {
        if (!!props.nodeId) {
            props.getClinicsForNode(props.nodeId);
            if(props.canViewProviderInfo) {
                getCorporateGroupSummary(props.nodeId);
            }
        }
    }, [props.nodeId]);

    useEffect(() => {
        if(props.canViewNotifications && !props.isAdmin) {
            props.getProviderDashboard();
        }
    }, []);

    useEffect(() => {
        const alerts = filter(props.notifications, n => (n.isAlert && !n.dismissed && n.enrollmentState === PENDING));
        if(!!alerts.length) {
            setSelectedNotificationId(alerts[0].notificationId);
        }
    }, [props.notifications]);

    const getPageTitle = () => {
        if (!props.node) {
            return "";
        }

        const displayName = props.providerName || props.node?.name;

        switch (props.node?.nodeType) {
            case OrgNodeTypes.HQ:
                return `${displayName} | HQ`;
            default:
                return `${displayName} | ${upperFirst(props.node?.nodeType)}`;
        }
    };

    const handleNotificationClicked = (id) => {
        setSelectedNotificationId(id);
    }

    const handleNotificationModalClose = () => {
        setSelectedNotificationId(null);
    }

    const handleGoToClinicDashboard = (row) => {
        if(props.isAdmin) {
            props.history.push(ClinicAdminLinks.getLinkLocation("dashboard", row.clinicId));
        } else {
            props.history.push(ClinicProviderLinks.getLinkLocation("dashboard", props.nodeId, row.clinicId));
        }
    }

    const pageTitle = getPageTitle(props.node);
    const selectedNotification = selectedNotificationId ? find(props.notifications, {notificationId: selectedNotificationId}) : null;

    return (
        <ProviderBase
            match={props.match}
            location={props.location}
            pageTitle={pageTitle}
            providerId={props.providerId}
            nodeId={props.nodeId}
            collapsible={props.canViewNotifications}
        >
            <>
                <div
                    data-testid="corporate_group_dashboard_component"
                    className="flex flex-wrap height-100-perc"
                >
                    <div className={classnames("flex-1 flex flex-column", styles.root)} style={{minWidth: "500px"}}>
                        {props.canViewProviderInfo && (
                            <ProviderTileGrid
                                tiles={tiles}
                                providerId={props.providerId}
                                nodeId={props.nodeId}
                                isAdmin={props.isAdmin}
                                isCorporateGroup
                                canViewCorporateGroupClinic={!props.canViewCorporateGroupClinic}
                            />
                        )}
                        <CorporateGroupClinicTable
                            onRowClick={(row) => handleGoToClinicDashboard(row)}
                            nodeId={props.nodeId}
                            providerId={props.providerId}
                            isAdmin={props.isAdmin}
                        />
                    </div>
                    {/*Hide the notification from Admins since they can't load a provider's notifications anyway*/}
                    {(props.canViewNotifications && !props.isAdmin) && (
                        <div className="flex-none" style={{maxWidth: "350px", width: "33%", minWidth: "250px"}}>
                            <NotificationsDashboardWidget
                                limit={10}
                                notifications={props.notifications}
                                unread={props.notificationTotal}
                                onClick={handleNotificationClicked}
                                showDismissed
                            />
                        </div>
                    )}
                </div>
                <NotificationModal
                    nodeId={props.nodeId}
                    providerId={props.providerId}
                    notification={selectedNotification}
                    show={!!selectedNotification}
                    canAcceptNotifications={props.canAcceptNotifications}
                    canDeclineNotifications={props.canDeclineNotifications}
                    onClose={handleNotificationModalClose}
                />
                <SpinnerTakeover show={showSpinner || props.clinicsAreLoading} />
            </>
        </ProviderBase>
    );
}

CorporateGroupDashboard.propTypes = {
    location: PropTypes.object.isRequired,
    userRole: PropTypes.oneOf([UserRoles.PROVIDER_ADMIN, UserRoles.PROVIDER_MANAGER, UserRoles.PROVIDER_RD, UserRoles.PROVIDER_DM, UserRoles.PROVIDER_USER]),
    providerId: PropTypes.number,
    nodeId: PropTypes.number
};

const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const provider = get(state.entities.providers, ownProps.providerId, null);

        //Permissions
        const canViewNotifications = userHasPermission(PermissionTypes.VIEW, UserPermissions.NOTIFICATIONS, userProfile);
        const canAcceptNotifications = userHasPermission(PermissionTypes.ACCEPT, UserPermissions.NOTIFICATIONS, userProfile);
        const canDeclineNotifications = userHasPermission(PermissionTypes.DECLINE, UserPermissions.NOTIFICATIONS, userProfile);
        const canViewProviderInfo = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_INFO, userProfile);
        const canViewCorporateGroupClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);

        return {
            userProfile,
            providerName: provider?.name,
            node: state.entities.nodes?.[ownProps.nodeId],
            notifications: state.entities.notifications,
            notificationTotal: getUnreadNotificationCount(state),
            clinicsAreLoading: state.entities.providers.areClinicsLoading,
            isAdmin: !!(ownProps?.location?.pathname?.includes("/admin") && ownProps.providerId && ownProps.nodeId),

            // Permissions
            canViewNotifications,
            canAcceptNotifications,
            canDeclineNotifications,
            canViewProviderInfo,
            canViewCorporateGroupClinic
        };
    },

    (dispatch) => ({
        getProviderDashboard: () => dispatch(ProviderActions.getProviderDashboard()),
        getClinicsForNode: (nodeId) => dispatch(ProviderActions.getNodeClinics(nodeId))
    }),
);

export default compose(
    withRouter,
    connector
)(CorporateGroupDashboard);
