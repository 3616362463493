import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./LabelValueRow.scss";
import Tooltip from "components/common/Tooltip";

export default function LabelValueRow(props) {
    return (
        <div
            className={classnames(styles.root, {
                [styles.inline]: props.inline,
                [styles.noMargin]: props.noMargin,
                [styles.border]: props.border,
            })}
        >
            {props.icon && (
                <div className={styles.icon}>
                    {props.iconTitle ? (
                        <Tooltip
                            tip={props.iconTitle}
                            position={props.tooltTipPosition}
                        >
                            <i className={props.icon} />
                        </Tooltip>
                    ) : (
                        <i className={props.icon} />
                    )}
                </div>
            )}
            <div className={classnames(styles.label, {
                [styles.smallLabel]: props.smallLabel,
            })}>
                {props.label}
            </div>
            {props.isBool ? (
                <div
                    className={styles.value}
                    style={props.valueStyle}
                >
                    {props.value ? (
                        <i className="fa fa-check-circle text-success" />
                    ) : (
                        <i className="fa fa-times-circle text-danger" />
                    )}
                </div>
            ) : (
                <div
                    className={styles.value}
                    style={props.valueStyle}
                >
                    <div className="flex align-center">
                        <div className="flex-1">{props.value}</div>
                        {props.contentAfter ? (
                            <div>
                                {props.contentAfter}
                            </div>
                        ) : null}
                    </div>
                </div>
            )}
        </div>
    );
}

LabelValueRow.defaultProps = {
    icon: null,
    noMargin: false,
    iconTitle: null,
    border: true,
    inline: true,
    isBool: false,
    smallLabel: false,
    valueStyle: {},
    toolTipPosition: "right",
};

LabelValueRow.propTypes = {
    icon: PropTypes.string,
    noMargin: PropTypes.bool,
    iconTitle: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    border: PropTypes.bool,
    inline: PropTypes.bool,
    isBool: PropTypes.bool,
    smallLabel: PropTypes.bool,
    toolTipPosition: PropTypes.string,
    valueStyle: PropTypes.object,
    contentAfter: PropTypes.node,
};
