import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import * as AdminActions from "actions/AdminActions";
import * as ClinicActions from "actions/ClinicActions";
import * as UserActions from "actions/UserActions";
import * as AdminApi from "api/AdminApi";
import Button from "components/common/Button";
import DataTable from "components/common/datatable/DataTable";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import LocalData from "utils/LocalData";
import { handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function ClinicRepsTable(props) {
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        props.getClinicReps(props.clinicId);
    }, [props.clinicId]);

    if (!(props.canViewClinicManagement && props.clinicId && props.reps?.length)) {
        return null;
    }

    const handleImpersonate = (e, row) => {
        e.stopPropagation();
        e.preventDefault();
        console.log("here")

        if (props.canImpersonateAccount) {
            setLoading(true);
            props.setRecentUser({
                id: row.userId,
                username: row.userName,
                fullName: `${row.firstName} ${row.lastName}`,
                clinicId: null,
                locationType: null,
                territoryCode: row.territoryCode,
                timeStamp: new Date()
            });
            Sentry.setContext("impersonating", {
                originalUserId: props.userProfile.id,
                originalUserRoles: props.userProfile.roles,
            });
            props.setUserUnknown(); // This clears the current user from state.
            // Sentry.captureMessage("Impersonation Initiated");
            AdminApi.impersonateUser(row.userName)
                .then(res => {
                    LocalData.setToken(res.body.token);
                    props.impersonateUser(res.body.token);
                    history.push("/");
                })
                .catch(error => {
                    handleErrorResponse("attempting to impersonate", error);
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }

    const ACTION_COLUMN = [{
        name: "",
        selector: "userId",
        key: "userId",
        format: row => (
            <Button
                title="Impersonate User"
                type="primary"
                className="margin-left-sm"
                iconOnly
                text
                onClick={(e) => handleImpersonate(e, row)}
            >
                <i className="fa fa-eye" />
            </Button>
        ),
    }];

    const REP_COLUMNS = [{
        name: "Provider Name",
        selector: "providerName",
        key: "providerName",
    }, {
        name: "Territory Code",
        selector: "territoryCode",
        key: "territoryCode",
    }, {
        name: "Rep Name",
        selector: "firstName",
        key: "firstName",
        format: row => (
            <div className="nowrap">{row.firstName} {row.lastName}</div>
        ),
    }, {
        name: "Username",
        selector: "userName",
        key: "userName",
    }, {
        name: "ID",
        selector: "userId",
        key: "userId",
    },
    ...props.canImpersonateAccount ? ACTION_COLUMN : []];

    // const handleGoToUserDashboard = (row) => {
    // TODO: when a row in the rep table is clicked, go to that reps dashboard
    //     console.log(row, "ROW");
    // };

    return (
        <div
            className="card"
            style={{ gridColumn: "1 / -1" }}
        >
            <DataTable
                columns={REP_COLUMNS}
                data={props.reps}
                green
                highlightOnHover
                // onRowClicked={handleGoToUserDashboard}
                pagination={false}
                striped
                title="Reps"
            />
            <SpinnerTakeover show={loading} />
        </div>
    );
}

ClinicRepsTable.defaultProps = {
    canImpersonateAccount: false,
    canViewClinicManagement: false,
    reps: [],
};

ClinicRepsTable.propTypes = {
    canImpersonateAccount: PropTypes.bool,
    canViewClinicManagement: PropTypes.bool,
    clinicId: PropTypes.number.isRequired,
    reps: PropTypes.arrayOf(PropTypes.shape({

    })).isRequired,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canImpersonateAccount = userHasPermission(PermissionTypes.IMPERSONATE, UserPermissions.ACCOUNT, userProfile);
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            canImpersonateAccount,
            canViewClinicManagement,
            userProfile,
            reps: state.entities.reps[ownProps.clinicId],
        }
    },
    (dispatch) => ({
        getClinicReps: (clinicId) => dispatch(ClinicActions.getClinicReps(clinicId)),
        impersonateUser: (token) => dispatch(AdminActions.impersonateUser(token)),
        setRecentUser: (userInfo) => dispatch(UserActions.setRecentUser(userInfo)),
        setUserUnknown: () => dispatch(UserActions.setUserUnknown()),
    })
)(ClinicRepsTable);
