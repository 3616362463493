import getApiRoot from "./getApiRoot";
import request  from "utils/request";

export function getProductsForGameTypeAndPeriod(gameType, period, productCategoryId, userId) {
    return request()
        .query({
            gameType,
            period,
            productCategoryId,
            userId,
        })
        .get(`${getApiRoot()}/v1/services/bigames/brands/productbrandsforgametypeandperiod/`)
        .send();
}