import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import clone from "lodash/clone";
import includes from "lodash/includes";
import isArray from "lodash/isArray";
import map from "lodash/map";
import reject from "lodash/reject";
import sortBy from "lodash/sortBy";
import Button from "components/common/Button";
import useFormHandlers from "utils/useFormHandlers";
import * as ButtonTypes from "constants/ButtonTypes";

const schema = Yup.array().of(
    Yup.string()
);

export default function ClinicTagsForm(props) {
    const { formData, setFormData } = useFormHandlers(
        props.initialData,
        schema,
    );

    const handleTagClicked = (tagId) => {
        let tagList = clone(formData); // Default `formData` is an object (non-iterable), which will bomb if no tags are already set.
        if (!isArray(tagList)) {
            tagList = [];
        }
        let newTags = [...tagList];
        if (includes(tagList, tagId)) {
            newTags = reject(newTags, t => t === tagId);
            setFormData(newTags);
        } else {
            newTags.push(tagId);
            setFormData(newTags);
        }
        props.onSave(newTags);
    };

    return map(sortBy(props.allTags, "id"), (tag) => {
        return (
            <Button
                title={tag.description}
                small
                key={tag.id}
                onClick={() => handleTagClicked(tag.id)}
                type={includes(formData, tag.id) ? ButtonTypes.SELECTED : ButtonTypes.OUTLINE}
            >
                {tag.id}
            </Button>
        );
    });
}


ClinicTagsForm.propTypes = {
    initialData: PropTypes.arrayOf(PropTypes.string).isRequired,
    allTags: PropTypes.shape({
        id: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
    onSave: PropTypes.func.isRequired,
};

