import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import styles from "./ProviderReports.scss";
import includes from "lodash/includes";
import InformationTile from "components/common/InformationTile";
import {getNodeId} from "utils/AdminData";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as StyleId from "constants/CssStyleIds";
import * as UserPermissions from "constants/UserPermissions";
import getProviderId from "utils/getProviderId";
import filter from "lodash/filter";
import flatMap from "lodash/flatMap";
import { getProviderBasePath } from "utils/routingUtils";
import { NHO } from "constants/OrgNodeTypes";
import PropTypes from "prop-types";

function CorporateGroupReports(props) {
    const basePath = getProviderBasePath(props.path, props.providerId, props.nodeId);
    const renderReportTiles = () => {
        const tiles = [];
        if(props.canViewCouponAudit && props.clinicId) {
            tiles.push(
                <div key={"cumulativeOffers"}>
                    <Link to={`${basePath}/reports/cumulative-offer-tracking`}>
                        <InformationTile
                            title={"Cumulative Offer Tracking"}
                        >
                            <div className={styles.tileInfo}>
                                <div className={styles.tileIcon}>
                                    <i className={"fas fa-chart-bar"}/>
                                </div>
                                <div className={styles.description}>
                                    View summary of parasiticide sales in comparison to previous year
                                </div>
                            </div>
                        </InformationTile>
                    </Link>
                </div>
            )
        }

        if(props.canViewClientCampaignTracking) {
            tiles.push(
                <div key="clientCampaignTracking">
                    <Link to={`${basePath}/reports/client-campaign-tracking`}>
                        <InformationTile
                            title="Pet Owner List"
                        >
                            <div className={styles.tileInfo}>
                                <div className={styles.tileIcon}>
                                    <i className={"fas fa-mail-bulk"}/>
                                </div>
                                <div className={styles.description}>
                                    View mailing lists to manually opt-out specific clients from Merck Email Campaigns.
                                </div>
                            </div>
                        </InformationTile>
                    </Link>
                </div>
            );
        }

        if(props.canViewProviderPayoutReport){
            tiles.push(
                <div key="providerCheckSummary">
                    <Link to={`${basePath}/reports/payout-report`}>
                        <InformationTile
                            title="Payout Report"
                        >
                            <div className={styles.tileInfo}>
                                <div className={styles.tileIcon}>
                                    <i className={"fa-solid fa-money-check-pen"}/>
                                </div>
                                <div className={styles.description}>
                                    Manufacturer Check Totals By Clinic.
                                </div>
                            </div>
                        </InformationTile>
                    </Link>
                </div>
            );
        }

        tiles.push(
            <div key="marketShare">
                <InformationTile
                    title="Custom Reports"
                    style={StyleId.INACTIVE}
                    overlay="Coming Soon"
                >
                    <div className={styles.tileInfo}>
                        <div className={styles.tileIcon}>
                            <i className={"fas fa-chart-pie"} />
                        </div>
                        <div className={styles.description}>
                            Soon you'll be able to view reports here
                        </div>
                    </div>
                </InformationTile>
            </div>
        );

        if(props.canViewCouponAudit) {
            tiles.push(
                <div key={"corporateGroupAuditHistory"}>
                    <Link to={`${basePath}/reports/corp-audit-history`}>
                        <InformationTile
                            title={"Corporate Audit History"}
                        >
                            <div className={styles.tileInfo}>
                                <div className={styles.tileIcon}>
                                    <i className={"fas fa-badge-dollar"}/>
                                </div>
                                <div className={styles.description}>
                                    View coupon audit history for a 30 day period
                                </div>
                            </div>
                        </InformationTile>
                    </Link>
                </div>
            )
        }

        return tiles;
    }

    return (
        <div className={styles.root}>
            <div className={styles.gridContainer}>
                <div className={styles.grid}>
                    {renderReportTiles()}
                </div>
            </div>
        </div>
    );
}

CorporateGroupReports.propTypes = {
    path: PropTypes.string.isRequired,
    providerId: PropTypes.string,
    nodeId: PropTypes.string,
}
export default connect(
    (state, ownProps) => {
        const params = ownProps?.match?.params || {};
        const userProfile = state.user.userProfile;
        const node = state.entities.nodes?.[ownProps.nodeId];
        //Permissions
        const canViewCorporateGroupClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);
        const canViewProviderPayoutReport = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_PAYOUT_REPORT, userProfile);
        const canViewCouponAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_AUDIT, userProfile);

        return {
            userProfile,
            node,
            isAdmin: !!(ownProps?.location?.pathname?.includes("/admin") && ownProps.providerId && ownProps.nodeId),
            territoryId: params.territoryId,
            districtId: params.districtId,
            regionId: params.regionId,

            // Permissions
            canViewCorporateGroupClinic,
            canViewProviderPayoutReport,
            canViewCouponAudit,
        };
    }
)(CorporateGroupReports);
