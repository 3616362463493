import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./UnprocessedCoupons.scss";
import filter from "lodash/filter";
import find from "lodash/find";
import includes from "lodash/includes";
import flatMap from "lodash/flatMap";
import values from "lodash/values";
import * as CouponActions from "actions/CouponActions";
import {windowSizeContext} from "AppRoot";
import ApprovalButtons from "components/common/ApprovalButtons";
import ApproveDeclineSingleCoupon from "components/coupons/widgets/ApproveDeclineSingleCoupon/ApproveDeclineSingleCoupon";
import CouponAccordionList from "components/coupons/widgets/CouponAccordionList";
import FixCouponErrorForm from "components/coupons/widgets/FixCouponErrorForm";
import Modal from "components/common/Modal";
import RunningTotals from "components/coupons/widgets/RunningTotals";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import { getPendingCouponCount, getUnprocessedCoupons} from "utils/ClinicData";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import AccessDenied from "components/common/AccessDenied";
import * as UserPermissions from "constants/UserPermissions";

function UnprocessedCoupons(props) {
    const [selectedRows, setSelectedRows] = useState({});
    const [currentVendor, setCurrentVendor] = useState(null);
    const [currentEdit, setCurrentEdit] = useState(null);
    const [currentDecline, setCurrentDecline] = useState(null);
    const [currentApprove, setCurrentApprove] = useState(null);

    const context = useContext(windowSizeContext) || {};

    useEffect(() => {
        if (props.canViewClinicCouponAudit) {
            props.getUnprocessedCoupons(props.clinicId);
        }
    }, [props.clinicId, props.canViewClinicCouponAudit]);

    useEffect(() => {
        if (props.canViewClinicCouponLibrary) {
            props.getPrograms(props.clinicId);
        }
    }, [props.clinicId, props.canViewClinicCouponLibrary]);


    const filterByText = (coupons) => {
        if (!props.search) {
            return coupons;
        }

        const text = props.search.toLowerCase();
        return filter(coupons, coupon => {
            return (
                includes(coupon?.invoiceNumber?.toString(), text) ||
                includes(coupon?.lastName?.toLowerCase(), text) ||
                includes(coupon?.petName?.toLowerCase(), text) ||
                includes(coupon?.invoiceStaffMember?.toLowerCase(), text) ||
                includes(coupon?.product?.toLowerCase(), text) ||
                includes(coupon?.offerCode?.toLowerCase(), text)
            );
        });
    }

    const getSelectedCoupons = () => {
        if(!currentVendor) {
            return [];
        }

        // return only the coupons for the selected Manufacturer
        return selectedRows[currentVendor];
    }

    const handleSelectionChanged = (id, isOpen, closedId) => {
        const currentId = isOpen ? id : null;
        setCurrentVendor(currentId);
        // may want to do something with closedId one day... for now we have it.
    }

    const getApprovalLock = () => {
        // Must have a vendor open, must have selected rows
        return !currentVendor || !selectedRows[currentVendor] || !selectedRows[currentVendor].length;
    }

    const handleRowSelectChanged = (vendorId, rows) => {
        const selected = {
            ...selectedRows,
            [vendorId]: rows,
        };

        setSelectedRows(selected);
    }

    const handleApproveCouponsClicked = (initials, coupons=[]) => {
        if(props.canEditClinicCouponAudit) {
            const couponList = coupons.length ? coupons : getSelectedCoupons();
            const remainingVendorCoupons = filter(props.coupons, c => {
                return (
                    (currentVendor === c.vendorId) &&
                    !includes(couponList, c.externalRedemptionId)
                )
            });
            props.processCoupons(initials, couponList, props.clinicId);
            clearSelectedCoupons();
            if(!remainingVendorCoupons?.length) {
                setCurrentApprove(null);
                setCurrentVendor(null);
            } else {
                setCurrentApprove(null);
            }
        }
    }

    const handleDeclineCouponsClicked = (initials, coupons=[]) => {
        if(props.canEditClinicCouponAudit) {
            const couponList = coupons.length ? coupons : getSelectedCoupons();
            const remainingVendorCoupons = filter(props.coupons, c => {return (currentVendor === c.vendorId) && !includes(couponList, c.externalRedemptionId)});
            props.declineCoupons(initials, couponList, props.clinicId);
            clearSelectedCoupons();
            if(!remainingVendorCoupons?.length) {
                setCurrentDecline(null);
                setCurrentVendor(null);
            } else {
                setCurrentDecline(null);
            }
        }
    }

    const clearSelectedCoupons = () => {
        const selected = {
            ...selectedRows,
            [currentVendor]: null
        };

        setSelectedRows(selected);
    }

    const handleEditCouponRedemption = (id) => {
        if (props.canViewClinicCouponAudit) {
            setCurrentEdit(id);
        }
    }

    const renderCouponList = (canApproveOrDecline = false) => {
        let couponList = values(props.coupons);
        if (couponList && couponList.length > 0) {
            if (props.search) {
                couponList = filterByText(couponList);
            }
        }
        if (!couponList.length) {
            return(
                <div className={styles.noCoupons}>
                    <span>No Coupons to show! Try changing your search.</span>
                </div>
            )
        }
        const providerList = flatMap(values(props.programs), "provider");

        return (
            <CouponAccordionList
                clinicId={props.clinicId}
                showStatusIcon={false}
                coupons={couponList}
                selectedRows={selectedRows}
                onSelect={canApproveOrDecline ? handleSelectionChanged : undefined}
                onRowSelectChange={canApproveOrDecline ? handleRowSelectChanged : undefined}
                onEditClick={handleEditCouponRedemption}
                onApproveClick={(couponId) => setCurrentApprove(couponId)}
                onDeclineClick={(couponId) => setCurrentDecline(couponId)}
                providerList={providerList}
                canViewClinicCouponAudit={props.canViewClinicCouponAudit}
                canEditClinicCouponAudit={props.canEditClinicCouponAudit}
            />
        );
    }

    if(!props.canViewClinicCouponAudit) {
        return <AccessDenied customMessage="You do not have permission to view Pending Coupons"/>;
    } else if(!props.coupons) {
        return <SpinnerTakeover show/>;
    } else if (!props.couponCount) {
        return(
            <div className={styles.root}>
                {!context.isPhone && (
                    <div className={styles.topLine}>
                        <h2><i className="fas fa-ticket" /> Pending Coupons</h2>
                    </div>
                )}
                <RunningTotals
                    clinicId={props.clinicId}
                />
                <div className={styles.noCoupons}>
                    <span>There are no coupons to process</span>
                </div>
            </div>
        );
    }

    const approvalLocked = getApprovalLock();

    const handleAfterSaveCouponEdit = () => {
        setCurrentEdit(null);
        props.getUnprocessedCoupons(props.clinicId);
    };

    return (
        <>
            <div className={styles.root}>
                <div className={styles.topLine}>
                    {!context.isPhone && (
                        <h2><i className="fas fa-ticket" /> Pending Coupons</h2>
                    )}
                    {props.canEditClinicCouponAudit &&
                        <ApprovalButtons
                            onApprove={handleApproveCouponsClicked}
                            onDecline={handleDeclineCouponsClicked}
                            locked={approvalLocked}
                        />
                    }
                    {!!props.isModal && (
                        <Link
                            className={classnames("btn btn-color-outline btn-wide btn-full", styles.viewAll)}
                            to={"/coupons"}
                        >
                            View All
                        </Link>
                    )}

                </div>
                <RunningTotals
                    clinicId={props.clinicId}
                />
                {renderCouponList(props.canEditClinicCouponAudit)}
                {props.canEditClinicCouponAudit && (
                    <div className="flex">
                        <ApprovalButtons
                            onApprove={handleApproveCouponsClicked}
                            onDecline={handleDeclineCouponsClicked}
                            locked={approvalLocked}
                        />
                    </div>
                )}
            </div>
            <Modal
                show={!!currentEdit}
                onClose={() => setCurrentEdit(null)}
                modalTitle="Edit Coupon"
                mediumSmall
                tall
                clickOutsideToClose={false}
            >
                {!!currentEdit && (
                    <FixCouponErrorForm
                        couponId={currentEdit}
                        onDecline={(couponId) => setCurrentDecline(couponId)}
                        onAfterSave={handleAfterSaveCouponEdit}
                        onClose={() => setCurrentEdit(null)}
                    />
                )}~
            </Modal>
            {/*Modal for Declining a Single Coupon*/}
            <Modal
                show={!!currentDecline}
                onClose={() => setCurrentDecline(null)}
                modalTitle={"Decline Coupon"}
                mediumSmall
                tall
                clickOutsideToClose={false}
            >
                {!!currentDecline && (
                    !props.canEditClinicCouponAudit ? (
                        <AccessDenied/>
                    ) : (
                        <ApproveDeclineSingleCoupon
                            couponId={currentDecline}
                            coupon={find(props.coupons, {id: currentDecline})}
                            onClose={() => setCurrentDecline(null)}
                            onSubmit={handleDeclineCouponsClicked}
                        />
                    )
                )}
            </Modal>
            {/*Modal for Approving a single coupon*/}
            <Modal
                show={!!currentApprove}
                onClose={() => setCurrentApprove(null)}
                modalTitle={"Approve Coupon"}
                mediumSmall
                tall
                clickOutsideToClose={false}
            >
                {!!currentApprove && (
                    !props.canEditClinicCouponAudit ? (
                        <AccessDenied/>
                    ) : (
                        <ApproveDeclineSingleCoupon
                            couponId={currentApprove}
                            coupon={find(props.coupons, {id: currentApprove})}
                            onClose={() => setCurrentApprove(null)}
                            onSubmit={handleApproveCouponsClicked}
                            isApprove
                        />
                    )
                )}
            </Modal>
        </>
    );
}

UnprocessedCoupons.propTypes = {
    isModal: PropTypes.bool,
    clinicId: PropTypes.number,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        //  Permissions
        const canViewClinicCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        const canViewClinicCouponAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_AUDIT, userProfile);
        const canEditClinicCouponAudit = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_COUPON_AUDIT, userProfile);
        const canViewCorporateGroupClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);
        const canEditClinicManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            coupons: getUnprocessedCoupons(state),
            // couponLibrary: getCouponLibrary(ownProps.clinicId),
            couponCount: getPendingCouponCount(state),
            programs: state.entities.programs,
            search: state.entities.coupons.query,
            //  Permissions
            canEditClinicCouponAudit,
            canEditClinicManagement,
            canViewClinicCouponAudit: canViewClinicCouponAudit || canViewCorporateGroupClinic,
            canViewClinicCouponLibrary,
        }
    },
    (dispatch) => ({
        getUnprocessedCoupons: (clinicId) => dispatch(CouponActions.getUnprocessedCoupons(clinicId)),
        // getCouponDetails: (id) => dispatch(CouponActions.getUnprocessedCouponDetails(id)),
        getPrograms: (clinicId) => dispatch(CouponActions.getPrograms(clinicId)),
        processCoupons: (initials, coupons, clinicId) => dispatch(CouponActions.processCoupons(initials, coupons, clinicId)),
        declineCoupons: (initials, coupons, clinicId) => dispatch(CouponActions.declineCoupons(initials, coupons, clinicId)),
    }),
)(UnprocessedCoupons);
