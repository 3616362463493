import React, { useEffect, useState } from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Button from "components/common/Button";
import WellnessEnrollmentModal from "components/greenlineWellness/widgets/WellnessEnrollmentModal";
import WellnessPaymentsStripe from "../WellnessPaymentsStripe";

import * as GreenlineWellnessActions from "actions/GreenlineWellnessActions";
import {getClinicInfo} from "../../../../utils/ClinicData";
import {PermissionTypes, userHasPermission} from "../../../../utils/permissions/rolesPermissions";
import * as UserPermissions from "../../../../constants/UserPermissions";

function NewPlanEnrollmentBtn(props) {
    const [showPlanEnrollmentForm, setShowPlanEnrollmentForm] = useState(false);

    useEffect(() => {
        if (props.canViewClinicWellnessPlans && !props.clinicWellnessPlans && !!props.clinicId) {
            props.loadClinicWellnessPlans(props.clinicId, false);
        }
    }, [props.clinicWellnessPlans, props.clinicId]);

    return (
        <>
            <Button
                className="margin-left-x-sm"
                onClick={() => {setShowPlanEnrollmentForm(true)}}
                action
                title="New Plan Enrollment"
            >
                <i className={"icon far fa-plus"} />
                <span>New Plan Enrollment</span>
            </Button>
            <WellnessPaymentsStripe>
                <WellnessEnrollmentModal
                    show={showPlanEnrollmentForm}
                    onClose={() => setShowPlanEnrollmentForm(false)}
                    clinicId={props.clinicId}
                    plans={props.clinicWellnessPlans}
                />
            </WellnessPaymentsStripe>
        </>
    );
}

NewPlanEnrollmentBtn.propTypes = {
    clinicId: PropTypes.number.isRequired,
}

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canViewClinicWellnessPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);
        return {
            clinicWellnessPlans: state.wellness.clinicWellnessPlans[ownProps.clinicId],
            //Permissions
            canViewClinicWellnessPlans,
        }
    },
    (dispatch) => ({
        loadClinicWellnessPlans: (clinicId, includeInactive) => dispatch(GreenlineWellnessActions.loadClinicWellnessPlans(clinicId, includeInactive)),
    })
)(NewPlanEnrollmentBtn);
