import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./InvoiceDetails.scss";
import map from "lodash/map";
import Button from "components/common/Button";
import SampleInvoice from "components/common/products/Sample-Invoice.png";

export default function InvoiceDetails(props) {
    const { sampleInvoices = [] } = props;
    const [selectedInvoice, setSelectedInvoice] = useState(null);

    return (
        <div data-testid="invoice_details_component">
            {!props.invoiceId && props.sampleInvoices && !selectedInvoice && ( props.sampleInvoices.length > 1) ? (
                <div className="text-center">
                    <div className={styles.directions}>Click on a sample below to view a larger version</div>
                    <div className="flex">
                        {map(props.sampleInvoices, (sampleInvoice, index) => (
                            <div
                                data-testid={`invoice_details_sample_${index}`}
                                className={classnames(styles.invoicePreview, "flex-1")}
                                key={index}
                                onClick={() => setSelectedInvoice(sampleInvoice)}
                            >
                                <img src={SampleInvoice} alt="Sample Invoice"/>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div
                    data-testid="invoice_details_selected_invoice"
                    className={styles.invoicePreview}
                >
                    {selectedInvoice && (
                        <Button
                            data-testid="invoice_details_all_samples_button"
                            className="margin-bottom-sm"
                            onClick={() => setSelectedInvoice(null)}
                            type="outline"
                        >
                            <i className="fa fa-reply margin-right-sm"/> All Samples
                        </Button>
                    )}
                    {/*TODO: Change to show the correct INVOICE image*/}
                    <img src={SampleInvoice} alt="Sample Invoice"/>
                </div>
            )}

            <div className="flex">
                <div className="flex-1"/>
                <Button
                    data-testid="invoice_details_close"
                    className="flex-none"
                    type="outline"
                    onClick={props.handleClose}
                >
                    Close
                </Button>
            </div>
        </div>
    );
}

InvoiceDetails.propTypes = {
    invoiceId: PropTypes.number,
    sampleInvoices: PropTypes.array,
    handleClose: PropTypes.func.isRequired,
};
