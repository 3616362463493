import React, { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import * as Yup from "yup";
import * as styles from "./FixCouponErrorForm.scss";
import includes from "lodash/includes";
import debounce from "lodash/debounce";
import map from "lodash/map";
import { checkValidStateZip } from "api/CouponsApi";
import { windowSizeContext } from "AppRoot";
import Button from "components/common/Button";
import CheckboxInput from "components/common/CheckboxInput";
import DateInput from "components/common/DateInput";
import TextBox from "components/common/TextBox";
import logger from "utils/logger";
import toast from "utils/toast";
import useFormHandlers from "utils/useFormHandlers";

const schema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    emailRequired: Yup.bool(),
    email: Yup.string()
        .email()
        .when("emailRequired", (emailRequired, schema) => {
            return emailRequired[0] ? schema.required() : schema.nullable(true);
        }),
    address1: Yup.string().required(),
    address2: Yup.string().nullable(true),
    city: Yup.string().required(),
    state: Yup.string().min(2).max(2).required(true),
    postalCode: Yup.string().required(),
    petName: Yup.string().required(),
    petDOB: Yup.date().required(),
});

export default function FixCouponErrorForm(props) {
    const { submitLabel = "Submit" } = props;
    const {formData, isValid, handleUpdate, invalidFields, changedData} = useFormHandlers(
        props.initialData,
        schema,
        props.initialData?.errors || [],
    );

    const [addressCityStatePostalCodeValid, setAddressCityStatePostalCodeValid] = useState(true);
    const [skipEmail, setSkipEmail] = useState(false);
    const emailError = includes(formData.errors, "email");

    const context = useContext(windowSizeContext) || {};
    const maxDate = new moment(formData?.invoiceDate).format("MMM DD YYYY");
    const minDate = "Jan 01 1900";

    const checkAddressValid = useCallback(debounce((city, state, postalCode) => {
        checkValidStateZip({ city, state, postalCode })
            .then((res) => {
                setAddressCityStatePostalCodeValid(res?.body?.valid);
            })
            .catch((err) => {
                logger.error("VALIDATION ERROR: ", err, true);
                setAddressCityStatePostalCodeValid(false);
            });
    }, 500), []);

    useEffect(() => {
        checkAddressValid(formData.city, formData.state, formData.postalCode);
    }, [formData])

    const handleSkipEmail = ({ value }) => {
        setSkipEmail(value);
        handleUpdate({ name: "email", value: "" });
    }

    const handleSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if(formData.petDOB?.value){
            const petDOB = new moment(formData.petDOB.value);
            if(petDOB.isBefore(minDate)) {
                toast.error("Pet Date of Birth is before 1900");
                return;
            } else if(petDOB.isAfter(maxDate)){
                toast.error("Pet Date of Birth is after the invoice date");
                return;
            }
        }

        const requiredFields = {};
        map(formData.errors, errorField => {
            requiredFields[errorField] = formData[errorField];
        });
        const dataToSubmit = {
            ...requiredFields,
            ...changedData,
        };

        props.onSubmit(formData, dataToSubmit);
    };

    const renderSubmitButton = () => {
        if (!props.onSubmit || (emailError && skipEmail)) {
            return null;
        }
        const formValid = isValid && addressCityStatePostalCodeValid;
        return (
            <Button
                data-testid="fix_coupon_error_form_submit_btn"
                className="margin-left-auto margin-top-sm"
                disabled={!formValid}
                large
                buttonType="submit"
            >
                {submitLabel}
            </Button>
        )
    };

    return (
        <form
            data-testid="fix_coupon_error_form_component"
            className={styles.couponErrorForm}
            onSubmit={handleSubmit}
        >
            <div className={styles.couponDetails}>
                <div className="flex-none">
                    <div className={styles.detailItem}>
                        <label>Invoice Date:</label>
                        <span>{new moment(formData?.invoiceDate).format("MM/DD/YYYY")}</span>
                    </div>
                    <div className={styles.detailItem}>
                        <label>Invoice Number:</label>
                        <span>{formData?.invoiceNumber}</span>
                    </div>
                </div>
                <div className="flex-none">
                    <div className={styles.detailItem}>
                        <label>Offer &amp; Code:</label>
                        <span>{formData?.offerCode} <small>(19-12345)</small></span>
                    </div>
                    <div className={styles.detailItem}>
                        <label>Products:</label>
                        <span>{formData?.product}</span>
                    </div>
                </div>
            </div>
            <div className={styles.editableFields}>
                <h3>Please correct the fields in red*</h3>
                <div className={styles.field}>
                    <TextBox
                        data-testid="fix_coupon_error_form_first_name"
                        onChange={handleUpdate}
                        name="firstName"
                        hasError={includes(invalidFields, "firstName")}
                        value={formData.firstName}
                        label="First Name"
                        alignedLeft={!context.isPhone}
                        required
                    />
                    <TextBox
                        data-testid="fix_coupon_error_form_last_name"
                        onChange={handleUpdate}
                        name="lastName"
                        hasError={includes(invalidFields, "lastName")}
                        value={formData.lastName}
                        label="Last Name"
                        alignedLeft={!context.isPhone}
                        required
                    />
                    <TextBox
                        data-testid="fix_coupon_error_form_email"
                        onChange={handleUpdate}
                        name="email"
                        hasError={includes(invalidFields, "email")}
                        value={formData.email}
                        disabled={skipEmail}
                        label="Client Email"
                        alignedLeft={!context.isPhone}
                        required={formData.emailRequired}
                    />
                    {emailError && (
                        <>
                            <CheckboxInput
                                data-testid="fix_coupon_error_form_skip_email"
                                name="skipEmail"
                                onChange={handleSkipEmail}
                                label={<span>No Client Email</span>}
                                checked={skipEmail}
                                extraStyles={{
                                    paddingLeft: "125px",
                                    paddingBottom: "0.25em"
                                }}
                            />
                            <div
                                style={{
                                    paddingLeft: "125px",
                                    paddingBottom: "1em"
                                }}
                                className="text-sm"
                            >
                                ***Please note by selecting "Decline" on this coupon for not having a valid email address you will not be missing out on any funds or reimbursement to your clinic.
                                Offers requiring a valid email address are purely client participation based and do not affect clinics funds if an email is not provided.
                                <b> Please note adding your clinic email address to this field is fraudulent.</b>
                            </div>
                        </>
                    )}
                    <TextBox
                        data-testid="fix_coupon_error_form_address_1"
                        onChange={handleUpdate}
                        name="address1"
                        hasError={includes(invalidFields, "address1")}
                        value={formData.address1}
                        label="Address 1"
                        alignedLeft={!context.isPhone}
                        required
                    />
                    <TextBox
                        data-testid="fix_coupon_error_form_address_2"
                        onChange={handleUpdate}
                        name="address2"
                        hasError={includes(invalidFields, "address2")}
                        value={formData.address2}
                        label="Address 2"
                        alignedLeft={!context.isPhone}
                    />
                    <TextBox
                        data-testid="fix_coupon_error_form_city"
                        onChange={handleUpdate}
                        name="city"
                        hasError={!addressCityStatePostalCodeValid || includes(invalidFields, "city")}
                        value={formData.city}
                        label="City"
                        alignedLeft={!context.isPhone}
                        required
                    />
                    <TextBox
                        data-testid="fix_coupon_error_form_state"
                        onChange={handleUpdate}
                        name="state"
                        hasError={!addressCityStatePostalCodeValid || includes(invalidFields, "state")}
                        value={formData.state}
                        label="State"
                        alignedLeft={!context.isPhone}
                        maxLength={2}
                        required
                    />
                    <TextBox
                        data-testid="fix_coupon_error_form_postal_code"
                        onChange={handleUpdate}
                        name="postalCode"
                        hasError={!addressCityStatePostalCodeValid || includes(invalidFields, "postalCode")}
                        value={formData.postalCode}
                        label="Postal Code"
                        alignedLeft={!context.isPhone}
                        required
                    />
                    <TextBox
                        data-testid="fix_coupon_error_form_pet_name"
                        onChange={handleUpdate}
                        name="petName"
                        hasError={includes(invalidFields, "petName")}
                        value={formData.petName}
                        label="Pet Name"
                        alignedLeft={!context.isPhone}
                        required
                    />
                    <DateInput
                        data-testid="fix_coupon_error_form_pet_dob"
                        onChange={handleUpdate}
                        name="petDOB"
                        hasError={includes(invalidFields, "petDOB")}
                        value={formData.petDOB}
                        label="Pet Date Of Birth"
                        aligned={!context.isPhone}
                        minDate={minDate}
                        maxDate={maxDate}
                        showDropdowns
                        required
                    />
                </div>
            </div>
            <div className="margin-top-md">*These changes to the client name or address are only temporary. Permanent changes must be done in your Practice Management System.</div>
            <div className={styles.bottomButtons}>
                {!!props.showDecline && (
                    <Button
                        data-testid="fix_coupon_error_form_decline_btn"
                        className="margin-left-auto margin-top-sm"
                        onClick={props.onDecline}
                        type="danger"
                        large
                    >
                        Decline
                    </Button>
                )}
                <Button
                    data-testid="fix_coupon_error_form_cancel_btn"
                    className="margin-left-auto margin-top-sm"
                    onClick={props.onClose}
                    type={"gray"}
                    large
                >
                    Cancel
                </Button>
                {renderSubmitButton()}
            </div>
        </form>
    );
}

FixCouponErrorForm.propTypes = {
    initialData: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    onDecline: PropTypes.func,
    submitLabel: PropTypes.string,
    showDecline: PropTypes.bool,
};
