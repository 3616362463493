import React, { useEffect } from "react";
import { connect } from "react-redux";
import filter from "lodash/filter";
import * as ClinicActions from "actions/ClinicActions";
import * as CouponActions from "actions/CouponActions";
import AccessDenied from "components/common/AccessDenied";
import AdminBase from "components/layout/AdminBase";
import AdminClinicInfo from "components/admin/forms/AdminClinicSettingsFormContainer/AdminClinicInfo";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import { CLINIC_TAGS } from "constants/AdminControls";

function EditClinicSettings(props) {

    useEffect(() => {
        props.getProviderList();
    }, []);

    useEffect(() => {
        props.getProviderLocations(props.clinicId);
    }, [props.clinicId]);

    return (
        <AdminBase
            clinicId={props.clinicId}
            collapsible
            pageTitle="Clinic Details"
            controls={CLINIC_TAGS}
            hasGreenBackground
        >
            {props.canViewClinicManagement ? (
                <AdminClinicInfo
                    canResetPasswords={props.canResetPasswords}
                    clinic={props.clinic}
                    clinicId={props.clinicId}
                    greenCorporateGroups={props.greenCorporateGroups}
                    providers={props.providers}
                />
            ) : <AccessDenied/>}
            <SpinnerTakeover show={props.working}/>
        </AdminBase>
    )
}

export default connect(
    (state, ownProps) => {
        const clinicId = Number(ownProps.match.params.clinicId);
        const userProfile = state.user.userProfile;
        const canResetPasswords = userHasPermission(PermissionTypes.EDIT, UserPermissions.ADMIN_PASSWORD_MANAGEMENT, userProfile);
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);

        return {
            clinicId,
            canResetPasswords,
            canViewClinicManagement,
            clinic: state.entities.clinicDetails?.[clinicId],
            greenCorporateGroups: filter(state.entities.clinicProviderLocations[clinicId], cg => !!(cg?.enrollmentDate && cg?.goGreenDate && cg?.isCorporateGroup)),
            providers: state.entities.providers,
            working: !!state.applicationState.working,
        }
    },
    (dispatch) => ({
        getProviderList: () => dispatch(CouponActions.getProviderList()),
        getProviderLocations: (clinicId) => dispatch(ClinicActions.getProviderLocations(clinicId)),
    })
)(EditClinicSettings);
