import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import * as styles from "./DataTable.scss";
import difference from "lodash/difference";
import filter from "lodash/filter";
import find from "lodash/find";
import flatMap from "lodash/flatMap";
import flatten from "lodash/flatten";
import forEach from "lodash/forEach";
import includes from "lodash/includes";
import map from "lodash/map";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";
import sumBy from "lodash/sumBy";
import Button from "components/common/Button";
import DataTablePagination from "components/common/datatable/DataTablePagination";
import ResultsPerPageDropdown from "components/coupons/widgets/ResultsPerPageDropdown";
import TextBox from "components/common/TextBox";
import Tooltip from "components/common/Tooltip";
import logger from "utils/logger";
import { formatUSD } from "utils/numeric";
import Dropdown from "components/common/Dropdown";
import Image from "components/common/Image";

export default function DataTable(props) {
    const {
        paginationPerPage = 10,
        pagination = true,
        selectedRowIds = [],
        noResultsText = "No Data Available",
    } = props;

    const [sortField, setSortField] = useState(null);
    const [sortAsc, setSortAsc] = useState(true);
    const [expandedRowIds, setExpandedRowIds] = useState([]);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState("");
    const [groupStatus, setGroupStatus] = useState( {}); // Falsy by key, true=open, "false"=collapsed
    const [resultsPerPage, setResultsPerPage] = useState(paginationPerPage);
    const [showSelectAllMessage, setShowSelectAllMessage] = useState(false);
    const [filterValues, setFilterValues] = useState({});

    useEffect(() => {
        if(props.search) {
            handleSearch(props.search)
        } else{
            handleSearch("")
        }
    }, [props.search]);

    useEffect(() => {
        //Goes to the first page if the length of the data is changed so the user won't end up on page 7 of 3.
        setPage(0);
    }, [props.data.length]);

    let data = props.data;

    if (props.allowSearch && search?.length) {
        const searchFields = map(filter(props.columns,{searchable: true}), "selector");
        const searchTokens = search.toLowerCase().split(" ");
        data = filter(data, (row) => {
            for (let i = 0; i < searchFields.length; i++) {
                const searchValue = find(props.columns, {key: searchFields[i]}).searchValue;
                const fieldValue = searchValue ? searchValue(row) : row[searchFields[i]];
                if (fieldValue) {
                    let allMatch = true;
                    for (let j = 0; j < searchTokens.length; j++) {
                        const token = searchTokens[j];
                        if (!fieldValue.toString().toLowerCase().includes(token)) {
                            allMatch = false;
                        }
                    }
                    if (allMatch) return true;
                }
            }
            return false;
        });
    }

    // Sort in the UI if there the data is not returned pre-sorted
    if (!!sortField && !props.onSort) {
        logger.log("SORTING");
        // Get a flattened list of all the visible columns
        const visibleColumns = flatten(map(props.columns, col => {
            if(!col.groupedItems || col.groupedItems && !groupStatus[col.key]) {
                return col;
            } else {
                return col.groupedItems
            }
        }));

        let fieldSpec = find(visibleColumns, {selector: sortField});
        data = sortBy(data, (row) => {
            let value = row[sortField];
            if (fieldSpec.sortValue) {
                value = fieldSpec.sortValue(row);
            }
            return value;
        });
        if (!sortAsc) {
            data = reverse(data);
        }
    }

    const rowsPerPage = props.changePagination ? resultsPerPage : paginationPerPage;
    const totalPages = pagination ? (Math.ceil(data.length / rowsPerPage)) : 1;
    const canGoNext = page + 1 < totalPages;
    const canGoPrevious = page > 0;

    const selectableData =  !!props.getRowIsSelectable ? filter(data, (d) => props.getRowIsSelectable(d)) : data;
    const selectableDataIds = flatMap(selectableData, "id");
    const allDataSelected = !!selectedRowIds.length && !difference(selectedRowIds, selectableDataIds).length;

    const disabledRows = !!props.getRowIsDisabled ? flatMap(filter(data, (d) => props.getRowIsDisabled(d)), "id") : data;

    const pageData = pagination ? data.slice(page * rowsPerPage, (page + 1) * rowsPerPage) : data;
    const pageSelectableData = !!props.getRowIsSelectable ? filter(pageData, (d) => props.getRowIsSelectable(d)) : pageData;
    const pageSelectableDataIds = flatMap(pageSelectableData, "id");
    //Check that all of the visible rows are selected
    const allPageDataSelected = !!selectedRowIds.length && !difference(selectedRowIds, pageSelectableDataIds).length;

    const handleSearch = (term) => {
        if(page > 0) {
            setPage(0);
        }
        setSearch(term);
    };

    const handleSelectRow = (row) => {
        if (selectedRowIds.includes(row.id)) {
            props.onRowSelectChange(filter(selectedRowIds, (id) => id !== row.id));
        } else {
            props.onRowSelectChange([
                ...selectedRowIds,
                row.id,
            ]);
        }
    };

    const handleExpandedRow = (e, row) => {
        e.stopPropagation();
        if (expandedRowIds.includes(row.id)) {
            setExpandedRowIds(filter(expandedRowIds, (id) => id !== row.id));
        } else {
            setExpandedRowIds([
                ...expandedRowIds,
                row.id,
            ]);
        }
    };

    const handleSelectRowsOnPage = () => {
        if (!allPageDataSelected) {
            if(props.canSelectAll) {
                setShowSelectAllMessage(true);
            }
            props.onRowSelectChange(map(pageSelectableData, "id"));
        } else {
            if(props.canSelectAll) {
                setShowSelectAllMessage(true);
            }
            props.onRowSelectChange([]);
        }
    };

    const handleSelectAllRows = () => {
        setShowSelectAllMessage(false);

        if (!allDataSelected) {
            props.onRowSelectChange(map(selectableData, "id"));
        } else {
            props.onRowSelectChange([]);
        }
    };

    const handleSortClick = (selector) => {
        let direction;
        if (selector === sortField) {
            direction = !sortAsc
        } else {
            direction = true
            setSortField(selector);
        }

        if (props.onSort) {
            const directionStr = !!direction ? "asc" : "desc";
            props.onSort(selector, directionStr);
        }

        setSortAsc(direction);
    };

    const handleGroupToggle = (evt, key) => {
        evt.stopPropagation();

        const group = find(props.columns, {key});
        const itemSelectors = map(group.groupedItems, item => item.selector);

        // If it's not in the combined column, move on
        if (sortField && (group.selector === sortField || itemSelectors.includes(sortField))) {
            if (groupStatus[key] && sortField !== group.selector){
                // It's not in a visible column, set it back to group column
                setSortField(group.selector);
            } else if (!groupStatus[key] && !itemSelectors.includes(sortField)) {
                // It's not in a visible column, clear it.
                setSortField(null);
            }
        }

        setGroupStatus({
            ...groupStatus,
            [key]: !groupStatus[key]
        });
    };

    const totalsRowLabel = (
        <Tooltip position="right" tip="Total of all pages">
            <span><i className="fa fa-info-circle"/> Totals:</span>
        </Tooltip>
    );

    const formatTableField = (fieldSpec, row, isTotalRow=false, showTotalLabel=false) => {
        let content;
        if (fieldSpec.format && !isTotalRow) {
            content = fieldSpec.format(row);
        } else {
            content = row[fieldSpec.selector];

            //Handle Field type
            if (fieldSpec.type === "number" || fieldSpec.type === "currency") {
                content = Number(content);
            }
            if (fieldSpec.type === "currency") {
                content = formatUSD(content);
            }
        }

        let fieldKey = `${row.id}_${fieldSpec.key}`;
        if (!!fieldSpec.groupedItems) {
            fieldKey = `group_${fieldKey}`
        }
        if (!!isTotalRow) {
            fieldKey = `${fieldKey}_total`
        }

        if (isTotalRow && showTotalLabel) {
            return (
                <td key={fieldKey} className="flex spaced-content">
                    <div className="flex-none">{totalsRowLabel}</div>
                    <div className="flex-none">{content}</div>
                </td>
            );
        }

        return (
            <td
                key={fieldKey}
                className={classnames({
                    [fieldSpec.totalClasses]: isTotalRow
                })}
            >
                {content}
            </td>
        )
    };

    const renderTableField = (fieldSpec, row, isTotalRow=false, showTotalLabel=false) => {
        if (fieldSpec.groupedItems && !!groupStatus[fieldSpec.key]) {
            // Open: show groupedItems instead
            return map(fieldSpec.groupedItems, child => formatTableField(child, row, isTotalRow, showTotalLabel));
        }

        return formatTableField(fieldSpec, row, isTotalRow, showTotalLabel);
    };

    const handleGoNext = () => {
        setPage(page + 1)
    }

    const handleGoPrevious = () => {
        setPage(page - 1)
    }

    const handleFilterValueChanged = (evt, columnName, callback) => {
        const newFilterValues = {
            ...filterValues,
        };
        newFilterValues[columnName] = evt.value
        setFilterValues(newFilterValues)

        if(callback) {
            callback(evt.value)
        }
    }

    const renderTableRows = (data) => {
        if(!data.length) {
            return renderNoResults();
        }

        if(!props.expandedData) {
            return map(data, d => renderTableRow(d));
        }

        const rows = [];
        forEach(data, (d) => {
            rows.push(renderTableRow(d));
            if(!!expandedRowIds.includes(d.id)) {
                rows.push(renderExpandableRow(d));
            }
        });

        return rows;
    }

    const renderTotalRow = (data) => {
        let isFirst = true;
        return (
            <tr className="total">
                {props.selectableRows && (
                    <td className="flex-none no-wrap">
                        {totalsRowLabel}
                    </td>
                )}
                {!!props.expandedData ? (
                    !props.selectableRows ? (
                        <td className="flex-none no-wrap">
                            {totalsRowLabel}
                        </td>
                    ) : (
                        <td/>
                    )
                ) : null}

                {map(props.columns, (fieldSpec) => {
                    if (fieldSpec.total) {
                        let columnData;
                        if(fieldSpec.rowTotal) {
                            columnData = sumBy(data, d => {
                                return Number(fieldSpec.rowTotal(d));
                            });
                        } else {
                            columnData = sumBy(data, d => {
                                return Number(d[fieldSpec.selector])
                            });
                        }

                        if (fieldSpec.dollarTotal) {
                            columnData = formatUSD(columnData)
                        }

                        if (isFirst && !(props.selectableRows || props.expandedData)) {
                            isFirst = false;
                            return renderTableField(fieldSpec, {id: "total", [fieldSpec.selector]: columnData}, true, true);
                        }
                        return renderTableField(fieldSpec, {id: "total", [fieldSpec.selector]: columnData}, true);
                    } else {
                        if (isFirst && !(props.selectableRows || props.expandedData)) {
                            isFirst = false;
                            return (
                                <td key="first" className="flex-none no-wrap">
                                    {totalsRowLabel}
                                </td>
                            );
                        }
                        return <td key={`${fieldSpec.id}_empty`}/>
                    }
                })}
            </tr>
        );
    }

    const renderNoResults = () => {
        const cols = props.columns.length + 1;

        return (
            <tr className="no-results">
                <td colSpan={cols}>
                    {!!props.search?.length ? (
                        <h3>No results for this search</h3>
                    ) : (
                        <h3>{noResultsText}</h3>
                    )}
                </td>
            </tr>
        );
    }

    const renderExpandableRow = (row) => {
        const cols = props.columns.length + 1;
        const fieldKey = `${row.id}_${props.expandedData.key}`;

        return (
            <tr className="expanded-content" key={fieldKey} >
                <td colSpan={cols}>
                    {props.expandedData.format(row)}
                </td>
            </tr>
        );
    };

    const renderTableRow = (row) => {
        let allowSelect = props.selectableRows;
        if (props.getRowIsSelectable) {
            allowSelect = allowSelect && props.getRowIsSelectable(row);
        }
        let extraProps = {};
        if (props.conditionalRowProps) {
            extraProps = {
                ...extraProps,
                ...props.conditionalRowProps(row),
            };
        }

        const handleRightClick = (e, row) => {
            if(!!props.onRightClick) {
                e.stopPropagation();
                e.preventDefault();
                props.onRightClick(row);
            }
        }

        return (
            <tr
                {...extraProps}
                data-testid={`data_table_row_${row.id}`}
                key={row.id}
                onClick={() => props.onRowClicked && props.onRowClicked(row)}
                onMouseEnter={() => props.onRowHoverStart && props.onRowHoverStart(row)}
                onMouseLeave={() => props.onRowHoverEnd && props.onRowHoverEnd(row)}
                onContextMenu={(e) => props.onRightClick ? handleRightClick(e, row) : {}}
                className={classnames({
                    [styles.clickable]: (!includes(disabledRows, row.id) && (props.onRowClicked || props.onRightClick)),
                    [styles.disabled]: !!includes(disabledRows, row.id),
                    [styles.forceHover]: includes(props.forceHover, row.id),
                }, props.getRowClassName && props.getRowClassName(row))}
            >
                {props.selectableRows && (
                    <td key={`${row.key}-selector`}>
                        {allowSelect && (
                            <input
                                type="checkbox"
                                checked={!!selectedRowIds.includes(row.id)}
                                onChange={() => handleSelectRow(row)}
                            />
                        )}
                        {!allowSelect && props.selectionDisabledIcon && props.selectionDisabledIcon(row)}
                    </td>
                )}

                {!!props.expandedData && (
                    <td key={`${row.key}-expander`} onClick={(e) => handleExpandedRow(e, row)}>
                        <div className="expander-button">
                            <i className={classnames({
                                "fas fa-plus-square": !expandedRowIds.includes(row.id),
                                "fas fa-minus-square": !!expandedRowIds.includes(row.id),
                            })} />
                        </div>
                    </td>
                )}

                {map(props.columns, (fieldSpec) => renderTableField(fieldSpec, row))}
            </tr>
        )
    };

    const renderColumnTopHead = (c) => {
        return (
            <th key={c.key}>
                <span className="th-inner">
                    <span className="title">{c.topHeader}</span>
                </span>
            </th>
        )
    }

    const renderGroupHeading = (groupHeading) => {
        const addSearch = groupHeading.includeSearch && props.allowSearch && props.searchAsFirstColumn;
        const searchBoxCombo = addSearch && groupHeading.content ? (
            <div className={"flex flex-align-center"}>
                <div className="flex-1 margin-bottom-x-sm margin-right-sm">
                    {searchBar}
                </div>
                {groupHeading.content}
            </div>
        ) : null;

        const headingContent = groupHeading.content ? (
            <>
                {searchBoxCombo || groupHeading.content}
            </>
        ) : (
            <span className="th-inner">
                <span className="title">{groupHeading.title}</span>
            </span>
        )
        return (
            <th
                key={groupHeading.key}
                colSpan={groupHeading.colSpan}
                style={groupHeading.styles}
            >
                {headingContent}
            </th>
        )
    }

    const renderColumnHead = (c, groupOptions, wrapLabels) => {
        const showGroupToggle = groupOptions && (groupOptions.showOpen || groupOptions.showClose);
        const toggleClass = groupOptions && groupOptions.showOpen ? "fas fa-chevron-square-right" : "fas fa-chevron-square-left";

        return (
            <th
                data-testid={`data_table_header_${c.key}`}
                key={c.key}
                className={classnames({
                    "hoverable": c.sortable,
                    "selected": c.sortable && c.selector === sortField,
                    "wrappedLabels": wrapLabels,
                })}
                onClick={c.sortable ? () => handleSortClick(c.selector) : null}
            >
                <span className="th-inner">
                <span title={c.info ? c.info : null} className="title">{c.name} {c.info && (<i className="fa fa-info-circle"/>)}</span>
                    {c.sortable && (
                        <i
                            className={classnames("margin-left-x-sm", {
                                "fa fa-sort": sortField !== c.selector,
                                "fa fa-sort-down": sortField === c.selector && sortAsc,
                                "fa fa-sort-up": sortField === c.selector && !sortAsc,
                            })}
                        />
                    )}
                    {c.filter?.enabled && (
                        <Dropdown
                            placeholder={c.filter.title}
                            options={c.filter.values}
                            name={`${c.name}filterValue`}
                            value={filterValues[c.name]}
                            onChange={(evt) => handleFilterValueChanged(evt, c.name, c.filter.onChange)}
                            insideScrollingTable={props.scrollRows}
                        />
                    )}
                    {showGroupToggle && (
                        <span className="expander" onClick={(e) => handleGroupToggle(e, groupOptions.groupKey)}><i className={toggleClass} /></span>
                    )}

                </span>
            </th>
        )
    }

    const searchBar = (
        <TextBox
            onChange={({value}) => handleSearch(value)}
            name="search"
            value={search}
            placeholder="Search..."
            noPadding
        />
    );

    return (
        <div
            data-testid="data_table_root"
            className={classnames(styles.root, {
                [styles.scrollRows]: props.scrollRows,
            })}
        >
            <div className={classnames("data-table-controls flex full-width flex-centered spaced-content", {
                "flex-row-reverse": !props.title,
            })}>
                <div className="flex-2 empty">
                    {props.title && (<h2>{props.title}</h2>)}
                </div>
                {props.changePagination && (
                    <div className="flex-1 margin-bottom-x-sm">
                        <ResultsPerPageDropdown
                            placeHolder="Results Per Page"
                            onChange={({value}) => {setResultsPerPage(value)}}
                            value={resultsPerPage}
                            max={props.data.length}
                        />
                    </div>
                )}
                {props.allowSearch && !props.hideSearchBar && !props.searchAsFirstColumn && (
                    <div className="flex-1 margin-bottom-x-sm margin-right-sm">
                        {searchBar}
                    </div>
                )}
                {props.onCreateClick && (
                    <div className="flex-none margin-left-sm">
                        <Button
                            icon
                            small
                            onClick={props.onCreateClick}
                        >
                            <i className="fa fa-plus" /> Add
                        </Button>
                    </div>
                )}
            </div>
            <div className={styles.tableWrapper}>
                <table
                    data-testid="data_table_table"
                    className={classnames("table", {
                        "table-green": props.green && !props.striped,
                        "striped": props.striped && !props.green,
                        "striped-green": props.striped && props.green,
                        "less-padding": props.lessPadding,
                    })}
                >
                    {props.useColgroup && (
                        <colgroup>
                            {map(props.columns, (c) => (
                                <col {...(c.colSettings||{})} />
                            ))}
                        </colgroup>
                    )}
                    <thead>
                        {!!showSelectAllMessage && (
                            <tr>
                                <th colSpan={props.selectableRows ? (props.columns.length + 1) : props.columns.length}>
                                    Everything on this page has been {allPageDataSelected ? "deselected" : "selected"}. Would you like to
                                    <Button
                                        text
                                        type={props.green ? "light" : "primary"}
                                        onClick={handleSelectAllRows}
                                        className="margin-left-x-sm"
                                    >
                                        {!!allDataSelected ? "Deselect" : "Select"} All {props.data.length} items
                                    </Button>?
                                </th>
                            </tr>
                        )}
                        {!!filter(props.columns, "topHeader").length && (
                            <tr className="transparentRow">
                                {map(props.columns, (c) => {
                                    return renderColumnTopHead(c);
                                })}
                            </tr>
                        )}
                        {!!props.columnGroupings && (
                            <tr className="columnGroupings">
                                {map(props.columnGroupings, (groupHeading) => renderGroupHeading(groupHeading))}
                            </tr>
                        )}
                        <tr>
                            {props.selectableRows && (
                                <th key="select-all">
                                    <input type="checkbox" checked={!!allPageDataSelected || allDataSelected} onChange={handleSelectRowsOnPage} />
                                </th>
                            )}
                            {!!props.expandedData && (
                                <th key="expand-col" />
                            )}
                            {map(props.columns, (c) => {
                                if (c.groupedItems && !!groupStatus[c.key]) {
                                    // Open: show groupedItems instead
                                    return map(c.groupedItems, (child, indx) => renderColumnHead(child, {
                                        groupKey: c.key,
                                        showClose: indx === c.groupedItems.length - 1
                                    }))
                                } else if(c.groupedItems) {
                                    // Closed: Render the open button
                                    return renderColumnHead(c, { groupKey: c.key, showOpen: true }, props.wrapLabels);
                                } else {
                                    // Nothing special here
                                    return renderColumnHead(c, null, props.wrapLabels);
                                }
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {renderTableRows(pageData)}
                        {props.showTotals && renderTotalRow(data)}
                    </tbody>
                </table>
            </div>
            {totalPages > 1 && (
                <DataTablePagination
                    canGoNext={canGoNext}
                    onNext={handleGoNext}
                    canGoPrevious={canGoPrevious}
                    onPrevious={handleGoPrevious}
                    page={page+1}
                    totalPages={totalPages}
                    minimal={props.minimalPagination}
                />
            )}
        </div>
    );
}

DataTable.propTypes = {
    title: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        name: PropTypes.any,
        type: PropTypes.oneOf(["number", "string", "currency"]),
        selector: PropTypes.string,
        sortable: PropTypes.bool,
        format: PropTypes.func,
        sortValue: PropTypes.func,
        searchValue: PropTypes.func,
        searchable: PropTypes.bool,
        filter: PropTypes.shape({
            enabled: PropTypes.bool,
            title: PropTypes.bool.isRequired,
            values: PropTypes.array.isRequired,
            onChange: PropTypes.func.isRequired
        }),
        total: PropTypes.bool,
        dollarTotal: PropTypes.bool, // Show the total for the column in dollars
        rowTotal: PropTypes.func,
        groupedItems: PropTypes.arrayOf(PropTypes.shape( {
            key: PropTypes.string.isRequired,
            name: PropTypes.string,
            selector: PropTypes.string,
            sortable: PropTypes.bool,
            format: PropTypes.func,
            sortValue: PropTypes.func,
            searchValue: PropTypes.func,
            searchable: PropTypes.bool
        })),
        info: PropTypes.bool,
        colSettings: PropTypes.object,
    })),
    expandedData: PropTypes.shape({
        key: PropTypes.string.isRequired,
        name: PropTypes.string,
        info: PropTypes.bool,
        selector: PropTypes.string,
        sortable: PropTypes.bool,
        format: PropTypes.func,
    }),
    getRowIsSelectable: PropTypes.func,
    getRowIsDisabled: PropTypes.func,
    onRowClicked: PropTypes.func,
    onRowHoverStart: PropTypes.func,
    onRowHoverEnd: PropTypes.func,
    onRightClick: PropTypes.func,
    green: PropTypes.bool,
    striped: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.any.isRequired,
    })),
    selectableRows: PropTypes.bool,
    onRowSelectChange: PropTypes.func,
    selectedRowIds: PropTypes.array,
    pagination: PropTypes.bool,
    paginationPerPage: PropTypes.number,
    minimalPagination: PropTypes.bool,
    selectionDisabledIcon: PropTypes.func,
    conditionalRowProps: PropTypes.func,
    allowSearch: PropTypes.bool,
    hideSearchBar: PropTypes.bool,
    searchAsFirstColumn: PropTypes.bool,
    onCreateClick: PropTypes.func,
    search: PropTypes.string,
    //Link rows so related rows from the second set show under the first by this value
    connectDataSetsBy: PropTypes.string,
    getRowClassName: PropTypes.func,
    changePagination: PropTypes.bool,
    onSort: PropTypes.func,
    lessPadding: PropTypes.bool,
    showTotals: PropTypes.bool,
    canSelectAll: PropTypes.bool,
    forceHover: PropTypes.arrayOf(PropTypes.number),
    scrollRows: PropTypes.bool,
    useColgroup: PropTypes.bool,
    noResultsText: PropTypes.string,
    wrapLabels: PropTypes.bool,
};
