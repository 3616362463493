import getApiRoot from "./getApiRoot";
import request from "utils/request";

export function searchProducts(options) {
    return request()
        .query({
            limit: 5000,
            ...options
        })
        .get(`${getApiRoot()}/v1/admin/products/`)
        .send();
}

export function loadFoodMappings(clinicId) {
    return request()
        .query({clinicId})
        .get(`${getApiRoot()}/v1/products/foodmappings/`)
        .send();
}

export function createFoodMapping(data) {
    return request()
        .post(`${getApiRoot()}/v1/products/foodmappings/`)
        .send(data);
}

export function updateFoodMapping(data) {
    return request()
        .put(`${getApiRoot()}/v1/products/foodmappings/${data.clinicProductId}/`)
        .send(data);
}

export function deleteFoodMapping(clinicProductId) {
    return request()
        .delete(`${getApiRoot()}/v1/products/foodmappings/${clinicProductId}/`)
        .send();
}

export function loadPharmacyMappings(clinicId) {
    return request()
        .query({clinicId})
        .get(`${getApiRoot()}/v1/products/rxmappings/`)
        .send();
}

export function createPharmacyMapping(data) {
    return request()
        .post(`${getApiRoot()}/v1/products/rxmappings/`)
        .send(data);
}

export function updatePharmacyMapping(data) {
    return request()
        .put(`${getApiRoot()}/v1/products/rxmappings/${data.clinicProductId}/`)
        .send(data);
}

export function deletePharmacyMapping(clinicProductId) {
    return request()
        .delete(`${getApiRoot()}/v1/products/rxmappings/${clinicProductId}/`)
        .send();
}

export function getPIMs(clinicId, limit=99999) {
    return request()
        .query({
            clinicId,
            limit,
        })
        .get(`${getApiRoot()}/v1/pimsdata/products/`)
        .send();
}

export function loadFoodProducts() {
    return request()
        .get(`${getApiRoot()}/v1/products/foodproducts/`)
        .send();
}

export function loadPharmacyProducts() {
    return request()
        .get(`${getApiRoot()}/v1/products/rxproducts/`)
        .send();
}

export function loadVaccines() {
    return request()
        .get(`${getApiRoot()}/v1/vaccines/`)
        .send();
}

export function loadExamServices() {
    return request()
        .get(`${getApiRoot()}/v1/examservices/`)
        .send();
}

export function loadServiceMappings(clinicId) {
    return request()
        .query({clinicId})
        .get(`${getApiRoot()}/v1/examservices/mappings/`)
        .send();
}

export function createExamServiceMapping(data) {
    return request()
        .post(`${getApiRoot()}/v1/examservices/mappings/`)
        .send(data);
}

export function updateExamServiceMapping(data) {
    return request()
        .put(`${getApiRoot()}/v1/examservices/mappings/${data.mappingId}/`)
        .send(data);
}

export function deleteExamServiceMapping(mappingId) {
    return request()
        .delete(`${getApiRoot()}/v1/examservices/mappings/${mappingId}/`)
        .send();
}

export function loadVaccineMappings(clinicId) {
    return request()
        .query({
            clinicId
        })
        .get(`${getApiRoot()}/v1/vaccines/mappings/`)
        .send();
}

export function createVaccineMapping(data) {
    return request()
        .post(`${getApiRoot()}/v1/vaccines/mappings/`)
        .send(data);
}

export function updateVaccineMapping(data) {
    return request()
        .put(`${getApiRoot()}/v1/vaccines/mappings/${data.clinicProductMappingId}/`)
        .send(data);
}

export function deleteVaccineMapping(clinicProductMappingId) {
    return request()
        .delete(`${getApiRoot()}/v1/vaccines/mappings/${clinicProductMappingId}/`)
        .send();
}

export function getClinicProductOverrides(clinicId, productId) {
    return request()
        .query({
            clinicId,
            productId
        })
        .get(`${getApiRoot()}/v1/services/coupons/par/pricing/`)
        .send();
}

export function getClinicOverrides(clinicId) {
    return request()
        .get(`${getApiRoot()}/v1/services/coupons/par/overrides/${clinicId}/`)
        .send();
}

export function getProviderClinicLocationList(providerId, options) {
    return request()
        .query({
            offset: 0,
            limit: 20,
            ...options,
        })
        .get(`${getApiRoot()}/v1/providers/${providerId}/locations/`)
        .send();
}

export function updateProductOverrides(data) {
    return request()
        .post(`${getApiRoot()}/v1/services/coupons/par/pricing/`)
        .send(data);
}

// VET CHECK MAPPING
export function getClinicVetCheckEnrollment(clinicId) {
    return request()
        .query({
            clinicId
        })
        .get(`${getApiRoot()}/v1/vetcheckhandouts/mappings/clinicenrollment/`)
        .send();
}

export function loadVetCheckHandouts(clinicId) {
    return request()
        .get(`${getApiRoot()}/v2/vetcheckcontent/${clinicId}/`)
        .send();
}

//WELLNESS MAPPING
export function getClinicWellnessEnrollment(clinicId) {
    return request()
        .query({
            clinicId
        })
        .get(`${getApiRoot()}/v1/services/wellness/servicetypes/mappings/clinicenrollment/`)
        .send();
}

export function loadWellness() {
    return request()
        .get(`${getApiRoot()}/v1/services/wellness/servicetypes/`)
        .send();
}

export function loadWellnessMappings(clinicId) {
    return request()
        .query({
            clinicId
        })
        .get(`${getApiRoot()}/v1/services/wellness/servicetypes/mappings/`)
        .send();
}

export function createWellnessMapping(data) {
    return request()
        .post(`${getApiRoot()}/v1/services/wellness/servicetypes/mappings/`)
        .send(data);
}

export function updateWellnessMapping(data) {
    return request()
        .put(`${getApiRoot()}/v1/services/wellness/servicetypes/mappings/${data.mappingInstanceId}/`)
        .send(data);
}

export function deleteWellnessMapping(mappingInstanceId) {
    return request()
        .delete(`${getApiRoot()}/v1/services/wellness/servicetypes/mappings/${mappingInstanceId}/`)
        .send();
}

export function downloadWellnessMappings(clinicPriceScheduleId) {
    return request()
        .post(`${getApiRoot()}/v1/services/wellness/servicetypes/mappings/export/`)
        .responseType("blob")
        .send({
            clinicPriceScheduleId,
            exportType: "xlsx",
        });
}
