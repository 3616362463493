import request from "utils/request";
import getApiRoot from "./getApiRoot";

export function loadWellnessPayoutReport(clinicId, paymentDate=null) {
    return request()
        .post(`${getApiRoot()}/v1/services/wellness/clinics/${clinicId}/reports/billing/`)
        .send({ paymentDate })
}

export function loadWellnessPayoutReportExport(clinicId, paymentDate=null) {
    return request()
        .post(`${getApiRoot()}/v1/services/wellness/clinics/${clinicId}/reports/payoutreport/export/`)
	    .responseType("blob")
        .send({
		    paymentDate,
		    exportType: "xlsx",
		})
}
