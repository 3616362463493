import * as CouponsApi from "api/CouponsApi";
import keys from "lodash/keys";
import * as ApplicationStateActions from "actions/ApplicationStateActions";
import { getDefaultErrorHandler } from "actions/base";
import logger from "utils/logger";
import toast from "utils/toast";
import pluralizeWord from "utils/pluralizeWord";
import {handleErrorResponse} from "utils/request";
import * as ActionTypes from "constants/ActionTypes";
import * as Statuses from "constants/CouponStatusIds"
import * as StrayCouponsBatchTypes from "constants/StrayCouponsBatchTypes"
import {
    mapCouponSearchFromServerToApp,
    mapParIssuesFromServerToApp,
    mapProcessingCouponListFromAppToServer,
    mapProgramFromServerToApp,
    mapProgramOffersFromServerToApp,
    mapSingleCouponFromServerToApp,
    mapSingleTokenFromServerToApp,
    mapInvoicesFromServerToApp,
    mapProvidersFromServerToApp,
    mapStrayCouponsFromServerToApp
} from "data/serverMapping";

export function getUnprocessedCoupons(clinicId = null) {
    return (dispatch) => {
        CouponsApi.getUnprocessedCoupons(clinicId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.UNPROCESSED_COUPONS_LOADED,
                    coupons: mapCouponSearchFromServerToApp(res.body),
                });
            })
            .catch((error) => {
                handleErrorResponse("loading unprocessed coupons", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function getCouponDetails(couponId) {
    return (dispatch) => {
        CouponsApi.getCouponDetails(couponId)
            .then((res) => {
                const coupon = mapSingleCouponFromServerToApp(res.body);
                dispatch({
                    type: ActionTypes.COUPON_DETAILS_LOADED,
                    coupon,
                })
            })
            .catch((error) => {
                handleErrorResponse("loading coupon details", error);
            });

    }
}

/// A.K.A. "approve" coupons
export function processCoupons(initials, couponList, clinicId) {
    return (dispatch) => {
        const data = mapProcessingCouponListFromAppToServer(initials, couponList, Statuses.APPROVED, clinicId);

        CouponsApi.processCoupons(data)
            .then((res) => {
                toast.success(`Processed ${couponList.length} ${pluralizeWord(couponList.length, "coupon")}`);
                if (res.body) {
                    dispatch({
                        type: ActionTypes.COUPONS_PROCESSED,
                        approvalSummary: res.body,
                        couponList,
                        clinicId,
                    });
                }
            })
            .catch((error) => {
                handleErrorResponse("approving coupons", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function declineCoupons(initials, couponList, clinicId) {
    return (dispatch) => {
        const data = mapProcessingCouponListFromAppToServer(initials, couponList, Statuses.DECLINED, clinicId);

        CouponsApi.processCoupons(data)
            .then((res) => {
                toast.success(`Declined ${couponList.length} ${pluralizeWord(couponList.length, "coupon")}`);
                if (res.body) {
                    dispatch({
                        type: ActionTypes.COUPONS_DECLINED,
                        couponList,
                    });
                }
            })
            .catch((error) => {
                handleErrorResponse("declining coupons", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function getProgramOffersForProvider(clinicId, programId, asOfDate=null) {
    return (dispatch) => {
        CouponsApi.getProgramOffersForProvider(clinicId, programId, asOfDate)
            .then((res) => {
                dispatch({
                    type: ActionTypes.OFFERS_LOADED,
                    offers: mapProgramOffersFromServerToApp(res.body),
                    programId,
                });
            })
            .catch((error) => {
                handleErrorResponse("loading provider program offers", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

// API accepts 1 offer at a time
export function saveClinicProgramOfferSettings(offerSettings) {
    return (dispatch) => {
        CouponsApi.saveClinicProgramOfferSettings(offerSettings)
            .then((res) => {
                toast.success("Changes Saved");
                dispatch({
                    type: ActionTypes.OFFER_SETTINGS_SAVED,
                });
            })
            .catch((error) => {
                handleErrorResponse("updating program offer settings", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function getProgramOffers(programId, clinicId=null) {
    return (dispatch) => {
        CouponsApi.getProgramOffers(programId, clinicId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.OFFERS_LOADED,
                    offers: mapProgramOffersFromServerToApp(res.body),
                    programId, // A.K.A. providerId
                });
            })
            .catch((error) => {
                handleErrorResponse("loading program offers", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function getPrograms(clinicId= null) {
    return (dispatch) => {
        CouponsApi.getPrograms(clinicId)
            .then((res) => {
                logger.log(res.body, "HERE");
                dispatch({
                    type: ActionTypes.PROGRAMS_LOADED,
                    programs: mapProgramFromServerToApp(res.body),
                });
            })
            .catch((error) => {
                handleErrorResponse("loading programs", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function optInForOffer(offerId, programId) {
    return (dispatch) => {
        CouponsApi.optInOffer(offerId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.OFFER_OPT_IN,
                    offerId,
                    programId,
                });
            })
            .catch((error) => {
                handleErrorResponse("opting in", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function optOutForOffer(offerId, programId) {
    return (dispatch) => {
        CouponsApi.optOutOffer(offerId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.OFFER_OPT_OUT,
                    offerId,
                    programId
                });
            })
            .catch((error) => {
                handleErrorResponse("opting out", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function searchCoupons(query) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.COUPON_SEARCH_FILTERED,
            query,
        })
    }
}

export function clearCouponsSearch() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.COUPON_SEARCH_CLEARED,
        })
    }
}

export function fixCoupon(fixData) {
    return (dispatch) => {
        CouponsApi.fixCoupon(fixData)
            .then((res) => {
                const coupon = mapSingleCouponFromServerToApp(res.body);
                dispatch({
                    type: ActionTypes.COUPON_UPDATED,
                    coupon,
                });
            })
            .catch((error) => {
                handleErrorResponse("updating coupon", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                })
            });
    };
}

export function downloadPDF() {
    return (dispatch) => {
        toast.success("Downloading Coupon PDF");
        dispatch({
            type: ActionTypes.COUPON_DOWNLOADED,
        })
    }
}

export function downloadExcel() {
    return (dispatch) => {
        toast.success("Downloading Coupon Excel Sheet");
        dispatch({
            type: ActionTypes.COUPON_DOWNLOADED,
        })
    }
}

export function getProviderList() {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        CouponsApi.getProviderList()
            .then((res) => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.PROVIDER_LIST_LOADED,
                    providers: mapProvidersFromServerToApp(res.body),
                })
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading providers")(error);
            })
    }
}

export function getProviderById(providerId) {
    return (dispatch) => {
        CouponsApi.getProviderById(providerId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.PROVIDER_LOADED,
                    provider: res.body,
                })
            })
            .catch((error) => {
                handleErrorResponse("loading provider", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function getCouponsForReprocessing() {
    return (dispatch) => {
        CouponsApi.getCouponsForReprocessing()
            .then((res) => {
                dispatch({
                    type: ActionTypes.REPROCESSING_JOBS_LOADED,
                    reprocessingJobs: res.body,
                });
            })
            .catch((error) => {
                handleErrorResponse("loading coupons", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function createReprocessingJob(data, successCallback=undefined) {
    return (dispatch) => {
        CouponsApi.createReprocessingJob(data)
            .then((res) => {
                if (successCallback) {
                    successCallback()
                }
                dispatch({
                    type: ActionTypes.REPROCESSING_JOB_CREATED,
                    invoiceIds: data.invoices || [],
                    data: res.body,
                });
            })
            .catch((error) => {
                handleErrorResponse("creating reprocessing job", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function searchCouponsForReprocessing(query) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.REPROCESSING_JOBS_SEARCH_FILTERED,
            query,
        })
    }
}

export function clearCouponsForReprocessingSearch() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.REPROCESSING_JOBS_SEARCH_CLEARED,
        })
    }
}

export function loadClinicPar(clinicId, providerId) {
    return (dispatch) => {
        CouponsApi.getParItems(clinicId, providerId)
            .then(res => {
                const issues = mapParIssuesFromServerToApp(res.body);
                dispatch({
                    type: ActionTypes.COUPON_PRE_AUDIT_LOADED,
                    issues,
                });
            })
            .catch(error => {
                handleErrorResponse("loading PAR issues for clinic", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function approveDeclineParItems(data, items, comments, approve=false) {
    const newData = {
        externalRedemptionIds: items,
        comments,
        approvalState: approve ? Statuses.APPROVED : Statuses.DECLINED,
    }
    const actionType = approve ? ActionTypes.COUPON_PRE_AUDIT_APPROVED : ActionTypes.COUPON_PRE_AUDIT_DECLINED;

    return (dispatch) => {
        CouponsApi.updateParItems(newData)
            .then((res) => {
                dispatch({
                    type: actionType,
                    items: data,
                })
                const itemCount = keys(items)?.length;
                if (approve) {
                    toast.success(`Approved ${itemCount} ${itemCount === 1 ? "Item" : "Items"}`);
                } else {
                    toast.success(`Declined ${itemCount} ${itemCount === 1 ? "Item" : "Items"}`);
                }
            })
            .catch((error) => {
                handleErrorResponse("updating PAR items", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function getTokenById(tokenId) {
    return (dispatch) => {
        CouponsApi.getTokenById(tokenId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.TOKEN_DETAILS_LOADED,
                    data: mapSingleTokenFromServerToApp(res.body),
                });
            })
            .catch((error) => {
                handleErrorResponse("loading token details", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function updateRedemption(data) {
    return (dispatch) => {
        CouponsApi.updateRedemption({ action: data.action, tokenRedemptionId: data.tokenRedemptionId })
            .then((res) => {
                dispatch({
                    type: ActionTypes.TOKEN_REDEMPTION_UPDATED,
                    data: mapSingleTokenFromServerToApp(res.body),
                });
            })
            .catch((error) => {
                handleErrorResponse("updating redemption", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function searchInvoices(search) {
    return (dispatch) => {
        CouponsApi.searchInvoices(search)
            .then((res) => {
                dispatch({
                    type: ActionTypes.INVOICES_SEARCHED,
                    data: mapInvoicesFromServerToApp(res.body),
                });
            })
            .catch((error) => {
                handleErrorResponse("loading invoices", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function getCouponOfferHistory(clinicId, providerId) {
    return (dispatch) => {
        CouponsApi.getCouponOfferHistory(clinicId, providerId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.COUPON_OFFER_HISTORY_LOADED,
                    history: res.body,
                    providerId,
                    clinicId
                })
            })
            .catch((error) => {
                handleErrorResponse("loading coupon offer history", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error
                })
            })
    }
}

export function getCumulativeOfferTrackingOptions(clinicId) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.CUMULATIVE_OFFER_TRACKING_OPTIONS_LOADING });

        CouponsApi.getCumulativeOfferTrackingOptions(clinicId)
            .then(res => {
                dispatch({
                    type: ActionTypes.CUMULATIVE_OFFER_TRACKING_OPTIONS_LOADED,
                    clinicId,
                    options: res.body,
                });
            })
            .catch((error) => {
                handleErrorResponse("loading offer options", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function getCumulativeOfferTrackingData(clinicId, offerIds) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.CUMULATIVE_OFFER_TRACKING_LOADING });

        CouponsApi.getCumulativeOfferTrackingData(clinicId, offerIds)
            .then(res => {
                dispatch({
                    type: ActionTypes.CUMULATIVE_OFFER_TRACKING_LOADED,
                    clinicId,
                    data: res.body,
                });
            })
            .catch((error) => {
                handleErrorResponse("loading selected offer data", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function getLibraryOffers() {
    return (dispatch) => {
        CouponsApi.getLibraryOffers()
            .then(res => {
                dispatch({
                    type: ActionTypes.ALL_OFFERS_LOADED,
                    data: res.body,
                });
            })
            .catch((error) => {
                handleErrorResponse("loading offers", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function getStrayCoupons(clinicId=null) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());

        CouponsApi.getStrayCoupons(clinicId)
            .then(res => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.STRAY_COUPONS_LOADED,
                    data: mapStrayCouponsFromServerToApp(res.body),
                });
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading stray coupon")(error);
            });
    };
}

export function getStrayCouponStatuses() {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());

        CouponsApi.getStrayCouponStatuses()
            .then(res => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.STRAY_COUPON_STATUSES_LOADED,
                    data: res.body,
                });
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading stray coupon statuses")(error);
            });
    };
}

export function clearStrayCoupons() {
    return (dispatch) => {
        dispatch({ type: ActionTypes.STRAY_COUPONS_LOADED });
    }
}

export function resolveStrayCoupon(invoiceId) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());

        CouponsApi.resolveStrayCoupon(invoiceId)
            .then(res => {
                dispatch(ApplicationStateActions.endWorking());
                toast.success("Invoice Marked as Resolved");
                dispatch({
                    type: ActionTypes.STRAY_COUPONS_RESOLVED,
                    invoiceId
                });
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "resolving stray coupon")(error);
            });
    };
}

function getBatchActionType(action) {
    switch (action) {
        case StrayCouponsBatchTypes.Pending:
            return ActionTypes.STRAY_COUPON_MARK_PEND;
        case StrayCouponsBatchTypes.Resolved:
            return ActionTypes.STRAY_COUPONS_BATCH_RESOLVED;
        case StrayCouponsBatchTypes.Hidden:
            return ActionTypes.STRAY_COUPONS_BULK_MARK_HIDDEN;
        case StrayCouponsBatchTypes.Unhidden:
            return ActionTypes.STRAY_COUPONS_MARK_UNHIDDEN;
        case StrayCouponsBatchTypes.Eligible:
            return ActionTypes.INELIGIBLE_MARKED_ELIGIBLE;
        default:
            return ActionTypes.STRAY_COUPONS_MARK_UNHIDDEN; // A very low risk action
    }
}

function getStatus(status) {
    switch(status) {
        case "FixerInvoice":
            return "Fixer Invoice";
        case "GreenlineResolved":
            return "Greenline Resolved";
        case "SentToManufacturer":
            return "Sent To Manufacturer";
        default:
            return status;
    }
}

export function batchUpdateStrayCoupon({ invoiceIds, couponAction, status, manufacturerId }) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        const actionType = getBatchActionType(couponAction);
        CouponsApi.batchUpdateStrayCoupon({ invoiceIds, couponAction, status, manufacturerId })
            .then(res => {
                dispatch(ApplicationStateActions.endWorking());
                toast.success("Invoices Updated Successfully");
                dispatch({
                    type: actionType,
                    invoices: res.body,
                    status: getStatus(status),
                })
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "resolving stray coupons")(error);
            });
    };
}

export function markedHidden(invoiceId, isHidden) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.STRAY_COUPONS_MARK_HIDDEN,
            invoiceId,
            isHidden
        });
    };
}

export function markIsEligible(invoiceId) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.INELIGIBLE_MARKED_ELIGIBLE,
            invoiceIds: [invoiceId],
        })
    }
}
