import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as Yup from "yup";
import get from "lodash/get";
import includes from "lodash/includes";
import * as ClinicActions from "actions/ClinicActions";
import Button from "components/common/Button";
import CheckboxInput from "components/common/CheckboxInput";
import Dropdown from "components/common/Dropdown";
import TextBox from "components/common/TextBox";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import useFormHandlers from "utils/useFormHandlers";
import * as UserPermissions from "constants/UserPermissions";
import { countriesDropdownList } from "constants/Countries";
import { usStatesDropdownList, usStateValueOptions } from "constants/USStates";
import { BOTTOM } from "constants/TooltipPositionIds";
import produce from "immer";

const SCHEMA = Yup.object().shape({
    name: Yup.string().required(),
    street1: Yup.string().required(),
    street2: Yup.string(),
    city: Yup.string().required(),
    state: Yup.string().oneOf(usStateValueOptions).required(),
    zipCode: Yup.string().required(),
    countryCode: Yup.string()
        .oneOf(["US", "CA"])
        .default("US")
        .required(),
    clinicBillingName: Yup.string().nullable(true),
    billingAddress: Yup.object().shape({
        isActive: Yup.bool().default(false),
        city: Yup.string().nullable(true).default(null),
        countryCode: Yup.string().oneOf(["US", "CA"]).default("US"),
        state: Yup.string().oneOf(usStateValueOptions).nullable(true).default(null),
        street1: Yup.string().nullable(true).default(null),
        street2: Yup.string().nullable(true).default(null),
        zipCode: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(5, 'Must be exactly 5 digits')
            .max(5, 'Must be exactly 5 digits')
            .nullable(true)
            .default(null),
    }),
});

function ClinicDetailsForm(props) {
    const { formData, isValid, handleUpdate, invalidFields } = useFormHandlers(
        props.initialData,
        SCHEMA,
    );

	const handleSubmit = (e) => {
		e.stopPropagation();
		e.preventDefault();
        if (isValid && isValidBillingAddress(formData)) {
            const clinicData = produce(formData, draft => {
                if (get(draft, "billingAddress.isActive")) {
                    draft.billingAddress.stateProvince = draft.billingAddress.state;
                    draft.billingAddress.postalCode = draft.billingAddress.zipCode;
                }
            });
            props.updateClinic(clinicData);
            props.onClose();
        } else {
            console.warn("Invalid form fields - won't update clinic details");
        }
    }

    const isValidBillingAddress = values => {
        if (!values || !values.isActive) {
            return true;
        }

        if (!isValid) {
            return false; // Basic YUP field syntax failed (e.g., zip can only be numbers).
        }

        const hasAllFields = !!values.city &&
            !!values.countryCode &&
            !!values.state &&
            !!values.street1 &&
            !!values.zipCode;

        return hasAllFields;
    };

    return (
		<form onSubmit={handleSubmit}>
            <TextBox
                hasError={includes(invalidFields, "name")}
                label="Name"
                name="name"
                onChange={handleUpdate}
                required
                value={formData.name}
            />
			<h3>Clinic Address</h3>
            <TextBox
                hasError={includes(invalidFields, "street1")}
                label="Address line 1"
                name="street1"
                onChange={handleUpdate}
                required
                value={formData.street1}
            />
			<TextBox
                hasError={includes(invalidFields, "street2")}
                label="Address line 2"
                name="street2"
                onChange={handleUpdate}
                value={formData.street2}
            />
			<div className="flex spaced-content">
				<div className="flex-1">
					<TextBox
		                hasError={includes(invalidFields, "city")}
		                label="City"
		                name="city"
		                onChange={handleUpdate}
		                required
		                value={formData.city}
		            />
				</div>

				<div className="flex-1">
					<Dropdown
						hasError={includes(invalidFields, "state")}
		                label="State"
		                name="state"
		                onChange={handleUpdate}
		                options={usStatesDropdownList}
		                placeholder="Select a State"
		                required
		                value={formData.state}
		            />
				</div>
			</div>
			<div className="flex spaced-content">
				<div className="flex-1">
					<TextBox
		                hasError={includes(invalidFields, "zipCode")}
		                label="Postal Code"
		                name="zipCode"
		                onChange={handleUpdate}
		                required
		                value={formData.zipCode}
		            />
				</div>
				<div className="flex-1">
					<Dropdown
						hasError={includes(invalidFields, "countryCode")}
						label="Country"
						name="countryCode"
						onChange={handleUpdate}
						options={countriesDropdownList}
						placeholder="Select a Country"
						required
						value={formData.countryCode}
					/>
				</div>
			</div>
			<div className="flex align-center spaced-content">
				<div className="flex-1">
					<TextBox
		                hasError={includes(invalidFields, "clinicBillingName")}
		                label="Billing Name"
		                name="clinicBillingName"
		                onChange={handleUpdate}
		                value={formData.clinicBillingName}
		            />
				</div>
				<div className="flex-none">
					<CheckboxInput
	                    onChange={handleUpdate}
	                    name="billingAddress.isActive"
	                    checked={formData.billingAddress?.isActive}
	                    label="Different billing address?"
	                />
				</div>
			</div>
			{formData.billingAddress?.isActive && (
				<>
					<h3 className="flex-1">Billing Address</h3>
					<TextBox
		                hasError={includes(invalidFields, "billingAddress.street1")}
		                label="Address line 1"
		                name="billingAddress.street1"
		                onChange={handleUpdate}
		                required
		                value={formData.billingAddress.street1}
		            />
					<TextBox
		                hasError={includes(invalidFields, "billingAddress.street2")}
		                label="Address line 2"
		                name="billingAddress.street2"
		                onChange={handleUpdate}
		                value={formData.billingAddress.street2}
		            />
					<div className="flex spaced-content">
						<div className="flex-1">
							<TextBox
				                hasError={includes(invalidFields, "billingAddress.city")}
				                label="City"
				                name="billingAddress.city"
				                onChange={handleUpdate}
				                required
				                value={formData.billingAddress.city}
				            />
						</div>

						<div className="flex-1">
							<Dropdown
								hasError={includes(invalidFields, "billingAddress.state")}
				                label="State"
				                name="billingAddress.state"
				                onChange={handleUpdate}
				                options={usStatesDropdownList}
				                placeholder="Select a State"
				                required
				                value={formData.billingAddress.state}
				            />
						</div>
					</div>
					<div className="flex spaced-content">
						<div className="flex-1">
							<TextBox
				                hasError={includes(invalidFields, "billingAddress.zipCode")}
				                label="Postal Code"
				                name="billingAddress.zipCode"
				                onChange={handleUpdate}
				                required
				                value={formData.billingAddress.zipCode}
				            />
						</div>
						<div className="flex-1">
							<Dropdown
								hasError={includes(invalidFields, "billingAddress.countryCode")}
								label="Country"
								name="billingAddress.countryCode"
								onChange={handleUpdate}
								options={countriesDropdownList}
								placeholder="Select a Country"
								required
								value={formData.billingAddress.countryCode}
							/>
						</div>
					</div>
				</>
			)}
			<div className="flex flex-wrap spaced-content">
				<CheckboxInput
                    onChange={handleUpdate}
                    name="isActive"
                    checked={formData.isActive}
                    label="Active"
                />
				<CheckboxInput
                    onChange={handleUpdate}
                    name="isGatewayClinic"
                    checked={formData.isGatewayClinic}
                    label="Gateway Clinic"
                    information={(
						<span>
							When set, this clinic is allowed to sync.<br/>
							And if hospitals are available, this flag cannot be modified and hospitals can be assigned to other clinics.<br/>
							If this clinic is enrolled into wellness, Gateway flag cannot be changed.
						</span>
                    )}
                    informationPosition={BOTTOM}
                />
				<CheckboxInput
                    onChange={handleUpdate}
                    name="isTestClinic"
                    checked={formData.isTestClinic}
                    label="Test Clinic"
                />
			</div>
			<hr />
			<div className="flex justify-flex-end spaced-content">
				<div>
					<Button
						onClick={props.onClose}
						type="gray"
					>
						Cancel
					</Button>
				</div>
				<div>
					<Button
						disabled={!isValid || !isValidBillingAddress(formData.billingAddress)}
						buttonType="submit"
					>
						Save
					</Button>
				</div>
			</div>
		</form>
    );
}

ClinicDetailsForm.defaultProps = {
    initialData: {},
};

ClinicDetailsForm.propTypes = {
	clinicId: PropTypes.number.isRequired,
    initialData: PropTypes.object,
	onClose: PropTypes.func.isRequired,
};

export default connect(
    (state) => {
		const userProfile = state.user.userProfile;
        const canViewUserManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.USER_MANAGEMENT, userProfile);

        return {
            canViewUserManagement,
        }
    },
    (dispatch) => ({
	    updateClinic: (clinicUpdate) => dispatch(ClinicActions.adminUpdateClinicSettings(clinicUpdate)),
    })
) (ClinicDetailsForm);
