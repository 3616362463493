import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import styles from "./UnprocessedWellnessVisits.scss";
import values from "lodash/values";
import * as WellnessActions from "actions/WellnessActions";
import AccessDenied from "components/common/AccessDenied";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import TimeFilterDropdown from "components/coupons/widgets/TimeFilterDropdown";
import WellnessVisitTable from "components/wellness/widgets/WellnessVisitTable";
import {clinicHasWellness} from "utils/ClinicData";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import { getProcessedWellnessVisits, getUserCurrentClinic } from "utils/WellnessData";
import * as TimeFilterId from "constants/TimeFilterIds";
import * as UserPermissions from "constants/UserPermissions";
import NewPlanEnrollmentBtn from "components/greenlineWellness/widgets/WellnessEnrollmentModal/NewPlanEnrollmentBtn";

function ProcessedWellnessVisits(props) {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [period, setPeriod] = useState(TimeFilterId.LAST_90_DAYS);
    const [isWellnessHistoryPage, setIsWellnessHistoryPage] = useState(true);

    const fetchData = () => {
        if (props.canViewClinicWellnessAudit) {
            if ((period !== TimeFilterId.CUSTOM) || !!(startDate && endDate)) {
                // Only search Wellness Visits History if:
                // the period is not custom
                // OR there is a Start and End Date
                props.getWellnessVisitsHistory({
                    clinicId: props.clinicId,
                    startDate,
                    endDate,
                    period,
                });
            }
        }
    }

    useEffect(() => {
        fetchData();
    }, [props.clinicId, startDate, endDate, period, setIsWellnessHistoryPage]);

     // Dirt simple text filtering
    const filterByText = (visits) => {
        if(!props.search) {
            return visits;
        }
        const text = props.search.toLowerCase();

        let filtered = [...visits];

        filtered = filtered.filter(visit => {
            return (visit.extendedInvoiceNumber && visit.extendedInvoiceNumber.toString().includes(text)) ||
                (visit.dvm && visit.dvm.toLowerCase().includes(text)) ||
                (visit.client && visit.client.toLowerCase().includes(text)) ||
                (visit.pet && visit.pet.toLowerCase().includes(text)) ||
                (visit.productName && visit.productName.toLowerCase().includes(text)) ||
                (visit.productType && visit.productType.toLowerCase().includes(text));
        });

        return filtered;
    }

    const getFilteredList = () => {
        let filtered = values(props.visits);

        if (filtered && filtered.length > 0) {

            // Time filter handled by API call

            if (props.search) {
                filtered = filterByText(filtered);
            }
        }
        return filtered;
    }

    const filteredList = getFilteredList();

    const handleTimeFilterRangeChanged = ({startDate: timeFilterStartDate, endDate: timeFilterEndDate}) => {
        setPeriod(TimeFilterId.CUSTOM);
        setStartDate(timeFilterStartDate);
        setEndDate(timeFilterEndDate);
    }

    const handleTimeFilterChanged = ({value}) => {
        setPeriod(value);
    }

    const renderVisitList = (filtered) => {
        if (!filtered.length) {
            return (
                <div className={styles.noVisits}>
                    <span>No Visits meet the filter criteria! Try changing your filters.</span>
                </div>
            );
        }

        // Disable the download button if the custom date range has not been selected
        const downloadDisabled = ((period === TimeFilterId.CUSTOM) && !(startDate && endDate));

        return (
            <WellnessVisitTable
                clinicId={props.clinicId}
                visits={filtered}
                isWellnessHistoryPage={isWellnessHistoryPage}
                historical
                downloadOptions={{
                    clinicId: props.clinicId,
                    period,
                    startDate,
                    endDate
                }}
                downloadDisabled={downloadDisabled}
            />
        );
    };

    return (
        <>
            <div className={styles.root}>
                {!(props.canViewClinicWellnessAudit) ? (
                    <div>
                        <h2>Wellness History</h2>
                        <AccessDenied/>
                    </div>
                ) : (
                    <>
                        <div className="topLine">
                        <h2>Wellness History</h2>
                        <div className="resultCount">({filteredList.length} results)</div>
                        <div className="filters">
                            <div className="rangeFilter">
                                <div className="flex align-center">
                                    <label>Visit Date</label>
                                    <TimeFilterDropdown
                                        name="period"
                                        value={period}
                                        onChange={handleTimeFilterChanged}
                                        onRangeChange={handleTimeFilterRangeChanged}
                                    />
                                    {props.canViewClinicWellnessPlans && props.canGLWellness && (<NewPlanEnrollmentBtn clinicId={props.clinicId} />)}
                                </div>
                            </div>
                        </div>
                    </div>
                    {!(props.visits && props.visits.length) ? (
                        <div className={styles.noVisits}>
                            <span>No Visits to Show!</span>
                        </div>
                    ) : renderVisitList(filteredList)}
                    <SpinnerTakeover show={props.isWellnessLoading} />
                </>
                )}
            </div>
        </>
    );
}

ProcessedWellnessVisits.propTypes = {
    onClose: PropTypes.func,
    clinicId: PropTypes.number.isRequired,
    canGLWellness: PropTypes.bool,
}


const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canViewClinicWellnessAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);
        const canChangeDvmClinicAudit = userHasPermission(PermissionTypes.CHANGE_DVM, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);
        const canViewClinicWellnessPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);

        return {
            clinicHasWellness: clinicHasWellness(state, ownProps.clinicId),
            visits: getProcessedWellnessVisits(state),
            search: state.entities.wellness.query,
            coupons: state.entities.wellness,
            isWellnessLoading: state.entities.wellness.isWellnessLoading || false,
            // Permissions
            canViewClinicWellnessAudit,
            canChangeDvmClinicAudit,
            canViewClinicWellnessPlans,
        }
    },
    (dispatch) => ({
        getWellnessVisitsHistory: (options) => dispatch(WellnessActions.getProcessedWellnessVisits(options)),
        updateWellnessVisits: (dvmId, visits) => dispatch(WellnessActions.updateWellnessVisits(dvmId, visits)),
    }),
);


export default compose(
    withRouter,
    connector
)(ProcessedWellnessVisits);
