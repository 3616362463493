import request from "utils/request";
import getApiRoot from "./getApiRoot";

export function searchCoupons({ search, offset, limit, fromAllUsers, orderBy, orderDir }) {
    return request()
        .post(`${getApiRoot()}/v1/services/coupons/reprocessing/search/`)
        .send({
            search,
            offset,
            limit,
            fromAllUsers,
            orderBy,
            orderDir
        });
}

export function createReprocessingJob(data) {
    return request()
        .post(`${getApiRoot()}/v1/services/coupons/reprocessing/submit/`)
        .send(data);
}

export function getUnprocessedCoupons(clinicId = null) {
    const options = {
        period: "bot",
        limit: 999999,
        offset: 0,
        redemptionStates: "Unprocessed",
        clinicId,
    };
    return request()
        .post(`${getApiRoot()}/v1/services/coupons/clinicredemptions/search/`)
        .send(options);
}

export function getProcessedCoupons(options) {
    const searchQuery = {
        clinicId: null,
        providerId: null,
        period: "-90d",
        offset: 0,
        orderBy: "InvoiceDate", // InvoiceNumber, InvoiceDate, LastName, PetName, Product, Offer (null or any value not in list will order by ProviderId, CreateDate)
        orderDir: "desc", // ASC, DESC, or null
        limit: 99999,
        startDate: null,
        endDate: null,
        redemptionStates: "Approved, Declined",
        ...options,
    };

    return request()
        .post(`${getApiRoot()}/v1/services/coupons/clinicredemptions/search/`)
        .send(searchQuery);
}

export function processCoupons(couponsData) {
    return request()
        .post(`${getApiRoot()}/v1/services/coupons/clinicredemptions/batch/`)
        .send(couponsData);
}

export function fixCoupon(fixData) {
    return request()
        .put(`${getApiRoot()}/v1/services/coupons/clinicredemptions/validationupdate/`)
        .send(fixData);
}

export function getCouponDetails(couponId) {
    return request()
        .get(`${getApiRoot()}/v1/services/coupons/clinicredemptions/${couponId}/`)
        .send();
}

export function notificationOfferManagement(data={}) {
    return request()
        .post(`${getApiRoot()}/v1/services/coupons/cliniclibraryoffers/`)
        .send(data);
}

export function optInOffer(offerId, data={}) {
    return request()
        .put(`${getApiRoot()}/v1/services/coupons/cliniclibraryoffers/${offerId}/optin/`)
        .send(data);
}

export function optOutOffer(offerId) {
    return request()
        .put(`${getApiRoot()}/v1/services/coupons/cliniclibraryoffers/${offerId}/optout/`)
        .send();
}

export function getProgramOffers(providerId, clinicId) {
    return request()
        .query({
            clinicId
        })
        .get(`${getApiRoot()}/v1/services/coupons/cliniclibraries/${providerId}/`)
        .send();
}

export function getCouponOfferHistory(clinicId, providerId) {
    return request()
        .query({
            clinicId,
            providerId
        })
        .get(`${getApiRoot()}/v1/services/coupons/cliniclibraryoffers/history/`)
        .send();
}

export function getPrograms(clinicId) {
    return request()
        .query({
            clinicId
        })
        .get(`${getApiRoot()}/v1/services/coupons/cliniclibraries/`)
        .send();
}

export function getProviderList() {
    return request()
        .get(`${getApiRoot()}/v1/providers/`)
        .send();
}

export function getProviderById(providerId) {
    return request()
        .get(`${getApiRoot()}/v1/providers/${providerId}/`)
        .send();
}

export function getCouponsForReprocessing() {
    return request()
        .get(`${getApiRoot()}/v1/coupons/reprocess/`)
        .send();
}

export function getParItems(clinicId, providerId) {
    return request()
        .query({
            clinicId,
            providerId
        })
        .get(`${getApiRoot()}/v1/services/coupons/par/`)
        .send();
}

export function updateParItems(data) {
    return request(data)
        .post(`${getApiRoot()}/v1/services/coupons/par/`)
        .send(data);
}

export function getTokenById(tokenId) {
    return request()
        .get(`${getApiRoot()}/v1/services/coupons/reprocessing/tokens/${tokenId}/`)
        .send();
}

export function updateRedemption(data) {
    return request()
        .post(`${getApiRoot()}/v1/services/coupons/reprocessing/redemptionupdate/`)
        .send(data);
}

export function searchInvoices(search) {
    return request()
        .post(`${getApiRoot()}/v1/services/coupons/reprocessing/invoicesearch/`)
        .send(search);
}

export function getProgramOffersForProvider(clinicId, providerId, asOfDate=null) {
    return request()
        .query({
            asOfDate
        })
        .get(`${getApiRoot()}/v1/services/coupons/cliniclibraries/${clinicId}/availableoffers/${providerId}/`)
        .send();
}

export function updateProgramOffersForProvider(data) {
    return request()
        .put(`${getApiRoot()}/v1/services/coupons/cliniclibraries/availableoffers/${data.id}/`)
        .send(data);
}

export function saveClinicProgramOfferSettings(data) {
    return request()
        .put(`${getApiRoot()}/v1/services/coupons/cliniclibraries/availableoffers/${data.id}/`)
        .send(data);
}

export function downloadCouponHistory(options) {
    // Options: period, clinicId, startDate, endDate, exportType, redemptionStates
    return request()
        .post(`${getApiRoot()}/v1/services/coupons/clinicredemptions/export/`)
        .responseType("blob")
        .send({
            period: "-90d",
            redemptionStates: "Approved, Rejected",
            exportType: "xlsx",
            ...options
        });
}

export function getProviderPreferences(providerId, clinicId) {
    return request()
        .query({
            providerId,
            clinicId
        })
        .get(`${getApiRoot()}/v1/clinicproviderpreferences/`)
        .send();
}

export function updateProviderPreferences(data) {
    return request()
        .put(`${getApiRoot()}/v1/clinicproviderpreferences/${data.clinicProgramEnrollmentId}/`)
        .send(data);
}

export function getCumulativeOfferTrackingOptions(clinicId) {
    return request()
        .query({ clinicId })
        .get(`${getApiRoot()}/v1/services/coupons/cumulativeoffers/`)
        .send();
}

export function getCumulativeOfferTrackingData(clinicId, offerIds) {
    return request()
        .post(`${getApiRoot()}/v1/services/coupons/cumulativeoffers/incomplete/`)
        .send({
            clinicId,
            groupOfferIds: offerIds,
        });
}

export function downloadIncompleteCumulativeOffers(clinicId, groupOfferIds, options) {
    return request()
        .post(`${getApiRoot()}/v1/services/coupons/cumulativeoffers/incomplete/export/`)
        .responseType("blob")
        .send({
            clinicId,
            groupOfferIds,
            exportType: "xlsx",
            ...options,
        });
}

export function getLibraryOffers() {
    return request()
        .get(`${getApiRoot()}/v1/services/coupons/libraryoffers/`)
        .send();
}

export function checkValidStateZip({ city, state, postalCode }) {
    return request()
        .post(`${getApiRoot()}/v1/services/coupons/clinicredemptions/validatezipandstate/`)
        .send({ city, state, postalCode });
}

export function getStrayCoupons(clinicId, orderBy="invoiceDate") {
    // Note: The api supports orderBy and section query params, but since it currently returns all the data the UI
    //   needs at once then the UI handles all the ordering after initial load
    // -----------------------------------------------------------------------
    // orderBy: Can be 'customer', 'rebate', 'invoiceNumber', or 'invoiceDate'
    // section: Can be 'isResolved', 'isExpired', or 'isPending' (null returns data for all)
    // ---------------------------------------------------------------
    return request()
        .query({ orderBy })
        .get(`${getApiRoot()}/v1/services/coupons/clinicredemptions/instantrebateinvoicefailures/${clinicId}/`)
        .send();
}

export function getStrayCouponStatuses() {
    return request()
        .get(`${getApiRoot()}/v1/services/coupons/clinicredemptions/instantrebateinvoicefailures/status/`)
        .send();
}

export function resolveStrayCoupon(invoiceId) {
    return request()
        .put(`${getApiRoot()}/v1/services/coupons/clinicredemptions/instantrebateinvoicefailures/resolve/`)
        .send({ invoiceId });
}

export function batchUpdateStrayCoupon({ invoiceIds, couponAction, status, manufacturerId }) {
    return request()
        .put(`${getApiRoot()}/v2/services/coupons/clinicredemptions/instantrebateinvoicefailures/${couponAction}/batch/`)
        .send({ invoiceIds, manufacturerId, status });
}

export function markIsHidden(invoiceId, isHidden) {
    return request()
        .put(`${getApiRoot()}/v1/services/coupons/clinicredemptions/instantrebateinvoicefailures/invoice/${invoiceId}/${isHidden}/`)
        .send({
            invoiceId,
            isHidden
        });
}

export function sendStrayCouponGetHelp(formData) {
    return request()
        .post(`${getApiRoot()}/v1/services/coupons/clinicredemptions/instantrebateinvoicefailures/submit/`)
        .send(formData);
}

export function corporateGroupAuditHistoryExport(corporateGroupId, providerIds, days, redemptionStates=null, exportType="xlsx") {
    return request()
        .post(`${getApiRoot()}/v2/providers/audit/export/`)
        .responseType("blob")
        .send({
            days,
            corporateGroupId: corporateGroupId,
            exportType,
            providerIds,
            redemptionStates,
        });
}