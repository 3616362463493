import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./ClinicTags.scss";
import * as ClinicActions from "actions/ClinicActions";
import ClinicTagsForm from "./ClinicTagsForm";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function ClinicTagsFormContainer(props) {
    useEffect(() => {
        if (props.canViewClinicManagement) {
            props.loadTags();
        }
    }, [props.canViewClinicManagement]);

    if (!props.canViewClinicManagement) {
        return null;
    }

    const handelSave = (tags) => {
        props.updateClinicTags(props.clinicId, tags);
    };

    return (
        <div className={styles.tagList}>
            <ClinicTagsForm
                initialData={props.initialData}
                onSave={handelSave}
                allTags={props.tags}
            />
        </div>
    )
}

ClinicTagsFormContainer.defaultProps = {
    initialData: [],
}


ClinicTagsFormContainer.propTypes = {
    clinicId: PropTypes.number.isRequired,
    initialData: PropTypes.arrayOf(PropTypes.string),
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canEditClinicManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            canViewClinicManagement,
            canEditClinicManagement,
            tags: state.entities.tags,
        }
    },
    (dispatch) => ({
        loadTags: () => dispatch(ClinicActions.loadTags()),
        updateClinicTags: (clinicId, tags) => dispatch(ClinicActions.updateClinicTags(clinicId, tags))
    })
)(ClinicTagsFormContainer);

