import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as Yup from "yup";
import includes from "lodash/includes";
import uniq from "lodash/uniq";
import * as ClinicActions from "actions/ClinicActions";
import Button from "components/common/Button";
import TextBox from "components/common/TextBox";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import useFormHandlers from "utils/useFormHandlers";
import * as UserPermissions from "constants/UserPermissions";

const schema = Yup.object().shape({
    excludedCouponClientIdList: Yup.array().of(Yup.string()).default([]),
    excludedEmailClientIdList: Yup.array().of(Yup.string()).default([]),
    excludedReportingClientIdList: Yup.array().of(Yup.string()).default([]),
});

function ExcludedClientsForm(props) {
	const { formData, isValid, handleUpdate, invalidFields } = useFormHandlers(
        props.initialData,
        schema,
    );

	const handleChange = ({ name, value }) => {
		const newList = value.replace(/[^0-9,]/, '').split(",");
		handleUpdate({
			name,
			value: newList,
		});
	}

	const handleSubmit = (e) => {
		e.stopPropagation();
		e.preventDefault();
		if (isValid) {
			// Remove duplicate values before submitting
			const newData = {
				...formData,
				excludedCouponClientIdList: uniq(formData.excludedCouponClientIdList),
				excludedEmailClientIdList: uniq(formData.excludedEmailClientIdList),
				excludedReportingClientIdList: uniq(formData.excludedReportingClientIdList),
			}
			props.updateClinic(newData);
			props.onClose();
        }
	}

    return (
		<form onSubmit={handleSubmit}>
            <TextBox
                hasError={includes(invalidFields, "excludedCouponClientIdList")}
                label="Excluded from Coupons"
                name="excludedCouponClientIdList"
                onChange={handleChange}
                value={formData.excludedCouponClientIdList}
            />
			<TextBox
                hasError={includes(invalidFields, "excludedEmailClientIdList")}
                label="Excluded from Emailing"
                name="excludedEmailClientIdList"
                onChange={handleChange}
                value={formData.excludedEmailClientIdList}
            />
			<TextBox
                hasError={includes(invalidFields, "excludedReportingClientIdList")}
                label="Excluded from Reporting"
                name="excludedReportingClientIdList"
                onChange={handleChange}
                value={formData.excludedReportingClientIdList}
            />
			<hr />
			<div className="text-mid-gray">*Changes to these fields could take a minute to propagate</div>
			<div className="flex justify-flex-end spaced-content">
				<div>
					<Button
						onClick={props.onClose}
						type="gray"
					>
						Cancel
					</Button>
				</div>
				<div>
					<Button
						disabled={!isValid}
						buttonType="submit"
					>
						Save
					</Button>
				</div>
			</div>
		</form>
    );
}

ExcludedClientsForm.defaultProps = {
    initialData: {},
};

ExcludedClientsForm.propTypes = {
	clinicId: PropTypes.number.isRequired,
    initialData: PropTypes.object,
	onClose: PropTypes.func.isRequired,
};

export default connect(
    (state) => {
		const userProfile = state.user.userProfile;
        const canViewUserManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.USER_MANAGEMENT, userProfile);

        return {
            canViewUserManagement,
        }
    },
    (dispatch) => ({
	    updateClinic: (clinicUpdate) => dispatch(ClinicActions.adminUpdateClinicSettings(clinicUpdate)),
    })
) (ExcludedClientsForm);
