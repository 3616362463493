import * as ActionTypes from "constants/ActionTypes";
import * as StripeApi from "api/StripeApi";
import { handleErrorResponse } from "utils/request";
import { mapStripeSubscriptionPricesFromServerToApp } from "../data/serverMapping";

export function getClinicSubscriptions(clinicId) {
    return (dispatch) => {
        StripeApi.getClinicSubscriptions(clinicId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_SUBSCRIPTIONS_LOADED,
                    clinicId,
                    subscriptions: res.body
                });
            })
            .catch((error) => {
                handleErrorResponse("loading clinic subscriptions", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function getProducts() {
    return (dispatch) => {
        StripeApi.getProducts()
            .then((res) => {
                dispatch({
                    type: ActionTypes.GREENLINE_PRODUCTS_LOADED,
                    products: res.body
                });
            })
            .catch((error) => {
                handleErrorResponse("loading products", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function getPriceOptions(productId) {
    return (dispatch) => {
        StripeApi.getPriceOptions(productId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.PRICE_OPTIONS_LOADED,
                    productId,
                    prices: mapStripeSubscriptionPricesFromServerToApp(res.body),
                });
            })
            .catch((error) => {
                handleErrorResponse("loading product price options", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}
