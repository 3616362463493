import React, { useState } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import includes from "lodash/includes";
import lowerCase from "lodash/lowerCase";
import some from "lodash/some";
import upperCase from "lodash/upperCase";
import * as ClinicApi from "api/ClinicApi";
import Button from "components/common/Button";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import TextBox from "components/common/TextBox";
import { handleErrorResponse,handleSuccessResponse } from "utils/request";
import useFormHandlers from "utils/useFormHandlers";
import styles from "../UserCreateForm.scss";

const schema = Yup.object().shape({
	clinicId: Yup.string().required(),
	clinicAdminUserId: Yup.string().required(),
	newPassword: Yup.string()
		.min(10)
		.max(50)
		.matches(/^(?=.*[a-z])/, 'Must contain at least one lowercase character')
		.matches(/^(?=.*[A-Z])/, 'Must contain at least one uppercase character')
		.matches(/^(?=.*[0-9])/, 'Must contain at least one number')
		.matches(/^(?=.*[!@#%&])/, 'Must contain at least one special character')
		.required(),
});

export default function PasswordResetForm(props) {
    const { formData, isValid, handleUpdate, invalidFields } = useFormHandlers(
        props.initialData,
        schema,
    );
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);

	const hasDigit = some(formData.newPassword, (char) => /\d/.test(char));
    const hasLowercase = some(formData.newPassword, char => char >= 'a' && char <= 'z');
    const hasSpecialCharacter = some(formData.newPassword, char => /[^a-zA-Z0-9]/.test(char));
    const hasUppercase = some(formData.newPassword, char => char === upperCase(char) && char !== lowerCase(char));
    const tooLong = formData.newPassword?.length > 50;
    const tooShort = formData.newPassword?.length ? formData.newPassword.length < 10 : true;
    const passwordCorrect = !includes(invalidFields, "newPassword") && hasDigit && hasLowercase && hasSpecialCharacter && hasUppercase && !tooLong && !tooShort;

	if (!props.canResetPasswords) {
		return null;
	}

	const getCompleteIcon = (isComplete, required) => {
		if (isComplete) {
			return <i className="fa fa-check text-success" />
		} else {
			if (required) {
				return <i className="fa fa-times text-danger"/>
			} else return <span style={{ paddingLeft: "1.2em" }} />;
		}
	};

	const onSave = async () => {
		setErrorMessage(null)
        setLoading(true);

		try {
            await ClinicApi.resetAdminPassword(props.initialData.clinicId, props.initialData.clinicAdminUserId, formData.newPassword)
            handleSuccessResponse('Password Reset');
			props.onClose();
        }
        catch (e) {
            handleErrorResponse('Password Reset Failed', e)
            if (e.message === 'Bad Request') {
                setErrorMessage(`Password not reset. Check complexity requirements.`)
            }
        } finally {
	        setLoading(false);
        }
	}

    const handleSubmit = (e) => {
        e.stopPropagation();
		e.preventDefault();
		onSave();
    }

	if (!props.initialData.clinicAdminUserId) {
		return null;
	}

    return (
        <form onSubmit={handleSubmit}>
	        <TextBox
		        label="New Password"
		        onChange={handleUpdate}
		        inputType="password"
		        name="newPassword"
		        hasError={includes(invalidFields, "newPassword") || !passwordCorrect || !!errorMessage}
		        value={formData.newPassword}
		        autoComplete="new-password"
	        />

	        <ul className={styles.passwordRequirements}>
	            <li>{getCompleteIcon(!(tooShort || tooLong), tooShort)} Must be at least 10 Characters (up to 50)</li>
		        <li>{getCompleteIcon(hasLowercase)} at least 1 lowercase letter</li>
		        <li>{getCompleteIcon(hasUppercase)} at least 1 uppercase letter</li>
		        <li>{getCompleteIcon(hasDigit)} at least 1 digit</li>
		        <li>{getCompleteIcon(hasSpecialCharacter)} at least 1 special character (#, $, %, etc.)</li>
	        </ul>
	        <div className="flex justify-flex-end border-top">
		        <div className="flex-none">
			        <Button
				        disabled={!isValid || !passwordCorrect}
				        type="submit"
				        onClick={handleSubmit}
			        >
				        Reset Password
			        </Button>
		        </div>
	        </div>
	        <div className="flex flex-column" hidden={!errorMessage}>
		        <small style={{color: "red"}}>{errorMessage}</small>
	        </div>
	        <SpinnerTakeover show={loading} />
        </form>
    );
}

PasswordResetForm.defaultProps = {
	canResetPasswords: false,
	initialData: {},
};

PasswordResetForm.propTypes = {
	canResetPasswords: PropTypes.bool,
	initialData: PropTypes.object,
};
