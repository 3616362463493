import React, { useEffect, useState } from "react";
import {useHistory, useLocation} from "react-router-dom";
import { connect } from "react-redux";
import styles from "./ClinicGames.scss";
import filter from "lodash/filter";
import includes from "lodash/includes";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import * as ClinicActions from "actions/ClinicActions";
import AccessDenied from "components/common/AccessDenied";
import BasicGridTiles from "components/common/BasicGridTiles";
import BiProductDisclosures from "components/common/BiProductDisclosures";
import Button from "components/common/Button";
import ClinicBase from "components/layout/ClinicBase";
import GreenlineGameCard from "components/games/elements/GreenlineGameCard";
import { getNodeId } from "utils/AdminData";
import * as ClinicAdminLinks from "utils/ClinicAdminLinks";
import * as ClinicLinks from "utils/ClinicLinks";
import { getClinicGames, getClinicInfo } from "utils/ClinicData";
import getPrimaryClinicIdForUser from "utils/getPrimaryClinicIdForUser";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import { CLINIC_TAGS } from "constants/AdminControls";
import * as UserPermissions from "constants/UserPermissions";

function ClinicGames(props) {
    const history = useHistory();
    const { pathname } = useLocation();
    const isAdmin = !!includes(pathname, "/admin");
    const [gamesList, setGamesList] = useState([]);

    useEffect(() => {
        if(props.canViewClinicBIGamesDashboard) {
            props.getClinicGreenlineGames(props.clinicId);
        }
    }, [props.clinicId]);

    useEffect(() => {
        setGamesList(orderBy(filter(props.games, game => game.isCurrent === true), ["startDate"], ["desc"]));
    }, [props.games])

    const goToHistoric = () => {
        if (isAdmin) {
            history.push(ClinicAdminLinks.getLinkLocation("historic-games", props.clinicId));
        } else {
            history.push(ClinicLinks.getLinkLocation("historic-games"));
        }
    };

    const getAllCards = () => {
        return map(gamesList, game => (
            <GreenlineGameCard
                game={game}
                key={`game_${game.greenlineGameId}`}
                clinicId={props.gameClinicId}
                nodeId={props.nodeId}
            />
        ));
    }

    return (
        <ClinicBase
            pageTitle="Greenline Games™"
            clinicId={props.clinicId}
            adminControls={CLINIC_TAGS}
            overflowHidden
        >
            <div className={styles.root}>
                {props.canViewClinicBIGamesDashboard ? (
                    <>
                        <section className="flex-1 overflow-y-scroll">
                            <div className={"flex border-bottom"}>
                                <Button
                                    onClick={() => {}}
                                    disabled
                                >
                                    Current Games
                                </Button>
                                <Button onClick={goToHistoric}>
                                    Historic Games
                                </Button>
                            </div>
                            <h3>Current Games</h3>
                            {!!gamesList.length ? (
                                <BasicGridTiles tiles={getAllCards()} large />
                            ):(
                                <div>No Current Games Available</div>
                            )}
                        </section>
                        <div className="text-x-sm border-top margin-bottom-sm padding-md">
                            <BiProductDisclosures/>
                        </div>
                    </>
                ) : (
                    <AccessDenied/>
                )}
            </div>
        </ClinicBase>
    );
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const nodeId = getNodeId(state, ownProps);
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;
        const userProfile = state.user.userProfile;
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);

        return {
            clinicId,
            canViewClinicBIGamesDashboard,
            gameClinicId: Number(clinicId || getPrimaryClinicIdForUser(state.user.userProfile)),
            games: getClinicGames(state),
            nodeId,
            clinicInfo,
        }
    },
    (dispatch) => ({
        getClinicGreenlineGames: (clinicId) => dispatch(ClinicActions.getGreenlineGames(clinicId)),
    }),
)(ClinicGames);
