import React, { useEffect, useState } from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import {reject, map, orderBy, merge} from "lodash";
import TextBox from "components/common/TextBox";
import Button from "components/common/Button";
import DateInput from "components/common/DateInput";
import getLocalTimezone from "utils/getLocalTimezone";
import getDaysAgo from "utils/getDaysAgo";
import * as ClinicActions from "actions/ClinicActions";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";


function ManageClinicPriceSchedule(props) {
    const [formData, setFormData] = useState({});

    const combinedData = {
        ...props.pricingSchedules,
    };

    merge(combinedData, formData);

    const fetchData = () => {
        if(props.canViewClinicWellnessPriceSchedules) {
            props.loadPricingSchedule(props.clinicId);
        }
    }

    useEffect(() => {
        if (!props.pricingSchedule) {
            fetchData();
        }
    }, [props.clinicId]);

    const handleSave = (e) => {
        e.stopPropagation();
        e.preventDefault();

        if(props.canEditClinicWellnessPriceSchedules) {
            //Update the PriceSchedules before submitting
            const filteredData = orderBy(reject(combinedData, "isDeleted"), ["clinicPriceScheduleId"], ["asc"]);
            const newFormData = map(filteredData, (d, index) => {
                const newData = {...d}
                const nextEntry = filteredData[index + 1];
                //Set the End Dates to the day before the next startDate
                newData.endDate = nextEntry ? moment(nextEntry.startDate).subtract(1, 'day').format("MM/DD/YYYY") : d.endDate;
                //Remove the cliniPriceScheduleIds for unsaved clinicPrices
                if (d.isNew) {
                    newData.clinicPriceScheduleId = null;
                }
                return newData;
            });

            props.batchUpdateClinicPriceSchedules(props.clinicId, newFormData);
            setFormData({});
            props.onCancel();
        }
    }

    const handleChange = ({name, value}, clinicPriceScheduleId=null) => {
        if(clinicPriceScheduleId) {
            // UPDATE/DELETE
            const newFormData = {
                ...formData,
                [clinicPriceScheduleId]: {
                    ...combinedData[clinicPriceScheduleId],
                    [name]: value,
                }
            }
            setFormData(newFormData);
        } else {
            // CREATE
            const lastItem = orderBy(combinedData, ["clinicPriceScheduleId"], ["desc"])[0];
            const nextClinicPriceScheduleId = lastItem?.clinicPriceScheduleId ? lastItem.clinicPriceScheduleId + 1 : 1;
            const filteredClinicPriceSchedules = orderBy(reject(combinedData, "isDeleted"), ["clinicPriceScheduleId"], ["desc"])[0];
            const nextDay = filteredClinicPriceSchedules ? moment(filteredClinicPriceSchedules.endDate).add(1, 'day').format("MM/DD/YYYY") : new moment().format("MM/DD/YYYY");
            const nextYear = filteredClinicPriceSchedules ? moment(filteredClinicPriceSchedules.endDate).add(1, 'year').format("MM/DD/YYYY") : new moment(nextDay).add(1,'year').format("MM/DD/YYYY");

            const newFormData = {
                ...formData,
                [nextClinicPriceScheduleId]: {
                    clinicId: props.clinicId,
                    clinicPriceScheduleId: nextClinicPriceScheduleId,
                    name: "",
                    startDate: nextDay,
                    endDate: nextYear,
                    isDeleted: false,
                    isNew: true
                }
            }
            setFormData(newFormData);
        }
    };

    const filteredFormData = reject(combinedData, "isDeleted");

    const rows = map(filteredFormData, (schedule, index) => {
        const lastSchedule = filteredFormData[index - 1];
        const nextSchedule = filteredFormData[index + 1];
        const minStartDate = lastSchedule ? moment(lastSchedule.startDate).add(1, 'day').format("MM/DD/YYYY") : null;
        const maxEndDate = nextSchedule ? moment(nextSchedule.endDate).subtract(1, 'day').format("MM/DD/YYYY") : null;
        const preferredEndDate = nextSchedule ? moment(nextSchedule.startDate).subtract(1, 'day').format("MM/DD/YYYY") : null;
        const endDate = preferredEndDate ? preferredEndDate : schedule.endDate ? moment(schedule.endDate) : null;
        return(
            <tr key={schedule.clinicPriceScheduleId}>
                <td>
                    <TextBox
                        inputType="text"
                        value={schedule.name}
                        name="name"
                        onChange={(date) => handleChange(date, schedule.clinicPriceScheduleId)}
                        placeholder="Enter Name"
                        required
                        disabled={!props.canEditClinicWellnessPriceSchedules}
                    />
                </td>
                <td>
                    <DateInput
                        value={schedule.startDate ? moment(schedule.startDate) : null}
                        dateFormat={"MM/DD/YYYY"}
                        onChange={(date) => handleChange({name: "startDate", value:date.value}, schedule.clinicPriceScheduleId)}
                        placeholderText="--Select Start Date--"
                        selectsStart
                        minDate={minStartDate ? minStartDate : null}
                        maxDate={moment(schedule.endDate, "MM/DD/YYYY")}
                        showDropdowns
                        required
                        disabled={!props.canEditClinicWellnessPriceSchedules}
                    />
                </td>
                <td>
                    <DateInput
                        value={endDate}
                        dateFormat={"MM/DD/YYYY"}
                        onChange={(date) => handleChange({name: "endDate", value:date.value}, schedule.clinicPriceScheduleId)}
                        placeholderText="--Select End Date--"
                        selectsEnd
                        minDate={getLocalTimezone(schedule.startDate)}
                        maxDate={maxEndDate ? maxEndDate : null}
                        showDropdowns
                        required
                        disabled={!!nextSchedule || !props.canEditClinicWellnessPriceSchedules}
                    />
                </td>
                <td>
                    <div>{getDaysAgo(schedule.startDate, endDate)}</div>
                </td>
                <td>
                    {schedule.isNew && (
                        <Button
                            type="danger"
                            iconOnly
                            onClick={() => handleChange({name:"isDeleted", value: true}, schedule.clinicPriceScheduleId)}
                            disabled={!props.canEditClinicWellnessPriceSchedules}
                        >
                            <i className="fas fa-trash-alt"/>
                        </Button>
                    )}
                </td>
            </tr>
        );
    })

    if(!props.canViewClinicWellnessPriceSchedules) {
        return <AccessDenied/>;
    }

    return (
        <form onSubmit={handleSave}>
            <div className="text-danger text-bold text-center">The Pricing Schedule Dates Cannot Overlap</div>
            <hr className="margin-top-sm margin-bottom-md"/>
            <table className="table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Duration (days)</th>
                        <th/>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
            <div className="margin-top-x-sm">
                <Button
                    small
                    type="primary"
                    onClick={() => handleChange({name: "clinicId", value: props.clinicId})}
                    disabled={!props.canEditClinicWellnessPriceSchedules}
                >
                     <i className="text-sm fa fa-plus margin-right-x-sm"/> Add
                </Button>
            </div>
            <hr className="margin-top-md"/>
            <div className="flex justify-flex-end spaced-content margin-top-md">
                {!!props.onCancel && (
                    <Button onClick={props.onCancel} type="gray">
                        Cancel
                    </Button>
                )}
                <Button
                    large
                    wide
                    buttonType="submit"
                    disabled={!props.canEditClinicWellnessPriceSchedules}
                >
                    Save Changes
                </Button>
            </div>
            {/*<SpinnerTakeover show={loading}/>*/}
        </form>
    )
}

ManageClinicPriceSchedule.propTypes = {
    clinicId: PropTypes.number.isRequired,
    onCancel:PropTypes.func,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canViewClinicWellnessPriceSchedules = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_PRICE_SCHEDULES, userProfile);
        const canEditClinicWellnessPriceSchedules = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_PRICE_SCHEDULES, userProfile);
        return {
            canViewClinicWellnessPriceSchedules,
            canEditClinicWellnessPriceSchedules,
            pricingSchedules: state.entities.pricingSchedules[ownProps.clinicId]
        }
    },
    (dispatch) => ({
        loadPricingSchedule: (clinicId) => dispatch(ClinicActions.loadPricingSchedule(clinicId)),
        batchUpdateClinicPriceSchedules: (clinicId, data) => dispatch(ClinicActions.batchUpdateClinicPriceSchedules(clinicId, data)),
    }),
)(ManageClinicPriceSchedule);
