import isArray from 'lodash/isArray'
import map from "lodash/map"
import reject from 'lodash/reject'
import toInteger from 'lodash/toInteger'
import getApiRoot from "./getApiRoot";
import request from "utils/request";
import * as ProviderIds from "constants/ProviderIds"
import * as TimeFilters from "constants/TimeFilterIds";

export function getWellnessPlans(clinicId, status=null, providerId=null) {
    //wellnessPlanStates = ["Active", "Cancelled", "Patient Could Not Be Found "]
    return request()
        .post(`${getApiRoot()}/v1/services/wellness/clinicpetplans/search/`)
        .send({
            clinicId,
            limit: 999999,
            offset: 0,
            period: "bot",
            providerId: providerId,
            wellnessPlanStates: status,
        });
}

export function searchWellnessPlans(search, clinicId) {
    return request()
        .post(`${getApiRoot()}/v1/services/wellness/clinicpetplans/search/`)
        .send({
            clinicId,
            limit: 999999,
            offset: 0,
            period: "bot",
            search,
        });
}

export function getClinicPlanTemplates(clinicId) {
    return request()
        .get(`${getApiRoot()}/v1/services/wellness/clinics/${clinicId}/plans/${ProviderIds.GREENLINE_WELLNESS}/clinicplans/`)
        .send();
}

export function addClinicWellnessPlan(clinicId, plan, clinicPriceScheduleId=null,) {
    const payload = {
        ...plan,
        clinicPriceScheduleId,
        planServices: plan.services,
        enrollmentUpdates: {
            wellnessClinicPlanInstanceEnrollment: {
                enrollmentMappings: reject(plan.enrollmentMappings, 'isDeleted'),
                services: map(plan.services, s => {
                    return {
                        planServiceTypeId: s.planServiceTypeId,
                        enrollmentMappings: reject(s.enrollmentMappings, 'isDeleted')
                    }
                })
            }
        }
    }
    return request()
        .post(`${getApiRoot()}/v1/services/wellness/clinics/${clinicId}/plans/${plan.clinicPlanId}/`)
        .send(payload)
}

export function updateClinicWellnessPlan(clinicId, plan, startDate, houseAccountId = null) {
    const payload = {
        wellnessStartDate: startDate?.format('YYYY-MM-DD') || null,
        houseAccountClinicDvmId: houseAccountId,
        wellnessClinicPlanInstance: {
            ...plan,
            clinicPlanDto: {
                maxAgeInMonths: !!plan.maxAgeInYears ? null : toInteger(plan.maxAgeInMonths),
                maxAgeInYears: toInteger(plan.maxAgeInYears),
                maxWeightInPounds: toInteger(plan.maxWeightInPounds),
                minAgeInMonths: !!plan.minAgeInYears ? null : toInteger(plan.minAgeInMonths),
                minAgeInYears: toInteger(plan.minAgeInYears),
                minWeightInPounds: toInteger(plan.minWeightInPounds),
                alias: plan.alias,
            },
            enrollmentMappings: reject(plan.enrollmentMappings, "isDeleted"),
            services: map(plan.services, (sPayload) => {
                return {
                    ...sPayload,
                    enrollmentMappings: reject(sPayload.enrollmentMappings, "isDeleted"),
                }
            }),
        }
    }
    return request()
        .put(`${getApiRoot()}/v1/services/wellness/clinics/${clinicId}/plans/${plan.clinicPlanInstanceId}/`)
        .send(payload);
}

export function updateClinicWellnessPlanEnrollments(clinicId, clinicPlanInstanceId, enrollments) {
    return request()
        .put(`${getApiRoot()}/v1/services/wellness/clinics/${clinicId}/plans/${clinicPlanInstanceId}/enrollments/`)
        .send({
            wellnessClinicPlanInstanceEnrollment: {
                clinicPlanInstanceId: clinicPlanInstanceId,
                enrollmentMappings: enrollments,
            }
        })
}

export function getUnprocessedWellnessVisits(clinicId) {
    return request()
        .post(`${getApiRoot()}/v1/services/wellness/clinicvisits/search/`)
        .send({
            clinicId,
            period: TimeFilters.LAST_90_DAYS,
            limit: 999999,
            offset: 0,
            approvalStates: "Unprocessed",
        });
}

export function getProcessedWellnessVisits(options) {
    // Options:  period, offset, limit, clinicId, startDate, endDate
    return request()
        .post(`${getApiRoot()}/v1/services/wellness/clinicvisits/search/`)
        .send({
            period: "bot",
            limit: 9999,
            offset: 0,
            approvalStates: "Approved, Declined",
            ...options,
        });
}

export function downloadWellnessVisitsExcel(options) {
    // Options: period, clinicId, startDate, endDate, exportType, approvalStates
    return request()
        .post(`${getApiRoot()}/v1/services/wellness/clinicvisits/export/`)
        .responseType("blob")
        .send({
            period: "-90d",
            approvalStates: "Approved, Declined",
            exportType: "xlsx",
            ...options
        });
}

export function getWellnessPlanDetails(petId) {
    return request()
        .get(`${getApiRoot()}/v1/services/wellness/clinicpetplans/${petId}/`)
        .send()
}

/// This changes the DVM assigned to the visit(s)
export function updateWellnessVisits(dvmId, visits, applyToAllInvoiceLineItems = false) {
    const visitIds = isArray(visits) ? visits : [visits]; // Make sure it's an array.

    return request()
        .post(`${getApiRoot()}/v1/services/wellness/clinicvisits/changedvm/`)
        .send({
            dvmId,
            visitIds,
            applyToAllInvoiceLineItems,
        });
}

export function voidWellnessVisit(visitId) {
    return request()
        .post(`${getApiRoot()}/v1/services/wellness/clinicvisits/void/`)
        .send({
            visitId: visitId,
        });
}

export function loadWellnessReprocessing() {
    return request()
        .get(`${getApiRoot()}/v1/admin/wellness/reprocess/jobs/`)
        .send();
}

export function getClinicWellnessReprocessingData(clinicId) {
    return request()
        .get(`${getApiRoot()}/v1/admin/wellness/reprocess/${clinicId}/`)
        .send();
}

// Approve or Decline wellness visits
export function processWellnessVisits(data) {
    return request()
    .post(`${getApiRoot()}/v1/services/wellness/clinicvisits/approve/`)
    .send(data);
}

export function reprocessClinicWellnessJobs(clinicId) {
    return request()
    .post(`${getApiRoot()}/v1/admin/wellness/reprocess/${clinicId}/`)
    .send();
}

export function downloadWellnessReprocessingLog(requestId) {
    return request()
        .get(`${getApiRoot()}/v1/admin/wellness/reprocess/log/${requestId}/`)
        .responseType("blob")
        .send();
    }

export function loadPremierPetPlans(clinicId) {
    return request()
        .query({ clinicId })
        .get(`${getApiRoot()}/v1/services/wellness/clinicpetplans/premierpetplans/`)
        .send();
}

export function loadGreenlineWellnessPetPlans(clinicId) {
    return request()
        .query({ clinicId })
        .get(`${getApiRoot()}/v1/services/wellness/clinicpetplans/greenlinepetplans/`)
        .send();
}

export function updatePremierPetPlan(clinicId, petId, patientPlanEnrollmentId, externalPetPlanReference) {
    return request()
        .put(`${getApiRoot()}/v1/services/wellness/clinicpetplans/${petId}/`)
        .send({
            clinicId,
            patientPlanEnrollmentId,
            externalPetPlanReference,
        });
}

export function getPetOwners(clinicId, options={}) {
    const allOptions = {
        search: "",
        offset: 0,
        limit: 10,
        orderBy: "petOwnerFirstName",
        orderDir: "asc",
        ...options
    }

    return request()
        .post(`${getApiRoot()}/v1/services/wellness/clinics/${clinicId}/plans/petowners/search/`)
        .send(allOptions);
}

export function createWellnessCustomerSelection(clinicId, petOwner) {
    return request()
        .post(`${getApiRoot()}/v1/services/wellness/clinicpetplans/CustomerSelection/`)
        .send({
            clinicId,
            petOwner
        })
}

export function createWellnessSubscription(clinicId, customerId, data) {
    return request()
        .post(`${getApiRoot()}/v1/services/wellness/billing/subscribe/${clinicId}/${customerId}/`)
        .send(data)
}

export function cancelWellnessSubscription(clinicId, patientPlanEnrollmentId, cancelReason, initials) {
    return request()
        .delete(`${getApiRoot()}/v1/services/wellness/billing/subscriptions/${clinicId}/${patientPlanEnrollmentId}/`)
        .send({
            initials,
            cancelReason 
        })
}

export function renewSubscription(clinicId, planInstanceId) {
    return request()
        .post(`${getApiRoot()}/v1/services/wellness/billing/renew/${clinicId}/${planInstanceId}/`)
        .send()
}

export function getWellnessCards(clinicId, customerId) {
    return request()
        .query({ clinicId, customerId })
        .get(`${getApiRoot()}/v1/services/wellness/billing/cards/`)
        .send()
}

export function createWellnessPaymentOption(clinicId, tokenId, customerId) {
    return request()
        .post(`${getApiRoot()}/v1/services/wellness/billing/cards/`)
        .send({
            clinicId,
            tokenId,
            customerId
        })
}

export function deletePaymentOption(cardId, clinicId, customerId) {
    return request()
        .query({
            clinicId,
            customerId
        })
        .delete(`${getApiRoot()}/v1/services/wellness/billing/cards/${cardId}/`)
        .send()
}

export function updateDefaultCard(cardId, clinicId, customerId) {
    return request()
        .query({
            clinicId,
            customerId
        })
        .put(`${getApiRoot()}/v1/services/wellness/billing/cards/defaultcard/${cardId}/`)
        .send()
}

export function getStripeCustomerId(clinicId, petOwnerId) {
    return request()
        .query({
            clinicId,
            petOwnerId
        })
        .get(`${getApiRoot()}/v1/services/wellness/billing/customerid/`)
        .send()
}