import request, { anonymousRequest } from "utils/request";
import getApiRoot from "./getApiRoot";

export function searchUsers(data) {
    const allData = {
        clinicId: null,
        limit: null,
        orderBy: "userId",
        orderDir: "desc",
        providerId: null,
        roles: [],
        ...data,
        activeStatusFilter: data?.activeStatusFilter || data?.status || "all",
    };

    return request()
        .post(`${getApiRoot()}/v1/accounts/users/search/`)
        .send(allData);
}

// This URL is actually an admin call, server will 401 anyone else
export function updateUser(data) {
    return request()
        .put(`${getApiRoot()}/v1/accounts/users/${data.userId}/`)
        .send(data)
}

export function loadUserInfo() {
    return request()
        .get(`${getApiRoot()}/v1/userprofile/`)
        .send();
}

export function createUser(data) {
    return request()
        .post(`${getApiRoot()}/v1/accounts/users/`)
        .send(data)
}

export function updateUserSelf(data) {
    return request()
        .put(`${getApiRoot()}/v1/userprofile/`)
        .send(data)
}

export function getClinicUserRoleIds(mode = "Edit") {
    return request()
        .query({ mode })
        .get(`${getApiRoot()}/v1/accounts/userroles/`)
        .send();
}

export function healthCheck() {
    return anonymousRequest()
        .get(`${getApiRoot()}/v1/healthcheck/`)
        .send();
}

export function resendEmailConfirmation(userId) {
    return request()
        .post(`${getApiRoot()}/v1/accounts/users/${userId}/ResendEmailConfirmation/`)
        .send();
}