import React, { useEffect } from "react";
import {connect} from "react-redux";
import styles from "./WellnessHistory.scss";
import * as ClinicActions from "actions/ClinicActions";
import AccessDenied from "components/common/AccessDenied";
import ClinicBase from "components/layout/ClinicBase";
import ProcessedWellnessVisits from "components/wellness/widgets/ProcessedWellnessVisits";
import {clinicHasWellness, getClinicInfo} from "utils/ClinicData";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import {CLINIC_TAGS} from "constants/AdminControls";
import * as SearchTypes from "constants/SearchTypes";
import * as UserPermissions from "constants/UserPermissions";

function WellnessHistory(props) {
    useEffect(() => {
        props.getProviderLocations(props.clinicId);
    }, [props.clinicId]);

    return (
        <ClinicBase
            searchConfig={SearchTypes.WELLNESS_VISITS}
            clinicId={props.clinicId}
            adminControls={CLINIC_TAGS}
            docTitle="Wellness History"
        >
            <div className={styles.root}>
                {props.canViewClinicWellnessAudit && props.hasWellness ? (
                    <ProcessedWellnessVisits
                        clinicId={props.clinicId}
                        canGLWellness={props.canGLWellness}
                    />
                ) : (
                    <AccessDenied />
                )}
            </div>
        </ClinicBase>
    );
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;
        const userProfile = state.user.userProfile;
        const canViewClinicWellnessAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);
        const canGLWellness = clinicInfo?.programs?.Wellness?.providers.includes(69);
        return {
            clinicId,
            canViewClinicWellnessAudit,
            hasWellness: clinicHasWellness(state, clinicId),
            canGLWellness,
        }
    }, (dispatch) => ({
        getProviderLocations: (clinicId) => dispatch(ClinicActions.getProviderLocations(clinicId)),
    })
)(WellnessHistory);
