import React, {useMemo} from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router";
import classnames from "classnames";
import * as styles from "./ProviderClinicsProgramEnrollmentTable.scss"
import get from "lodash/get";
import find from "lodash/find";
import filter from "lodash/filter";
import groupBy from "lodash/groupBy";
import map from "lodash/map";
import Button from "components/common/Button";
import Image from "components/common/Image";
import ProgramStatusIcon from "components/provider/elements/ProgramStatusIcon";
import ProgramStatusIconLegend from "components/provider/widgets/ProgramStatusIconLegend";
import SortableDataTable from "components/common/SortableDataTable";
import { ENROLLED, SIGNATURE_PROGRAM_STATUSES } from "constants/ProgramStatuses";
import * as UserPermissions from "constants/UserPermissions";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import toast from "utils/toast";

function ProviderClinicsProgramEnrollmentTable({
    canViewClinicInfo,
    canViewCorporateGroupClinic,
    hasImages,
    isAdmin,
    nodeId,
    nodeType,
    onRedemptionsIconClicked,
    onRowClick,
    onRowHoverStart,
    onRowHoverEnd,
    onSignatureIconClicked,
    providers,
    rawData,
    resultsPerPage,
    search,
    showAllColumns = true,
    title,
}) {

    const onRowHover = (rowHover) => {
        if (rowHover) {
            onRowHoverStart();
        } else {
            onRowHoverEnd();
        }
    }

    const handleClick = (e, data) => {
        e.stopPropagation();
        e.preventDefault();
        onSignatureIconClicked(data);
    }

    const manufacturerColumns = useMemo(() => {
        if (providers && hasImages) {
            const mfrs = groupBy(rawData?.[0]?.programs || [], "providerId");
            return map(mfrs, mfr => {
                const mfrId = mfr[0].providerId;
                const mfrData = providers?.[mfrId] || {};
                return {
                    providerImage: mfrData?.smallImageUri,
                    providerId: mfrId,
                    providerName: mfrData?.name,
                    colspan: mfr.length
                }
            });
        }
        return [];
    }, [rawData, providers, hasImages]);

    const getRowProgramCount = (programsList) => {
        const enrolledPrograms = filter(programsList, program =>
            SIGNATURE_PROGRAM_STATUSES.includes(program.enrollmentStatus));
        return enrolledPrograms.length;
    }
    const getProviderImageHeader = (providerImage, providerName) => {
        return (
            <div
                style={{
                    backgroundColor: "#FFFFFF",
                    padding: 0,
                    textAlign: "center",
                    border: "2px solid #D5D5D5",
                    borderRadius: "8px",
                    margin: "0 -0.75rem",
                }}
            >
                <Image src={providerImage} alt={providerName} title={providerName}/>
            </div>
        );
    }
    const columnGroupings = useMemo(() => {
        if (!manufacturerColumns?.length) {
            return null;
        }

        const mfrGroups = map(manufacturerColumns, mfr => ({
            key: `provider_${mfr.providerId}_header`,
            title: mfr.providerName,
            colSpan: mfr.colspan,
            content: getProviderImageHeader(mfr.providerImage, mfr.providerName),
            styles: {
                backgroundColor: "#FFFFFF",
            }
        }));

        return [
            {
                key: "search_legend",
                title: "",
                colSpan: 2,
                styles: {
                    backgroundColor: "#FFFFFF"
                },
                content: <ProgramStatusIconLegend />,
                includeSearch: true,
            },
            ...mfrGroups,
        ];
    }, [manufacturerColumns, providers]);

    const LINKS = [{
        name: "Links",
        selector: "",
        key: "links",
        colSettings: { width: "40px"},
        format: row => {
            const enrolledCount = getRowProgramCount(row.programs);
            const hasEnrolled = enrolledCount > 0;

            return (
                <div className="flex">
                    <Button
                        type="success"
                        iconOnly
                        text
                        onClick={(e) => handleClick(e, row)}
                        title="Clinic Program Enrollment Forms"
                        disabled={!hasEnrolled}
                    >
                        <i className="fa-solid fa-file-signature margin-right-sm"/>
                    </Button>
                </div>
            );
        }
    }];

    const MINIMAL_COLUMNS = [
        ...LINKS,
        {
            name: "Clinic",
            selector: "clinicName",
            key: "clinicName",
            sortable: true,
            searchable: true,
            format: row => (
                <div>
                    <div className={classnames({
                        "text-primary": (canViewClinicInfo || canViewCorporateGroupClinic)
                    })}>
                        {row.clinicName}
                    </div>
                    <div style={{ fontSize: "0.7em" }}>{row.clinicCity}</div>
                </div>
            ),
        }
    ];

    const removeProviderName = (programName) => {
        return programName.replace("Merck ", "").replace("BI ", "").replace("Elanco ","");
    }

    const MANUFACTURER_COLUMNS = useMemo(() => {
        return map(rawData?.[0]?.programs || [], (progObj, index) => ({
            name: removeProviderName(progObj.program),
            selector: `programs[${index}]`,
            colSettings: { width: "140px"},
            key: `mfr_${progObj.providerId}-col_${progObj.columnOrder}`,
            sortable: true,
            sortValue: row => row.programs[index].enrollmentStatus,
            format: row => {
                const {enrollmentStatus, enrollmentStatusDate} = row.programs[index] || {};
                return enrollmentStatus ? <ProgramStatusIcon status={enrollmentStatus} statusDate={enrollmentStatusDate}/> : null;
            },
            total: true,
            rowTotal: row => {
                const { enrollmentStatus } = row.programs[index] || {};
                const isEnrolled = enrollmentStatus === ENROLLED || !!enrollmentStatus && enrollmentStatus.indexOf(ENROLLED) > -1;
                return isEnrolled ? 1 : 0;
            }
        }));
    }, [rawData]);

    const ALL_COLUMNS = [
        ...MINIMAL_COLUMNS,
        ...MANUFACTURER_COLUMNS,
    ];

    return (
        <div className={styles.root}>
            <SortableDataTable
                striped
                green
                title={title}
                resultsPerPage={resultsPerPage || 25}
                columns={ALL_COLUMNS}
                columnGroupings={columnGroupings}
                rawData={rawData}
                onClick={onRowClick}
                onHover={onRowHover}
                allowSearch
                searchAsFirstColumn
                search={search}
                showTotals
                useColgroup
                wrapLabels
            />
        </div>
    );
}

ProviderClinicsProgramEnrollmentTable.propTypes = {
    rawData: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    resultsPerPage: PropTypes.number,
    showAllColumns: PropTypes.bool,
    onSignatureIconClicked: PropTypes.func,
    providerId: PropTypes.number,
    nodeId: PropTypes.number,
    nodeType: PropTypes.string,
    isAdmin: PropTypes.bool,
    onRowClick: PropTypes.func,
    onRowHoverStart: PropTypes.func,
    onRowHoverEnd: PropTypes.func
}

const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        //Permissions
        const node = state.entities.nodes[ownProps.nodeId];
        const canViewClinicInfo = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_INFO, userProfile);
        const canViewCorporateGroupClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);
        const providers = state.entities.providers;
        const hasImages = !!providers?.[1]?.smallImageUri;

        return {
            node,
            providers,
            hasImages,
            search: state.entities.genericSearch,
            userProfile,
            //Permissions
            canViewClinicInfo,
            canViewCorporateGroupClinic,
        };
    },
);

export default compose(
    withRouter,
    connector
) (ProviderClinicsProgramEnrollmentTable);
