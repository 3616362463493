import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ProgramStatusIconLegend.scss";
import ProgramStatusIcon from "components/provider/elements/ProgramStatusIcon";
import { CANCELLED, DECLINED, ELIGIBLE, ENROLLED, INELIGIBLE } from "constants/ProgramStatuses";

function ProgramStatusIconLegend(props) {
    return (
        <div className={styles.root}>
            <div className={styles.title}>Legend</div>
            <div className={styles.iconList}>
                <ProgramStatusIcon status={ELIGIBLE} fullExplanation />
                <ProgramStatusIcon status={ENROLLED} fullExplanation />
                <ProgramStatusIcon status={DECLINED} fullExplanation />
                <ProgramStatusIcon status={CANCELLED} fullExplanation />
                <ProgramStatusIcon status={INELIGIBLE} fullExplanation />
            </div>
        </div>
    );
}


ProgramStatusIconLegend.propTypes = {};

export default ProgramStatusIconLegend;
