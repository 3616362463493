import getApiRoot, { getIsDemoApi } from "api/getApiRoot";
import request from "utils/request";

export function getProviders() {
    return request()
        .get(`${getApiRoot()}/v1/providers/`)
        .send();
}

export function getProviderDashboard() {
    return request()
        .get(`${getApiRoot()}/v1/providerdashboard/`)
        .send();
}

export function getProviderGames(userId, isDemo=false) {
    return request()
        .query({ userId })
        .get(`${getApiRoot()}/v1/services/bigames${getIsDemoApi(isDemo)}/games/`)
        .send();
}

export function getProviderClinicsProgramEnrollment(searchData) {
    return request()
        .post(`${getApiRoot()}/v1/providers/orgnodes/clinics/corporatesummary/programinfo/`)
        .send(searchData)
}

export function exportProviderClinicsProgramEnrollment(nodeId=null, type=null) {
    const exportType = type ? type : "xlsx";
    return request()
        .query({ exportType })
        .post(`${getApiRoot()}/v1/providers/orgnodes/clinics/corporatesummary/export/`)
        .responseType("blob")
        .send({
            nodeId: nodeId,
            limit: 9999,
            orderBy: "clinicName",
            orderDir: "DESC"
        });
}

export function getChildNodes(parentNodeId) {
    return request()
        .query({parentNodeId})
        .get(`${getApiRoot()}/v1/providers/orgnodes/children/`)
        .send();
}

export function getNodeClinics(nodeId=null) {
    return request()
        .post(`${getApiRoot()}/v1/providers/orgnodes/clinics/`)
        .send({
            nodeId: nodeId
        });
}

export function updateCorporateGroupLocationId(locationInfo) {
    return request()
        .put(`${getApiRoot()}/v1/providers/orgnodes/clinics/`)
        .send(locationInfo);
}

export function getClinicAggregates(nodeId=null, year=null) {
    return request()
        .query({
            nodeId,
            year,
        })
        .get(`${getApiRoot()}/v1/providerdashboard/clinicaggregates/`)
        .send();
}

export function exportProviderClinics(nodeId=null, type=null) {
    const exportType = type ? type : "xlsx";
    return request()
        .query({ exportType })
        .post(`${getApiRoot()}/v1/providers/orgnodes/clinics/export/`)
        .responseType("blob")
        .send({ nodeId });
}

export function getCorporateGroupSummary(nodeId) {
    return request()
        .post(`${getApiRoot()}/v1/providers/orgnodes/clinics/corporatesummary/`)
        .send({ nodeId });
}

export function getProviderGameClinics(userId=null, daysCurrent=30) {
    return request()
        .query({
            daysCurrent,
            userId
        })
        .get(`${getApiRoot()}/v1/services/bigames/clinics/`)
        .send();
}

export function getGameTemplates(userId) {
    return request()
        .query({ userId })
        .get(`${getApiRoot()}/v1/services/bigames/templates/`)
        .send();
}

export function getPreviousGames(userId, isDemo = false) {
    return request()
        .query({ userId })
        .get(`${getApiRoot()}/v1/services/bigames${getIsDemoApi(isDemo)}/games/previous/`)
        .send();
}

export function getGameProductBrands(userId, productCategoryId) {
    return request()
        .query({userId, productCategoryId})
        .get(`${getApiRoot()}/v1/services/bigames/brands/`)
        .send();
}

export function getGameProductBrandsForGame(userId, gameType, productCategoryId) {
    return request()
        .query({ userId, gameType, productCategoryId})
        .get(`${getApiRoot()}/v1/services/bigames/brands/productbrandsforgametype/`)
        .send();
}

export function getDemoGameProductBrands() {
    return request()
        .get(`${getApiRoot()}/v1/services/bigames/demo/games/products/`)
        .send();
}

export function getClinicRedemptions(data) {
    return request()
        .post(`${getApiRoot()}/v1/services/coupons/clinicredemptions/providersummary/`)
        .send(data);
}

export function downloadClinicRedemptions(search) {
    return request()
        .post(`${getApiRoot()}/v1/services/coupons/clinicredemptions/providersummary/export/`)
        .responseType("blob")
        .send(search);
}

export function getGameInvites(daysCurrent=30, userId=null) {
    return request()
        .query({
            daysCurrent,
            userId
        })
        .get(`${getApiRoot()}/v1/services/bigames/clinics/`)
        .send();
}

export function getDemoGameInvites() {
    return request()
        .get(`${getApiRoot()}/v1/services/bigames/demo/games/clinicgames/clinics/`)
        .send();
}

export function getClinicReviewData(userId, data, isMultiClinic = false) {
    const endPoint = `clinic${isMultiClinic ? "s": ""}`;
    return request()
        .query({
            userId
        })
        .post(`${getApiRoot()}/v1/services/bigames/reviewdata/${endPoint}/`)
        .send(data);
}

export function getOnGardReviewData(data, userId=null) {
    return request()
        .query({ userId })
        .post(`${getApiRoot()}/v1/services/bigames/reviewdata/ongard/`)
        .send(data);
}

export function getDemoReviewData() {
    return request()
        .get(`${getApiRoot()}/v1/services/bigames/demo/games/clinicDoses/`)
        .send();
}

export function getNode(nodeId = null) {
    return request()
        .query({ nodeId })
        .get(`${getApiRoot()}/v1/providers/orgnodes/`)
        .send();
}

export function getGameById(gameId, userId=null, isDemo = false) {
    return request()
        .query({ userId })
        .get(`${getApiRoot()}/v1/services/bigames${getIsDemoApi(isDemo)}/games/${gameId}/`)
        .send();
}

export function copyGameById(copyId, userId=null) {
    return request()
        .query({ userId })
        .get(`${getApiRoot()}/v1/services/bigames/games/${copyId}/copy/`)
        .send();
}

export function sendGameInvite(inviteData) {
    return request()
        .post(`${getApiRoot()}/v1/services/bigames/clinics/`)
        .send(inviteData);
}

export function getTemplateById(templateId, userId=null) {
    return request()
        .query({ userId })
        .get(`${getApiRoot()}/v1/services/bigames/templates/${templateId}/`)
        .send();
}

export function getBiGamesClinics(userId=null) {
    return request()
        .query({ userId })
        .get(`${getApiRoot()}/v1/services/bigames/clinics/optins/`)
        .send();
}

export function updateBiGamesClinicAlias(data) {
    return request()
        .put(`${getApiRoot()}/v1/services/bigames/clinics/${data.clinicId}/alias/`)
        .send(data);
}

export function getSnapshot(snapshotId, userId=null, isDemo=false) {
    return request()
        .query({ userId })
        .get(`${getApiRoot()}/v1/services/bigames${getIsDemoApi(isDemo)}/snapshots/${snapshotId}/`)
        .send();
}

export function getSnapshots(gameId, userId = null, isDemo = false) {
    return request()
        .query({ userId })
        .get(`${getApiRoot()}/v1/services/bigames${getIsDemoApi(isDemo)}/games/${gameId}/snapshots/`)
        .send();
}

export function getLast2Snapshots(gameId, userId = null, clinicId = null, isDemo=false) {
    return request()
        .query({
            clinicId,
            userId,
        })
        .get(`${getApiRoot()}/v1/services/bigames${getIsDemoApi(isDemo)}/games/${gameId}/lasttwosnapshots/`)
        .send();
}

export function saveGame(data, userId=null) {
    return request()
        .query({ userId })
        .post(`${getApiRoot()}/v1/services/bigames/games/`)
        .send(data);
}

export function updateGame(data, userId=null) {
    return request()
        .query({ userId })
        .put(`${getApiRoot()}/v1/services/bigames/games/${data.greenlineGameId}/`)
        .send(data);
}

export function deleteGame(greenlineGameId, userId=null) {
    return request()
        .query({ userId })
        .delete(`${getApiRoot()}/v1/services/bigames/games/${greenlineGameId}/`)
        .send()
}

export function exportCorporateGroupDashboard(nodeId=null, type="xlsx") {
    return request()
        .query({ exportType: type })
        .post(`${getApiRoot()}/v1/providerdashboard/clinics/export/`)
        .responseType("blob")
        .send({
            nodeId: nodeId
        })
}

export function exportClinicAggregates(manufacturerId, type="xlsx", nodeId=null, year=null) {
    return request()
        .query({
            exportType: type,
            nodeId,
            year,
        })
        .post(`${getApiRoot()}/v1/providerdashboard/clinicaggregates/export/${manufacturerId}/`)
        .responseType("blob")
        .send()
}

export function getProviderHierarchies(providerId) {
    return request()
        .get(`${getApiRoot()}/v1/providers/${providerId}/hierarchies/`)
        .send();
}

export function getReassignmentStatus(gameId) {
    return request()
        .get(`${getApiRoot()}/v1/services/bigames/games/${gameId}/reassignmentstatus/`)
        .send();
}

export function reassignGame(data) {
    return request()
        .post(`${getApiRoot()}/v1/services/bigames/games/reassignowner/`)
        .send(data);
}

export function updateCorporateGroupVisibility(data) {
    return request()
        .put(`${getApiRoot()}/v1/providers/corporategroup/visibility/`)
        .send(data);
}

export function exportProviderCheckSummary(data, type="xlsx") {
    return request()
        .query({ exportType: type })
        .post(`${getApiRoot()}/v1/providerdashboard/checktotals/export/`)
        .responseType("blob")
        .send(data)
}

export function getProviderCheckSummary(data) {
    return request()
        .post(`${getApiRoot()}/v1/providerdashboard/checktotals/`)
        .send(data);
}

export function exportNHOProviderCheckSummary(checkNumber, type="xlsx") {
    return request()
        .query({
            checkNumber,
            exportType: type
        })
        .get(`${getApiRoot()}/v1/providerdashboard/nho/checktotals/export/`)
        .responseType("blob")
        .send();
}

export function getNHOProviderCheckSummary(checkNumber) {
    return request()
        .query({ checkNumber })
        .get(`${getApiRoot()}/v1/providerdashboard/nho/checktotals/`)
        .send();
}

export function getProviderReports(providerId) {
    return request()
        .query({providerId})
        .get(`${getApiRoot()}/v1/services/reporting/providerreports/looker/embed/`)
        .send()
}

// To get fake clinic games for the provider demo view of the clinic game page
export function getClinicGameDemos() {
    return request()
        .get(`${getApiRoot()}/v1/services/bigames/demo/games/clinicgames/`)
        .send();
}

export function getCorporateGroups() {
    return request()
        .get(`${getApiRoot()}/v1/registration/clinics/corporateGroups/`)
        .send();
}
