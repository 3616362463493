import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import * as PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./Sidebar.scss";
import filter from "lodash/filter";
import includes from "lodash/includes";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import * as ClinicActions from "actions/ClinicActions";
import * as ProgramActions from "actions/ProgramActions";
import Image from "components/common/Image";
import InformationBadge from "components/common/InformationBadge";
import BI_PREMIER_LOGO_TEXT from "components/common/branding/premier_bi_logo_text.png";
import BI_PREMIER_LOGO_ICON from "components/common/branding/premier_bi_logo_small.png";
import VETCHECK_LOGO_SM from "components/common/branding/vetcheck/vetcheck_icon.png";
import ELIGIBILITY_TRACKER_IMAGE from "components/common/branding/eligibility_tracker_icon.svg";
import * as ClinicData from "utils/ClinicData";
import {
    getCouponCount,
    getNewOfferCount,
    getStrayCouponCount,
    getUnreadNotificationCount,
    getWellnessCount
} from "utils/getPending";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import { getNodeId } from "utils/AdminData";
import { getLinkIsVisible } from "utils/permissions/sidebarVisibilityRules";
import * as ProviderIds from "constants/ProviderIds";
import * as UserPermissions from "constants/UserPermissions";
import { ACTIVE, REGISTERED } from "constants/VetCheckActivationStates";
import { ELIGIBLE, ENROLLED } from "constants/ProgramStatuses";

export function SidebarLinks(props) {
    const location = useLocation();
    const pendingCoupons = getCouponCount(props.stats)
    const newOffers = getNewOfferCount(props.stats);
    const strayCoupons = getStrayCouponCount(props.stats);
    const couponStats = pendingCoupons + newOffers + strayCoupons;
    const pendingVisits = getWellnessCount(props.stats);
    const notificationStats = getUnreadNotificationCount(props.stats);
    const hasPositiveStatus = props.vetCheckStatus?.activationState && (props.vetCheckStatus.activationState === ACTIVE || props.vetCheckStatus.activationState === REGISTERED);

    useEffect(() => {
        props.getDashboardStats(props.clinicId);
        props.getClinicPrograms(props.clinicId);
    }, [props.clinicId]);

    const LINKS = useMemo(() => [{
        icon: "far fa-shield-alt",
        textImage: BI_PREMIER_LOGO_TEXT,
        iconImage: BI_PREMIER_LOGO_ICON,
        id: "bi-premier",
        location: `/premier/${ProviderIds.BOEHRINGER_INGELHEIM}`,
        text: "Premier by Boehringer Ingelheim",
    }, {
    //     icon: "far fa-shield-alt",
    //     // textImage: ELANCO_PREMIER_LOGO_TEXT,
    //     // iconImage: ELANCO_PREMIER_LOGO_ICON,
    //     id: "elanco-premier",
    //     location: `/premier/${ProviderIds.ELANCO}`,
    //     text: "Premier by Elanco",
    // }, {
        icon: "far fa-tachometer-fast",
        id: "dashboard",
        location: "/",
        text: "Dashboard",
    }, {
        icon: "far fa-bell",
        id: "notifications",
        location: "/notifications",
        text: "Notification Center",
        stats: notificationStats,
    }, {
        icon: "far fa-ticket",
        id: "coupons",
        location: "/coupons",
        text: "Coupons",
        stats: couponStats,
        subItems: [{
            id: "pending_coupons",
            location: "/coupons",
            text: "Pending Coupons",
            visible: props.canViewCouponAudit,
            stats: pendingCoupons,
            statMessage: "pending",
        }, {
            id: "coupon_history",
            location: "/coupons/history",
            text: "Coupon History",
            visible: props.canViewCouponAudit,
        }, {
            id: "coupon_library",
            location: "/coupons/library",
            text: "Coupon Library",
            visible: props.canViewCouponLibrary,
            stats: newOffers,
            statMessage: "new"
        }, {
            id: "stray_coupons",
            location: "/coupons/stray",
            text: "Stray Coupons",
            visible: props.canViewInstantRebateInvoiceFailures,
            stats: strayCoupons,
        }],
    }, {
        icon: "far fa-book",
        id: "programs",
        location: "/programs",
        text: "Programs",
        stats: filter(props.clinicPrograms, { status: ELIGIBLE })?.length,
        subItems: map(orderBy(filter(props.clinicPrograms, { status: ENROLLED }), "programName"), clinicProgram => {
            return {
                id: `program_${clinicProgram.programId}`,
                location: `/programs/${clinicProgram.programId}`,
                text: clinicProgram.programName,
                visible: true,
                // TODO: get the number
                // stats: filter(clinicProgram, { status: ELIGIBLE })?.length,
            }
        }),
    }, {
        icon: "far fa-paw-alt",
        id: "wellness",
        location: "/wellness",
        text: "Pet Wellness Plans",
        stats: pendingVisits,
        subItems: [{
            id: "wellness_audit",
            location: "/wellness/audit",
            text: "Wellness Audit",
            visible: props.canViewWellnessAudit,
            stats: pendingVisits,
            statMessage: "pending",
        }, {
            id: "wellness_history",
            location: "/wellness/history",
            text: "Wellness History",
            visible: props.canViewWellnessAudit,
        }, {
            id: "pet_plan_tracking",
            location: "/wellness/patients",
            text: "Pet Plan Tracking",
            visible: props.canViewWellnessPetPlans,
        }, {
            id: "dvm_commission_setup",
            location: "/wellness/commission/dvm",
            text: "DVM Commission Setup",
            visible: props.canViewWellnessDvms,
        }, {
            id: "plan_setup",
            location: "/wellness/plans",
            text: "Plan Setup",
            visible: props.canViewClinicWellnessPlans,
        }],
    }, {
        icon: "far fa-trophy-alt",
        id: "games",
        location: "/games",
        text: "Greenline Games",
    }, {
        iconImage: VETCHECK_LOGO_SM,
        icon: "far fa-notes-medical",
        id: "vetcheck",
        location: "/vetcheck",
        text: "VetCheck",
        subItems: [{
        //     id: "send_history",
        //     location: "/vetcheck/send-history",
        //     text: "Send History",
        //     visible: props.hasVetCheck && hasPositiveStatus && props.canViewClinicVetCheck,
        // }, {
            id: "handouts_sent",
            location: "/vetcheck/handouts-sent",
            text: "Handouts Sent",
            visible: props.hasVetCheck && hasPositiveStatus && props.canViewClinicVetCheck,
        }, {
            id: "forms_sent",
            location: "/vetcheck/forms-sent",
            text: "Client Forms Sent",
            visible: props.hasVetCheck && hasPositiveStatus && props.canViewClinicVetCheck,
        // }, {
        //     id: "charts_sent",
        //     location: "/vetcheck/charts-sent",
        //     text: "Charts Sent",
        //     visible: props.hasVetCheck && hasPositiveStatus && props.canViewClinicVetCheck,
        }, {
            id: "opt_outs",
            location: "/vetcheck/opt-out",
            text: "Pet Owner Opt-Outs",
            visible: props.hasVetCheck && hasPositiveStatus && props.canViewClinicVetCheck,
        }, {
            id: "manage_automations",
            location: "/vetcheck/manage-automations",
            text: "Manage Automations",
            visible: props.hasVetCheck && hasPositiveStatus && props.canViewClinicVetCheck && props.canViewVetCheckMapping,
        }, {
            id: "vetcheck_dashboard",
            location: "/vetcheck/dashboard",
            text: "VetCheck Dashboard",
            visible: props.hasVetCheck && hasPositiveStatus && props.canViewClinicVetCheck && !!props.vetCheckStatus?.authCode,
        }],
    }, {
        icon: "far fa-chart-line",
        id: "reports",
        location: "/reports",
        text: "Reports",
    // }, {
    //     icon: "fas fa-box-check",
    //     id: "product",
    //     location: "/product-mapping",
    //     text: "Product Mapping",
    }, {
        icon: "far fa-books",
        id: "resources",
        location: "/resources",
        text: "Resources",
    }], [props.userProfile, props.clinicInfo, props.stats, props.clinicPrograms]);

    const getExactMatch = (link) => {
        const { pathname } = location;

        let relativePath = pathname;
        if (props.clinicId && pathname.indexOf(props.clinicId) > -1) {
            if (pathname.indexOf("/admin/") > -1 || pathname.indexOf("/provider/") > -1) {
                relativePath = pathname.split(props.clinicId)[1];
            }
        }

        const pathParts = relativePath.substr(1).split("/");
        const exact = relativePath === link;
        const partial = pathParts[0].indexOf(link.substr(1)) > -1;

        return {
            partial,
            exact
        }
    };

    const getBadgeInfo = (link) => {
        if (!link.stats) {
            return null;
        }
        return (
            <InformationBadge
                data-testid={`${link.id}_status`}
                message={`${link.stats} ${link.statMessage || ""}`}
            />
        );
    }

    const getDefaultLocation = (link) => {
        const visibleSubLinks = filter(link?.subItems, { visible: true });
        if (link.id === "wellness" && props.clinicHasGreenlineWellness) {
            // This is for the Greenline Wellness Pet Plan page
            return link.location;
        } else if (link.id === "vetcheck" || link.id === "programs") {
            // This is for if the default location is not a sublink
            return link.location;
        } else if (visibleSubLinks?.length) {
            return visibleSubLinks[0].location;
        } else {
            return link.location;
        }
    }

    const subLinks = ( link ) => {
        return map(filter(link.subItems, { visible: true }), (item, index) => {
            let {exact: subExact} = getExactMatch(item.location);
            if (item.disabled) {
                return (
                    <div
                        data-testid={`sidebar_links_${item.id}`}
                        key={`navItem_${link.id}_sub_${index}`}
                        className={classnames(styles.navItem, styles.disabled, {
                            [styles.selected]: subExact,
                            "test-selected": subExact,
                        })}
                    >
                        <span className={styles.icon}><i className="fas fa-circle"/></span>
                        <span className={styles.text}>{item.text}</span>
                        {getBadgeInfo(item)}
                    </div>
                )
            }
            return (
                <NavLink
                    data-testid={`sidebar_links_${item.id}`}
                    key={`navItem_${link.id}_sub_${index}`}
                    className={classnames(styles.navItem, {
                        [styles.selected]: subExact,
                        "test-selected": subExact,
                    })}
                    to={`${props.basePath}${item.location}`}
                >
                    <span className={styles.icon}><i className="fas fa-circle"/></span>
                    <span className={styles.text}>{item.text}</span>
                    {getBadgeInfo(item)}
                </NavLink>
            );
        });
    }

    const navLink = (link) => {
        const defaultLocation = getDefaultLocation(link);
        const { partial, exact} = getExactMatch(link.location);
        let selected = exact;
        let showNavBadge = true;
        let showSubs = false;

        if (link.subItems?.length) {
            showSubs = partial || exact;
            showNavBadge = !showSubs;
            selected = partial || exact;
        }

        const item = (
            <>
                {link.iconImage ? (
                    <span className={classnames(styles.icon, {
                        [styles.vetCheckIcon]: (link.id === "vetcheck")
                    })}>
                        <Image style={{maxHeight: "30px", maxWidth: "100%"}} src={link.iconImage} title={link.text} alt={link.text}/>
                    </span>
                ) : (
                    <span className={styles.icon}><i className={link.icon}/></span>
                )}
                {link.textImage ? (
                    <span className={styles.text}>
                        <Image style={{maxHeight: "30px", maxWidth: "100%"}} src={link.textImage} title={link.text}/>
                    </span>
                ) : (
                    <span className={styles.text}>{link.text}</span>
                )}
                {showNavBadge && getBadgeInfo(link)}
            </>
        );

        return (
            <div
                key={`nav_item_${link.id}`}
                className={styles.subNavList}
            >
                {link.disabled ? (
                    <div
                        data-testid={`sidebar_links_${link.id}`}
                        className={classnames(styles.navItem, styles.disabled, {
                            [styles.selected]: selected,
                            "test-selected": selected,
                        })}
                    >
                        {item}
                    </div>
                ) : (
                    <NavLink
                        data-testid={`sidebar_links_${link.id}`}
                        to={`${props.basePath}${defaultLocation}`}
                        className={classnames(styles.navItem, {
                            [styles.selected]: selected,
                            "test-selected": selected,
                        })}
                    >
                        {item}
                    </NavLink>
                )}
                {showSubs && (
                    <div className={styles.subLinks}>
                        {subLinks(link)}
                    </div>
                )}
            </div>
        );
    }

    const renderNavItems = () => {
        return map(LINKS, link => {
            if (!getLinkIsVisible(link, props, "clinic")) {
                // skip this one...
                return;
            }

            return navLink(link);
        });
    }

    return (
        <div
            data-testid="sidebar_links_component"
            className={styles.navItems}>
            {renderNavItems()}
        </div>
    );
}

SidebarLinks.propTypes = {
    getDashboardStats: PropTypes.func.isRequired,
    clinicId: PropTypes.number.isRequired,
    basePath: PropTypes.string.isRequired,
    userProfile: PropTypes.object,
    clinicInfo: PropTypes.object,
    nodeId: PropTypes.number,
    userClinics: PropTypes.arrayOf(PropTypes.object),
    hasGames: PropTypes.bool,
    hasVetCheck: PropTypes.bool,
    hasWellness: PropTypes.bool,
    clinicHasGreenlineWellness: PropTypes.bool,
    clinicHasPremierWellness: PropTypes.bool,
    hasCoupons: PropTypes.bool,
    stats: PropTypes.object,
    user: PropTypes.object,
    vetCheckStatus: PropTypes.object,
    canViewClinicBIGamesDashboard: PropTypes.bool,
    canViewNotifications: PropTypes.bool,
    canViewCouponAudit: PropTypes.bool,
    canViewCouponLibrary: PropTypes.bool,
    canViewInstantRebateInvoiceFailures: PropTypes.bool,
    canViewWellnessAudit: PropTypes.bool,
    canViewWellnessPetPlans: PropTypes.bool,
    canViewWellnessDvms: PropTypes.bool,
    canViewClinicWellnessPlans: PropTypes.bool,
    canViewClinicVetCheck: PropTypes.bool,
    canViewVetCheckMapping: PropTypes.bool,
    clinicHasBIPrioritySupport: PropTypes.bool,
    clinicHasElancoPrioritySupport: PropTypes.bool,
    canViewProgramEnrollmentClinic: PropTypes.bool,
    canViewProviderBiGamesClinicInvites: PropTypes.bool,
    canViewProviderBiGamesDrilldown: PropTypes.bool,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const clinicInfo = ClinicData.getClinicInfo(state, ownProps.clinicId);
        const nodeId = getNodeId(state, ownProps);
        //Permissions
        const canViewNotifications = userHasPermission(PermissionTypes.VIEW, UserPermissions.NOTIFICATIONS, userProfile);
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
        const canViewCouponAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_AUDIT, userProfile);
        const canViewCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        const canViewInstantRebateInvoiceFailures = userHasPermission(PermissionTypes.VIEW, UserPermissions.INSTANT_REBATE_INVOICE_FAILURES, userProfile);
        const canViewWellnessAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);
        const canViewWellnessPetPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PET_PLANS, userProfile);
        const canViewWellnessDvms = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        const canViewClinicWellnessPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);
        const canViewClinicVetCheck = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_VETCHECK, userProfile);
        const canViewCorporateGroupClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);
        const clinicHasBIPrioritySupport = !!includes(clinicInfo?.prioritySupport, ProviderIds.BOEHRINGER_INGELHEIM);
        const clinicHasElancoPrioritySupport = !!includes(clinicInfo?.prioritySupport, ProviderIds.ELANCO);
        const canViewVetCheckMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.VETCHECK_MAPPING, userProfile);
        const canViewProviderBiGamesClinicInvites = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES_CLINIC_INVITES, userProfile);
        const canViewProviderBiGamesDrilldown = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES_DRILLDOWN, userProfile);
        const canViewProgramEnrollmentClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROGRAM_ENROLLMENT_CLINIC, userProfile);

        return {
            userProfile,
            clinicInfo,
            nodeId,
            userClinics: userProfile.clinics,
            unreadNotifications: ClinicData.getUnreadNotificationCount(state, ownProps.clinicId),
            hasGames: ClinicData.clinicHasGames(state, ownProps.clinicId),
            hasVetCheck: ClinicData.clinicHasVetCheck(state, ownProps.clinicId),
            hasWellness: ClinicData.clinicHasWellness(state, ownProps.clinicId),
            clinicHasGreenlineWellness: ClinicData.clinicHasGreenlineWellness(state, ownProps.clinicId),
            clinicHasPremierWellness: ClinicData.clinicHasPremierWellness(state, ownProps.clinicId),
            clinicPrograms: state.entities.clinicPrograms?.[ownProps.clinicId],
            hasCoupons: ClinicData.clinicHasCoupons(state, ownProps.clinicId),
            stats: state.entities.stats,
            user: state.user,
            vetCheckStatus: state.entities.clinicVetCheckStatus[ownProps.clinicId],

            //Permissions
            canViewClinicBIGamesDashboard,
            canViewNotifications,
            canViewCouponAudit: canViewCouponAudit || canViewCorporateGroupClinic,
            canViewCouponLibrary,
            canViewInstantRebateInvoiceFailures,
            canViewWellnessAudit,
            canViewWellnessPetPlans,
            canViewWellnessDvms,
            canViewClinicWellnessPlans,
            canViewClinicVetCheck,
            canViewVetCheckMapping,
            clinicHasBIPrioritySupport,
            clinicHasElancoPrioritySupport,
            canViewProgramEnrollmentClinic,
            canViewProviderBiGamesClinicInvites,
            canViewProviderBiGamesDrilldown,
        };
    },
    (dispatch) => ({
        getClinicPrograms: (clinicId) => dispatch(ProgramActions.loadClinicPrograms(clinicId)),
        getDashboardStats: (clinicId) => dispatch(ClinicActions.getClinicDashboardStats(clinicId)),
    })
)(SidebarLinks);
