import React, { useState } from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import Button from "components/common/Button";
import ExcludedClientsForm from "./ExcludedClientsForm";
import LabelValueRow from "components/common/LabelValueRow";
import Modal from "components/common/Modal";
import Tooltip from "components/common/Tooltip";
import { RIGHT } from "constants/TooltipPositionIds";

export default function ExcludedClientsPanel(props) {
	const [showEdit, setShowEdit] = useState(false);

	const handleCloseEditForm = () => {
		setShowEdit(false);
	}

    return (
		<div className="card">
			<div className="flex align-center spaced-content">
				<h4 className="flex-1">
					<Tooltip
						position={RIGHT}
						tip="Changes to these fields could take a minute to propagate"
					>
						<i className="fa fa-info-circle text-dark-gray" />
					</Tooltip> Excluded Client IDs
				</h4>
				<Tooltip
					position="left"
					tip="Edit Excluded Client IDs"
				>
					<div className="flex-none">
						<Button
							iconOnly
							onClick={() => setShowEdit(true)}
							type="primary"
						>
							<i className="fa fa-pencil" />
						</Button>
					</div>
				</Tooltip>
			</div>
			<LabelValueRow
				label="Excluded from Coupons"
				value={map(props.clinic.excludedCouponClientIdList, (id, index) => (
					<span key={`coupons_${index}`}>{index > 0 && ", "}{id}</span>
				))}
			/>
			<LabelValueRow
				label="Excluded from Emailing"
				value={map(props.clinic.excludedEmailClientIdList, (id, index) => (
					<span key={`email_${index}`}>{index > 0 && ", "}{id}</span>
				))}
			/>
			<LabelValueRow
				label="Excluded from Reporting"
				value={map(props.clinic.excludedReportingClientIdList, (id, index) => (
					<span key={`reporting_${index}`}>{index > 0 && ", "}{id}</span>
				))}
			/>
			<Modal
				modalTitle="Edit Excluded Client IDs"
				show={showEdit}
				onClose={handleCloseEditForm}
				small
			>
				<ExcludedClientsForm
					clinicId={props.clinicId}
					initialData={props.clinic}
					onClose={handleCloseEditForm}
				/>
			</Modal>
		</div>
    );
}

ExcludedClientsPanel.defaultProps = {
    clinic: {},
};

ExcludedClientsPanel.propTypes = {
    clinic: PropTypes.object,
    clinicId: PropTypes.number.isRequired,
};
