import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import classnames from "classnames";
import moment from "moment";
import get from "lodash/get";
import includes from "lodash/includes";
import keys from "lodash/keys";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import * as styles from "./ProgramLandingPage.scss";
import * as ProgramActions from "actions/ProgramActions";
import AccessDeniedPage from "components/clinic/pages/AccessDeniedPage";
import Accordion from "components/common/Accordion";
import AccordionSection from "components/common/AccordionSection";
import Button from "components/common/Button";
import ClinicBase from "components/layout/ClinicBase";
import Image from "components/common/Image";
import ProgramOptInDetailsModal from "components/clinic/elements/ProgramOptInDetailsModal";
import ProgramTile from "components/programs/ProgramTile";
import ProgramWalkThrough from "components/programs/ProgramWalkThrough";
import { CANCELLED, DECLINED, ELIGIBLE, ENROLLED, INELIGIBLE } from "constants/ProgramStatuses";
import * as UserPermissions from "constants/UserPermissions";
import { getWalkthroughId } from "constants/ProgramConstants";
import { getClinicInfo } from "utils/ClinicData";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import SpinnerTakeover from "../common/SpinnerTakeover";

function ProgramLandingPage(props) {
    const params = useParams();
	const {
		canEditProgramEnrollmentClinic = false,
		canViewProgramEnrollmentClinic = false,
		clinicId = Number(params.clinicId),
		getClinicProgramDetails,
		getClinicProgramServices,
		getClinicPrograms,
		program,
		programDetails,
		programServices,
		walkThruId,
	} = props;


	const history = useHistory();
	const { pathname } = useLocation();

	const nodeId = Number(params.nodeId);
    const programId  = Number(get(params, "programId", null)) || null;
    const isAdmin = clinicId && includes(pathname, "/admin");
    const isProvider = nodeId && includes(pathname, "/provider");
    // If is an admin then append some string to beginning of URL
	const linkBase = isAdmin ? `/admin/clinic/${clinicId}` : isProvider ? `/provider/location/${nodeId}/clinic/${clinicId}` : '';
    const [showManageEnrollment, setShowManageEnrollment] = useState(false);
    const [showManageServiceEnrollment, setShowManageServiceEnrollment] = useState(false);
    const [emailSectionOpen, setEmailSectionOpen] = useState(false);
	const ELIGIBILITY_TRACKER_PROGRAM = 7;
	const ELIGIBILITY_TRACKER_SERVICE = 10;
	const eligibilityTracker = programServices?.[ELIGIBILITY_TRACKER_SERVICE];
	const eligibilityTrackerEnrolled = eligibilityTracker?.status === ENROLLED;

    useEffect(() => {
		if (canViewProgramEnrollmentClinic) {
			getClinicPrograms(clinicId);
		}
    }, [clinicId]);

	useEffect(() => {
		if (program?.programId === ELIGIBILITY_TRACKER_PROGRAM) {
			if (eligibilityTrackerEnrolled) {
				history.push(`${linkBase}/programs/eligibility-tracker`);
			}
		}
	}, [program, programServices]);

	useEffect(() => {
		if (canViewProgramEnrollmentClinic) {
			getClinicProgramDetails(clinicId, programId);
			getClinicProgramServices(clinicId, programId);
		}
    }, [clinicId]);

	if (!canViewProgramEnrollmentClinic) {
		return <AccessDeniedPage />;
	}

	const handleIframeLoaded = () => {
		const iFrameID = document.getElementById(`program_iframe_${programDetails.programId}`);
        if (iFrameID) {
            // here you can make the height, I delete it first, then I make it again
            iFrameID.height = "";
            iFrameID.height = iFrameID.contentWindow.document.body.scrollHeight + 40 +"px";
        }
	};

	const handleOpen = () => {
		setEmailSectionOpen(!emailSectionOpen);
	}


	const renderProgramContent = () => {
		if (walkThruId) {
			const service = programServices?.[ELIGIBILITY_TRACKER_SERVICE];
			if (service) {
				return (
					<>
						<ProgramWalkThrough walkThruId={walkThruId}/>
						{canEditProgramEnrollmentClinic && (
							<div className="flex flex-centered padding-top-md">
								<Button
									onClick={() => setShowManageServiceEnrollment(true)}
									disabled={(program.status === INELIGIBLE && !program.acceptedTerms)}
								>
									Manage Enrollment
								</Button>
							</div>
						)}
						<ProgramOptInDetailsModal
							key={`Program_service_${clinicId}_${programId}_${service.programServiceId}`}
							clinicId={clinicId}
							onClose={() => setShowManageServiceEnrollment(false)}
							parentProgram={programId}
							program={service}
							programId={service.programServiceId}
							show={showManageServiceEnrollment}
							linkBase={linkBase}
						/>
					</>
				);
			} else return <SpinnerTakeover />;
		} else if (!!programDetails) {
			return (
				<>
					<h2 className="text-center">{programDetails?.title}</h2>
					<div className={styles.status}>{program?.status}</div>
					{/*<div dangerouslySetInnerHTML={{ __html: programDetails?.body }}/>*/}
					{!!programDetails.body && (
						<iframe
							id={`program_iframe_${programDetails.programId}`}
							style={{
								width: "100%",
								flex: "none",
								border: "none",

							}}
							scrolling="no"
							srcDoc={programDetails.body}
							onLoad={handleIframeLoaded}
						/>
					)}
					{programDetails.expandableContent && (
						<Accordion>
							<AccordionSection
								id="email_example"
								onClick={handleOpen}
								header={programDetails.expandableContentName ? (
									<div dangerouslySetInnerHTML={{__html: programDetails.expandableContentName}}/>
								) : ""}
							>
								<div className="flex">
									<div className={styles.emailExample}>
										{programDetails.expandableContentHeader && (
											<div
												className="border-bottom"
												dangerouslySetInnerHTML={{__html: programDetails.expandableContentHeader}}
											/>
										)}
										<div className="flex flex-centered">
											<iframe
												id={`program_expandable_iframe_${programDetails.programId}`}
												style={{
													width: "100%",
													flex: "none",
													border: "none",
												}}
												scrolling="no"
												srcDoc={programDetails.expandableContent}
												onLoad={(e) => handleIframeLoaded(e, `program_expandable_iframe_${programDetails.programId}`)}
											/>
										</div>
									</div>
								</div>
							</AccordionSection>
						</Accordion>
					)}
					<div className="text-center">Activated
						on {moment(program.userActionDate).format("MM/DD/YYYY")} by {program.signature}</div>
					{canEditProgramEnrollmentClinic && (
						<div className="flex flex-centered padding-top-md">
							<Button
								onClick={() => setShowManageEnrollment(true)}
								disabled={(program.status === INELIGIBLE && !program.acceptedTerms)}
							>
								Manage Enrollment
							</Button>
						</div>
					)}
				</>
			);
		}

		return null;
	}

	// This is to prevent the live chat button from loading IF the page is going to redirect
	if (programId === ELIGIBILITY_TRACKER_PROGRAM && eligibilityTrackerEnrolled) {
		return <SpinnerTakeover />;
	}

    return (
        <ClinicBase
            collapsible
            clinicId={clinicId}
            docTitle={program.programName || "Program"}
            pageTitle={(
                <div className="flex align-center spaced-content">
                    <div style={{ display: "flex" }}>
	                    <Image
                            src={program.programImage}
                            title={program.programName}
                            alt={program.programName}
                            style={{
                                width: "40px",
                            }}
                        />
                    </div>
                    <div>{program.programName}</div>
                </div>
            )}
        >
            <div className={classnames(styles.root, {
                "flex spaced-content": programDetails?.body,
            })}>
                <div
	                className={classnames("flex flex-direction-column", {
						"spaced-content": !programDetails,
						"spaced-content-vertical": programDetails,
	                })}
	                style={{ maxWidth: programDetails ? "216px" : "100%"}}
                >
					<div className="padding-top-md padding-bottom-md">
						<Button
							icon
							onClick={() => history.push(`/admin/clinic/${clinicId}/programs`)}
						>
							<i className="fa fa-arrow-left fa-fw" /> Programs
						</Button>
					</div>
                    {map(orderBy(programServices, [p => {
                        if (p.status === ELIGIBLE) return 0;
                        else if (p.status === ENROLLED) return 1;
                        else if (p.status === DECLINED) return 2;
                        else if (p.status === CANCELLED) return 3;
						else if (p.status === INELIGIBLE) return 4;
                        else return 5;
                    }, "programServiceName"]), service => (
                        <ProgramTile
							id={`program_id_${service.programId}_tile`}
                            key={service.programServiceId}
                            canEditProgramEnrollmentClinic={canEditProgramEnrollmentClinic}
                            clinicId={clinicId}
                            imageStyle={{
	                            display: "flex",
	                            margin: "auto",
								maxWidth: "100%",
	                            width: "auto",
							}}
                            parentProgram={programId}
                            style={{ width: "216px" }}
                            tile={{
                                ...service,
                                id: service.programServiceId,
                                image: service.programServiceImage,
                                name: service.programServiceName,
                                startDate: service.programServiceStartDate,
                                endDate: service.programServiceEndDate,
                            }}
                        />
                    ))}
                </div>
				<div className={classnames(styles.programDetails, {
					[styles.showBorder]: keys(programServices)?.length,
				})}>
					{renderProgramContent()}
				</div>
            </div>
            <ProgramOptInDetailsModal
                key={`Program_service_${clinicId}_${programId}`}
                clinicId={clinicId}
                onClose={() => setShowManageEnrollment(false)}
                program={program}
                programId={programId}
                show={showManageEnrollment}
				linkBase={linkBase}
            />
        </ClinicBase>
    )
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        let programId = get(ownProps.match.params, "programId", null);
        programId = programId ? Number(programId) : null;
		const walkThruId = getWalkthroughId(programId);
		const clinic = getClinicInfo(state, adminClinicId);
        const clinicId = clinic ? clinic.clinicId : undefined;
        const clinicPrograms = get(state.entities.clinicPrograms, clinicId, {});
        const clinicProgramDetails = get(state.entities.clinicProgramDetails, clinicId, {});
		const programDetails = get(clinicProgramDetails, programId, null);
		const programs = get(state.entities.clinicProgramServices, clinicId, {});
		const userProfile = state.user.userProfile;
        const canEditProgramEnrollmentClinic = userHasPermission(PermissionTypes.EDIT, UserPermissions.PROGRAM_ENROLLMENT_CLINIC, userProfile);
        const canViewProgramEnrollmentClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROGRAM_ENROLLMENT_CLINIC, userProfile);
        return {
			clinic,
            clinicId,
			walkThruId,
			programDetails,
            program: get(clinicPrograms, programId, {}),
	        programServices: get(programs, programId, {}),
	        canEditProgramEnrollmentClinic,
	        canViewProgramEnrollmentClinic,
        };
    },
	(dispatch) => ({
		getClinicPrograms: (clinicId) => dispatch(ProgramActions.loadClinicPrograms(clinicId)),
		getClinicProgramDetails: (clinicId, programId) => dispatch(ProgramActions.loadClinicProgramDetails(clinicId, programId)),
		getClinicProgramServices: (clinicId, programId) => dispatch(ProgramActions.loadClinicProgramServices(clinicId, programId)),
	})
)(ProgramLandingPage);
