import React from "react"
import PropTypes from "prop-types";
import {autobind} from "core-decorators";
import classnames from "classnames";
import styles from "./SortableDataTable.scss";
import map from "lodash/map";
import Button from "components/common/Button";
import DataTable from "components/common/datatable/DataTable";

class SortableDataTable extends React.Component {

    state = {
        showDownloadOptions: false,
    };

    shouldShowDownloadOptions() {
        return !!this.props.downloadExcel && !!this.props.downloadPDF;
    }

    @autobind
    toggleShowDownloadOptions(e) {
        if (this.shouldShowDownloadOptions()) {
            e.stopPropagation();
            if (!this.state.showDownloadOptions) {
                window.addEventListener("click", this.toggleShowDownloadOptions);
            } else {
                window.removeEventListener("click", this.toggleShowDownloadOptions);
            }
            this.setState({ showDownloadOptions: !this.state.showDownloadOptions });
        }
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.toggleShowDownloadOptions);
    }

    @autobind
    generateData() {
        if (Array.isArray(this.props.rawData)) {
            return this.props.rawData;
        }

        // Making sure it's an array by mapping it.
        return map(this.props.rawData, item => item);
    }

    @autobind
    handleRowClick(rowData) {
        if (this.props.onClick) {
            this.props.onClick(rowData);
        }
    }

    @autobind
    handleRowHoverStart(rowData) {
        if (this.props.onHoverStart) {
            this.props.onHoverStart(rowData);
        }
    }

    @autobind
    handleRowHoverEnd(rowData) {
        if (this.props.onHoverEnd) {
            this.props.onHoverEnd(rowData);
        }
    }

    @autobind
    handleRightClick(rowData) {
        if (this.props.onRightClick) {
            this.props.onRightClick(rowData);
        }
    }

    @autobind
    handleDownloadClicked() {
        const showOptions = this.shouldShowDownloadOptions();
        this.setState({ showDownloadOptions: showOptions });

        if (!showOptions) {
            if (this.props.downloadPDF) {
                this.props.downloadPDF();
            } else if (this.props.downloadExcel) {
                this.props.downloadExcel();
            }
        }
    }

    render() {
        if (!this.props.rawData) {
            return (
                <div
                    data-testid="sortable_data_table_no_results"
                    className={styles.root}
                >
                    <span className={styles.noData}>No Results</span>
                </div>
            );
        }

        const { columns, columnGroupings, expandedData } = this.props;
        const data = this.generateData();
        const canDownloadPdf = !!this.props.downloadPDF;
        const canDownloadExcel = !!this.props.downloadExcel;
        const needsVerticalMargin = !!this.props.header || canDownloadPdf || canDownloadExcel;

        return (
            <div
                data-testid="sortable_data_table_root"
                className={styles.root}
            >
                <div className={classnames("flex-none full-width flex", [{ "margin-top-sm": needsVerticalMargin }, { "margin-bottom-sm": needsVerticalMargin }])}>
                    {this.props.header &&
                        <div className="flex-none">
                            {this.props.header}
                        </div>
                    }
                    {(canDownloadPdf || canDownloadExcel) && (
                        <div
                            className={classnames(styles.download, "flex-none")}
                            onClick={this.toggleShowDownloadOptions}
                        >
                            <Button
                                data-testid="sortable_data_table_download_button"
                                className="margin-right-sm"
                                onClick={this.handleDownloadClicked}
                                icon
                                small
                            >
                                <i className="fas fa-download" />
                                Download
                            </Button>
                            {!!this.state.showDownloadOptions && (
                                <div className={styles.options}>
                                    {canDownloadPdf && (
                                        <div
                                            className={styles.option}
                                            onClick={this.props.downloadPDF}
                                        >
                                            Download PDF
                                        </div>
                                    )}
                                    {canDownloadExcel && (
                                        <div
                                            className={styles.option}
                                            onClick={this.props.downloadExcel}
                                        >
                                            Download Excel Sheet
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <DataTable
                    title={this.props.title}
                    columns={columns}
                    columnGroupings={columnGroupings}
                    expandedData={expandedData}
                    data={data}
                    pagination={!this.props.noPagination}
                    minimalPagination={this.props.minimalPagination}
                    striped={this.props.striped}
                    green={this.props.green}
                    paginationPerPage={this.props.resultsPerPage ? this.props.resultsPerPage : 25}
                    onRowClicked={this.props.onClick ? this.handleRowClick : null}
                    onRowHoverStart={this.handleRowHoverStart}
                    onRowHoverEnd={this.handleRowHoverEnd}
                    onRightClick={this.props.onRightClick ? this.handleRightClick : null}
                    onRowSelectChange={this.props.onRowSelectChange}
                    highlightOnHover
                    selectionDisabledIcon={this.props.selectionDisabledIcon}
                    //customStyles={customStyles}
                    selectableRows={this.props.selectable}
                    selectedRowIds={this.props.selectedRowIds}
                    getRowIsSelectable={row => !row?.requiresValidation}
                    getRowIsDisabled={this.props.getRowIsDisabled}
                    conditionalRowProps={this.props.conditionalRowProps}
                    allowSearch={this.props.allowSearch}
                    searchAsFirstColumn={this.props.searchAsFirstColumn}
                    hideSearchBar={this.props.hideSearchBar}
                    onCreateClick={this.props.onCreateClick}
                    search={this.props.search}
                    changePagination={this.props.changePagination}
                    onSort={this.props.onSort}
                    lessPadding={this.props.lessPadding}
                    showTotals={this.props.showTotals}
                    forceHover={this.props.forceHover}
                    scrollRows
                    useColgroup={this.props.useColgroup}
                    noResultsText={this.props.noResultsText}
                    wrapLabels={this.props.wrapLabels}
                />
            </div>
        );
    }
}

SortableDataTable.propTypes = {
    title: PropTypes.string,
    columns: PropTypes.array,
    columnGroupings: PropTypes.array,
    expandedData: PropTypes.object,
    rawData: PropTypes.any,
    onClick: PropTypes.func,
    onHoverStart: PropTypes.func,
    onHoverEnd: PropTypes.func,
    onRightClick: PropTypes.func,
    onRowSelectChange: PropTypes.func,
    conditionalRowProps: PropTypes.func,
    selectable: PropTypes.bool,
    striped: PropTypes.bool,
    green: PropTypes.bool,
    resultsPerPage: PropTypes.number,
    noPagination: PropTypes.bool,
    minimalPagination: PropTypes.bool,
    downloadExcel: PropTypes.func,
    downloadPDF: PropTypes.func,
    allowSearch: PropTypes.bool,
    hideSearchBar: PropTypes.bool,
    onCreateClick: PropTypes.func,
    search: PropTypes.string,
    searchAsFirstColumn: PropTypes.bool,
    changePagination: PropTypes.bool,
    selectedRowIds: PropTypes.array,
    onSort: PropTypes.func,
    lessPadding: PropTypes.bool,
    showTotals: PropTypes.bool,
    forceHover: PropTypes.arrayOf(PropTypes.number),
    useColgroup: PropTypes.bool,
    noResultsText: PropTypes.string,
    wrapLabels: PropTypes.bool,
};

SortableDataTable.defaultProps = {
    green: true,
    striped: true
};

export default SortableDataTable;
