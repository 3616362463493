import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import styles from "./ClinicTags.scss";
import includes from "lodash/includes";
import Button from "components/common/Button";
import TextBox from "components/common/TextBox";
import useFormHandlers from "utils/useFormHandlers";

const schema = Yup.object().shape({
	id: Yup.string().required(),
	description: Yup.string().required(),
});

export default function CreateTagForm(props) {
    const { formData, handleUpdate, invalidFields, isValid } = useFormHandlers(
        props.initialData,
        schema,
    );

    if (!props.canViewClinicManagement) {
        return null;
    }

    const handleCreateTag = (e) => {
		e.stopPropagation();
		e.preventDefault();
		if (isValid) {
			props.onSubmit(formData);
			handleUpdate([{
				name: "id",
				setToInitialState: true,
			}, {
				name: "description",
				setToInitialState: true,
			}]);
		}
    };

    return (
        <form className={styles.addTags} onSubmit={handleCreateTag}>
            <TextBox
                onChange={handleUpdate}
                name="id"
                value={formData.id}
                placeholder="Tag ID"
                hasError={includes(invalidFields, "id")}
            />
            <TextBox
                onChange={handleUpdate}
                name="description"
                value={formData.description}
                placeholder="Description"
                hasError={includes(invalidFields, "description")}
            />
            <Button
                className="flex-none margin-bottom-x-sm"
                disabled={!isValid}
                buttonType="submit"
            >
                Add New Tag
            </Button>
        </form>
    )
}


CreateTagForm.defaultProps = {
	canViewClinicManagement: false,
};

CreateTagForm.propTypes = {
	canViewClinicManagement: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
};
