import React, {useState} from "react";
import PropTypes, {object} from "prop-types";
import styles from "./ActivityGrid.scss";
import find from "lodash/find";
import map from "lodash/map";
import reject from "lodash/reject";
import DashboardTile from "components/dashboard/widgets/DashboardTile";
import Dropdown from "components/common/Dropdown";

function ActivityGrid(props) {
    const [currentRange, setCurrentRange] = useState("-30d")

    const renderTiles = () => {
        //TODO: Remove once we have real data - Keeps "Cats Covered" and "Dogs Defended" from showing for now
        const tiles = reject(props.tiles, (t) => {
            return(
                // If the user doesn't have Wellness Access then hide the wellness tile
                (!props.hasWellness && (t.id === "WellnessPlanVisits")) ||

                // Temporary (until CatsCovered and DogsDefended has real data
                ((t.id === "CatsCovered") || (t.id === "DogsDefended"))
            )
        })

        //  Once removed, update "tiles" to be "props.tiles"
        return map(tiles, tile => (renderTile(tile)));
    }

    const renderTile = (tile) => {
        let badgeInfo = null;
        const visitsPending = !props.stats ? 0 : find(props.stats, {id: "WellnessPending"}).value;
        const couponsPending = !props.stats ? 0 : find(props.stats, {id: "CouponsPending"}).value;

        if (tile.type === "coupons_pending"){
            badgeInfo = couponsPending ? `${couponsPending} pending` : null;
        } else if (tile.type === "wellness_visits") {
            badgeInfo = visitsPending ? `${visitsPending} pending` : null;
        }

        return (
            <DashboardTile
                key={tile.id}
                title={tile.title}
                badge={badgeInfo}
                largeValue={tile.value}
                label={tile.label||null}
                type={tile.type}
                timeRange={currentRange}
                hasDataSet={tile.hasDataSet}
            />

        );
    }

    const handleRangeChange = ({value}) => {
        setCurrentRange(value);
        props.handlePeriodChanged(value);
    }

    // TODO: Get list from User Settings - values might not even be correct
    const rangeOptions = [
        { name: "Last 30 days", value: "-30d"},
        { name: "Last 90 days", value: "-90d"},
        { name: "Last 6 months", value: "-6m"},
        { name: "Last 12 months", value: "-12m"},
        { name: "This year", value: "ytd"}
    ]

    return (
        <>
            <div className="sectionHeader">
                <h3>{props.title}</h3>
                {!!props.handlePeriodChanged && (
                    <Dropdown
                        name="currentRange"
                        onChange={handleRangeChange}
                        showOptions
                        small
                        value={currentRange}
                        options={rangeOptions}
                    />
                )}
            </div>
            <div className={styles.root}>
                {renderTiles()}
            </div>
        </>
    );
}

ActivityGrid.propTypes = {
    stats: PropTypes.arrayOf(object),
    handlePeriodChanged: PropTypes.func,
    title: PropTypes.string,
};

export default ActivityGrid;

