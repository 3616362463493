import request from "utils/request";
import getApiRoot from "./getApiRoot";

export function getWellnessPlans(clinicId, status=null, providerId=null) {
    //wellnessPlanStates = ["Active", "Cancelled", "Patient Could Not Be Found "]
    return request()
        .post(`${getApiRoot()}/v1/services/wellness/clinicpetplans/search/`)
        .send({
            period: "bot",
            limit: 999999,
            offset: 0,
            clinicId,
            wellnessPlanStates: status,
            providerId: providerId,
        });
}

export function updatePetPlan(clinicId, petId, patientPlanEnrollmentId, planPatientGreenlineId) {
    return request()
        .put(`${getApiRoot()}/v1/services/wellness/clinicpetplans/${petId}/`)
        .send({
            clinicId,
            patientPlanEnrollmentId,
            planPatientGreenlineId
        });
}

export function getWellnessDashboardTiles(clinicId) {
    return request()
        .get(`${getApiRoot()}/v1/services/wellness/clinics/${clinicId}/plans/dashboard/`)
        .send();
}
