import React, {useEffect, useState} from "react"
import {connect} from "react-redux";
import PropTypes from "prop-types";
import includes from "lodash/includes";
import map from "lodash/map";
import filter from "lodash/filter";
import find from "lodash/find";
import remove from "lodash/remove";
import AccessDenied from "components/common/AccessDenied";
import CheckboxInput from "components/common/CheckboxInput";
import GreenlineSupportPhoneNumber from "components/support/elements/GreenlineSupportPhoneNumber";
import NotificationButtons from "components/notifications/elements/NotificationButtons";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {getClinicInfo} from "utils/ClinicData";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import {
    DECLINED,
    ENROLLED,
    PENDING,
    PUPPY_GRADUATION_OPT_IN,
    MERCK_ADOBE_ONLY,
    ADOBE_SIGN_BASE_PATH,
    BI_ET_NON_CC_OPTIN
} from "constants/Notifications";
import * as UserPermissions from "constants/UserPermissions";
import ToggleSwitch from "../../common/ToggleSwitch";

function LapsedUserOptIn(props) {
    if(!props.canViewClinicCouponLibrary) {
        return (
            <AccessDenied
                customMessage="You do not have the correct permissions to access this information. Please contact Greenline for help resolving this issue."
            />
        );
    }
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(null);
    const [signed, setSigned] = useState(false);
    const [checkedGroups, setCheckedGroups] = useState([]);
    const [sendCommunicationToPetOwners, setSendCommunicationToPetOwners] = useState(null);

    useEffect(() => {
        if(props.enrollmentState === PENDING && !props.isPreview) {
            setLoading(true);
        }
    }, [props.enrollmentState]);

    useEffect(() => {
        if (props.selectedOfferGroups?.length) {
            setCheckedGroups(props.selectedOfferGroups);
        } else {
            const defaultSelectedGroups = filter(props.offerGroups||[], grp => grp.selected);
            if (defaultSelectedGroups.length) {
                setCheckedGroups([defaultSelectedGroups[0].id]);
                setChecked(map(defaultSelectedGroups[0].offerClooMap, ccloId => ccloId));
            }
        }
    }, [props.selectedOfferGroups, props.offerGroups]);

    const combinedData = checked ? checked : props.acceptedOffers;

    const selectedOffers = props.canSelectOffers ? combinedData : map(props.offers, "ccloId");
    const allRequired = signed && (!props.canSelectOffers || !!selectedOffers?.length) && (props.notification.notificationType !== BI_ET_NON_CC_OPTIN || sendCommunicationToPetOwners !== null);
    const isEnrolled = props.enrollmentState === ENROLLED;
    const isDeclined = props.enrollmentState === DECLINED;
    const isPending = props.enrollmentState === PENDING;


    const handleCheckedChange = ( clooId, value ) => {
        if(isPending || isDeclined) {
            let newChecked = [...combinedData];
            if (!!value) {
                newChecked.push(clooId);
            } else {
                remove(newChecked, c => c === clooId);
            }
            setChecked(newChecked);
        }
    };

    const handleDecline = async () => {
        // No event included
        props.onDecline();
    };

    const handleSubmit = async (e) => {
        // Form event included
        e.stopPropagation();
        e.preventDefault();
        let formFieldsJson = null;
        if (props.notification.notificationType === BI_ET_NON_CC_OPTIN) {
            formFieldsJson = {
                sendCommunicationToPetOwners: sendCommunicationToPetOwners,
            }
        }
        props.onSubmit(null, formFieldsJson, selectedOffers);
    };

    const eventHandler = (e) => {
        if(e.origin === ADOBE_SIGN_BASE_PATH){
            const type = JSON.parse(e.data)?.type;
            //Show the spinner until the document has loaded
            if(loading && (type === "PAGE_LOAD")){
                setLoading(false);
            }
            if(type === "ESIGN") {
                setSigned(true);
            }
        }
    }

    const handleOfferGroupClicked = (id, value) => {
        // Treat like radio buttons...
        const offerGroup = find(props.offerGroups, ["id", id]);
        if (value) {
            setCheckedGroups([id]);
            const groupOffers = map(offerGroup.offerClooMap, offerId => offerId);
            setChecked(groupOffers);
        } else {
            setCheckedGroups([]);
            setChecked(null);
        }
    };

    if(!window.addEventListener){
        window.attachEvent('onmessage', eventHandler);
    } else{
        window.addEventListener('message', eventHandler,false);
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="full-width full-height">
                <div>
                    {(isPending || isDeclined) && (
                        <div id="adobeSign" className="full-width">
                            {props.isPreview ? (
                                <div
                                    width="100%"
                                    height="100%"
                                    style={{border: 0, overflow: "hidden", minHeight: "800px", backgroundColor: "#AAAAAA"}}
                                    className={"flex flex-centered"}
                                >
                                    <h2>Adobe Sign Goes Here</h2>
                                </div>
                            ):(
                                <iframe
                                    src={props.notificationDetails?.controlParams?.embed}
                                    width="100%"
                                    height="100%"
                                    frameBorder="0"
                                    style={{border: 0, overflow: "hidden", minHeight: "800px"}}
                                />
                            )}

                        </div>
                    )}
                    <div className="margin-md">
                        {(props.canSelectOffers) && (
                            <>
                                <div>
                                    <div className="text-lg text-center">Available Programs</div>
                                    {isPending || isDeclined ? (
                                        <div className="text-center">Please select the products you would like to participate with Below</div>
                                    ) : (
                                        <div className="text-center">Selected product participation</div>
                                    )}
                                </div>
                                <div className="flex flex-centered">
                                    <div className="margin-top-sm">
                                        {props.offerGroups ? (
                                            map(props.offerGroups, grp => (
                                                <CheckboxInput
                                                    key={`group_${grp.id}`}
                                                    name="bsc"
                                                    label={grp.label}
                                                    checked={!!includes(checkedGroups, grp.id)}
                                                    onChange={({value}) => handleOfferGroupClicked(grp.id, value)}
                                                    large
                                                    disabled={!(isPending || isDeclined)}
                                                />
                                            ))
                                        ) : (
                                            map(props.offers, o => (
                                                <CheckboxInput
                                                    key={`offer_${o.ccloId}`}
                                                    name="bsc"
                                                    label={o.libraryTitle}
                                                    checked={!!includes(selectedOffers, o.ccloId)}
                                                    onChange={({value}) => handleCheckedChange(o.ccloId, value)}
                                                    large
                                                    disabled={!(isPending || isDeclined)}
                                                />
                                            ))
                                        )}
                                    </div>
                                </div>
                            </>
                        )}

                        {props.notification.notificationType === MERCK_ADOBE_ONLY && (
                            <div>
                                {(props.notification.notificationId === "Merck-2023-RefillReminders") ? (
                                        <div className="text-center text-bold">**To OPT-IN to the Refill Reminders Merck Email Campaign, click on the link below.**</div>
                                ) : (
                                    <>
                                        <div className="text-center"><b>If you want to participate in the {props.isPuppyGraduation ? "Puppy Graduation":  "Lapsed User"} Program, please Opt-In below!</b></div>
                                        <div className="text-center margin-top-sm">Your current programs can be viewed and edited in your Coupon Library.</div>
                                    </>
                                )}
                            </div>
                        )}
                        {props.notification.notificationType === BI_ET_NON_CC_OPTIN && (
                            <div className="flex flex-column flex-centered text-center">
                                <div>Please select the below options regarding email campaigns and finish opt-in.</div>
                                <div>
                                    <div className="margin-top-sm">Do you want email personalized offer communications to pet owners?</div>
                                    <div className="text-left padding-left-md">
                                        <CheckboxInput
                                            onChange={() => setSendCommunicationToPetOwners(true)}
                                            name="sendCommunicationToPetOwners"
                                            checked={sendCommunicationToPetOwners}
                                            label="Approve"
                                            circle
                                        />
                                        <CheckboxInput
                                            onChange={() => setSendCommunicationToPetOwners(false)}
                                            name="sendCommunicationToPetOwners"
                                            checked={sendCommunicationToPetOwners === false}
                                            label="Decline"
                                            circle
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="margin-top-md">
                            <div className="text-center">
                                {(props.notification.notificationType === PUPPY_GRADUATION_OPT_IN) ? (
                                    <span>If you would like assistance in creating the code or you have questions, <br/>please reach out to our support</span>
                                ) : "If you would like assistance or have questions, please reach out to our support"}
                                <br/>team by using the live chat at the bottom of your Greenline webpage<br />or <span>call <GreenlineSupportPhoneNumber clinicId={props.clinicId}/></span>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                {isEnrolled && <div className="text-center full-width">Opted in.</div>}
                {isDeclined && <div className="text-center full-width">Offer declined.</div>}
                {(isPending || isDeclined) && (
                    <div className="full-width margin-top-md">
                        {props.canSelectOffers && (<div className="text-center">All required fields must be filled in before accepting</div>)}
                        <div className="flex justify-center spaced-content margin-top-sm">
                            <NotificationButtons
                                notificationDetails={props.notificationDetails}
                                onDecline={handleDecline}
                                onDismiss={props.onDismiss}
                                enrollDisabled={!allRequired}
                                isPreview={props.isPreview}
                            />
                        </div>
                    </div>
                )}
            </div>
            <SpinnerTakeover show={loading}/>
        </form>
    );
}

LapsedUserOptIn.propTypes = {
    clinicId: PropTypes.number.isRequired,
    notification: PropTypes.object.isRequired,
    notificationDetails: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDecline: PropTypes.func.isRequired,
    onDismiss: PropTypes.func,
    enrollmentState: PropTypes.string.isRequired,
    acceptedOffers: PropTypes.array.isRequired,
    offers: PropTypes.array.isRequired,
    offerGroups: PropTypes.array,
    selectedOfferGroups: PropTypes.array,
    canSelectOffers: PropTypes.bool,
    isPreview: PropTypes.bool
}

LapsedUserOptIn.defaultProps = {
    canSelectOffers: false
}

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const clinicInfo = getClinicInfo(state, ownProps.clinicId);
        //Permissions
        const canViewClinicCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        let isPuppyGraduation = ownProps.notification?.notificationType === PUPPY_GRADUATION_OPT_IN;
        if (!isPuppyGraduation) {
            isPuppyGraduation = ownProps.notification?.notificationId === 'Merck-2023-MM-NEWPUPPY';
        }

        return {
            clinicInfo,
            canViewClinicCouponLibrary,
            programOffers: state.entities?.programOffers[8]?.offers,
            isPuppyGraduation,
        }
    }
)(LapsedUserOptIn);
