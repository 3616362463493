import {combineReducers} from "redux";
import entities from "./entities";
import applicationState from "./applicationState";
import user from "./user";
import wellness from "./wellness";

export default combineReducers({
    entities,
    applicationState,
    user,
    wellness,
});
